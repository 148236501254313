import { createEcsEvent } from '../../../configuration/createEvent';

const defaultProperties = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/custom-reports',
};

export const CustomReportsTabClickedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: 'custom_reports',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const IntuitAssistToggledEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_ia_toggle',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'intuit_assist',
    ui_action: 'toggled',
    ui_access_point: 'center',
  },
  {
    cr_ia_toggle: '',
  },
);

export const ImagineBarTextExecutedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_imagine_bar',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'generate_visualization',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    genos_text: '',
  },
);

export const ImagineBarSuggestionClickedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_imagine_bar_suggestions',
    action: 'engaged',
    ui_object: 'dropdown_item',
    ui_object_detail: 'imagine_bar_suggestions',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_suggestion_text: '',
  },
);

export const ImagineBarFiltersExecutedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_imagine_bar_filters',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'generate_visualization',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_metric_name: '',
    start_date: '',
    end_date: '',
    group_by: '',
  },
);

export const PreBuiltVisualizationClickedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_prebuilts',
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'visualization_description',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_metric_name: '',
  },
);

export const GeneratedSuccessfulVisualization = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization',
    action: 'succeeded',
    ui_object_detail: 'generate_visualization_success',
    ui_action: 'loaded',
  },
  {
    query_origin: '',
  },
);

export const GeneratedFailedVisualization = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_imagine_bar_failed_content',
    action: 'failed',
    ui_object_detail: 'generate_visualization_failure',
    ui_action: 'error_generated',
  },
  {
    cr_error_reasoning: '',
  },
);

export const SuccessfulGenerationModifiedAndExecutedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization_success_edit',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'modify',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_metric_name: '',
    start_date: '',
    end_date: '',
    group_by: '',
  },
);

export const PartiallySuccessfulGenerationModifiedAndExecutedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization_partialsuccess_edit',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'generate_visualization',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_metric_name: '',
    start_date: '',
    end_date: '',
    group_by: '',
  },
);

export const FailedGenerationModifiedAndExecutedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization_failed_edit',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'generate_visualization',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_metric_name: '',
    start_date: '',
    end_date: '',
    group_by: '',
  },
);

export const BadWordsModelViolatedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_error_flagged_content',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    cr_flagged_content: '',
  },
);

export const AnotherAvailableVisualizationSelectedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization_option',
    action: 'engaged',
    ui_object: 'dropdown_item',
    ui_object_detail: 'custom_reports_visualization_dropdown',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    cr_vchart_name: '',
  },
);

export const TemplateAPICallFailedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_error_template_api_response',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    cr_error_reasoning: '',
  },
);

export const CDPAPICallFailedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_error_cdp_api_response',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    cr_error_reasoning: '',
  },
);

export const MetricsReportVisualizerTabViewed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_metrics_visualizer',
    action: 'viewed',
    ui_object: 'tab',
    ui_object_detail: 'metrics_visualizer',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const MetricsVisualizerThumbsUpEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'thumbs_up',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const MetricsVisualizerThumbsDownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: 'analytics/custom-reports',
    object: 'reporting',
    object_detail: 'custom_reports_visualization',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'thumbs_down',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const downloadedReportEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'downloaded',
    ui_object: 'button',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const deleteReportEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'deleted',
    ui_object: 'dropdown_item',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const viewedNextEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'viewed',
    ui_object: 'arrow',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const paywallStartedEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'checkout',
    object_detail: 'upgrade_trigger_custom_reports',
    action: 'started',
    ui_object: 'popup',
    ui_object_detail: '',
    ui_action: 'viewed',
    ui_access_point: 'center',
  },
  {},
);

export const paywallClosedEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'checkout',
    object_detail: 'account_payment',
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const paywallCompleteEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'checkout',
    object_detail: 'account_payment',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'pay_now',
    ui_action: 'pay_now',
    ui_access_point: 'card_section_right',
    user_package_plan_selected: 'standard',
  },
  {},
);

export const editEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'engaged',
    ui_object: 'combo_button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const rerunEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'engaged',
    ui_object: 'combo_button',
    ui_object_detail: 'rerun',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const viewEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports',
    action: 'engaged',
    ui_object: 'combo_button',
    ui_object_detail: 'view_details_&_history',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const viewGraphEvent = createEcsEvent(
  {
    ...defaultProperties,
    object: 'reporting',
    object_detail: 'custom_reports_graph',
    action: 'navigated',
    ui_object: 'screen',
    ui_object_detail: '',
    ui_action: 'visited',
    ui_access_point: '',
  },
  {},
);
