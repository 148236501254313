import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a  user lands on profile page."
 */
export const ProfileLandedEvent = createEcsEvent(
  /**
   * This param contains the profile0Event the event will contain.
   * These properties can never be changed inside of the profile0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'user_profile',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks  on continue button."
 */
export const ProfileContinueEvent = createEcsEvent(
  /**
   * This param contains the profile1Event the event will contain.
   * These properties can never be changed inside of the profile1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'user_profile',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    consent_phone_messaging: '',
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
/**
 * "This event fires when a user clicks on back icon"
 */
export const ProfileBackEvent = createEcsEvent(
  /**
   * This param contains the profile2Event the event will contain.
   * These properties can never be changed inside of the profile2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'user_profile',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'back',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
/**
 * "This event fires when the user is not able to go to next screen."
 */
export const ProfileLoadErrorEvent = createEcsEvent(
  /**
   * This param contains the profile3Event the event will contain.
   * These properties can never be changed inside of the profile3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'user_profile',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { error: '', entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
