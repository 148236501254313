import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'campaign_manager',
  object: 'automations',
};

export const CreateCampaignEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the CreateCampaignEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaign-manager',
    object_detail: 'create_campaign',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_campaign',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const CampaignListTabEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the CampaignListTabEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaign-manager',
    object_detail: 'campaigns_list_view',
    action: 'navigated',
    ui_object: 'link',
    ui_object_detail: 'campaigns_list',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
