import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'onboarding_checklist',
  screen: '/',
  object: 'segment',
};

export const homepageCreatePBSEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'homepage_create_segment',
    action: 'engaged',
    ui_object: 'checklist',
    ui_object_detail: 'choose_a_pre_built_segment',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {
    pbs_used: '',
    funnel_id: '',
  },
);
