import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'internationalization',
  object: 'account',
  action: 'engaged',
  ui_action: 'clicked',
  ui_object_detail: 'language_selector',
};

export const AvatarMenuI18nEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'global_nav',
    object_detail: 'language_preference_l1',
    ui_object: 'sub_menu',
    ui_access_point: 'header',
  },
  {
    screen: '',
    inapp_lang_preference: '',
  },
);

export const GtkmI18nEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'account_setup',
    screen: 'signup/setup',
    ui_object: 'dropdown',
    ui_access_point: 'footer',
  },
  {
    object_detail: '',
    onboarding_lang_preference: '',
  },
);
