const Events = {
  Audience: {
    Completed: 'audience:completed',
    Engaged: 'audience:engaged',
    Failed: 'audience:failed',
    Started: 'audience:started',
    Selected: 'audience:selected',
    Viewed: 'audience:viewed',
  },
};

const ObjectDetails = {
  AddCustomer: 'add_customer',
  CreatedPipeline: 'create_pipeline',
  ViewPipeline: 'view_pipeline',
  JobSettings: 'job_settings',
  JobStatus: 'job_status',
  PipelineSetup: 'pipeline_setup',
  PipelineStage: 'pipeline_stage',
  PipelineValue: 'pipeline_value',
};

const Action = {
  Started: 'started',
  Engaged: 'engaged',
  Completed: 'completed',
  Viewed: 'viewed',
  Selected: 'selected',
  Failed: 'failed',
};

const UIObject = {
  Button: 'button',
  Card: 'card',
  Link: 'link',
  Tab: 'tab',
  TextField: 'text_field',
  Dropdown: 'dropdown',
  Field: 'field',
  RadioButton: 'radio_button',
};

const UIAction = {
  Clicked: 'clicked',
  Deleted: 'deleted',
  Dragged: 'dragged',
  Started: 'started',
};

const UIAccessPoint = {
  Header: 'header',
  HeaderContentPane: 'header_content_pane',
  Center: 'center',
  FixedBottomCenter: 'fixed_bottom_center',
  StageHeader: 'stage_header',
  Modal: 'modal',
  FixedUpperRight: 'fixed_upper_right',
  QuickAction: 'quick_action',
  StageContentPane: 'stage_content_pane',
  MenuShortcuts: 'menu_shortcuts',
  HeadlinerContentPane: 'headerliner_content_pane',
};

export { Action, Events, ObjectDetails, UIAccessPoint, UIAction, UIObject };
