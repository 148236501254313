import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_CAMPAIGN_NAME_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',

  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'campaign_name',

  action: 'engaged',

  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: 'main_content_pane',
};

/**
 * This event fires when a user clicks the "Save" button from the Campaigns Checklist page after editing a campaign name.
 */
export const CompleteUpdateCampaignNameEvent = createEcsEvent(
  {
    ...DEFAULT_CAMPAIGN_NAME_PROPERTIES,
    ui_object_detail: 'save',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

/**
 * This event fires when a user clicks the "Save" button from the Campaigns Checklist page after editing a campaign name.
 */
export const BeginUpdateCampaignNameEvent = createEcsEvent(
  {
    ...DEFAULT_CAMPAIGN_NAME_PROPERTIES,
    ui_object_detail: 'edit_name',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);
