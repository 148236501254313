import { createEcsEvent } from '../../configuration/createEvent';

export const ModalOpenedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'human_assistance',
    scope_area: 'support',
    object: 'content',
    object_detail: 'live_help_modal',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: '',
    ui_action: 'viewed',
    ui_access_point: 'modal',
  },
  {
    screen: '',
  },
);
