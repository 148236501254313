import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'reporting',
  object_detail: 'sms_report',
  ui_access_point: 'performance_metrics_datawell',
  screen: '/sms',
  action: 'engaged',
};

export const SmsDataWellEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the SmsDataWellToggleEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    ui_object: 'datawell',
    ui_action: 'toggled',
    ui_object_detail: '',
  },
);

export const SmsPastMonthDataWellHoverEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tooltip',
    ui_action: 'hovered',
    ui_object_detail: 'metric_description',
  },
  {
    metric_name_past_month: '',
  },
);

export const SmsLastMessageDataWellHoverEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tooltip',
    ui_action: 'hovered',
    ui_object_detail: 'metric_description',
  },
  {
    metric_name_last_message: '',
  },
);
