import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user lands on the Connect Accounts page.  "
 */
export const ConnectAccountsLandedEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts0Event the event will contain.
   * These properties can never be changed inside of the connectAccounts0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks the Subscribed radio button. "
 */
export const ConnectAccountsSubscribedRadioEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts1Event the event will contain.
   * These properties can never be changed inside of the connectAccounts1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'subscribed',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks the non-subscribed radio button. "
 */
export const ConnectAccountsNonSubscribedRadioEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts2Event the event will contain.
   * These properties can never be changed inside of the connectAccounts2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'non-subscribed',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks the connect account button."
 */
export const ConnectAccountsConnectEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts3Event the event will contain.
   * These properties can never be changed inside of the connectAccounts3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_accounts',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks continue without connecting button. "
 */
export const ConnectAccountsContinueWithoutConnectingEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts4Event the event will contain.
   * These properties can never be changed inside of the connectAccounts4Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'continue_without_connecting',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks the back button."
 */
export const ConnectAccountsBackEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts5Event the event will contain.
   * These properties can never be changed inside of the connectAccounts5Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'back',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    current_step: '',
    previous_step: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
/**
 * "This event fires when the Connect Accounts page fails to load"
 */
export const ConnectAccountsPageLoadErrorEvent = createEcsEvent(
  /**
   * This param contains the connectAccounts6Event the event will contain.
   * These properties can never be changed inside of the connectAccounts6Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_setup',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { error: '', entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
