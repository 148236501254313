import {
  ECS_EVENT_BUILDER_SCREEN,
  ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL,
  ECS_EVENT_ACTION_PROPERTY_ENGAGED,
} from '@mc/constants/automation';
import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
  object: 'automations',
};

export const ExploreAiJourneysEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/explore',
    object_detail: 'automate_this_email',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'explore_ai_journeys',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const ContinueToJourneyEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_a_store',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_journey',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    pbj_type: '',
  },
);

export const ConnectMyStoreEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_a_store',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_my_store',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    pbj_type: '',
  },
);

export const CloseModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_a_store',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    pbj_type: '',
  },
);

export const EmailGenBeginEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'prebuilt_journey',
    action: 'created',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    journey_id: 0,
    pbj_type: '',
    journey_created_from_genai: false,
    ai_email_type: '',
    intuit_tid: '',
    skipped_genai: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const AiPbjGetStartedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey',
    object_detail: 'prebuilt_journey',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'banner',
  },
  {
    ai_email_type: '',
    journey_created_from_genai: false,
  },
);

export const RequestGenAISubjectEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: ECS_EVENT_BUILDER_SCREEN,
    object_detail: ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL,
    action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    intuit_tid: '',
    ai_custom_prompt: '',
    generated_text: '',
    ai_tone_selected: '',
    ai_email_type: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const ClickRequestGenAISubjectEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: ECS_EVENT_BUILDER_SCREEN,
    object_detail: ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL,
    action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    ai_custom_prompt: '',
    ai_tone_selected: '',
    ai_email_type: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
    ai_options_presented: '',
    ai_use_case: '',
    content_type: '',
    is_replicated_from_journey: false,
  },
);

export const ApplyGenAiSubjectEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: ECS_EVENT_BUILDER_SCREEN,
    object_detail: ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL,
    action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    intuit_tid: '',
    ai_custom_prompt: '',
    generated_text: '',
    ai_tone_selected: '',
    ai_email_type: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
    generated_preview_text: '',
    ai_options_pos: 1,
    ai_options_presented: 1,
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    generation_attempt_count: 1,
    content_type: '',
    is_replicated_from_journey: false,
  },
);

export const AiPbjClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'prebuilt_journey',
    ui_object: 'card',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { is_genai_related: false, screen: '', pbj_type: '', ai_email_type: '' },
);

export const ClickTryAgainAIAssistantEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'journey_map_step',
    screen: ECS_EVENT_BUILDER_SCREEN,
    ui_object: 'button',
    ui_object_detail: 'try_again',
    ui_action: 'clicked',
    ui_access_point: 'cjb_email_checklist_subject_line',
  },
  {
    ai_custom_prompt: '',
    ai_email_type: '',
    ai_options_presented: 1,
    ai_tone_selected: '',
    ai_use_case: '',
    is_replicated_from_journey: false,
    journey_id: 0,
    journey_created_from_genai: false,
  },
);

export const ClickDismissAIAssistantEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'journey_map_step',
    screen: ECS_EVENT_BUILDER_SCREEN,
    ui_object: 'button',
    ui_object_detail: 'discard',
    ui_action: 'clicked',
    ui_access_point: 'cjb_email_checklist_subject_line',
  },
  {
    ai_custom_prompt: '',
    ai_email_type: '',
    ai_options_presented: 1,
    ai_tone_selected: '',
    ai_use_case: '',
    intuit_tid: '',
    is_replicated_from_journey: false,
    journey_id: 0,
    journey_created_from_genai: false,
  },
);
export const ClickWriteWithAIAssistantEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'journey_map_step',
    screen: ECS_EVENT_BUILDER_SCREEN,
    ui_object: 'link',
    ui_object_detail: 'write_with_ai',
    ui_action: 'clicked',
    ui_access_point: 'cjb_email_checklist_subject_line',
    ai_button_exists: 'y',
    content_type: 'subject_line',
  },
  {
    ai_email_type: '',
    is_replicated_from_journey: false,
    journey_id: 0,
  },
);

export const ClickEditWithAIAssistantEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'journey_map_step',
    screen: ECS_EVENT_BUILDER_SCREEN,
    ui_object: 'link',
    ui_object_detail: 'rewrite_with_ai',
    ui_action: 'clicked',
    ui_access_point: 'cjb_email_checklist_subject_line',
    ai_button_exists: 'y',
    content_type: 'subject_line',
  },
  {
    ai_email_type: '',
    is_replicated_from_journey: false,
    journey_id: 0,
  },
);
export const BeginUpdateSubjectEventNuni = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'customer_journey_builder',
    screen: 'customer-journey/builder',
    object: 'automations',
    object_detail: 'journey_map_step',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'cjb_email_checklist_subject_line',
  },
  {
    journey_id: 0,
    ai_email_type: '',
    is_replicated_from_journey: false,
    created_from_source: '',
    ai_button_exists: true,
    has_ai_post_trial_reminder: false,
  },
);

const BASE_CJB_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  screen: 'customer-journey/builder',
  object: 'checkout',
  object_detail: 'genai_monetization',
  action: 'engaged',
  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: 'ai_trial_reminder_toolbar',
};

export const ClickUpgradePostTrialReminderEvent = createEcsEvent(
  {
    ...BASE_CJB_PROPERTIES,
    ui_object_detail: 'upgrade_to_keep_ai_tools',
  },
  {
    campaign_id: 0,
    checklist_step_state: '',
    editor_type: '',
    journey_id: 0,
  },
);

export const DismissPostTrialReminderEvent = createEcsEvent(
  {
    ...BASE_CJB_PROPERTIES,
    ui_object_detail: 'dismiss',
  },
  {
    campaign_id: 0,
    checklist_step_state: '',
    editor_type: '',
    journey_id: 0,
  },
);

// event for when user clicks thumbs up icon
export const submitPositiveFeedbackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'pbj_review_flow',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'thumbs_up',
    ui_action: 'clicked',
    ui_access_point: 'feedback_modal',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: true,
    is_replicated_from_journey: false,
  },
);

// event for when user clicks thumbs down icon
export const clickThumbsDownFeedbackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'pbj_review_flow',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'thumbs_down',
    ui_action: 'clicked',
    ui_access_point: 'feedback_modal',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: true,
    is_replicated_from_journey: false,
  },
);

// event for when user submits negative feedback
export const submitNegativeFeedbackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'pbj_review_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'submit',
    ui_action: 'clicked',
    ui_access_point: 'feedback_modal',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
    feedback_response_type: [],
    feedback_text: '',
  },
);
