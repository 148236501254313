import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const BorderStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'text_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'selected',
    ui_access_point: 'side_panel',
    ui_object_detail: 'text_border_style',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
    nuni_text_border_style: '',
  },
);

export const TemplateBorderStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'text_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'selected',
    ui_access_point: 'side_panel',
    ui_object_detail: 'text_border_style',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
    nuni_text_border_style: '',
  },
);

export const BorderWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'text_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'text_border_width',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
    nuni_text_border_width: 0,
  },
);

export const TemplateBorderWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'text_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'text_border_width',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
    nuni_text_border_width: 0,
  },
);
