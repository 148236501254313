import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'account',
};

export const SmsDeactivationSkipButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'why_you_want_to_deactivate_sms_modal',
  },
  {},
);

export const SmsDeactivationSubmitButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'submit',
    ui_action: 'clicked',
    ui_access_point: 'why_you_want_to_deactivate_sms_modal',
  },
  {
    sms_deactivate_survey_answer_selected: '',
    sms_deactivate_survey_custom_feedback: '',
  },
);

export const SmsDeactivationCloseIconEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'why_you_want_to_deactivate_sms_modal',
  },
  {},
);

export const SmsBillingPlansDeactivatedContactCustomerSupport = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'customer_support',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SmsBillingPlansReactivateButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_reactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reactivate_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SmsCardDeactivationPlanButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'deactivate_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SmsBillingPlansDeactivationConfirmationModalX = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'deactivate_your_sms_plan_modal',
  },
  {},
);

export const SmsBillingPlansDeactivationConfirmationModalCS = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'contact_customer_support',
    ui_action: 'clicked',
    ui_access_point: 'deactivate_your_sms_plan_modal',
  },
  {},
);

export const SmsBillingPlansDeactivationConfirmationModalPrimaryButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/billing/plans',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'deactivate',
    ui_action: 'clicked',
    ui_access_point: 'deactivate_your_sms_plan_modal',
  },
  {},
);
