import { Action, UIAccessPoint, UIAction, UIObject } from './constants';
import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'pipeline',
  screen: 'business-pipelines/board',
  object: 'audience',
  object_detail: 'job_settings',
  action: Action.Engaged,
};

// fires when a user clicks '+' button to add a product or service
export const AddProductOrServiceClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_action: UIAction.Clicked,
    ui_access_point: UIAccessPoint.Modal,
    ui_object: UIObject.Button,
    ui_object_detail: 'add_a_product_or_service',
  },
  {
    pipeline_id: -1,
    pipeline_job_id: -1,
    contact_id: '',
  },
);

// fires when a user selects a product or service from the dropdown
export const ProductOrServiceSelectClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_action: UIAction.Clicked,
    ui_access_point: UIAccessPoint.Modal,
    ui_object: UIObject.Dropdown,
    ui_object_detail: 'select_a_product_or_service',
  },
  {
    pipeline_id: -1,
    pipeline_job_id: -1,
    contact_id: '',
  },
);

// fires when a user clicks '-' button on one of their selected products/services
export const RemoveProductOrServiceClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_action: UIAction.Clicked,
    ui_access_point: UIAccessPoint.Modal,
    ui_object: UIObject.Button,
    ui_object_detail: 'remove_a_product_or_service',
  },
  {
    pipeline_id: -1,
    pipeline_job_id: -1,
    contact_id: '',
  },
);

// fires when a user clicks *out of* the quantity input next to one of their products/services
export const ProductOrServiceQuantityClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'business-pipelines/board',
    ui_action: UIAction.Clicked,
    ui_access_point: UIAccessPoint.Modal,
    ui_object: UIObject.Field,
    ui_object_detail: 'quantity_selected',
  },
  {
    pipeline_id: -1,
    pipeline_job_id: -1,
    contact_id: '',
  },
);

// fires when a user clicks the 'Open Estimate' input field
export const OpenEstimateClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_action: UIAction.Clicked,
    ui_access_point: UIAccessPoint.Modal,
    ui_object: UIObject.Field,
    ui_object_detail: 'open_estimate',
  },
  {
    pipeline_id: -1,
    pipeline_job_id: -1,
    contact_id: '',
  },
);
