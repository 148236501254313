import { createEcsEvent } from '../../configuration/createEvent';

// ECS Events Source: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=632572981&range=A411

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'appointment',
  screen: 'crm/appointments/',
  object: 'audience',
};

export const AppointmentsOnboardingModalViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'onboarding_modal',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const AppointmentsWelcomeViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'welcome_page',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    gmail_connections_count: -1,
  },
);

export const AppointmentsWelcomeGmailConnectClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'welcome_page',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'gmail_connect',
    ui_action: 'clicked',
    ui_access_point: 'card',
  },
  {},
);

export const AppointmentsWelcomeSetAvailabilityClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'welcome_page',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'availability',
    ui_action: 'clicked',
    ui_access_point: 'card',
  },
  {},
);

export const AppointmentsStartSchedulingViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'start_scheduling',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const AppointmentsStartSchedulingClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'start_scheduling',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'schedule_appointment',
    ui_action: 'clicked',
    ui_access_point: 'card',
  },
  {},
);
