import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user lands on 'payment confirmation page'"
 */
export const PaymentConfirmationLandedEvent = createEcsEvent(
  /**
   * This param contains the paymentConfirmation0Event the event will contain.
   * These properties can never be changed inside of the paymentConfirmation0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'payment_confirmation',
    action: 'engaged',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'checkout',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on close button"
 */
export const PaymentConfirmationCloseEvent = createEcsEvent(
  /**
   * This param contains the paymentConfirmation1Event the event will contain.
   * These properties can never be changed inside of the paymentConfirmation1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'payment_confirmation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'checkout',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on 'Login to Mailchimp button'"
 */
export const PaymentConfirmationLoginEvent = createEcsEvent(
  /**
   * This param contains the paymentConfirmation2Event the event will contain.
   * These properties can never be changed inside of the paymentConfirmation2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'payment_confirmation',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'login_mailchimp',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user fails to load the Confirmation Page"
 */
export const PaymentConfirmationPageLoadErrorEvent = createEcsEvent(
  /**
   * This param contains the paymentConfirmation3Event the event will contain.
   * These properties can never be changed inside of the paymentConfirmation3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'payment_confirmation',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { error: '', qb_realm_id: '', qb_pseudonym_id: '', entry_point: '' },
);
