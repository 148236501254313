import { createEcsEvent } from '../../../configuration/createEvent';

const EMAIL_CHECKLIST_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'campaign_subject',
  action: 'engaged',
};

export const TrackCompleteSendCampaignEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,

    object_detail: 'send_campaign',
    action: 'completed',

    ui_object: 'button',
    ui_object_detail: 'send_now',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: '',
    has_ai_assistant_contents: '',
    has_ai_content_generator_contents: '',
    has_ai_visual_branding: '',
    ai_email_type: '',
    generated_content_intuit_tids: '',
    intuit_tid: '',
    image_intuit_tids: '',
    segment_id: '',
    segment_send_to: '',
    segment_do_not_send_to: '',
  },
);
