import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const CampaignAdjustProductBlockImageAlignmentEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'product_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'adjusted',
    ui_access_point: 'side_panel',
    adjusted_style: 'image-alignement',
    ui_object_detail: 'product_styles',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    platform_type: '',
    store_ids: [],
    product_ids: [],
  },
);

export const TemplateAdjustProductBlockImageAlignmentEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'product_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'adjusted',
    ui_access_point: 'side_panel',
    adjusted_style: 'image-alignement',
    ui_object_detail: 'product_styles',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    store_ids: [],
    product_ids: [],
  },
);

export const ImageBorderStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'selected',
    ui_access_point: 'side_panel',
    ui_object_detail: 'image_border_style',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
    nuni_image_border_style: '',
  },
);

export const ImageTemplateBorderStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'selected',
    ui_access_point: 'side_panel',
    ui_object_detail: 'image_border_style',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
    nuni_image_border_style: '',
  },
);

export const ImageBorderWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'image_border_width',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
    nuni_image_border_width: 0,
  },
);

export const ImageTemplateBorderWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'image_border_width',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
    nuni_image_border_width: 0,
  },
);

export const BorderRadiusEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'image_border_radius',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
    nuni_image_border_radius: 0,
  },
);

export const TemplateBorderRadiusEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'image_border_radius',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
    nuni_image_border_radius: 0,
  },
);

export const EditImageEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
  },
);

export const RemoveImageEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
  },
);

export const SizeImageEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    ui_object_detail: 'original',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
  },
);

export const AlignImageEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'alignment_toggle_side_panel',
  },
  {
    ui_object_detail: 'original',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
  },
);

export const UploadImageEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'dropdown_list',
    ui_action: 'clicked',
    ui_access_point: 'replace_dropdown_menu',
    ui_object_detail: 'upload_image',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
  },
);

export const BrowseImagesEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'image_content',
    action: 'customized',
    ui_object: 'dropdown_list',
    ui_action: 'clicked',
    ui_access_point: 'replace_dropdown_menu',
    ui_object_detail: 'browse_image',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'image',
  },
);
