import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_editor',

  object: 'campaign',
  object_detail: 'dynamic_content',
  action: 'customized',

  editor_type: 'nuni',
};

export const DynamicContentSidePanelButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'dynamic_content',
    ui_action: 'clicked',
    ui_access_point: 'dynamic_content_settings_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const DynamicContentWhyUseAccordionClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'accordion',
    ui_object_detail: 'why_use_dynamic_content',
    ui_action: 'clicked',
    ui_access_point: 'dynamic_content_settings_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const DynamicContentKbArticleLinkClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'link',
    ui_object_detail: 'read_about_dynamic_content',
    ui_action: 'clicked',
    ui_access_point: 'dynamic_content_accordion',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const DynamicContentResetButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'reset',
    ui_action: 'clicked',
  },
  {
    screen: '',

    ui_access_point: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const DynamicContentSaveButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
  },
  {
    screen: '',

    ui_access_point: '',

    campaign_id: 0,
    campaign_type: '',
    dynamic_content_rule_count: 0,
    dynamic_content_rule_options: [],
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const DynamicContentConfirmButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'confirm',
    ui_action: 'clicked',
  },
  {
    screen: '',

    ui_access_point: '',

    campaign_id: 0,
    campaign_type: '',
    dynamic_content_rule_count: 0,
    dynamic_content_rule_options: [],
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const DynamicContentEditButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
  },
  {
    screen: '',

    ui_access_point: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

// Visibility Panel Events

export const VisibilityTabClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'tab',
    ui_object_detail: 'visibility',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
    user_template_id: 0,
  },
);

export const VisibilityContentToggleEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'radio_button',
    ui_object_detail: 'choose_who_should_see_this_content',
    ui_action: 'clicked',
    ui_access_point: 'visibility_tab_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
    dynamic_content_option: '',
  },
);

export const VisibilityResetButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'reset',
    ui_action: 'clicked',
    ui_access_point: 'visibility_tab_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const VisibilitySaveButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'visibility_tab_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
    dynamic_content_rule_count: 0,
    dynamic_content_rule_options: [],
  },
);

export const AddAnotherRuleButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'add_another_rule',
    ui_action: 'clicked',
    ui_access_point: 'visibility_tab_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const RemoveRuleButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'icon',
    ui_object_detail: 'trash',
    ui_action: 'clicked',
    ui_access_point: 'visibility_tab_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);

export const EditButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'visibility_tab_side_panel',
  },
  {
    screen: '',

    campaign_id: 0,
    campaign_type: '',
    is_dynamic_content_enabled: false,
    nuni_section: '',
    template_id: 0,
  },
);
