import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'settings_and_tracking',
};

const CONFIGURABLE_PROPERTIES = {
  campaign_id: 0,
};

export const TrackSettingsBeginUpdateEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const TrackSettingsCancelUpdateEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);
