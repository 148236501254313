import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'tasks',
  screen: 'crm/global-tasks',
  object: 'audience',
  object_detail: 'task_workflow',
};

export const GlobalTaskListOpened = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);

export const TaskCreatedTopRight = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add_task',
    ui_action: 'clicked',
    ui_access_point: 'fixed_upper_right',
  },
  {
    pipeline_job_id: -1,
    pipeline_id: -1,
    contact_id: '',
  },
);

export const TaskCreatedCenter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add_task',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    pipeline_job_id: -1,
    pipeline_id: -1,
    contact_id: '',
  },
);

export const TaskSaved = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    task_name: '',
    task_id: '',
    task_due_date: '',
    task_description: false,
    contact_id: '',
  },
);

export const TaskEdited = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'upper_right',
  },
  {
    task_name: '',
    task_id: '',
    task_due_date: '',
    contact_id: '',
    pipeline_job_id: -1,
    pipeline_id: -1,
  },
);

export const DueDateChosen = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'due_date',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    task_name: '',
    task_id: '',
    task_due_date: '',
    contact_id: '',
    pipeline_job_id: -1,
    pipeline_id: -1,
  },
);

export const TaskCompleted = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'task_done',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {
    task_name: '',
    task_id: '',
    pipeline_job_id: -1,
    task_due_date: '',
    pipeline_id: -1,
    contact_id: '',
  },
);

export const TaskDeleted = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'upper_right',
  },
  {
    task_name: '',
    task_id: '',
    task_due_date: '',
    contact_id: '',
    pipeline_job_id: -1,
    pipeline_id: -1,
  },
);

export const JobRelationshipCreated = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'related_to_job',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    task_id: '',
    pipeline_job_id: -1,
    task_due_date: '',
    task_name: '',
    pipeline_id: -1,
    contact_id: '',
  },
);

export const JobLinked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'choose_job',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    task_id: '',
    pipeline_job_id: -1,
    task_due_date: '',
    task_name: '',
    pipeline_id: -1,
    contact_id: '',
  },
);

export const PersonalTaskRelationshipCreated = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'personal_task',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    task_id: '',
    task_due_date: '',
    task_name: '',
    contact_id: '',
  },
);

export const taskCompletedFilter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'completed',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const taskSortingDueDate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'sort_by_due_date',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const taskSortingCustom = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'sort_by_custom',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AddTaskEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_task',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);
