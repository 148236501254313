import { createEcsEvent } from '../../configuration/createEvent';

const NEA_DEFAULT_PROPERTIES = {
  purpose: 'prod',
  scope_area: 'smart_content_generation',
  initiative_name: 'bulk_email',
  screen: 'campaigns/wizard/neapolitan',
  object: 'campaign',
  object_detail: 'add_content',
  action: 'engaged',
};

const NUNI_DEFAULT_PROPERTIES = {
  purpose: 'prod',
  scope_area: 'smart_content_generation',
  initiative_name: 'core_offerings',
  screen: 'email/editor',
  object: 'campaign',
  object_detail: '',
  action: 'engaged',
  editor_type: 'nuni',
};

export const NeaWriteWithAiEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'write_with_ai',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: 'paragraph',
    ai_email_type: '',
    block_type: '',
  },
);

export const RejectTermsEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'content_generation',

    ui_object: 'button',
    ui_object_detail: 'maybe_later',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: 'paragraph',
    ai_email_type: '',
    block_type: '',
  },
);

export const SelectToneEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'dropdown',
    ui_object_detail: 'tone_selector',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    ai_tone_selected: '',
    campaign_id: '',
    campaign_type: '',
    content_type: 'paragraph',
    ai_email_type: '',
    block_type: '',
  },
);

export const PromptStarterEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'prompt_starter',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    prompt_starter: '',
    campaign_id: '',
    campaign_type: '',
    content_type: 'paragraph',
    ai_email_type: '',
  },
);

export const GenerateEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'add_content',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'generate',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_custom_prompt: '',
    generated_text: '',
    intuit_tid: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    ai_email_type: '',
    campaign_type: '',
    block_type: 'text',
  },
);

export const InsertContentEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'insert_content',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    generated_text: '',
    ai_email_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    generation_attempt_count: 1,
    added_to_existing_block_or_new_created: 'existing',
    campaign_type: '',
  },
);

export const ApplyEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'apply',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    generated_text: '',
    ai_email_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    generation_attempt_count: 1,
    added_to_existing_block_or_new_created: 'existing',
    campaign_type: '',
  },
);

export const TryAgainEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'content_generation',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'try_again',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    regenerated_text: '',
    intuit_tid: '',
    content_type: 'paragraph',
    campaign_type: '',
  },
);

export const ShortenEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'shorten',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    campaign_type: '',
    block_type: '',
  },
);

export const LengthenEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'lengthen',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    campaign_type: '',
    block_type: '',
  },
);

export const ChangeToneEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'change_tone',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    ai_tone_selected: '',
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    campaign_type: '',
    block_type: '',
  },
);

export const CorrectSpellingGrammarEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'correct_spelling_grammar',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    campaign_type: '',
    block_type: '',
  },
);

export const CustomPromptEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'custom',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    campaign_type: '',
    block_type: '',
  },
);

export const ApplyTextBottomEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'apply_text',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    campaign_type: '',
    block_type: 'text',
  },
);

export const ShareFeedbackEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'feedback',

    ui_object: 'button',
    ui_object_detail: 'share_feedback',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    content_type: 'paragraph',
    campaign_type: '',
  },
);

export const ViewFeedbackModalEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'feedback',
    action: 'viewed',

    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    campaign_type: '',
    block_type: '',
  },
);

export const SubmitFeedbackModalEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'feedback',

    ui_object: 'button',
    ui_object_detail: 'submit',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    campaign_type: '',
    block_type: '',
    nea_inline_feedback: '',
  },
);

export const InsertBottomEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'insert',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    campaign_type: '',
    block_type: '',
  },
);

export const CancelBottomEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    campaign_type: '',
    block_type: '',
  },
);

export const RewriteWithAIEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'rewrite_with_ai',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    campaign_id: '',
    content_type: 'paragraph',
    ai_email_type: '',
    campaign_type: '',
    block_type: '',
  },
);

export const TrackInlineAIFeedbackEvents = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    action: 'engaged',
    object: 'campaign',
    object_detail: 'ai_feedback',
    ui_access_point: 'in_line_ai_editor',
    ui_object: 'button',
    ui_action: 'clicked',
    generated_text: '',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    intuit_tid: '',
    ui_object_detail: '',
  },
);

export const TrackNeaInlineAIFeedbackEvents = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    action: 'engaged',
    editor_type: 'nea',
    scope_area: 'smart_content_generation',
    object_detail: 'ai_feedback',
    ui_access_point: 'in_line_ai_editor',
    ui_object: 'button',
    ui_action: 'clicked',
    generated_text: '',
    entrypoint_detail: 'bottom_bar',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    intuit_tid: '',
    ui_object_detail: '',
  },
);

export const TrackNeaInlineAIFeedbackModalEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    action: 'engaged',
    editor_type: 'nea',
    scope_area: 'smart_content_generation',
    object_detail: 'ai_feedback',
    ui_access_point: 'in_line_ai_modal',
    generated_text: '',
    ui_action: 'clicked',
    ui_object: 'button',
    entrypoint_detail: 'custom_prompt_bar',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    feedback_text: '',
    feedback_response_choice: [],
    intuit_tid: '',
    ui_object_detail: '',
  },
);

export const TrackNeaInlineAIFeedbackModalCloseEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    action: 'engaged',
    editor_type: 'nea',
    scope_area: 'smart_content_generation',
    object_detail: 'ai_feedback',
    ui_access_point: 'in_line_ai_modal',
    generated_text: '',
    ui_action: 'clicked',
    ui_object: 'button',
    entrypoint_detail: 'custom_prompt_bar',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    intuit_tid: '',
    ui_object_detail: '',
  },
);

export const TrackNeaInlineAIFeedbackModalTextFieldFocusEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    action: 'engaged',
    editor_type: 'nea',
    scope_area: 'smart_content_generation',
    object_detail: 'ai_feedback',
    ui_access_point: 'in_line_ai_modal',
    generated_text: '',
    ui_action: 'focus',
    ui_object: 'button',
    entrypoint_detail: 'custom_prompt_bar',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    intuit_tid: '',
    ui_object_detail: '',
  },
);

export const TrackInlineAIFeedbackModalEvents = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    action: 'engaged',
    object: 'campaign',
    object_detail: 'ai_feedback',
    ui_access_point: 'in_line_ai_modal',
    generated_text: '',
  },
  {
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    feedback_text: '',
    feedback_response_choice: [],
    intuit_tid: '',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
  },
);

export const TrackEditWithAIActionsEvents = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    action: 'engaged',
    object: 'campaign',
    object_detail: 'content_generation',
  },
  {
    ai_tone_selected: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    intuit_tid: '',
    ui_action: '',
    ui_access_point: '',
    ui_object_detail: '',
    ui_object: '',
  },
);

// ContentGenAiAssistantEvent
const NUNI_ASSISTANT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
  screen: 'email/editor',

  object: 'smart_content',
  object_detail: 'content_generation',
  action: 'selected',

  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: 'in_line_ai_editor',
};

export const clickWriteWithAssistantEvent = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    ui_object_detail: 'write_with_ai',
    ui_access_point: 'email_editor',
  },
  {
    campaign_id: '',
    content_type: '',
    ai_email_type: '',
    campaign_type: '',
    ai_options_presented: 1,
  },
);

export const clickRewriteWithAssistantEvent = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    ui_object_detail: 'rewrite_with_ai',
    ui_access_point: 'email_editor',
  },
  {
    campaign_id: '',
    content_type: '',
    ai_email_type: '',
    campaign_type: '',
    ai_options_presented: 1,
  },
);

export const clickGenerateTextAssistantEventV2 = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    ui_object_detail: 'airplane',
  },
  {
    campaign_id: '',
    content_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    ai_email_type: '',
    campaign_type: '',
    ai_options_presented: 1,
  },
);

export const clickApplyGeneratedTextAssistantEvent = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    ui_object_detail: 'arrow',
  },
  {
    campaign_id: '',
    content_type: '',
    generated_text: '',
    ai_email_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    generation_attempt_count: '',
    added_to_existing_block_or_new_created: '',
    campaign_type: '',
    intuit_tid: '',
    ai_options_presented: 1,
    ai_options_pos: 1,
    has_genai_brand_voice: false,
  },
);

export const clickTryAgainAssistantEventV2 = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    ui_object_detail: 'try_again',
  },
  {
    campaign_id: '',
    ai_email_type: '',
    content_type: '',
    campaign_type: '',
    ai_options_presented: 1,
  },
);

export const clickShowButtonForLongTextEvent = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object: 'campaign',
    action: 'engaged',
    object_detail: 'content_generation',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'inline_ai_editor',
  },
  {
    ui_object_detail: '',
    content_type: 'paragraph',
    ai_options_presented: 1,
    ai_options_pos: 1,
  },
);

export const discardGeneratedTextAssistantEvent = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    ui_object_detail: 'discard',
  },
  {
    campaign_id: '',
    ai_email_type: '',
    content_type: '',
    campaign_type: '',
    ai_prompt_id: '',
    ai_options_presented: 1,
  },
);

export const sendFeedbackAssistantEvent = createEcsEvent(
  {
    ...NUNI_ASSISTANT_PROPERTIES,
    object_detail: 'feedback',
    ui_object_detail: 'feedback_airplane',
  },
  {
    content_type: '',
    feedback_text: '',
    campaign_type: '',
  },
);

// Email Checklist
const EMAIL_CHECKLIST_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'campaign_subject',
  action: 'engaged',
  ui_action: 'clicked',
  ui_access_point: 'main_content_pane',
};

export const writeWithAIEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object_detail: 'write_with_ai',
    ui_object: 'link',
  },
  {
    campaign_id: '',
    content_type: '',
    campaign_type: '',
    ai_email_type: '',
    checklist_step_state: '',
    has_replicated_email: false,
    has_ai_inline_preview_text: false,
    // Added for Open Rate Drivers : GEN-2266
    genai_purpose_value: '',
    genai_industry_self_declared: '',
  },
);

export const rewriteWithAIEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object_detail: 'rewrite_with_ai',
    ui_object: 'link',
  },
  {
    campaign_id: '',
    content_type: '',
    campaign_type: '',
    ai_email_type: '',
    checklist_step_state: '',
    has_replicated_email: false,
    has_ai_inline_preview_text: false,
    // Added for Open Rate Drivers : GEN-2266
    genai_purpose_value: '',
    genai_industry_self_declared: '',
  },
);

export const clickGenerateTextEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object_detail: 'airplane',
    ui_object: 'button',
  },
  {
    ai_prompt_id: '',
    content_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    ai_email_type: '',
    checklist_step_state: '',
    campaign_type: '',
    campaign_id: '',
    ai_use_case: '',
    ai_options_presented: 1,
    has_replicated_email: false,
    has_ai_inline_preview_text: false,
    // Added for Open Rate Drivers : GEN-2266
    genai_purpose_value: '',
    genai_industry_self_declared: '',
  },
);

export const applyGeneratedTextEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object_detail: 'up_arrow',
    ui_object: 'button',
  },
  {
    ai_prompt_id: '',
    campaign_id: '',
    content_type: '',
    generated_text: '',
    generated_preview_text: '',
    ai_email_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_options_pos: 1,
    ai_tone_selected: '',
    generation_attempt_count: '',
    checklist_step_state: '',
    intuit_tid: '',
    campaign_type: '',
    ai_options_presented: 1,
    ai_use_case: '',
    has_replicated_email: false,
    has_ai_inline_preview_text: false,
    // Added for Open Rate Drivers : GEN-2266
    genai_purpose_value: '',
    genai_industry_self_declared: '',
    has_genai_brand_voice: false,
  },
);

export const clickTryAgainEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'try_again',
  },
  {
    ai_prompt_id: '',
    campaign_id: '',
    ai_email_type: '',
    regenerated_text: '',
    intuit_tid: '',
    content_type: '',
    campaign_type: '',
    ai_use_case: '',
    ai_options_presented: 1,
    has_replicated_email: false,
    has_ai_inline_preview_text: false,
    // Added for Open Rate Drivers : GEN-2266
    genai_purpose_value: '',
    genai_industry_self_declared: '',
  },
);

export const discardGeneratedTextEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'discard',
  },
  {
    ai_prompt_id: '',
    campaign_id: '',
    ai_email_type: '',
    content_type: '',
    campaign_type: '',
    ai_use_case: '',
    ai_options_presented: 1,
    has_replicated_email: false,
    has_ai_inline_preview_text: false,
    // Added for Open Rate Drivers : GEN-2266
    genai_purpose_value: '',
    genai_industry_self_declared: '',
  },
);

export const clickCreateNewItemAiAssistant = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    action: 'customized',
    added_to_existing_block_or_new_created: 'new',
    object_detail: 'add_content',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'create_new_block_with_text',
    ui_access_point: 'inline_ai_modal',
  },
  {
    campaign_id: '',
    content_type: '',
    generated_text: '',
    ai_email_type: '',
    ai_custom_prompt: '',
    ai_shortcut_selected_h1: '',
    ai_shortcut_selected_h2: '',
    ai_tone_selected: '',
    generation_attempt_count: '',
    campaign_type: '',
    intuit_tid: '',
    has_genai_brand_voice: false,
  },
);

export const emailPurposeSelectChecklistEvent = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'campaign_subject',
    screen: 'campaigns/edit',
    ui_action: 'selected',
    ui_object: 'drop_down',
    ui_object_detail: 'email_purpose',
    ui_access_point: 'main_content_pane',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    checklist_step_state: '',
    content_type: '',
    genai_purpose_prefilled: '',
    genai_purpose_value: '',
  },
);

export const emailOtherPurposeSubmitChecklistEvent = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'campaign_subject',
    screen: 'campaigns/edit',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'submit',
    ui_access_point: 'email_purpose_freeform',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    checklist_step_state: '',
    content_type: '',
    genai_purpose_value: '',
    genai_purpose_freeform_text: '',
  },
);

/** Adding New ECS Events for AI Brand Voice */

export const feedbackThumbsUpAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings_feedback',
    screen: 'email/editor',
    ui_action: 'clicked',
    ui_object: 'icon',
    ui_object_detail: 'thumbs_up',
    ui_access_point: 'left_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

export const feedbackThumbsDownAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings_feedback',
    screen: 'email/editor',
    ui_action: 'clicked',
    ui_object: 'icon',
    ui_object_detail: 'thumbs_down',
    ui_access_point: 'left_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    feedback_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

export const feedbackTextFocusAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings_feedback',
    screen: 'email/editor',
    ui_action: 'focused',
    ui_object: 'text_box',
    ui_object_detail: 'free_text',
    ui_access_point: 'left_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

export const feedbackSubmitAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings_feedback',
    screen: 'email/editor',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'submit',
    ui_access_point: 'left_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
    feedback_text: '',
    feedback_response_choice: [],
  },
);

export const feedbackCancelAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings_feedback',
    screen: 'email/editor',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_access_point: 'left_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

// Brand Voice Settings ECS
export const openAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings',
    screen: 'email/editor',
    ui_object: 'button',
    ui_object_detail: 'view_brand_voice_analysis',
    ui_action: 'clicked',
    ui_access_point: 'inline_ai_editor',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

export const closeAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings',
    screen: 'email/editor',
    ui_object: 'button',
    ui_object_detail: 'done',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

export const dismissAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings',
    screen: 'email/editor',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'right_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

export const toggleAIBrandVoice = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    object_detail: 'brand_voice_settings',
    screen: 'email/editor',
    ui_object: 'toggletip',
    ui_object_detail: 'brand_voice_toggle',
    ui_action: 'toggle',
    ui_access_point: 'left_rail',
  },
  {
    ai_email_type: '',
    ai_use_case: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    has_genai_brand_voice: false,
    intuit_tid: '',
  },
);

// GenAI Free Trial ECS Events
const NUNI_POST_FREE_TRIAL_DEFAULT_PROPERTIES = {
  scope_area: 'email_editor',
  object: 'checkout',
  object_detail: 'genai_monetization',
};

export const NuniViewPostTrialReminderEvent = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    ...NUNI_POST_FREE_TRIAL_DEFAULT_PROPERTIES,
    ui_object: 'toolbar',
    ui_action: 'loaded',
    ui_access_point: 'center',
    ui_object_detail: 'ai_post_trial_reminder',
  },
  {
    campaign_type: '',
    content_type: '',
    campaign_id: '',
  },
);

export const NuniClickUpgradePostTrialReminderEvent = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    ...NUNI_POST_FREE_TRIAL_DEFAULT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'upgrade_to_keep_ai_tools',
    ui_action: 'clicked',
    ui_access_point: 'ai_trial_reminder_toolbar',
  },
  {
    campaign_type: '',
    content_type: '',
    campaign_id: '',
  },
);

export const NuniDismissPostTrialReminderEvent = createEcsEvent(
  {
    ...NUNI_DEFAULT_PROPERTIES,
    ...NUNI_POST_FREE_TRIAL_DEFAULT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'dismiss',
    ui_action: 'clicked',
    ui_access_point: 'ai_trial_reminder_toolbar',
  },
  {
    campaign_type: '',
    content_type: '',
    campaign_id: '',
  },
);
