import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
  object: 'automations',
  screen: 'customer-journey/builder',
  object_detail: 'pbj_review_flow',
};

export const ClickNextInReviewEditCardEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview_card',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
    journey_review_step_name: '',
  },
);

export const ClickPaginationCaratForwardEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_right',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview_card',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
    journey_review_step_name: '',
  },
);

export const ClickPaginationCaratBackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_left',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview_card',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
    journey_review_step_name: '',
  },
);

export const ClickStepOnJourneyPreviewMapEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'journey_step',
    ui_object_detail: 'touchpoint',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
    journey_review_step_name: '',
    ai_email_type: '',
  },
);

export const ClickEditJourneyEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_journey',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
  },
);

export const ClickEditContentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_content',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    journey_id: 0,
    created_from_source: '',
    pbj_type: '',
    journey_created_from_genai: false,
    is_replicated_from_journey: false,
    touchpoint_step_name: '',
    ai_email_type: '',
    campaign_id: 0,
  },
);

export const ChangeAudienceEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience',
    ui_action: 'selected',
    ui_access_point: 'header',
  },
  {
    journey_id: 0,
    pbj_type: '',
    journey_created_from_genai: false,
    cjb_workflow_status: '',
  },
);
