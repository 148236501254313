import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

/**
 * Default Event properties for the verify email address
 *
 * @type {{ui_action: string, initiative_name: string, ui_access_point: string, purpose: string, object_detail: string, ui_object: string, action: string, scope_area: string, object: string}}
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_domain',

  object: 'account',
  object_detail: 'verify_email_address',
  action: 'engaged',

  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: 'modal',
};

/** This event fires when a user clicks the "Send verification email" button within the "Verify an email" modal. **/
export const SendVerificationEmailButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object_detail: 'send_verification_email',
  },
  { campaign_id: 0, screen: '' },
);

/** This event fires when a user clicks the "Learn more about email verification" link within the "Verify an Email" modal. **/
export const LearnMoreAboutEmailVerificationLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'link',
    ui_object_detail: 'learn_more_about_email_verification',
  },
  { campaign_id: 0, screen: '' },
);

/** This event fires when a user either clicks the "Cancel" button or the close "X" icon to dismiss the "Verify an Email" modal during the step where a user would enter an email address. **/
export const AddressModalVerificationCanceledEvent = createEcsEvent(
  { ...DEFAULT_EVENT_PROPERTIES, ui_object_detail: 'cancel' },
  { campaign_id: 0, screen: '' },
);

/** This event fires when a user clicks the Verify button within the "Verify an Email" modal during the step where a user would enter a verification code. **/
export const VerifyCodeButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    object_detail: 'verify_email_address_code',

    ui_object_detail: 'verify',
  },
  { campaign_id: 0, screen: '' },
);

/** This event fires when a user either clicks the "Cancel" button or the close "X" icon to dismiss the "Verify an Email" modal during the step where a user would enter a verification code. **/
export const VerifyCodeModalVerificationCanceledEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'verify_email_address_code',

    ui_object_detail: 'cancel',
  },
  { campaign_id: 0, screen: '' },
);

/** This event fires when a user clicks the "Resend Verification Code" link **/
export const ResendVerificationCodeLinkClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    object_detail: 'verify_email_address_code',

    ui_object: 'link',
    ui_object_detail: 'resend_verification_link',
  },
  { campaign_id: 0, screen: '' },
);
