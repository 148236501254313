import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_editor',
  screen: 'email/editor',

  object: 'campaign',
  object_detail: 'product_rec_content',
  action: 'customized',
};

const CONFIGURABLE_PROPERTIES = {
  campaign_id: 0,
  campaign_type: '',
  editor_type: '',
  platform_type: '',
  recommendation_type: '',
  template_id: 0,
};

export const EmailEditorProductRecsSelectTypeEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);
