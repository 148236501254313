import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'share_your_email',
};

const CONFIGURABLE_PROPERTIES = {
  campaign_id: 0,
};

export const TrackShareCampaignUrlBeginEditEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'link',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const TrackShareCampaignUrlCancelEditEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const TrackShareCampaignUrlCloseEditEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const TrackShareCampaignUrlCompleteEditEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,

    share_url_path: '',
    used_default_share_path: true,
  },
);
