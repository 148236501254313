import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'automations',
};

export const SmsAutomationsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
  },
  {
    action: 'engaged',
    object_detail: '',
    ui_object: 'button',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
);

export const SmsPbjEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the SmsPbjEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'preview_prebuilt_journey',
    ui_action: 'clicked',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    action: 'selected',
    ui_access_point: 'cards_list',
    ui_object: '',
    ui_object_detail: '',
    pbj_type: '',
  },
);
