import { createEcsEvent } from '../../configuration/createEvent';

/*
 * This event should fire when the bulk tags page is viewed.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:82
 */

export const AudienceOrganizationViewBulkTagsPageEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/tags/bulk-tag',
    object: 'audience',
    object_detail: 'bulk_tag',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);

/*
 * This event should fire when a user clicks "save" in the bulk tag page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:83
 */

export const AudienceOrganizationClickSaveInBulkTagEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/tags/bulk-tag',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_tag',
    ui_action: 'clicked',
    ui_access_point: 'center',
    has_save_bulk_tag_error_encountered: false,
  },
  {
    audience_tag_name: '',
  },
);
