import { createEcsEvent } from '../../configuration/createEvent';

export const AorgReactCreateAudiencePageViewed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactCreateAudienceClickCampaignUrlsLink = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'campaign_urls',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactCreateAudienceClickAuthorizedToUseThisDomainLink = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'authorized_to_use_domain_name',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

// to do
export const AorgReactCreateAudienceClickLearnMoreAboutDomainsLink = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more_about_domains',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactCreateAudienceClickWhyIsThisNecessaryLink = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'why_is_this_necessary',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactCreateAudienceClickGdprAppliesToYouLink = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'GDPR_applies_to_you',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactCreateAudienceClickEditNotificationEmailLink = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'edit_notification_email',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactCreateAudienceViewEditEmailModal = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'edit_notification_email',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: 'edit_notification_email_modal',
    ui_action: '',
    ui_access_point: 'modal',
  },
  {},
);

export const AorgReactCreateAudienceClickEditModalCancelButton = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'edit_notification_email',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AorgReactCreateAudienceClickEditModalXButton = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'edit_notification_email',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AorgReactCreateAudienceClickEditModalSaveButton = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'edit_notification_email',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    edit_email_modal_saved_successfully: false,
    edit_email_modal_error_encountered: 'same_email',
  },
);

export const AorgReactCreateAudienceEditEmailSuccess = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'edit_notification_email',
    action: 'succeeded',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AorgReactCreateAudienceClickAudienceBreadcrumb = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'audience',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const AorgReactCreateAudienceClickViewAudiencesBreadcrumb = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_audiences',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const AorgReactCreateAudienceClickGetMoreAudienceTips = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'get_more_audience_tips',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const AorgReactCreateAudienceClickSave = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    create_audience_saved_successfully: true,
    create_audience_error_encountered: null,
    custom_url_selected: false,
    reminder_reused: false,
  },
);

export const AorgReactCreateAudienceSuccessfulSave = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/create-audience/',
    object: 'audience',
    object_detail: 'create_audience',
    action: 'succeeded',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {},
);
