import { createEcsEvent } from '../../configuration/createEvent';

// Default event props for the Groups page owned by AOrg
const DEFAULT_EVENT_PROPS = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  screen: 'lists/dashboard/groups',
  object: 'audience',
  object_detail: 'groups_index',
};

/*
 * Fire when a user views the groups index page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:93
 */

export const AudienceOrganizationViewGroupsPageEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
    zero_state: '',
  },
);

/**
 * Fires when a user interacts with an element on the groups index page
 * Configurable properties are utilized to fire the event as we expect it to be fired
 */
export const AudienceOrganizationGroupsEvent = createEcsEvent(
  DEFAULT_EVENT_PROPS,
  {
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    zero_state: '',
  },
);

/*
 * Fire when user clicks to "save" their newly created group. Pass group category name, number of group names, radio button selection
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:97
 */

export const AudienceOrganizationClickSaveWhileCreatingAGroupsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'group_creation_form',
  },
  {
    group_category_name: '',
    group_names: '',
    number_of_groups: '',
    signup_form_option: '',
  },
);
