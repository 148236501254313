import { createEcsEvent } from '../../configuration/createEvent';

// ECS Events Source: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=632572981

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'multi_vertical',
  screen: '/crm/integrations/google/connect',
  object: 'audience',
  object_detail: 'google_account',
};

export const GoogleConnectTermsViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const GoogleConnectTermsAgreeClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'agree',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);
