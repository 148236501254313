import { createEcsEvent } from '../../../configuration/createEvent';

const EMAIL_CHECKLIST_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'campaign_subject',
  action: 'engaged',
};

const UPDATE_SEND_TEST_EMAIL_PROPERTIES = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  ui_access_point: 'main_content_pane',
  object_detail: 'test_email',
  ui_object_detail: 'send_a_test_email',
  ui_object: 'button',
  ui_action: 'clicked',
};

//This event fires when a user clicks the "Add Send Time" or "Edit Send Time" button from the Campaign checklist page to open the "Send Time" campaign checklist item.
export const BeginSendTestEmailEvent = createEcsEvent(
  {
    ...UPDATE_SEND_TEST_EMAIL_PROPERTIES,
  },
  {
    campaign_id: '',
    campaign_type: '',
    editor_type: '',
  },
);
