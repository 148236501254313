import { createEcsEvent } from '../../configuration/createEvent';
const DEFAULT_MANAGER_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  object: 'campaign',
  editor_type: 'nuni',
};

const DEFAULT_PON_EVENT_PROPERTIES = {
  scope_area: 'email_editor',
  screen: '/email/editor',
  object_detail: 'digital_self_help',
  ui_object_detail: 'self_help_content',
  ui_access_point: 'side_panel',
};

const MUTABLE_PON_EVENT_PROPERTIES = {
  campaign_id: 0,
  template_id: 0,
  platform_type: '',
  campaign_type: '',
  digital_self_help_button_text: '',
};

//panel
export const CampaignsTrackSidePanelNavEvent = createEcsEvent(
  {
    ...DEFAULT_MANAGER_EVENT_PROPERTIES,
    object_detail: 'side_panel_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'far_left_pane',
    scope_area: 'email_editor',
    screen: 'email/editor',
  },
  {
    campaign_id: 0,
    ui_object_detail: '',
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);

export const TemplatesTrackSidePanelNavEvent = createEcsEvent(
  {
    ...DEFAULT_MANAGER_EVENT_PROPERTIES,
    object_detail: 'side_panel_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'far_left_pane',
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
  },
  {
    user_template_id: 0,
    ui_object_detail: '',
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const NuniSelfHelpPONViewEvent = createEcsEvent(
  {
    ...DEFAULT_MANAGER_EVENT_PROPERTIES,
    ...DEFAULT_PON_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_action: 'loaded',
  },
  {
    ...MUTABLE_PON_EVENT_PROPERTIES,
  },
);

export const NuniSelfHelpPONClickedEvent = createEcsEvent(
  {
    ...DEFAULT_MANAGER_EVENT_PROPERTIES,
    ...DEFAULT_PON_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
  },
  {
    ...MUTABLE_PON_EVENT_PROPERTIES,
  },
);
