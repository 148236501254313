import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'human_assistance',
  scope_area: 'support',
  object: 'content',
  object_detail: 'in_app_help_overlay',
};

export const InAppHelpOverlayViewedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'viewed',
  },
  {
    screen: '',
  },
);

export const EmailUsClickedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'email_us',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
  },
  {
    screen: '',
  },
);

export const McAssistantClickedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'ask_mailchimp_assistant',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
  },
  {
    screen: '',
  },
);
