import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the Subscriber Count step"
 */
export const SubscriberCountViewedEvent = createEcsEvent(
  /**
   * This param contains the SubscriberCountViewedEvent the event will contain.
   * These properties can never be changed inside of the SubscriberCountViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'subscriber_count',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "Answer 'How many email subscribers do you have?' and click  'Next' and successfully passes validation. Not Required"
 */
export const SubscriberCountNextEvent = createEcsEvent(
  /**
   * This param contains the SubscriberCountNextEvent the event will contain.
   * These properties can never be changed inside of the SubscriberCountNextEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'subscriber_count',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    proposed_subscriber_count: '',
    entry_point: '',
  },
);

/**
 * "Answer 'How many email subscribers do you have?' and click  'Next' and successfully passes validation. Not Required"
 */
export const SubscriberCountEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the SubscriberCountEngagedSkipEvent the event will contain.
   * These properties can never be changed inside of the SubscriberCountEngagedSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'subscriber_count',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the Subscriber Count page"
 */
export const SubscriberCountEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the SubscriberCountEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the SubscriberCountEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'subscriber_count',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const SubscriberCountFailedEvent = createEcsEvent(
  /**
   * This param contains the SubscriberCountFailedEvent the event will contain.
   * These properties can never be changed inside of the SubscriberCountFailedEvent.
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'subscriber_count',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
