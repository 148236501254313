import { createEcsEvent } from '../../configuration/createEvent';

/*
 * This event should fire when a user views the import history page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:38
 */

const DEFAULT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  object: 'audience',
  object_detail: 'import_history',
  screen: '/audience/import-history',
};

/*
This event should fire when a user clicks on the 'Import Contacts' in the Import History page header
* ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:266
*/
export const AudienceOrganizationClickImportContactsInHeader = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'button',
    ui_object: 'import_contacts',
    ui_object_detail: 'clicked',
    ui_action: 'header',
    ui_access_point: 'has_import_history_experiment',
  },
  {},
);

/*
This event should fire when a user clicks on the 'View New Contacts' CTA in the import history experiment slat
* ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:267
*/

export const AudienceOrganizationClickViewNewContacts = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'button',
    ui_object: 'view_new_contacts',
    ui_object_detail: 'clicked',
    ui_action: 'import_slat',
    ui_access_point: '',
  },
  {},
);

/*
This event should fire when a user clicks on the 'View Errors' CTA in the import history experiment slat
* ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:268
*/
export const AudienceOrganizationClickViewErrors = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_errors',
    ui_action: 'clicked',
    ui_access_point: 'import_slat',
  },
  {
    has_import_history_experiment: '',
  },
);

/*
This event should fire when a user clicks on the 'Download Errors' CTA in the import history error detail modal
* ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:269
*/
export const AudienceOrganizationClickDownloadErrorsWithinErrorModal = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_errors',
    ui_action: 'clicked',
    ui_access_point: 'import_error_modal',
  },
  {
    has_import_history_experiment: '',
  },
);

/*
This event should fire when a user clicks on the 'Start New Import' CTA in the import history page
* when that page is in its zero state
* ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:269
*/
export const AudienceOrganizationClickStartNewImportZeroState = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'start_new_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

/*
This event should fire when a user clicks on the 'Retry' button on a previously failed import
* ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:127
*/

export const AudienceOrganizationClickRetryOnFailedImport = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'button',
    ui_object: 'retry',
    ui_object_detail: 'clicked',
    ui_action: 'center',
    ui_access_point: '',
  },
  { has_import_history_experiment: '' },
);

export const AudienceOrganizationViewImportHistoryPageEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
    has_import_history_experiment: false,
  },
);

/*
 * This event should fire when a user clicks the "start a new import" CTA
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:39
 */

export const AudienceOrganizationClickStartNewImportEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'start_new_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

/*
 * This event should fire when a user clicks to "undo" an import
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:40
 */

export const AudienceOrganizationClickUndoOnAnImportEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'undo',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    has_import_history_experiment: '',
  },
);

/*
 * This event should fire when a user clicks undo while in the undo confirmation modal
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:41
 */

export const AudienceOrganizationClickUndoInTheUndoConfirmationModalEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'undo',
    ui_action: 'clicked',
    ui_access_point: 'undo_modal',
  },
  {
    undo_import_error: false,
    has_import_history_experiment: '',
  },
);

/*
 * This event should fire when a user clicks to "view" an import
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:42
 */

export const AudienceOrganizationClickViewOnAnImportEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_import_errors: false,
  },
);

/*
 * This event should fire when a user clicks the header of an import to view the contacts on the contact table
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:43
 */

export const AudienceOrganizationClickTheImportHeaderToViewContactsOnTheContactTableEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'import_title',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

/*
 * This even should fire when a user clicks the hyperlink that appears the first few days after there was an error on an import. What kind of error should be passed as an event specific param.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:44
 */

export const AudienceOrganizationClickErroredContactListHyperlinkEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'contacts_with_errors',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_history_error_list_type: '',
  },
);

/*
 * This event should fire when a user clicks "role based" to view the help article
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:45
 */

export const AudienceOrganizationClickRoleBasedHelpArticleEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'role_based',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_import_history_experiment: '',
  },
);

/*
 * This event should fire when a user changes the number of imports viewable on the page using the dropdown at the bottom of the page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:46
 */

export const AudienceOrganizationChangeNumberOfImportsDisplayedEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'number_of_imports_displayed',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    num_of_record_shown: '',
    has_import_history_experiment: '',
  },
);

export const AudienceOrganizationImportErrorSelfHelpClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    scope_area: 'all_contacts',
    object_detail: 'digital_self_help',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'help_selection',
    ui_action: 'clicked',
    ui_access_point: 'center',
    digital_self_help_button_text: 'Help me fix import errors',
    manage_contacts_audience_h_nav_option: 'import_history',
  },
  {},
);

export const AudienceOrganizationImportErrorSelfHelpViewEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    scope_area: 'all_contacts',
    object_detail: 'digital_self_help',
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'help_selection',
    ui_action: 'loaded',
    ui_access_point: 'center',
    digital_self_help_button_text: 'Help me fix import errors',
    manage_contacts_audience_h_nav_option: 'import_history',
  },
  {},
);
