import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  object: 'automations',
};

export const SelectActionFromJourneySlat = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'all_customer_journeys',
    action: 'engaged',
    ui_object: 'slat_combo_button',
    ui_object_detail: 'slat_action',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {
    screen: '',
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const SelectActionFromClassicSlat = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/my-journeys/',
    object_detail: 'classic_automation',
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'slat_action',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {
    action_taken: '',
  },
);

export const ReplicateSuccessfulFromSlat = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'replicate_a_journey',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    journey_id: 0,
    replicated_from_journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const PauseJourneyClickFromSlatModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'paused',
    object_detail: 'journey_list',
    ui_object: 'button',
    ui_object_detail: 'pause_journey',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    journey_id: 0,
    pbj_type: '',
    journey_created_from_genai: false,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);
