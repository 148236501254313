import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'all_campaigns',
  screen: '/campaigns/show-email',
  object: 'campaign',
};

export const CampaignShowViewReportClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'all_campaigns_email_show',
    ui_object: 'button',
    ui_object_detail: 'view_report',
    ui_access_point: 'header',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowPreviewTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'all_campaigns_email_show',
    ui_object: 'tab',
    ui_object_detail: 'campaign_preview',
    ui_access_point: 'center',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowHTMLTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'all_campaigns_email_show',
    ui_object: 'tab',
    ui_object_detail: 'html_source',
    ui_access_point: 'center',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowPlainTextTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'all_campaigns_email_show',
    ui_object: 'tab',
    ui_object_detail: 'plain_text_email',
    ui_access_point: 'center',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowDetailsTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'all_campaigns_email_show',
    ui_object: 'tab',
    ui_object_detail: 'details',
    ui_access_point: 'center',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowEmailView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    object_detail: 'all_campaigns_email_show',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowMVTView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    object_detail: 'all_campaigns_mvt_show',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
  },
  {
    campaign_id: 0,
  },
);

export const CampaignShowRSSView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    object_detail: 'all_campaigns_rss_show',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
  },
  {
    campaign_id: 0,
  },
);
