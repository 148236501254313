import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the ecomm step"
 */
export const ecommViewedEvent = createEcsEvent(
  /**
   * This param contains the ecommViewedEvent the event will contain.
   * These properties can never be changed inside of the ecommViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ecomm_type',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "Answer 'How do you sell to your customers?' and click  'Next'  successfully passes validation.
 *  Only seen by people with specific answers to Business Objective question. Not required"
 */
export const ecommNextEvent = createEcsEvent(
  /**
   * This param contains the ecommNextEvent the event will contain.
   * These properties can never be changed inside of the ecommNextEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ecomm_type',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    ecomm_preference: '',
    entry_point: '',
  },
);

/**
 * "Answer 'How do you sell to your customers?' and click 'Skip' CTA, and successfully passes validation.
 *  Only seen by people with specific answers to Business Objective question. Not required"
 */
export const ecommEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the ecommEngagedSkipEvent the event will contain.
   * These properties can never be changed inside of the ecommEngagedSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ecomm_type',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the ecomm page"
 */
export const ecommEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the ecommEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the ecommEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ecomm_type',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const ecommFailedEvent = createEcsEvent(
  /**
   * This param contains the ecommFailedEvent the event will contain.
   * These properties can never be changed inside of the ecommFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ecomm_type',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
