import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'human_assistance',
  scope_area: 'contact_us',
  screen: 'channel_options',
  object: 'content',
  object_detail: 'contact_us_flow',
};

export const ChatChannelCardCtaEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'chat',
    ui_action: 'clicked',
    ui_access_point: 'page_body',
  },
  {},
);
