import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user lands on the Plan Selector step"
 */
export const PlanViewedEvent = createEcsEvent(
  /**
   * This param contains the PlanViewedEvent the event will contain.
   * These properties can never be changed inside of the PlanViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selector',
    action: 'viewed',
    ui_object: 'signup/setup',
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user chooses a package plan and then clicks the 'Next' CTA"
 */
export const PlanEngagedCompleteEvent = createEcsEvent(
  /**
   * This param contains the PlanEngagedCompleteEvent the event will contain.
   * These properties can never be changed inside of the PlanEngagedCompleteEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selector',
    action: 'engaged',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);
