import { createEcsEvent } from '../../configuration/createEvent';

// Properties that will never change. screen is '/' since this is the homepage.
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_guideme',
  scope_area: 'homepage',
  object: 'recommendation',
  object_detail: 'onboarding_checklist',
  homepage_content: 'onboarding checklist',
};

const CTA_EVENT_PROPERTIES = {
  action: 'engaged',
  ui_action: 'clicked',
  ui_access_point: 'center',
};

export const OnboardingChecklistViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_object_detail: 'onboarding_checklist',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    // TODO: Add in module header tracking once checklist is refactored.
    oc_module_header: '',
    oc_progress: '',
    oc_scenario: '',
    screen: '',
  },
);

export const OnboardingChecklistCollapseExpandEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'accordion',
  },
  {
    ui_object_detail: '',
    oc_module_header: '',
    oc_progress: '',
    oc_scenario: '',
    screen: '',
  },
);

export const OnboardingChecklistPrimaryCTAEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ...CTA_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'primary_cta',
  },
  {
    oc_module_header: '',
    oc_progress: '',
    oc_scenario: '',
    oc_homepage_primary_cta_content: '',
    screen: '',
  },
);

export const OnboardingChecklistInlineCTAEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ...CTA_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'inline_text_cta',
  },
  {
    oc_module_header: '',
    oc_progress: '',
    oc_scenario: '',
    oc_inline_cta_content: '',
    screen: '',
  },
);

export const OnboardingChecklistDismissEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ...CTA_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'dismiss_checklist',
    oc_module_header: 'dismiss',
  },
  {
    oc_progress: '',
    oc_scenario: '',
    screen: '',
  },
);
