import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  screen: '/account/features-review',
  object: 'account',
  object_detail: 'downgrade_account',
  event_source: 'front_end:js_dojo (mc-events-emitter)',
};

export const FeaturesReviewPageViewedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const FeaturesReviewPageCheckboxClickedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'downgrade_confirmation',
    ui_action: 'click',
    ui_access_point: 'center',
  },
  {},
);

export const FeaturesReviewPageDowngradeButtonClickedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'downgrade',
    ui_action: 'click',
    ui_access_point: 'center',
  },
  {},
);
