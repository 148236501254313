import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'segment',
};

export const SendToNewSegmentSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'recipients_bulk_checklist',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'new_segment_or_group',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {
    has_elc_release_1_sms_cohort_ff: '',
    segmentation_method: '',
    audience_is_sms_enabled: false,
    sms_registration_status: '',
  },
);

export const SendToSavedSegmentSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'recipients_bulk_checklist',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_to_saved_segment',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {
    has_elc_release_1_sms_cohort_ff: '',
    segmentation_method: '',
    audience_is_sms_enabled: false,
    sms_registration_status: '',
  },
);
