import { createEcsEvent } from '../../configuration/createEvent';

// Properties that will never change. screen is '/' since this is the homepage.
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_guideme',
  scope_area: 'homepage',
  screen: '/',
  object: 'recommendation',
  object_detail: 'product_onboarding_appointment',
};

// Note: HAO is Human Assisted Onboarding
// Event sent when an user views the HAO Banner.
export const HAOViewedEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_object_detail: 'human_assistance_banner',
    ui_action: 'loaded',
    ui_access_point: 'banner',
    homepage_content: 'hao banner',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    appointment_banner_header: '',
  },
);

export const HAOEngagedEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'banner',
    homepage_content: 'hao banner',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    ui_object_detail: '',
    appointment_banner_header: '',
  },
);
