import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'campaign',
};

export const SmsPhoneNumberSelectionEngaged = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'engaged',
    object_detail: 'phone_number_selection',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    ui_object_detail: '',
    state_dropdown: '',
    area_code_dropdown: '',
  },
);

export const SettingsTabProgramInformationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/settings',
    action: 'engaged',
    object_detail: 'sms_program_information',
    ui_object: 'tab',
    ui_object_detail: 'program_information',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SettingsTabSendingOptionsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/settings',
    action: 'engaged',
    object_detail: 'sms_sending_options',
    ui_object: 'tab',
    ui_object_detail: 'sending_options',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SettingsTabOptInManagementEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/settings',
    action: 'engaged',
    object_detail: 'sms_opt_in_management',
    ui_object: 'tab',
    ui_object_detail: 'opt_in_management',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsOverviewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
  },
  {
    action: 'engaged',
    object_detail: '',
    ui_object: 'button',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'center',
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsHandleSelectAudienceEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'sender_information',
    ui_object: 'dropdown',
    ui_object_detail: 'audience_name',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {
    sms_experience_level: '',
    sms_num_audience_contacts: '0',
    sms_num_audiences: '0',
  },
);

export const SmsHandleHoverEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'sender_information',
    ui_action: 'hovered',
    ui_access_point: 'center',
  },
  {
    ui_object: '',
    ui_object_detail: '',
    sms_experience_level: '',
  },
);

export const SmsOverviewAccordionContentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
  },
  {
    ui_access_point: '',
    object_detail: '',
    ui_object_detail: '',
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsChecklistExpandedOrCollapsedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    object_detail: '',
    ui_object_detail: '',
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsSentViewEcsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'sms_abandoned_cart_module',
    ui_object: 'expandable_card',
    ui_object_detail: 'sms_abandoned_cart_setup',
    ui_action: 'scrolled',
    ui_access_point: 'center',
  },
  {
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsTrackAbandonedCartSettingsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'abandoned_cart_checklist',
    ui_object: 'button',
    ui_object_detail: 'abandoned_cart_settings',
    ui_action: 'clicked',
    ui_access_point: 'abandoned_cart_checklist',
  },
  {
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsCreateSmsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'campaign_content',
    ui_object: 'button',
    ui_object_detail: 'create_sms',
    ui_action: 'clicked',
    ui_access_point: 'carousel_card',
  },
  {
    sms_use_case_selection: '',
    sms_experience_level: '',
  },
);

export const SmsSubscriberGrowthDaySelectorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'subscriber_growth',
    ui_action: 'clicked',
    ui_object: 'dropdown',
    ui_object_detail: 'day_selector',
    ui_access_point: 'center',
  },
  {
    num_days_selected: '',
  },
);

export const SmsBrowseTemplatesClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'template_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_object_detail: 'browse_templates',
    ui_access_point: 'header',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsTemplatesCloseClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'template_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsTemplatesFromScratchClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'template_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'start_from_scratch',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsInsertTemplateClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'template_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'insert',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_registration_status: '',
    sms_template_title: '',
    sms_template_id: '',
  },
);

export const SmsSendPreviewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
  },
  {
    object_detail: 'send_preview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
);

export const SmsSendPreviewSendEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'send_preview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send',
    ui_action: '',
    ui_access_point: 'modal',
  },
  {
    has_prefill_sender_name_sms: '',
    links_shortened_tracked: '',
    num_links_shortened_tracked: 0,
    opt_out_instructions_included: '',
    sms_has_link_settings_enabled: '',
  },
);

export const TermsAndConditionsSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'engaged',
    ui_access_point: 'center',
  },
  {
    terms_option_selected: '',
    sms_program_country_selected: '',
  },
);

export const LinkSettingsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'link_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'link_settings',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_registration_status: '',
  },
);

export const EnableBrandedSubdomainToggled = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'link_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'branded_short_links',
    ui_action: 'toggled',
    ui_access_point: 'popover',
  },
  {
    sms_registration_status: '',
    sms_has_link_settings_enabled: false,
  },
);

export const SubdomainInputFieldClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'link_settings',
    action: 'engaged',
    ui_object: 'field',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'popover',
  },
  {
    sms_registration_status: '',
  },
);

export const SubdomainToggletipClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'link_settings',
    action: 'engaged',
    ui_object: 'toggletip',
    ui_object_detail: 'i',
    ui_action: 'clicked',
    ui_access_point: 'popover',
  },
  {
    sms_registration_status: '',
  },
);

export const SaveSubdomainClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'link_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'popover',
  },
  {
    sms_registration_status: '',
  },
);

export const CloseLinkSettingsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'link_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'popover',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsExperienceSelectionEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'sms_marketing_experience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { sms_marketing_selection: '' },
);

export const SmsExperienceSkipEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'sms_marketing_experience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ViewedOverviewPageEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'overview',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_registration_status: '',
    previous_page_url: '',
  },
);

export const GetStartedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'start_application',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    zero_state: true,
  },
);

export const ContinueApplicationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_application',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SubmitBusinessInformationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'business_information',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    legal_business_name: false,
    business_address: false,
    industry: '',
    sms_program_country_selected: '',
  },
);

export const SubmitBusinessInformationSecondPageEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'business_information_additional',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_program_country_selected: '',
  },
);

export const SubmitUseCasesPageEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'registration_use_cases',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_use_case_selection: '',
    num_sms_use_cases_selected: 0,
    is_donation_link_included: false,
    sms_program_country_selected: '',
  },
);

export const TypedDonationLink = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'registration_use_cases',
    action: 'engaged',
    ui_object: 'textbox',
    ui_object_detail: 'direct_donations',
    ui_action: 'typed',
    ui_access_point: 'center',
  },
  {},
);

export const SubmitMessagingProgramEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'messaging_program_information',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    customer_support_number: '',
    audience_number: '',
    sms_program_country_selected: '',
  },
);

export const CustomizeSampleTemplateEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'customize_sample_template',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AddLinkExistingEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'add_link_existing ',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SubmitApplicationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/application-submitted',
    object_detail: 'overview',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'engaged',
    ui_access_point: 'center',
  },
  {
    ui_object_detail: '',
  },
);

export const SmsLoginPurchaseEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'login_purchase',
  },
  {
    action: 'engaged',
    ui_access_point: '',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
  },
);
export const StartPurchaseGuideMeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const CreditChangeAmountEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'change_amount',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const SaveCreditChangeAmountEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'credit_selection',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    sms_credits_purchased: '',
  },
);

export const CreditCloseEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const SelectCreditPreferenceEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'credit_preference',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_subscriber_amount: '',
    sms_estimated_messages_per_month: '',
  },
);

export const CreditPricingInquiryEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'find_out_more_about_credits_and_pricing',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const CreditSelectAndSubmitEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'select_and_submit',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_purchase_type: '',
    sms_estimated_messages_per_month: '',
    sms_program_country_selected: '',
  },
);

export const AbandonedCartInSmsSetupEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    ui_access_point: 'center',
  },
  {
    object_detail: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
  },
);

export const TermsAndConditionsUrlValidationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_conditions_url_validation',
  },
  {
    action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
);

export const WebsiteUrlValidationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'url_validation',
  },
  {
    action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
);

export const SplashPageFreeUsersCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object_detail: 'overview',
    action: 'engaged',
    ui_object_detail:
      'learn_more_about_pricing_and_which_marketing_plan_will_work_best_for_you',
  },
  {},
);

export const SplashPagePaidUsersCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object_detail: 'overview',
    action: 'engaged',
    ui_object_detail:
      'learn_more_about_pricing_and_which_subscription_will_work_best_for_you',
  },
  {},
);

export const AbandonedCartInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
  },
  {
    object_detail: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
  },
);

export const PrivacyPolicyButtonInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_program_information',
    ui_object: 'button',
    ui_object_detail: 'edit_privacy_policy',
    ui_access_point: 'privacy_policy_card',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const PrivacyPolicyLinkInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_program_information',
    ui_object: 'link',
    ui_object_detail: 'privacy_policy_landing_page',
    ui_access_point: 'privacy_policy_card',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const TermsAndConditionsLinkInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_program_information',
    ui_object: 'link',
    ui_object_detail: 'terms_and_conditions_landing_page',
    ui_access_point: 'terms_and_conditions_card',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const DoubleOptInSmsLinkInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_opt_in_management',
    ui_object: 'link',
    ui_object_detail: 'double_opt_in_for_sms',
    ui_access_point: 'center',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const AbandonedCartOptInLinkInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_opt_in_management',
    ui_object: 'link',
    ui_object_detail: 'abandoned_cart_for_sms',
    ui_access_point: 'center',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const SmsQuietHoursLinkInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_sending_options',
    ui_object: 'link',
    ui_object_detail: 'quiet_hours',
    ui_access_point: 'center',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const SmsQuietHoursUpdateButtonInSmsSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    ui_action: 'clicked',
    object_detail: 'sms_sending_options',
    ui_object: 'button',
    ui_object_detail: 'update',
    ui_access_point: 'center',
  },
  {
    sms_marketing_selection: '',
    sms_use_case_selection: '',
  },
);

export const AbandonedCartConsentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings/',
    action: 'engaged',
    object_detail: 'abandoned_cart',
    ui_object: 'switch',
    ui_object_detail: 'abandoned_cart_consent',
    ui_action: 'toggled',
    ui_access_point: 'center',
  },
  {
    is_abandoned_cart_consent_enabled: false,
  },
);

export const ViewingBusinessInfoCPModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'business_information',
    action: 'engaged',
    ui_action: 'clicked',
  },
  {
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
  },
);

// When cleaning up SMS_AU flag
// Please check usage for this event and remove
export const SmsChecklistEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'bulk_checklist',
    ui_object: 'button',
    ui_action: 'clicked',
  },
  {
    action: 'completed',
    ui_object_detail: '',
    ui_access_point: 'modal',
    sms_registration_status: '',
    segment_do_not_send_to: '',
    segment_send_to: '',
    sms_send_date: '',
    sms_send_time: '',
  },
);

export const SmsBulkChecklistScheduleEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'bulk_checklist',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'schedule',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    sms_registration_status: '',
    sms_send_date: '',
    sms_send_time: '',
    segment_send_to: '',
    segment_do_not_send_to: '',
    has_prefill_sender_name_sms: '',
    links_shortened_tracked: '',
    num_links_shortened_tracked: '',
    opt_out_instructions_included: '',
    sms_has_link_settings_enabled: '',
  },
);

export const SmsBulkChecklistSendEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'bulk_checklist',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'send',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    sms_registration_status: '',
    segment_send_to: '',
    segment_do_not_send_to: '',
    has_prefill_sender_name_sms: '',
    links_shortened_tracked: '',
    num_links_shortened_tracked: '',
    opt_out_instructions_included: '',
    sms_has_link_settings_enabled: '',
  },
);

export const SmsBulkChecklistSendOrScheduleEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'bulk_checklist',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'send_or_schedule',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    sms_send_date: '',
    sms_send_time: '',
  },
);

export const SmsBulkChecklistFinishLaterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'bulk_checklist',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'finish_later',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsCampaignNameEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    object_detail: 'campaign_name_bulk_checklist',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    ui_object_detail: '',
  },
);

export const SmsContentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/bulk',
    action: 'engaged',
    ui_object_detail: 'edit',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object_detail: 'content_bulk_checklist',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsContentEditorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/edit',
    object_detail: 'editor',
    action: 'engaged',
    ui_action: 'clicked',
  },
  {
    ui_object_detail: '',
    ui_object: '',
    ui_access_point: '',
    links_shortened_tracked: '',
    sms_registration_status: '',
    emoji_name: '',
    num_links_shortened_tracked: 0,
    opt_out_instructions_included: '',
    has_prefill_sender_name_sms: '',
  },
);

export const CreditSubscriptionSkippedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'credit_subscription_skipped',
    action: 'engaged',
    ui_action: 'clicked',
    ui_object: 'link',
    ui_object_detail: 'Ill_do_this_later',
    ui_access_point: 'center',
  },
  {
    credit_preference: '',
    credits_purchased: '',
  },
);

export const SmsReplicateEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'campaign_list',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'replicate',
    ui_access_point: 'center',
    screen: '/sms',
    action: 'created',
  },
  {
    sms_registration_status: '',
    replicated_campaign_has_title: '',
    replicated_campaign_has_content: '',
    replicated_campaign_scheduled_type: '',
    replicated_campaign_segment_send_to: '',
    replicated_campaign_segment_do_not_send_to: '',
  },
);

export const SmsCreateCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'created',
    object_detail: 'new_sms_campaign',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    screen: '',
    sms_registration_status: '',
    sms_campaign_id: '',
    create_campaign_origin: '',
    replicate_from_campaign: '',
    has_campaign_name: '',
  },
);

export const SmsReactivateEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    object_detail: 'sms_reactivation',
    ui_object: 'button',
    ui_object_detail: 'reactivate_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    inside_current_billing_period: '',
  },
);

export const SmsShaftModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object_detail: 'ok_got_it',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'shaft_modal',
    object_detail: 'shaft_modal',
    screen: '/sms',
  },
  {},
);

export const NoPrivacyPolicy = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'not_yet_i_need_help_creating_one',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const HasPrivacyPolicy = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'yes_i_have_a_privacy_policy',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const PrivacyPolicyOptionSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_sms_privacy_policy: false,
    sms_program_country_selected: '',
  },
);

export const BackOnPrivacyPolicy = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AgreeOnPrivacyPolicy = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'i_agree',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const NextPrivacyPolicyOnTemplate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_program_country_selected: '',
  },
);

export const ClearTemplatePrivacyPolicy = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'clear_template',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ResetPrivacyPolicyTemplate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reset_template',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const DoesNotHaveTermsAndConditions = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'not_yet_i_need_help_creating_them',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const HasTermsAndCondition = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'yes_i_have_terms_and_conditions',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const CopiedLinkToPrivacyPolicyOnTermsAndConditions = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'copy_link_to_privacy_policy',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SelectedTermsAndConditionsOnTemplate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_sms_terms_and_conditions: false,
    sms_program_country_selected: '',
  },
);

export const AgreeDisclaimerOnTermsAndConditions = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'i_agree',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const ClearedTemplateOnTermsAndConditions = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'clear_template',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const ResetTermsAndConditionTemplate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reset_template',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const NextOnTermsAndConditionOptions = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_sms_terms_and_conditions: false,
    sms_program_country_selected: '',
  },
);

export const BackOnTermsAndCondition = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions_template',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ViewedRecommendedSmsPhoneNumber = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'viewed',
    object_detail: 'phone_number_recomendation',
    ui_object: '',
    ui_action: '',
    ui_access_point: '',
    ui_object_detail: '',
  },
  {
    area_code_detailed_recommended: '',
    area_code_state_recommended: '',
  },
);

export const ClickedPickAreaCode = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'engaged',
    object_detail: 'phone_number_recomendation',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'pick_a_different_area_code',
  },
  {},
);

export const SelectedNewState = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'engaged',
    object_detail: 'phone_number_search',
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'state_name',
  },
  { area_code_state: '' },
);

export const SelectedRecommendedPhoneNumber = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'engaged',
    object_detail: 'phone_number_recomendation',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'next',
  },
  {
    area_code_detailed_success: '',
    area_code_state_success: '',
  },
);

export const ViewedAreaCodeSelectionPage = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'viewed',
    object_detail: 'phone_number_search',
    ui_object: '',
    ui_action: '',
    ui_access_point: '',
    ui_object_detail: '',
  },
  {},
);

export const ClickedNextFromAreaCodeSearch = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    action: 'engaged',
    object_detail: 'phone_number_search',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'next',
  },
  {
    area_code_detailed_status: '',
    area_code_detailed: '',
    area_code_state: '',
  },
);

export const KbArticleSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'guides_to_maximize_sms',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_kb_article_name: '',
  },
);

export const ViewSendingCountrySelectionStep = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'program_country_selection',
    action: 'viewed',
    ui_object: '',
    ui_action: '',
    ui_access_point: '',
    ui_object_detail: '',
  },
  {},
);

export const SelectSendingCountry = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'program_country_selection',
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'country_selection',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_program_country_selected: '',
  },
);

export const SelectSendingCountrySubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'program_country_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_program_country_selected: '',
  },
);

export const NumberNoLongerAvailable = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'phone_number_recomendation',
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'no_longer_available_error',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const LearnAboutCreditsBottomLink = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'pricing_information',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'explore_our_guide_to_credits_and_pricing',
    ui_action: 'clicked',
    ui_access_point: 'pricing_bottom_sheet',
  },
  {},
);

export const FindNewNumber = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'phone_number_recomendation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'find_a_new_number',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SmsSplashDeactivatedContactCustomerSupport = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    object_detail: 'sms_deactivation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'contact_customer_support',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SmsExperienceStepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'sms_marketing_experience',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const BizInfo1StepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'business_information',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const BizInfo2StepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'business_information_additional',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const SenderInfoStepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'messaging_program_information',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const UseCasesStepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'registration_use_cases',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const PrivacyPolicyStepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'privacy_policy',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const TermsAndConditionsStepViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    object_detail: 'terms_and_conditions',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_program_country_selected: '',
  },
);

export const RequestShortCodeSettingsButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings',
    object_detail: 'sender_information',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'request_a_short_code',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const RequestShortCodeModalSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings',
    object_detail: 'sender_information',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'agree_and_submit',
    ui_action: 'clicked',
    ui_access_point: 'sms_request_short_code_modal',
  },
  {},
);

export const RequestShortCodeModalClose = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings',
    object_detail: 'sender_information',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'sms_request_short_code_modal',
  },
  {},
);

export const RequestShortCodeModalCheckboxSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'sms/settings',
    object_detail: 'sender_information',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'sms_short_code_terms',
    ui_action: 'clicked',
    ui_access_point: 'sms_request_short_code_modal',
  },
  {},
);
