import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_px',
  scope_area: 'monetization',
  object: 'checkout',
  object_detail: 'plan_selection',
  event_source: 'front_end:js_react(mc_emitter)',
};

export const CurrencyDropdownOpenEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'currency',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    screen: '',
  },
);

export const CurrencyDropdownChangeEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'currency',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    original_currency: '',
    updated_currency: '',
    upgrade_session_id: '',
    screen: '',
  },
);

export const ContactDropdownOpenEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'contact_tier',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    screen: '',
  },
);

export const ContactDropdownChangeEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'contact_tier',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    original_tier: '',
    updated_tier: '',
    upgrade_session_id: '',
    screen: '',
  },
);

export const TooltipOpenEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'tooltip',
    ui_object_detail: 'feature',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    tooltip_text: '',
    screen: '',
  },
);

export const PlanSelectionMadeEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'plan_type',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    plan_selected: '',
    screen: '',
  },
);

export const PlanSelectorCloseMonetizationFlowEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    screen: '',
  },
);

export const CheckoutCurrencyDropdownChangeEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_payment',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'currency',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    original_currency: '',
    updated_currency: '',
    upgrade_session_id: '',
    screen: '',
  },
);

export const CheckoutPaymentMethodSelectionEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_payment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'payment_method',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    selected_method: '',
    upgrade_session_id: '',
    screen: '',
  },
);

export const CheckoutCurrencyDropdownOpenEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_payment',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'currency',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    screen: '',
  },
);

export const CheckoutCloseMonetizationFlowEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_payment',
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    screen: '',
  },
);

export const CheckoutPaymentUnsuccessfullyEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_payment',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    upgrade_session_id: '',
    screen: '',
    order_id: '',
    order_amount: '',
    error_type: '',
    error_message: '',
    payment_method: '',
  },
);
