import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_discovery_experience',
  scope_area: 'integration',
  object: 'integration',
};

export const IntegrationFeatureCTAClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/manage',
    object_detail: 'post_connection_dashboard',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'integration_features',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
  },
);

export const HelpArticleClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/manage',
    object_detail: 'post_connection_dashboard',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'help_article',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
  },
);
