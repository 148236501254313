import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

const SHARED_DEFAULT_PROPERTIES = {
  scope_area: 'email_editor',

  object: 'campaign',
  object_detail: 'text_selection',
  action: 'customized',
};

export const AnchorLearnMoreLinkClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/editor',

    ui_object: 'link',
    ui_object_detail: 'learn_more_about_anchors',
    ui_action: 'clicked',
  },
  {
    ui_access_point: '',

    campaign_id: 0,
    campaign_type: '',
    nuni_section: '',
    template_id: 0,
  },
);

export const TemplateAnchorLearnMoreLinkClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/templates/editor',

    ui_object: 'link',
    ui_object_detail: 'learn_more_about_anchors',
    ui_action: 'clicked',
  },
  {
    ui_access_point: '',

    nuni_section: '',
    template_id: 0,
  },
);

export const AnchorToolSwitcherButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/editor',

    ui_object: 'icon',
    ui_object_detail: 'anchor',
    ui_action: 'clicked',
    ui_access_point: 'text_toolbar',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    nuni_section: '',
    template_id: 0,
  },
);

export const TemplateAnchorToolSwitcherButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/templates/editor',

    ui_object: 'icon',
    ui_object_detail: 'anchor',
    ui_action: 'clicked',
    ui_access_point: 'text_toolbar',
  },
  {
    nuni_section: '',
    template_id: 0,
  },
);

export const AnchorPickerModalClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/editor',

    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'anchor_properties_modal',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    nuni_section: '',
    template_id: 0,
  },
);

export const TemplateAnchorPickerModalClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/templates/editor',

    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'anchor_properties_modal',
  },
  {
    nuni_section: '',
    template_id: 0,
  },
);

export const AnchorPickerModalSavedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/editor',

    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'anchor_properties_modal',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    nuni_section: '',
    template_id: 0,
  },
);

export const TemplateAnchorPickerModalSavedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/templates/editor',

    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'anchor_properties_modal',
  },
  {
    nuni_section: '',
    template_id: 0,
  },
);

export const AnchorPickerModalRemovedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/editor',

    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'anchor_properties_modal',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    nuni_section: '',
    template_id: 0,
  },
);

export const TemplateAnchorPickerModalRemovedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...SHARED_DEFAULT_PROPERTIES,

    screen: '/email/templates/editor',

    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'anchor_properties_modal',
  },
  {
    nuni_section: '',
    template_id: 0,
  },
);
