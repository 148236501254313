import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const ShuffleBrandColorsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'templates',
    object: 'campaign',
    object_detail: 'template_preview_brand_colors',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'shuffle_colors',
    ui_action: 'clicked',
    ui_access_point: 'center',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    template_name: '',
  },
);

export const BrandColorSetApplied = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'templates',
    object: 'campaign',
    object_detail: 'template_preview_brand_colors',
    action: 'engaged',
    ui_object: 'color_card',
    ui_object_detail: 'brand_color_set',
    ui_action: 'clicked',
    ui_access_point: 'center',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    template_name: '',
  },
);

export const EditBrandColorsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'templates',
    object: 'campaign',
    object_detail: 'template_preview_brand_colors',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_colors',
    ui_action: 'clicked',
    ui_access_point: 'center',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    template_name: '',
  },
);

export const ResetBrandColorsEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'templates',
    object: 'campaign',
    object_detail: 'template_preview_brand_colors',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reset',
    ui_action: 'clicked',
    ui_access_point: 'center',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    template_name: '',
  },
);

export const LoadMoreBrandColorSetsEvents = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'templates',
    object: 'campaign',
    object_detail: 'template_preview_brand_colors',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'load_more',
    ui_action: 'clicked',
    ui_access_point: 'center',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    template_name: '',
  },
);
