import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',
  object: 'segment',
};

export const ecOpenSendToEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'bulk_email',
    object: 'campaign',
    object_detail: 'campaign_recipients',
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'send_to',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: 0,
  },
);

export const ecCreateSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_segment',
    ui_action: 'clicked',
    ui_access_point: 'segments_dropdown',
    mode: 'create',
  },
  {
    segmentation_method: '',
    campaign_id: '',
    pbs_used: '',
    funnel_id: '',
    has_elc_release_1_sms_cohort_ff: '',
    audience_is_sms_enabled: '',
  },
);

export const ecEditSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'edit_segment',
    ui_action: 'clicked',
    ui_access_point: 'segments_dropdown',
    mode: 'edit',
  },
  {
    segmentation_method: '',
    campaign_id: '',
    pbs_used: '',
    funnel_id: '',
    has_elc_release_1_sms_cohort_ff: '',
    audience_is_sms_enabled: '',
  },
);

export const ecSelectSavedSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'bulk_email',
    object_detail: 'campaign_recipients',
    action: 'engaged',
    ui_object: 'drop_down_item',
    ui_object_detail: 'send_to_saved_segment',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: 0,
    is_search_result: false,
    segment_id: '',
  },
);

export const ecSearchSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'bulk_email',
    object: 'campaign',
    object_detail: 'campaign_recipients',
    action: 'engaged',
    ui_object: 'search_bar',
    ui_object_detail: 'recipient_name_group',
    ui_action: 'typed',
    ui_access_point: 'send_to_dropdown',
  },
  {
    campaign_id: 0,
  },
);
