import { createEcsEvent } from '../../configuration/createEvent';

/*
Source of truth: Wayfinding tab on Mailchimp ECS v1 sheet:
https://docs.google.com/spreadsheets/d/1rqiSqIBT1nW5iCZhybVbx7YKsEh-B5fKHtPDq8dhgYY/edit#gid=652732638
*/

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'product_global_nav',
  object: 'navigation',
  action: 'selected',
  ui_object: 'sub_menu',
  ui_action: 'clicked',
  ui_access_point: 'right_header',
};

// Account L0
export const AppHeaderAccountL0Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'accounts_l0',
    ui_object_detail: 'accounts',
  },
  {
    screen: '/',
  },
);

// Account Notifications L1
export const AppHeaderAccountNotificationsL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_notifications_l1',
    ui_object_detail: 'notifications',
  },
  {
    screen: '/',
  },
);

// Account Profile L1
export const AppHeaderAccountProfileL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/account/profile',
    object_detail: 'account_profile_l1',
    ui_object_detail: 'profile',
  },
  {},
);

// Account and Billing L1
export const AppHeaderAccountAndBillingL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/account',
    object_detail: 'account_billing_l1',
    ui_object_detail: 'acount_&_billing',
  },
  {},
);

// Account Pricing Plans L1
export const AppHeaderAccountPricingPlansL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/products/',
    object_detail: 'account_pricing_l1',
    ui_object_detail: 'pricing_&_plans',
  },
  {},
);

// Account Hire an Expert L1
export const AppHeaderAccountHireAnExpertL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/experts/',
    object_detail: 'account_hire_expert_l1',
    ui_object_detail: 'hire_an_expert',
  },
  {},
);

// Account Get Cobrowse L1
export const AppHeaderAccountGetCobrowseL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_cobrowse_l1',
    ui_object_detail: 'get_cobrowse_code',
  },
  {
    screen: '/',
  },
);

// Account Logout L1
export const AppHeaderAccountLogoutL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_logout_l1',
    ui_object_detail: 'log_out',
  },
  {
    screen: '/',
  },
);

// Account Privacy L1
export const AppHeaderAccountPrivacyL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_privacy_l1',
    ui_object_detail: 'privacy',
    screen: 'https://www.intuit.com/privacy/statement/',
  },
  {},
);

// Account Privacy L1
export const AppHeaderAccountTermsL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_terms_l1',
    ui_object_detail: 'terms',
    screen: '/legal/terms/',
  },
  {},
);

// Account Cookie Preferences L1
export const AppHeaderAccountCookiePreferencesL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_cookie_preferences_l1',
    ui_object_detail: 'cookie_preferences',
  },
  {
    screen: '/',
  },
);
