import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user lands on the Business Address page."
 */
export const BusinessAddressLandedEvent = createEcsEvent(
  /**
   * This param contains the businessAddress0Event the event will contain.
   * These properties can never be changed inside of the businessAddress0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when a user clicks the Continue button leading to Checkout. "
 */
export const BusinessAddressContinueEvent = createEcsEvent(
  /**
   * This param contains the businessAddress1Event the event will contain.
   * These properties can never be changed inside of the businessAddress1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when a user clicks Back button. "
 */
export const BusinessAddressBackEvent = createEcsEvent(
  /**
   * This param contains the businessAddress2Event the event will contain.
   * These properties can never be changed inside of the businessAddress2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'back',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when a user encounters an error, possibly from not filling out required fields. "
 */
export const BusinessAddressLoadErrorEvent = createEcsEvent(
  /**
   * This param contains the businessAddress3Event the event will contain.
   * These properties can never be changed inside of the businessAddress3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
