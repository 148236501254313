import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_discovery_experience',
  scope_area: 'integration',
  object: 'integration',
};

export const IntegrationsPageViewed = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationsDirectoryHomeClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'clear_filters',
    ui_action: 'clicked',
    ui_access_point: 'header',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationsFindExpertButtonClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'find_an_expert',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationsClearFilterButtonClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'clear_filters',
    ui_action: 'clicked',
    ui_access_point: 'left',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationGotSearchResults = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory_search',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
    integration_search_filters: '',
    integration_search_count: 0,
  },
);

export const IntegrationsSearchPerformed = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'text_field',
    ui_object_detail: 'search_bar',
    ui_action: 'searched',
    ui_access_point: 'header',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
    integration_search_filters: '',
  },
);

export const IntegrationCardClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsAppPageEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'integration_name',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationsPaginationClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'directory_pagination',
    ui_action: 'clicked',
    ui_access_point: 'footer',
    user_id_required: false,
  },
  {
    integration_page_navigation: '',
  },
);

export const IntegrationZapierDotComCardViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'zapier_search_card',
    ui_action: 'loaded',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
  },
);

export const IntegrationZapierDotComCardClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/apps',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'zapier_search_card',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
  },
);
