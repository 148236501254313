import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'checkout',
};

export const SmsCheckoutPurchaseTriggerEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the SmsCheckoutPurchaseTriggerEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'purchase_trigger_sms_plan',
    ui_action: 'clicked',
  },
  {
    screen: '/sms',
    ui_object: '',
    ui_access_point: '',
    ui_object_detail: '',
  },
);

export const SplashPageFreeUsersCheckoutEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    object_detail: '',
    action: '',
    ui_object_detail: '',
  },
);

export const AutoPayExperienceCheckoutEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    ui_access_point: 'center',
    ui_action: 'clicked',
  },
  {
    object_detail: '',
    action: '',
    ui_object: '',
    ui_object_detail: '',
    credits_purchased: '',
  },
);

export const EssentialsCheckoutCompleteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'pay_now',
    ui_action: 'clicked',
    ui_access_point: 'bottom_sheet',
    object_detail: 'account_payment_inbox_messaging',
  },
  {
    sms_registration_status: '',
  },
);

export const EssentialsClicksUpgradeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'started',
    object_detail: 'upgrade_trigger_inbox_messaging',
    ui_object: 'button',
    ui_object_detail: 'upgrade_now',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsCreditsCheckoutEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_sms_credits',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_access_point: 'bottom_sheet',
  },
  {
    screen: '',
    action: '',
    ui_object_detail: '',
    sms_purchase_type: '',
    status: '',
    failure_reason: '',
    credits_purchased: '',
  },
);

export const SmsStartCheckoutEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_action: 'clicked',
  },
  {
    screen: '',
    object_detail: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    sms_campaign_id: '',
  },
);

export const SmsContinueToPaymentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms/setup',
    ui_access_point: 'center',
    ui_action: 'clicked',
    action: 'engaged',
    object_detail: 'account_payment_sms_credits',
    ui_object: 'button',
    ui_object_detail: 'next',
  },
  {
    credits_purchased: '',
  },
);

export const SmsFailedCheckoutEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_sms_credits',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_access_point: 'bottom_sheet',
    action: 'failed',
    ui_object_detail: 'update',
    status: 'failure',
    failure_reason: 'Failed to complete setup',
  },
  {
    screen: '',
    sms_purchase_type: '',
  },
);

export const SmsSuccessfulCheckoutEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_sms_credits',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_access_point: 'bottom_sheet',
    action: 'completed',
    ui_object_detail: 'pay_now',
    status: 'success',
  },
  {
    screen: '',
    sms_purchase_type: '',
  },
);
