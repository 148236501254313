import { createEcsEvent } from '../../configuration/createEvent';

/*
 * This event should fire when a user clicks on the "overview" tab of the horizontal navigation that shows up on most audience pages
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:116
 */

export const AudienceOrganizationOverviewEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'horizontal_audience_navigation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'overview',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
  },
);

/*
 * This event should fire when a user clicks on the "manage contacts" dropdown within the horizontal navigation that shows up on most audience pages
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:117
 */

export const AudienceOrganizationManageContactsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'horizontal_audience_navigation',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'manage_contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    manage_contacts_audience_h_nav_option: '',
  },
);

/*
 * This event should fire when a user clicks on the "add contacts" dropdown within the horizontal navigation that shows up on most audience pages
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:118
 */

export const AudienceOrganizationAddContactsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'horizontal_audience_navigation',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'add_contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    add_contacts_audience_h_nav_option: '',
  },
);

/*
 * This event should fire when a user clicks on the "subscriber preferences" tab of the horizontal navigation that shows up on most audience pages
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:119
 */

export const AudienceOrganizationSubscriberPreferencesEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'horizontal_audience_navigation',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'subscriber_preferences',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
  },
);

/*
 * This event should fire when a user clicks on the "settings"dropdown within the horizontal navigation that shows up on most audience pages
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:120
 */

export const AudienceOrganizationSettingsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'horizontal_audience_navigation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'settings',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    settings_audience_h_nav_option: '',
  },
);
