export const DEFAULT_EMAIL_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  object: 'campaign',
  editor_type: 'nuni',
};

export const DEFAULT_SELECT_EDITOR_MODAL_PROPERTIES = {
  ...DEFAULT_EMAIL_EVENT_PROPERTIES,
  scope_area: 'editor_selection',

  object: 'campaign',
  object_detail: 'default_editor_type',

  ui_action: 'clicked',
  ui_access_point: 'modal',
};

export const DEFAULT_PON_EVENT_PROPERTIES = {
  ...DEFAULT_EMAIL_EVENT_PROPERTIES,
  scope_area: 'email_editor',
  screen: '/email/editor',
  object_detail: 'digital_self_help',
  ui_object_detail: 'self_help_content',
};

export const DEFAULT_LIVE_EXPERT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'human_assistance',
  object: 'help_support',
  editor_type: 'nuni',
  scope_area: 'support',
  screen: '/email/editor',
  object_detail: 'live_help',
  ui_object_detail: 'live_expert_help',
  live_help_content: 'Live expert help',
};

export const MUTABLE_PON_EVENT_PROPERTIES = {
  campaign_id: 0,
  template_id: 0,
  platform_type: '',
  campaign_type: '',
  digital_self_help_button_text: '',
};

export const MUTABLE_LIVE_EXPERT_EVENT_PROPERTIES = {
  campaign_id: 0,
  template_id: 0,
  platform_type: '',
  campaign_type: '',
};
