import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'email_editor',
  object: 'campaign',
  object_detail: 'preview_content',
  action: 'engaged',
  ui_action: 'clicked',
};

const DEFAULT_EVENT_SPECIFIC_PARAMS = {
  campaign_id: 0,
  campaign_type: '',
  editor_type: '',
  template_id: 0,
  screen: '',
};

export const ChecklistPreviewClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'preview',
    ui_access_point: 'checklist_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
    screen: '/campaigns/edit',
  },
);

export const ToggleLiveMergeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'switch',
    ui_object_detail: 'enable_live_merge_tag_info',
    ui_access_point: 'header_info_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
    toggle_status: false,
  },
);

export const SwitchRecipientAddressEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'arrow',
    ui_access_point: 'header_info_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const SearchEmailAddressEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,

    ui_object: 'dropdown_item',
    ui_object_detail: 'search_for_an_email',
    ui_access_point: 'header_info_side_panel',
  },
  { ...DEFAULT_EVENT_SPECIFIC_PARAMS },
);

export const DesktopTabClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'desktop',
    ui_access_point: 'preview_wizard_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const MobileTabClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'mobile',
    ui_access_point: 'preview_wizard_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const InboxTabClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'inbox',
    ui_access_point: 'preview_wizard_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const ClosePreviewClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_access_point: 'preview_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const GetStartedClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const LearnMoreAboutInboxClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'learn_more_about_inbox_preview',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const SelectEmailClientEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'checkbox_button',
    ui_object_detail: 'email_client_name',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
    email_client_type: '',
  },
);

export const AddClientsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'add_x_clients',
    ui_access_point: 'preview_side_panel_footer',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
    email_client_type: [],
  },
);

export const XTokensButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'x_tokens',
    ui_access_point: 'preview_side_panel_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const UpdateClientsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'update_header',
    ui_access_point: 'preview_side_panel_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const AddClientHeaderClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'add_client',
    ui_access_point: 'preview_side_panel_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const EmalClientRowClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'checkbox',
    ui_object_detail: 'email_client_row',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const UpdateLinkClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'update',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const ViewHistoryLinkClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'view_history',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const LitmusLinkClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaigns/edit',
    ui_object: 'link',
    ui_object_detail: 'litmus',
    ui_access_point: 'purchase_tokens_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const SignUpForLitmusLinkClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaigns/edit',
    ui_object: 'link',
    ui_object_detail: 'sign_up_for_litmus',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const UpgradeYourAccountClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaigns/edit',
    ui_object: 'button',
    ui_object_detail: 'upgrade_your_account',
    ui_access_point: 'preview_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);
