import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_guideme',
  scope_area: 'homepage',
  screen: '/',
  object: 'reporting',
  object_detail: 'marketing_snapshot',
  homepage_content: 'marketing snapshot',
};

// Note: MS is Marketing Snapshot
// Event sent when an user views the MS module.
export const MSViewedEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_object_detail: 'marketing_snapshot',
    ui_action: 'loaded',
    ui_access_point: 'marketing_snapshot_module',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {},
);

export const MSChannelSelectionEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'channel_selection',
    ui_action: 'clicked',
    ui_access_point: 'marketing_snapshot_module',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    marketing_snapshot_channel_selection: '', // 'all', 'email', 'sms'
  },
);

export const MSTimeFrameSelectionEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'day_selector',
    ui_action: 'clicked',
    ui_access_point: 'marketing_snapshot_module',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    num_of_days: '', // '7', '30', '60'
  },
);

export const MSEmptyDraftSelectionEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'empty_draft_state_selection',
    ui_action: 'clicked',
    ui_access_point: 'marketing_snapshot_module',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    empty_draft_state_selection: '', // 'connect store', 'create email', 'continue working'
  },
);

export const MSLinksEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'in_line_cta_links',
    ui_action: 'clicked',
    ui_access_point: 'marketing_snapshot_module',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    inline_cta_link_text: '', // all inline cta links like 'see marketing dashboard' or 'view all drafts' etc.
  },
);
