import { createEcsEvent } from '../../configuration/createEvent';

// EmailGenerationEvent
const EMAIL_GENERATION_PROPERTIES = {
  scope_area: 'bulk_campaigns',
  screen: 'create_campaign',
  object: 'campaign',
  object_detail: 'content_generation',
};

export const clickCreateAIProductEmailEvent = createEcsEvent(
  {
    ...EMAIL_GENERATION_PROPERTIES,
    action: 'created',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'create_with_ai',
    ui_access_point: 'create_front_door',
  },
  {
    access_to_ecu_content_gen_beta: true,
    ai_email_type: '',
  },
);

export const productEmailGeneratedEvent = createEcsEvent(
  {
    ...EMAIL_GENERATION_PROPERTIES,
    action: 'customized',
  },
  {
    access_to_ecu_content_gen_beta: true,
    ai_email_type: '',
    intuit_tid: '',
    prompt_id: '',
    user_name: '',
    product_name: '',
    product_price: '',
    product_description: '',
    product_url: '',
    campaign_id: '',
    branding_intuit_tids: '',
  },
);

export const productEmailBrandingVariantSelectedEvent = createEcsEvent(
  {
    ...EMAIL_GENERATION_PROPERTIES,
    action: 'selected',
    ui_access_point: 'front_door_loading_screen',
    ui_object_detail: 'template_selector',
  },
  {
    access_to_ecu_content_gen_beta: true,
    ai_email_type: '',
    campaign_id: '',
    variant_chosen: '',
  },
);

export const productEmailSkipBrandingEvent = createEcsEvent(
  {
    ...EMAIL_GENERATION_PROPERTIES,
    action: 'selected',
    ui_access_point: 'front_door_loading_screen',
    ui_object_detail: 'no_thanks_will_do_later',
  },
  {
    access_to_ecu_content_gen_beta: true,
    ai_email_type: '',
    campaign_id: '',
    variant_chosen: '',
  },
);

// Image-Related Events
const IMAGE_PROPERTIES = {
  purpose: 'prod',

  scope_area: 'smart_content_generation',
  initiative_name: 'core_offerings',
  screen: 'customer-journey/builder',

  object: 'automations',
  object_detail: 'prebuilt_journey',
  action: 'engaged',
  ui_access_point: 'smart_draft_PBJ_preview',
};

export const ReplaceImageClickEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'replace_image',
    ui_action: 'clicked',
  },
  {
    ai_email_type: 'welcome new contacts',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: 1,
  },
);

export const CancelImageReplacementClickEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);

export const SaveImageReplacementClickEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);

export const EditInBuilderClickEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'edit_in_email_builder',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);

export const ImageSelectedInContentStudioEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'image',
    ui_object_detail: 'smart_draft_image',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);

export const SortImageLibraryEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'dropdown',
    ui_object_detail: 'sort_selection',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
    filter_by_uploader: '',
    filter_by_content_type: '',
    filter_by_other: '', // <type: string> (possible values: Newest first, Oldest first, Smallest first, Largest first, A-Z, Z-A)
  },
);

export const SearchImageLibraryEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'search_bar',
    ui_object_detail: 'search_open_text',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
    genai_image_search_criteria: '', // 128 char limit
  },
);

export const UploadImageLibraryClickEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'upload',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);

export const ImageLibraryUploadSuccessEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'image',
    ui_object_detail: 'upload_success',
    ui_action: '',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);

export const ImageLibraryUploadFailureEvent = createEcsEvent(
  {
    ...IMAGE_PROPERTIES,
    ui_object: 'image',
    ui_object_detail: 'upload_failed',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    template_id: 1,
    campaign_id: 1,
    intuit_tid: '',
  },
);
