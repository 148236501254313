import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_experience',
  scope_area: 'integration',
  object: 'integration',
  object_detail: 'integration_app_settings',
  action: 'engaged',
  screen: 'integrations/manage',
  ui_object: 'button',
  ui_action: 'clicked',
};

export const ClickEdit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'edit',
    ui_access_point: 'center',
  },
  { integration_name: '', integration_setting_id: '' },
);

export const ClickFollowLink = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'follow_link',
    ui_access_point: 'modal',
  },
  { integration_name: '', integration_setting_id: '' },
);

export const ModalDismissed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'x',
    ui_access_point: 'modal',
  },
  { integration_name: '', integration_setting_id: '' },
);

export const ClickGoBack = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'go_back',
    ui_access_point: 'modal',
  },
  { integration_name: '', integration_setting_id: '' },
);
