import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the LI step"
 */
export const LaborIllusionViewedEvent = createEcsEvent(
  /**
   * This param contains the LaborIllusionViewedEvent the event will contain.
   * These properties can never be changed inside of the LaborIllusionViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'labor_illusion_start',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when the LI module completes"
 */
export const LaborIllusionViewedCompleteEvent = createEcsEvent(
  /**
   * This param contains the LaborIllusionViewedCompleteEvent the event will contain.
   * These properties can never be changed inside of the LaborIllusionViewedCompleteEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'labor_illusion_end',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when the user manually redirects from the LI Modual"
 */
export const LaborIllusionEngagedRedirectEvent = createEcsEvent(
  /**
   * This param contains the LaborIllusionEngagedRedirectEvent the event will contain.
   * These properties can never be changed inside of the LaborIllusionEngagedRedirectEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'labor_illusion',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'click_here',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);
