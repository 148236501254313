import { createEcsEvent } from '../../configuration/createEvent';

export const AudienceListsMembersOnLoad = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'list/members',
    object: 'audience',
    object_detail: 'all_contacts',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);
