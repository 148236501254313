import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'human_assistance',
  scope_area: 'support',
  object: 'content',
  object_detail: 'ada',
  action: 'engaged',
  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: '3rd_party_addin',
};

export const PhoneSupportRequestedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    ui_object_detail: 'contact_support_via_phone',
  },
  {
    screen: '',
  },
);

export const LiveChatSelectedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    ui_object_detail: 'contact_support_via_chat',
  },
  {
    screen: '',
  },
);
