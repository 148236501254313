import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const CampaignAdjustProductBlockColorPickerMenu = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'product_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'adjusted',
    ui_access_point: 'side_panel',
    ui_object_detail: 'product_styles',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    template_name: '',
    platform_type: '',
    adjusted_style: '',
  },
);

export const TemplateAdjustProductBlockColorPickerMenu = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'product_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'adjusted',
    ui_access_point: 'side_panel',
    ui_object_detail: 'product_styles',
  },
  {
    user_template_id: 0,
    template_id: 0,
    template_name: '',
    platform_type: '',
    adjusted_style: '',
  },
);

export const ColorSelectionViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'color_selection',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'color',
    ui_action: 'loaded',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    color_viewed_from: '',
    ui_access_point: '',
  },
);

export const ColorSelectionAppliedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'color_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'color',
    ui_action: 'applied',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    color_viewed_from: '',
    ui_access_point: '',
    applied_method: '',
  },
);
