import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  screen: 'settings/data',
  initiative_name: 'react_migration_account_settings',
  scope_area: 'account_settings',
  object: 'data',
  action: 'engaged',
  ui_action: 'clicked',
  ui_access_point: 'center',
};

export const DataStatementLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    object_detail: 'external_link_statements',
  },
  {
    // used for 3 links
    // 'privacy_statement_analytics', 'privacy_statement_advertising', 'cookie_statement'
    ui_object_detail: '',
  },
);

export const CompletedExportLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'completed_export',
  },
  {},
);

export const SaveEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'save_button',
  },
  {
    // used for 2 buttons
    // 'save_data_analytics_settings', 'save_personalized_ad_settings'
    object_detail: '',
  },
);

export const ExportDataEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'export_data',
    object_detail: 'submit_export',
  },
  {},
);

export const ChangePreferencesEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'checkbox',
    ui_object_detail: ' change_preference',
  },
  {
    // used for 2 formfields using checkboxes
    // 'update_data_analytics_settings', 'update_personalized_ad_settings'
    object_detail: '',
  },
);

export const ExportDataTypeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'checkbox',
    ui_object_detail: 'select_data_type',
    object_detail: 'update_export_options',
  },
  {},
);
export const PredictedDemographicsLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'predicted_demographic',
  },
  {},
);
