import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const ChangeDisplayTypeEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'social_content',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'social_display_type',
    ui_access_point: 'side_panel',
    ui_action: 'selected',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'social',
    nuni_social_display_type: '',
  },
);
export const TemplateChangeDisplayTypeEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'social_content',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'social_display_type',
    ui_access_point: 'side_panel',
    ui_action: 'selected',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'social',
    nuni_social_display_type: '',
  },
);
