import { createEcsEvent } from '../../../configuration/createEvent';

const pageVisitProps = {
  object: 'reporting',
  action: 'viewed',
  purpose: 'prod',
  initiative_name: 'marketing_crm_analytics',
  scope_area: 'campaign_analytics',
};

export const ClickPerformancePageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/clicks',
    object_detail: 'email_click_performance_report',
  },
  {},
);

export const RecipientActivitySentPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/sent',
    object_detail: 'email_activity_sent_report',
  },
  {},
);

export const RecipientActivityOpenedPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/open',
    object_detail: 'email_activity_opened_report',
  },
  {},
);

export const RecipientActivityNotOpenedPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/not-opened',
    object_detail: 'email_activity_not_opened_report',
  },
  {},
);

export const RecipientActivityClickedPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/clicked',
    object_detail: 'email_activity_clicked_report',
  },
  {},
);

export const RecipientActivityBouncedPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/bounced',
    object_detail: 'email_activity_bounced_report',
  },
  {},
);

export const RecipientActivityUnsubscribedPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/unsubscribed',
    object_detail: 'email_activity_unsubscribed_report',
  },
  {},
);

export const RecipientActivityComplainedPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/activity/abuse',
    object_detail: 'email_activity_complained_report',
  },
  {},
);

export const EcommerceProductActivityPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/ecommerce/activity',
    object_detail: 'email_ecomm_product_activity_report',
  },
  {},
);

export const EcommerceOrderHistoryPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/ecommerce/history',
    object_detail: 'email_ecomm_order_history_report',
  },
  {},
);

export const SocialPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: 'reports/social-stats',
    object_detail: 'email_social_report',
  },
  {},
);
