import { createEcsEvent } from '../../configuration/createEvent';

import {
  OBJECT_DETAIL_EMBEDDED,
  OBJECT_DETAIL_LANDING_PAGE,
  OBJECT_DETAIL_PAYOFF_FOCUSED_ONBOARDING,
  OBJECT_DETAIL_POPUP,
  UI_ACCESS_POINT_CENTER,
  UI_OBJECT_DETAIL_PAYOFF_FOCUSED,
  VHP_FORM_PROPERTIES,
} from './constants';

const ECS_EVENT_OBJECT_RECOMMENDATION = 'recommendation';
const ECS_EVENT_ACTION_PROPERTY_STARTED = 'started';
const ECS_EVENT_UI_ACTION_CLICKED = 'clicked';
const ECS_EVENT_UI_OBJECT_LINK = 'link';
const ECS_EVENT_UI_OBJECT_AUDIENCE = 'audience';
const ECS_EVENT_ACTION_PROPERTY_VIEWED = 'viewed';
const ECS_EVENT_UI_OBJECT_PAGE = 'page';
const ECS_EVENT_UI_ACTION_LOADED = 'loaded';
const ECS_EVENT_SCOPE_AREA_LEAD_GENERATION = 'lead_generation';
const ECS_EVENT_INITIATIVE_NAME_MCM = 'marketing_channel_management';

const baseVHPFormsEvent = {
  ...VHP_FORM_PROPERTIES,
  ui_object: ECS_EVENT_OBJECT_RECOMMENDATION,
  object_detail: OBJECT_DETAIL_PAYOFF_FOCUSED_ONBOARDING,
};

const baseFormsClickEvent = {
  ...VHP_FORM_PROPERTIES,
  action: ECS_EVENT_ACTION_PROPERTY_STARTED,
  initiative_name: ECS_EVENT_INITIATIVE_NAME_MCM,
  scope_area: ECS_EVENT_SCOPE_AREA_LEAD_GENERATION,
  screen: '/',
  object: ECS_EVENT_UI_OBJECT_AUDIENCE,
  ui_object: ECS_EVENT_UI_OBJECT_LINK,
  ui_action: ECS_EVENT_UI_ACTION_CLICKED,
  ui_access_point: UI_ACCESS_POINT_CENTER,
};

export const VHPFormsOverviewPageEvent = createEcsEvent(
  {
    ...baseVHPFormsEvent,
    action: ECS_EVENT_ACTION_PROPERTY_VIEWED,
    ui_object: ECS_EVENT_UI_OBJECT_PAGE,
    ui_object_detail: UI_OBJECT_DETAIL_PAYOFF_FOCUSED,
    ui_action: ECS_EVENT_UI_ACTION_LOADED,
    ui_access_point: UI_ACCESS_POINT_CENTER,
  },
  {},
);

export const VHPPopUpFormClickEvent = createEcsEvent(
  {
    ...baseFormsClickEvent,
    object_detail: OBJECT_DETAIL_POPUP,
    ui_object_detail: OBJECT_DETAIL_POPUP,
  },
  {},
);

export const VHPEmbeddedFormClickEvent = createEcsEvent(
  {
    ...baseFormsClickEvent,
    object_detail: OBJECT_DETAIL_EMBEDDED,
    ui_object_detail: OBJECT_DETAIL_EMBEDDED,
  },
  {},
);

export const VHPLandingPageClickEvent = createEcsEvent(
  {
    ...baseFormsClickEvent,
    object_detail: OBJECT_DETAIL_LANDING_PAGE,
    ui_object_detail: OBJECT_DETAIL_LANDING_PAGE,
  },
  {},
);
