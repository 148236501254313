import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_guideme',
  scope_area: 'homepage',
  object: 'recommendation',
  object_detail: 'integration_takeover',
  homepage_content: 'integration takeover',
};

// NOTE: IT stands for Integration Takeover
// Event sent when an user views the full screen takeover.
export const ITFullScreenViewEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/new-user-integration/',
    action: 'viewed',
    ui_object: 'page',
    ui_object_detail: 'integration_takeover',
    ui_action: 'loaded',
    ui_access_point: 'integration_takeover_fullscreen',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {},
);

// Event sent when an user views the full screen takeover.
export const ITModalViewEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'integration_takeover',
    ui_action: 'loaded',
    ui_access_point: 'integration_takeover_modal',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {},
);

// Event sent when an user selects an integration on the modal.
export const ITIntegrationSelectionEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'integration_selection',
    ui_action: 'clicked',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    screen: '/', // will be /new-user-integration if full screen takeover
    ui_access_point: '', // integration_takeover_fullscreen or integration_takeover_modal
    integration_name: '', // Shopify, etc.
    integration_url: '',
  },
);

// Event sent when an user clicks a primary CTA on the modal.
export const ITPrimaryCTAEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_my_store',
    ui_action: 'clicked',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    screen: '/', // will be /new-user-integration if full screen
    ui_access_point: '', // integration_takeover_fullscreen or integration_takeover_modal
    integration_name: '', // Shopify, etc.
  },
);

// Event sent when an user clicks a primary CTA on the modal.
export const ITSecondaryCTAEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    screen: '/', // will be /new-user-integration if full screen
    ui_object_detail: '', // ill_do_this_later or x
    ui_access_point: '', // integration_takeover_fullscreen or integration_takeover_modal
  },
);
