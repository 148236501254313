import { createEcsEvent } from '../../../configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  scope_area: 'integration',
  screen: 'integrations/manage',
  object: 'integration',
  object_detail: 'manage_product_feeds',
  action: 'engaged',
  ui_object: 'button',
  ui_action: 'clicked',
};

export const CreateProductRecFeedClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
  },
  {
    ui_object_detail: 'create_a_new_product_feed',
    ui_access_point: '',
    store_id: -1,
  },
);

export const ReplicateProductRecFeedClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    ui_object_detail: 'replicate',
  },
  {
    ui_access_point: '',
    store_id: -1,
    product_feed_id: -1,
  },
);

export const EditProductRecFeedClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    ui_object_detail: 'edit',
  },
  {
    ui_access_point: '',
    store_id: -1,
    product_feed_id: -1,
  },
);

export const DeleteProductRecFeedClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
  },
  {
    ui_object_detail: 'delete',
    ui_access_point: '',
    store_id: -1,
    num_of_product_feeds_deleted: -1,
  },
);

export const SaveProductRecFeedClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,
    ui_object_detail: 'save',
    ui_access_point: 'bottom_sheet',
  },
  {
    store_id: -1,
    product_feed_id: -1,
  },
);
