import { createEcsEvent } from '../../configuration/createEvent';

// Properties that will never change. screen is '/' since this is the homepage.
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  screen: '/',
  homepage_content: 'homepage dashboard',
};

// Event sent when an user clicks audience dropdown.
export const AudienceDropDownEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience_select',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

// Event sent when an user clicks a shortcut / quick action.
export const ShortcutClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'homepage_quick_action',
  },
  {
    initiative_name: '',
    scope_area: '',
    object: '',
    object_detail: '',
    action: '',
    ui_object_detail: '',
  },
);

// Event sent when an user clicks a tooltip.
export const TooltipClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'tooltip',
    ui_object_detail: 'i',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    initiative_name: '',
    scope_area: '',
    object: '',
    object_detail: '',
  },
);

// Zero state refers to the state the user sees if they haven't started on the module.
// Event sent when an user clicks the "Use this journey" button on the zero-state Prebuilt Journey tiles.
export const ZeroStatePBJCTAEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'customer_journey_builder',
    object: 'automations',
    object_detail: 'previewed_prebuilt_journey',
    action: 'selected',
    ui_object: 'link',
    ui_object_detail: 'use_this_prebuilt_journey',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    pbj_type: '',
  },
);

// Event sent when an user clicks the "See all" or "All" CTAs next to the module header.
export const SeeCTAEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    initiative_name: '',
    scope_area: '',
    object: '',
    object_detail: '',
    ui_object_detail: '',
  },
);

// Event sent when an user clicks on the app icons on the zero state revenue module.
export const AppIconClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'integration_experience',
    scope_area: 'integration',
    object: 'integration',
    object_detail: 'connect_store',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'ecommerce_app_tile',
    ui_action: 'clicked',
    ui_access_point: 'revenue_datawell',
  },
  {
    app_tile_content: '',
  },
);

// Event sent when an user clicks on the Add contacts CTA only shown in the zero state audience module.
export const ZeroStateAudienceCTAEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    object: 'audience',
    object_detail: 'audience_overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

// Event sent when an user clicks on the first campaign CTA only shown in the zero state campaign module.
export const ZeroStateCampaignCTAEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'bulk_campaigns',
    object: 'campaign',
    object_detail: 'bulk_campaign',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'create_your_first_campaign',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const TimeFrameChangeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'analytics',
    object: 'reporting',
    action: 'customized',
    ui_object: 'button',
    ui_object_detail: 'day_selector',
    ui_action: 'clicked',
  },
  {
    initiative_name: '',
    object_detail: '',
    ui_access_point: '',
    num_of_days: '',
  },
);

export const CampaignNameClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'campaign_report',
    object: 'reporting',
    object_detail: 'individual_touchpoint',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'report_name',
    ui_action: 'clicked',
    ui_access_point: 'campaign_table',
  },
  {},
);

export const ReplicateCampaignClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'bulk_email',
    scope_area: 'bulk_campaigns',
    object: 'campaign',
    object_detail: 'bulk_campaign',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'plus_box',
    ui_action: 'clicked',
    ui_access_point: 'campaign_table',
  },
  {
    campaign_type: '',
  },
);

export const MarketingPerformanceInfoToggled = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    object: 'reporting',
    object_detail: 'marketing_performance',
    action: 'engaged',
    ui_object: 'toggletip',
    ui_object_detail: 'i',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const RevenueInfoToggled = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    object: 'reporting',
    object_detail: 'store_performance',
    action: 'engaged',
    ui_object: 'toggletip',
    ui_object_detail: 'i',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const CampaignPerformanceInfoToggled = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'bulk_campaigns',
    object: 'campaign',
    object_detail: 'campaign_overview',
    action: 'engaged',
    ui_object: 'toggletip',
    ui_object_detail: 'i',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);
