import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'classic_automations',
  object: 'automations',
  ui_action: 'clicked',
  ui_access_point: 'modal',
};

const CONFIGURABLE_PROPERTIES = {
  experience: '',
};

export const SelectClassicAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    ui_object: 'card',
    ui_object_detail: 'select_classic_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CloseClassicAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'close_automation_modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const SelectCustomAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'select_custom_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartCustomAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_custom_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelCustomAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_custom_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartAddTagAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_add_tag_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelAddTagAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_add_tag_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartWelcomeAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_welcome_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelWelcomeAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_welcome_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartBirthdayAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_birthday_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelBirthdayAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_birthday_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartRssAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_rss_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelRssAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_rss_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartAutomationFromStore = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_store_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelAutomationFromStore = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_store_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartOrderNotificationAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_order_notification_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelOrderNotificationAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_order_notification_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartListActivityAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_list_activity_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelListActivityAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_list_activity_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartSitePopupThankYouAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_site_popup_thankyou_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelSitePopupThankYouAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_site_popup_thankyou_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartFirstTimeAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_first_time_customer_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelFirstTimeAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_first_time_customer_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartBestCustomersAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_best_customers_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelBestCustomersAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_best_customers_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartRetargetAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_product_retargeting_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelRetargetAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_product_retargeting_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartPurchaseFollowupAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_purchase_followup_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelPurchaseFollowupAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_purchase_followup_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartCustomerReengagementAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_customer_reengagement_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelCustomerReengagementAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_customer_reengagement_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartListAddedDateAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_list_added_date_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelListAddedDateAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_list_added_date_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartListAddedDateSeriesAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_list_added_date_series_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelListAddedDateSeriesAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_list_added_date_series_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartSpecificDateAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_specific_date_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelSpecificDateAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_specific_date_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartRecurringDateAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_recurring_date_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelRecurringDateAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_recurring_date_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartApi30Automation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_api30_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelApi30Automation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_api30_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartEventApiAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_event_api_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelEventApiAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_event_api_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartSocialAdAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_social_ad_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelSocialAdAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_social_ad_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartSitePopupAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_site_popup_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelSitePopupAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_site_popup_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartPromoteLandingPageAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_promote_landing_page_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelPromoteLandingPageAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_promote_landing_page_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartProductPageAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_product_page_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelProductPageAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_product_page_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const StartEmbeddedFormAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_embedded_form_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const CancelEmbeddedFormAutomation = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'canceled',
    ui_object: 'button',
    ui_object_detail: 'canceled_embedded_form_automation',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);
