import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_domain',

  object: 'account',

  action: '',

  ui_object: '',
  ui_object_detail: '',
  ui_action: '',
  ui_access_point: '',
};

const CONFIGURABLE_PROPERTIES = {
  screen: '',
};

export const EntriCheckDomainResponseEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object_detail: 'entri_check_domain',
    action: 'viewed',
  },
  {
    ...CONFIGURABLE_PROPERTIES,

    email_domains: null,
    entri_provider: '',
    entri_setup_type: '',
  },
);

export const EntriCloseEntriEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object_detail: 'entri_show_entri',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,

    entri_last_step: '',

    email_domains: null,
    entri_result_code: null,
    entri_result_title: null,
    entri_result_details: null,
  },
);

export const EntriManualAuthenticationClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object_detail: 'entri_show_entri',

    action: 'engaged',

    ui_object: 'link',
    ui_object_detail: 'or_manually_authenticate_your_domain',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,

    email_domains: null,
    entri_result_code: null,
    entri_result_title: null,
    entri_result_details: null,
  },
);

export const EntriShowEntriEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object_detail: 'entri_show_entri',
    action: 'viewed',
  },
  {
    ...CONFIGURABLE_PROPERTIES,

    email_domains: null,
  },
);

export const EntriStepChangeEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object_detail: 'entri_show_entri',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,

    email_domains: null,
    entri_current_step: '',
    entri_result_code: null,
    entri_result_title: null,
    entri_result_details: null,
  },
);
