import { createEcsEvent } from '../../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: '/analytics/audience-analytics/',
  object: 'reporting',
  object_detail: 'audience_analytics',
};

export const ContactBreakdownChannelTabClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: 'contacts_breakdown_tab',
    ui_action: 'clicked',
    ui_access_point: 'contacts_breakdown_tab',
  },
  {
    tab_name: '',
  },
);

export const ContactBreakdownTimeframeClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'contacts_breakdown_timeframe_button',
    ui_action: 'clicked',
    ui_access_point: 'contacts_breakdown_button',
  },
  {
    timeframe: '',
  },
);

export const AudienceDashboardDateRangeDropDownSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'customized',
    ui_object: 'datepicker',
    ui_object_detail: 'date_range_popover',
    ui_action: 'updated',
    ui_access_point: 'date_range_filter',
  },
  {
    date_range: '',
  },
);

export const AudienceAnalyticsVisited = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    referrer: '',
    plan_type: '',
    date_range: '',
    zero_state: '',
  },
);

export const AudienceAnalyticsCustomizeAudience = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'customized',
    ui_object: 'drop_down',
    ui_object_detail: 'drop_down',
    ui_action: 'customized',
    ui_access_point: 'audience_filter',
  },
  {},
);

export const AudienceAnalyticsCustomizeTimeframe = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'customized',
    ui_object: 'drop_down',
    ui_object_detail: 'drop_down',
    ui_action: 'customized',
    ui_access_point: 'date_range_filter',
  },
  {
    date_range: '',
    period_text: '',
  },
);
