import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'crm_composite_app',
  object: 'audience',
  object_detail: 'crm_auto_enablement_terms_of_service',
};

const CONFIGURABLE_PROPERTIES = {
  screen: '',
  tos_entry_workflow: '',
  tos_entry_button_pressed: '',
};

export const AutoEnablementViewTermsOfService = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'crm_auto_enablement_modal',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const AutoEnablementAcceptedTermsOfService = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'accept_and_continue',
    ui_action: 'clicked',
    ui_access_point: 'crm_auto_enablement_modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const AutoEnablementDeclinedTermsOfService = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'decline',
    ui_action: 'clicked',
    ui_access_point: 'crm_auto_enablement_modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);
