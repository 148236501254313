import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'all_contacts',
  object: 'audience',
};

export const importFlowViewedEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_flow',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    previous_page_url: '',
    has_elc_release_1_sms_cohort_ff: '',

    // list import highlight integrations experiment
    is_integration_import_enabled: '',
  },
);

export const importFlowChooseMethodAndContinueEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'choose_method',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    has_elc_release_1_sms_cohort_ff: '',

    // list import highlight integrations experiment
    is_integration_import_enabled: '',
  },
);

export const importFlowContinueToOrganizeCopyPasteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'copy_paste_contacts',
    action: 'uploaded',
    ui_object: 'button',
    ui_object_detail: 'continue_to_organize',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowContinueToOrganizeCopyPasteErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'copy_paste_contacts',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'continue_to_organize',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_error: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowContinueToOrganizeFileUploadEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'upload_contacts',
    action: 'uploaded',
    ui_object: 'button',
    ui_object_detail: 'continue_to_organize',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    file_upload_method: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowContinueToOrganizeFileUploadErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'upload_contacts',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'continue_to_organize',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    import_contact_error: '',
    upload_file_type: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowContinueToTagEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'organize_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_tag',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    subscription_status: '',
    update_existing_contacts: '',
    groups: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowContinueToTagErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'organize_contacts',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'continue_to_tag',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    import_contact_error: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);
export const importFlowAddPopularTagEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'tag_contacts',
    action: 'selected',
    ui_object: 'option',
    ui_object_detail: 'popular_tag',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);
export const importFlowAddExistingTagEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'tag_contacts',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowContinueToMatchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'tag_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_match',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    num_tags_selected: '',
    tag_names: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);
export const importFlowContinueToMatchErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'tag_contacts',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'continue_to_match',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    import_contact_error: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowFinalizeImportEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'column_matching',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'finalize_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowFinalizeImportErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'column_matching',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'finalize_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    import_contact_error: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowCompleteImportEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'review',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'complete_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowCompleteImportErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'review',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'complete_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    import_contact_error: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowCancelImportEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'review',
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'cancel_import',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowExitEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_flow',
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'exit',
    ui_action: 'clicked',
    ui_access_point: 'top_header',
  },
  {
    import_contact_method: '',
    import_current_step: '',
    has_elc_release_1_sms_cohort_ff: '',

    // list import highlight integrations experiment
    is_integration_import_enabled: '',
  },
);

/**
 * This event should fire each time the SMS help article is clicked.
 * ECS Standards > Import Flow @ 8
 */
export const importFlowSMSHelpLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'choose_method',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'import_sms_contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

/**
 * Fire event when the subscription status article is clicked.
 * ECS Standards > Import Flow @ 24
 */
export const importFlowSMSLearnAboutSubscriptionLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'contact_subscription',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'subscription_status',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

/**
 * Fire event when the SMS consent article is clicked.
 * ECS Standards > Import Flow @ 25
 */
export const importFlowSMSConsentLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'contact_subscription',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'sms_marketing_consent',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

/**
 * This event should fire when a user clicks the button that allows them to move to the next step. Content on button may vary.
 * ECS Standards > Import Flow @ 26
 */
export const importFlowSMSContactSubcriptionContinueEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'contact_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_elc_release_1_sms_cohort_ff: '',
    viewed_marketing_channel_fields: '',
    import_contact_method: '',
    email_subscription_status: '',
    sms_subscription_status: '',
  },
);

/**
 * This event should fire if a user encounters an error while trying to progress to the next step
 * ECS Standards > Import Flow @ 27
 */
export const importFlowSMSContactSubcriptionContinueErrorEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'contact_subscription',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    consent_option: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const importFlowSMSConsentAndContinueEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'sms_consent',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    consent_option: '',
  },
);

export const importFlowSMSConsentModalViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'sms_consent',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowSMSConsentModalAcceptedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'sms_consent',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'ok',
    ui_action: 'clicked',
    ui_access_point: 'marketing_consent_modal',
  },
  {
    import_contact_method: '',
    consent_option: '',
  },
);

export const importFlowSMSConsentModalClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'sms_consent',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'marketing_consent_modal',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowConsentLinkClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'choose_method',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'consent_impacts_email_delivery',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowFileUploadMoreInfoLinkClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'choose_method',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'more_info',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_step: '',
    import_contact_method: '',
  },
);

export const importFlowHowToUploadFileLinkClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'upload_contacts',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_how',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowImportanceOfPermissionLinkClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'organize_contacts',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'importance_of_permission',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowSelectAudienceEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'organize_contacts',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience_switcher',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

export const importFlowRecoveryScreenViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_recovery',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    has_elc_release_1_sms_cohort_ff: '',
    import_contact_method: '',
  },
);

export const importFlowRecoveryRetryEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_recovery',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'retry',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_elc_release_1_sms_cohort_ff: '',
    import_contact_method: '',
  },
);

export const importFlowBreadCrumbClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_flow',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'import_step',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    has_elc_release_1_sms_cohort_ff: '',
    import_current_step: '',
    import_step_clicked: '',
  },
);

export const importFlowSelfHelpCtaClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'digital_self_help',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'help_selection',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
    digital_self_help_button_text: '',
    import_current_step: '',
  },
);

export const importFlowSelfHelpCtaViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'digital_self_help',
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'self_help_content',
    ui_action: 'loaded',
  },
  {
    ui_access_point: '',
    import_contact_method: '',
    import_current_step: '',
    digital_self_help_button_text: '',
  },
);

// Row 35: importance of permission
export const AudienceImportFlowImportanceOfPermissionLinkClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'list/members/import',
    object: 'audience',
    object_detail: 'contact_subscription',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'importance_of_permission',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    import_contact_method: '',
  },
);

// List Import - Highlight Integrations Events

export const ImportFlowIntegrationSelectionEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_integration_contacts',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'integration_name',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_url: '',
    integration_name: '',
  },
);

export const ImportFlowPrimaryCTA = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_integration_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'done',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: '',
    integration_url: '',
  },
);

export const ImportFlowInlineCTA = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'list/members/import',
    object_detail: 'import_integration_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'i_have_a_different_platform',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);
