import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  object: 'segment',
  object_detail: 'create_segment',
  action: 'engaged',
  ui_action: 'clicked',
  ui_access_point: 'bottom_sheet',
  segmentation_method: 'advanced',
  mode: 'create',
  pbs_used: '',
};

export const nlpSearchBarDropdownSelectEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'smart_content_generation',
    ui_object: 'dropdown',
    ui_object_detail: 'segment_conditions',
  },
  {
    funnel_id: '',
    screen: '',
    nlp_eligible_and_ff_enabled: '',
  },
);

export const nlpSearchBarInputTextEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'smart_content_generation',
    ui_object: 'text_input',
    ui_object_detail: 'segment_conditions',
  },
  {
    funnel_id: '',
    screen: '',
    nlp_eligible_and_ff_enabled: '',
  },
);

export const nlpUserSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'smart_content_generation',
    ui_object: 'button',
    ui_object_detail: 'generate_filter',
  },
  {
    funnel_id: '',
    screen: '',
    nlp_eligible_and_ff_enabled: '',
    user_query: '',
  },
);

export const nlpFilterOnMyOwnEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'filter_on_my_own',
  },
  {
    funnel_id: '',
    screen: '',
    scope_area: '',
    nlp_eligible_and_ff_enabled: '',
  },
);
