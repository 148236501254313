import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'partnerships_n_integrations',
  scope_area: 'multi_vertical',
  object: 'integration',
};

export const IntegrationSuccessPageLoaded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/signup/integration-success',
    object_detail: 'partner_referral_onboarding_flow',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    provider_id: '',
  },
);

export const IntegrationGetStartedPageLoaded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/signup/integration-get-started',
    object_detail: 'partner_referral_onboarding_flow',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    provider_id: '',
  },
);

export const IntegrationGetStartedLoginButtonClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/signup/integration-get-started',
    object_detail: 'partner_referral_onboarding_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'login',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    provider_id: '',
  },
);

export const IntegrationGetStartedCreateButtonClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/signup/integration-get-started',
    object_detail: 'partner_referral_onboarding_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_account',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    provider_id: '',
  },
);

export const IntegrationSignupPageLoaded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/signup/integration-signup',
    object_detail: 'partner_referral_onboarding_flow',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    provider_id: '',
  },
);

export const IntegrationSignupPageActivateButtonClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/signup/integration-signup',
    object_detail: 'partner_referral_onboarding_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'sign_up',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    provider_id: '',
  },
);
