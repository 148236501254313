import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  event_source: 'front_end:js_react',
};

export const DowngradeV1ConfirmStepViewEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'started',
    object: 'checkout',
    object_detail: 'downgrade_confirm',
    ui_object_detail: '',
    ui_action: '',
    ui_object: '',
    ui_access_point: '',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConfirmStepPrimaryCTAClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'completed',
    object: 'checkout',
    object_detail: 'downgrade_confirm',
    ui_object_detail: 'downgrade_to_a_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object: 'button',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConfirmStepSecondaryCTAClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'checkout',
    object_detail: 'downgrade_confirm',
    ui_object_detail: 'keep_my_plan',
    ui_action: 'click',
    ui_access_point: 'center',
    ui_object: 'button',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConfirmStepSeeAllLimitationsLinkClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'checkout',
    object_detail: 'downgrade_confirm',
    ui_object_detail: 'see_all_limitations_of_the_plan',
    ui_action: 'click',
    ui_access_point: 'center',
    ui_object: 'hyperlink',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConsiderStepContinueClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'checkout',
    object_detail: 'downgrade_consider',
    ui_object_detail: 'continue',
    ui_action: 'click',
    ui_access_point: 'center',
    ui_object: 'button',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConsiderStepKeepMyPlanCTAClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'checkout',
    object_detail: 'downgrade_consider',
    ui_object_detail: 'keep_my_plan',
    ui_action: 'click',
    ui_access_point: 'center',
    ui_object: 'button',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConsiderStepReviewAllFeaturesLinkClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'checkout',
    object_detail: 'downgrade_consider',
    ui_object_detail: 'review_all_features',
    ui_action: 'click',
    ui_access_point: 'center',
    ui_object: 'hyperlink',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);

export const DowngradeV1ConsiderStepTalkToSpecialistClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'help_support',
    object_detail: 'downgrade_consider',
    ui_object_detail: 'connect_with_a_specialist',
    ui_action: 'click',
    ui_access_point: 'center',
    ui_object: 'button',
  },
  {
    screen: '',
  },
);

export const DowngradeV1ConsiderStepViewedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'started',
    object: 'checkout',
    object_detail: 'downgrade_consider',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    ui_object: '',
  },
  {
    screen: '',
    current_marketing_plan: '',
    marketing_plan_selected: '',
    contact_tier_selected: '',
  },
);
