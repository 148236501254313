import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_experience',
  scope_area: 'integration',
  object: 'integration',
  action: 'engaged',
  screen: window.location.pathname,
  object_detail: 'app_header_integration_alert',
  ui_object: 'button',
  ui_action: 'clicked',
};

export const AlertPillClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'integration_error_pill',
    ui_access_point: 'header',
  },
  { integration_name: 'wix' },
);

export const AlertModalCTAClickFixNow = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'fix_now',
    ui_access_point: 'modal',
  },
  { integration_name: 'wix' },
);

export const AlertModalCTAClickIUnderstand = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'i_understand_acknowledge',
    ui_access_point: 'modal',
  },
  { integration_name: 'wix' },
);

export const AlertModalDismissed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'x',
    ui_access_point: 'modal',
  },
  { integration_name: 'wix' },
);
