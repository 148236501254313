import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const STEP_PROPERTIES = {
  purpose: 'prod',
  scope_area: 'email_domain',
  initiative_name: 'bulk_email',
  screen: 'account/domains',
  object_detail: 'custom_email_domains',
  object: 'account',
  action: 'engaged',
};

export const trackCopyDNSRecordValueEvent = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'copy',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
    email_domain_copy_button: '',
  },
);

export const trackContinueDomainsChecklistClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackOpenDMARCKnowledgeBaseArticleClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_action: 'clicked',
    ui_object: 'link',
    ui_object_detail: 'set_up_email_domain_authentication',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackOpenSupportClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'let_our_support_team_know',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackOpenRegistrarKnowledgeBaseArticleClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'registrar_help_link',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackOpenCNAMEKnowledgeBaseArticleClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'set_up_email_domain_authentication',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackOpenAboutSpamFiltersKnowledgeBaseArticleClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'about_spam_filters',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackCheckEmailDomainAuthenticationClick = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'check_status',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domain_checklist_step_title: '',
    email_domain_host: '',
  },
);

export const trackValimailAccountLink = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    object_detail: 'monitor_your_domain',
    ui_object: 'link',
    ui_object_detail: 'valimail_account',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domains: null,
  },
);

export const trackValimailAccountButton = createEcsEvent(
  {
    ...STEP_PROPERTIES,
    object_detail: 'monitor_your_domain',
    ui_object: 'button',
    ui_object_detail: 'monitor_sending_with_valimail',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    email_domains: null,
  },
);
