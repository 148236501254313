import { createEcsEvent } from '../../configuration/createEvent';

// These properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  object: 'segment',
  object_detail: 'global_condition',
  action: 'engaged',
  ui_action: 'clicked',
  ui_access_point: 'bottom_sheet',
  segmentation_method: 'advanced',
};

export const segmentsIndexStatusBarDeleteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_object_detail: 'delete_subscription_status',
    screen: 'audience/segments',
    scope_area: 'segments_index',
  },
  {
    funnel_id: '',
  },
);

export const segmentsIndexStatusBarDropdownDeleteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown_item',
    ui_object_detail: 'delete_subscription_status',
    screen: 'audience/segments',
    scope_area: 'segments_index',
  },
  {
    funnel_id: '',
  },
);

export const segmentsIndexStatusBarDropdownAddedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown_item',
    ui_object_detail: 'subscription_status',
    screen: 'audience/segments',
    scope_area: 'segments_index',
  },
  {
    funnel_id: '',
  },
);

export const emailChecklistStatusBarDeleteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_object_detail: 'delete_subscription_status',
    screen: 'campaigns/edit',
    scope_area: 'bulk/campaigns',
  },
  {
    campaign_id: 0,
    funnel_id: '',
  },
);

export const emailChecklistStatusBarDropdownDeleteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown_item',
    ui_object_detail: 'delete_subscription_status',
    screen: 'campaigns/edit',
    scope_area: 'bulk/campaigns',
  },
  {
    campaign_id: 0,
    funnel_id: '',
  },
);

export const emailChecklistStatusBarDropdownAddedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown_item',
    ui_object_detail: 'subscription_status',
    screen: 'campaigns/edit',
    scope_area: 'bulk/campaigns',
  },
  {
    campaign_id: 0,
    funnel_id: '',
  },
);
