import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const CampaignToggleProductDetailsEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'product_content',
    action: 'customized',
    ui_object: 'switch',
    ui_action: 'toggle',
    ui_access_point: 'side_panel',
    ui_object_detail: 'product_detail_switch',
  },
  {
    toggle_status: false,
    product_detail: '',
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    platform_type: '',
    store_ids: [],
    product_ids: [],
  },
);

export const TemplateToggleProductDetailsEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'product_content',
    action: 'customized',
    ui_object: 'switch',
    ui_action: 'toggle',
    ui_access_point: 'side_panel',
    ui_object_detail: 'product_detail_switch',
  },
  {
    toggle_status: false,
    product_detail: '',
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    store_ids: [],
    product_ids: [],
  },
);
