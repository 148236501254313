import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
};

export const HelpbotButtonViewedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'viewed',
    object: 'account',
  },
  {
    screen: '',
    object_detail: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    experiment_variant: '',
    event_source: 'front-end:js_react',
  },
);

export const HelpbotButtonClickedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    ui_action: 'clicked',
    object: 'help_support',
  },
  {
    screen: '',
    object_detail: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    entry_point: '',
    event_source: 'front-end:js_react',
    experiment_variant: '',
  },
);
