import { createEcsEvent } from '../../configuration/createEvent';

export const Cr2IntegrationClickedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'integration_experience',
    scope_area: 'support',
    screen: 'integrations/app',
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'get_help_from_a_specialist',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);
