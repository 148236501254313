import { createEcsEvent } from '../../../configuration/createEvent';

export const TrackSelectEditorEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    screen: 'campaigns/edit',
    scope_area: 'editor_selection',
    object: 'campaign',
    object_detail: 'editor_selection',
    action: 'selected',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ui_object_detail: '',
    campaign_id: '',
    editor_type: '',
  },
);
