import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  screen: 'account/close',
  object: 'account',
  object_detail: 'explore_plans',
  event_source: 'front_end:js_react',
};

export const ExplorePlansModalViewedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'explore_plans',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const ExplorePlansButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'explore_plans',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const ExplorePlansNotInterestedButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'im_not_interested',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const ExplorePlansOutsideModalClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'dismissed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: '',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);
