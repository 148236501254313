import { createEcsEvent } from '../../configuration/createEvent';

/*
 * This event should fire when the archive all contacts page is viewed.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:48
 */

export const AudienceOrganizationViewArchiveAllContactsPageEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/members/archive-all',
    object: 'audience',
    object_detail: 'archive_all_contacts',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);

/*
 * This event should fire when the user clicks "archive all contacts" on the archive all contacts page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:49
 */

export const AudienceOrganizationClickArchiveAllContactsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/members/archive-all',
    object: 'audience',
    object_detail: 'archive_all_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'archive_all_contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    contact_count: 0,
    has_contact_archive_error: '',
  },
);
