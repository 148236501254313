import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_discovery_experience',
  scope_area: 'integration',
  object: 'integration',
};

export const trackIntegrationConnectClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'open_app',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationPaginationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'discover_pagination',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_page_navigation: '',
  },
);

export const IntegrationsBecomePartnerEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'become_an_integration_partner',
    ui_action: 'click',
    ui_access_point: 'form_header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationsSortedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'sort_integrations',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_sort_order: '',
  },
);

export const DiscoverPageViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationsDiscoverySearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'text_field',
    ui_object_detail: 'search_bar',
    ui_action: 'searched',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
    integration_search_filters: '',
    integration_search_count: '',
  },
);

export const IntegrationClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'discover_integration',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationZapierCardViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'zapier_search_card',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
  },
);

export const IntegrationZapierCardClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'zapier_search_card',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
  },
);

export const IntegrationDetailViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'integration_details',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationReviewWriteReviewClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app/',
    object_detail: 'ratings_reviews',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'write_a_review',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_review_enabled: '',
    integration_installed: '',
    integration_left_review: '',
  },
);

export const IntegrationReviewModalClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app/',
    object_detail: 'ratings_reviews',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_review_enabled: '',
    integration_star_rating: '',
    integration_left_comment: '',
  },
);

export const IntegrationReviewConfirmationModalDoneEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app/',
    object_detail: 'ratings_reviews_confirmation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'done',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationReviewConfirmationModalClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app/',
    object_detail: 'ratings_reviews_confirmation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationReviewStarComponentClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app/',
    object_detail: 'ratings_reviews',
    action: 'engaged',
    ui_object: 'star_rating_component',
    ui_object_detail: 'star_rating',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    star_rating: '',
  },
);

export const IntegrationReviewSubmitReviewClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app/',
    object_detail: 'ratings_reviews',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'submit_review',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_star_rating: '',
    integration_left_comment: '',
  },
);

export const IntegrationsPromoBannerViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'integration_promo_popup',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationsPromoBannerGetStartedClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'integration_promo_popup',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationsPromoBannerClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'integration_promo_popup',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationPreviewClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'integration_preview',
    ui_action: 'clicked',
    ui_access_point: 'left_rail',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationPreviewClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationPreviewHotSpotsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'integration_preview_hotspots',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_hotspot: '',
  },
);

export const IntegrationPreviewHotSpotToolTipClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'engaged',
    ui_object: 'tooltip',
    ui_object_detail: 'integration_preview_hotspots',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_hotspot: '',
  },
);

export const IntegrationPreviewViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationPreviewTabsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: 'integration_preview_tabs',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_tab_name: '',
  },
);

export const IntegrationPreviewConnectClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'preview_experience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

/**
 * We call this function when the featured integration app is clicked on /account/connected-sites/discover
 */
export const trackFeaturedIntegrationClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/app',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'featured_card',
    ui_object_detail: 'featured_integration',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);
