import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all explore events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  object: 'automations',
  screen: 'customer-journey/explore/prebuilt',
};
// these properties are constant throughout all other PBJ events
const DEFAULT_PBJ_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  object: 'automations',
};

export const PreviewPagePbjCardClickedEvent = createEcsEvent(
  /**
   * This section contains the default properties
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'pbj_recommendation',
    action: 'selected',
    ui_object: 'card',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    pbj_type: '',
  },
);

export const PbjClickedToStartEvent = createEcsEvent(
  /**
   * This section contains the default properties
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'prebuilt_journey',
    ui_object: 'button',
    ui_object_detail: 'use_this_journey',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    pbj_type: '',
  },
);

export const PbjPreviewSelectedEvent = createEcsEvent(
  /**
   * This section contains the default properties
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    screen: 'customer-journey/explore/prebuilt',
    object_detail: 'previewed_prebuilt_journey',
    ui_object: 'card',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    pbj_type: '',
    has_promo_code: false,
    journey_id: null,
  },
);

export const PbjHomePageSelectedEvent = createEcsEvent(
  /**
   * This section contains the default properties
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_PBJ_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'previewed_prebuilt_journey',
    ui_object: 'card',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    pbj_type: '',
    has_promo_code: false,
    journey_id: null,
    ui_object_detail: 'prebuilt_journey',
    screen: '/',
  },
);

export const PbjClickedSelectAudienceEvent = createEcsEvent(
  {
    ...DEFAULT_PBJ_EVENT_PROPERTIES,
    action: 'created',
    screen: 'customer-journey/explore/prebuilt',
    object_detail: 'prebuilt_journey',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    pbj_type: '',
    journey_id: 0, // Actual value set in send event below
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const AiPbjStartedEvent = createEcsEvent(
  {
    ...DEFAULT_PBJ_EVENT_PROPERTIES,
    screen: 'customer-journey',
    object_detail: 'prebuilt_journey',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'ai_audience_modal',
  },
  {
    ai_email_type: '',
  },
);
