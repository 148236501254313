import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
};

const AI_FEEDBACK = 'ai_feedback';
const BRAND_ENGINE_LOGO = 'brand_engine_logo';
const FEEDBACK_MODAL = 'feedback_modal';
const LOGO_BOTTOM_SHEET = 'logo_bottom_sheet';

/**
 * Event for Clicking Change Logo on AI PBJ Multi Touchpoint Preview Gallery
 */
export const ChangeLogoAiPbjEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: BRAND_ENGINE_LOGO,
    screen: 'customer-journey/builder',

    ui_object: 'button',
    ui_object_detail: 'change_logo',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview_card',
  },
  {
    ai_email_type: '',
    journey_id: 0,
    journey_review_step_name: '',
    pbj_type: '',
  },
);

/**
 * Event for Clicking Done on the Bottomsheet
 */
export const ChangeLogoDoneAiPbjEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: BRAND_ENGINE_LOGO,
    screen: 'customer-journey/builder',

    ui_object: 'button',
    ui_object_detail: 'done',
    ui_action: 'clicked',
    ui_access_point: LOGO_BOTTOM_SHEET,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    journey_review_step_name: '',
    pbj_type: '',
  },
);

/**
 * Event for Clicking Upload Logo on the Bottomsheet
 */
export const UploadLogoClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: BRAND_ENGINE_LOGO,
    screen: 'customer-journey/builder',

    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: LOGO_BOTTOM_SHEET,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    journey_review_step_name: '',
    pbj_type: '',
    ui_object_detail: '',
  },
);

/**
 * Event for Upload Logo Failure on the Bottomsheet
 */
export const UploadLogoFailureEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: BRAND_ENGINE_LOGO,
    screen: 'customer-journey/builder',

    ui_access_point: LOGO_BOTTOM_SHEET,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    journey_review_step_name: '',
    pbj_type: '',
    ui_object_detail: '',
  },
);

/**
 * Event for Upload Logo Success on the Bottomsheet
 */
export const UploadLogoSuccessEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: BRAND_ENGINE_LOGO,
    screen: 'customer-journey/builder',

    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: LOGO_BOTTOM_SHEET,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    pbj_type: '',
    file_type: '',
    journey_review_step_name: '',
  },
);

/**
 * Event for Clicking Thumbs Up Positive Feedback button on the Bottomsheet
 */
export const LogoFeedbackThumbsUpEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: AI_FEEDBACK,
    screen: 'customer-journey/builder',
    ui_object: 'button',
    ui_object_detail: 'thumbs_up',
    ui_action: 'clicked',
    ui_access_point: LOGO_BOTTOM_SHEET,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    pbj_type: '',
    journey_review_step_name: '',
  },
);

/**
 * Event for Clicking Thumbs Down Negative Feedback button on the Bottomsheet
 */
export const LogoFeedbackThumbsDownEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: AI_FEEDBACK,
    screen: 'customer-journey/builder',
    ui_object: 'button',
    ui_object_detail: 'thumbs_down',
    ui_action: 'clicked',
    ui_access_point: LOGO_BOTTOM_SHEET,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    pbj_type: '',
    journey_review_step_name: '',
  },
);

/**
 * Event for Clicking the Close Button on the Customer Feedback Modal
 */
export const LogoCloseFeedbackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: AI_FEEDBACK,
    screen: 'customer-journey/builder',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: FEEDBACK_MODAL,
  },
  {
    ai_email_type: '',
    journey_id: 0,
    pbj_type: '',
    journey_review_step_name: '',
  },
);

/**
 * Event for Clicking the Submit Button on the Customer Feedback Modal
 */
export const LogoSubmitFeedbackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    created_from_source: 'ai_pbj',
    object: 'automations',
    object_detail: AI_FEEDBACK,
    screen: 'customer-journey/builder',
    ui_object: 'button',
    ui_object_detail: 'submit',
    ui_action: 'clicked',
    ui_access_point: FEEDBACK_MODAL,
  },
  {
    feedback_type: 'thumbs down',
    feedback_response_choice: '',
    feedback_text: '',
  },
);
