import { createEcsEvent } from '../../configuration/createEvent';

/*
## Notes on ECS system, terminology and usage
Think about createEcsEvent as 'defineEventSchema'. Each one corresponds to one row in the ECS tracking spreadsheet.

Think about defaultProperties as 'base properties'. These properties will be present each time an event is fired, and
their values will be the same each time.

Lastly, configurableProperties contains the properties the event requires, and are set via the second argument to
EcsTracking.track, eg.

// in this file:
const MyEvent = createEcsEvent({ .. }, { property_name_in_configurable_properties: '' });

// when the event is being fired:
EcsTracking.track(MyEvent, { property_name_in_configurable_properties: 'the specific value for this firing' });


## Notes on organization and reuse in this file
Avoid premature abstraction: For the moment, avoid refactoring these calls to createEcsEvent in an attempt to minimize
file size, or string duplication. ECS tracking spreadsheet tends to have some churn initially, and so leaving these
calls as simple as possible (albeit very long, redundant, etc) will make it easy to modify over time, and easy for
your teammates to make sense of changes during code review.

Correspond to the spreadsheet: one `export const SomethingClicked = createEcsEvent(...);` per spreadsheet row, order the
default properties in accordance with the column ordering in the spreadsheet. Copy the variable name you choose back
into the spreadsheet (in the first column, in parentheses, so that its easy to find which row it is). Avoid using row
numbers because those change over time.

Naming consts: NounVerbed, eg. InboxOpened not OpenInbox, SchedulingModalClosed or SchedulingModalCloseClicked not
UserClickScheduleModalClose.
*/

// NOTE: createEcsEvent sets the 'org' and 'scope' properties automatically. Do not provide them in defaultProperties.

export const InboxOpened = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    screen: '',
  },
);

export const InboxMessageSent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'send_message',
    ui_action: 'clicked',
    ui_access_point: 'fixed_bottom_right',
  },
  {
    screen: '',
    thread_id: '',
    message_id: '',
    generate_response_option_used: null,
    template_used: '',
    has_attachment: null,
    opt_out_footer_used: null,
    message_source_type: '',
    medium: '',
    video_meeting: null,
    previous_message_id: '',
    composition_session_id: '',
  },
);

export const InboxToDoTabClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'message_options',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'to_do',
    ui_action: 'clicked',
    ui_access_point: 'header_tab',
  },
  {},
);

export const AddCampaigns = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'campaign_automation_replies',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
  },
);

export const RemoveCampaigns = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'campaign_automation_replies',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {
    screen: '',
  },
);

export const RemoveForwardExistingInboxSource = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {
    screen: '',
  },
);

export const FixExistingSource = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox_source',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'fix',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {
    screen: '',
  },
);

export const RemoveSurveyResponses = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'survey_response',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {
    screen: '',
  },
);

export const InboxDoneTabClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'message_options',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'done',
    ui_action: 'clicked',
    ui_access_point: 'header_tab',
  },
  {},
);

export const InboxTrashTabClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'message_options',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'trash',
    ui_action: 'clicked',
    ui_access_point: 'header_tab',
  },
  {},
);

export const InboxAllTabClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'message_options',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'all',
    ui_action: 'clicked',
    ui_access_point: 'header_tab',
  },
  {},
);

export const InboxComposeButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_message',
    ui_action: 'clicked',
    ui_access_point: 'headliner_content_pane',
  },
  {
    screen: '',
  },
);

export const InboxMessageDiscarded = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'trash_can',
    ui_action: 'clicked',
    ui_access_point: 'fixed_bottom_right',
  },
  {},
);

export const InboxGenerateConsentModalSendClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'send',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const InboxGenerateConsentModalCancelClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const InboxSendEmailClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_email',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
  },
);

export const InboxAttachmentAdded = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_attachment',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {},
);

export const InboxReplyClickedFromThreadSelectorPane = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reply',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
    thread_id: '',
  },
);

export const InboxReplyClickedFromThreadActionBar = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reply',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    screen: '',
    thread_id: '',
  },
);

export const InboxManageLabelsClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_labels',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'manage',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const InboxAddLabelClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_labels',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add_label',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const InboxSaveLabelsClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_labels',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const InboxDeleteLabelClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_labels',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'trash_can',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const VideoMeetingIconClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'video_meeting',
    ui_action: 'clicked',
    ui_access_point: 'quick_action',
  },
  {
    screen: '',
  },
);

export const VideoMeetingModalDateClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'video_meeting',
    action: 'engaged',
    ui_object: 'field',
    ui_object_detail: 'choose_a_date',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const VideoMeetingModalTimeClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'video_meeting',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'select_time',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const VideoMeetingModalSaved = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'video_meeting',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const VideoMeetingModalClosed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'video_meeting',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AddNewMessageTemplate = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add_new_message_template',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SaveMessageTemplate = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'fixed_bottom_left',
  },
  {
    template_status: '',
  },
);

export const DeleteMessageTemplate = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const EditMessageTemplate = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const InboxEmailForwardChannelAddClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AdjustDraftResponseEllipsisClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'ellipses',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
  },
);

export const AdjustDraftResponseChangeToneToClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'change_tone_to',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
  },
);

export const InboxDraftResponseGenerated = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'generate_response',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    screen: '',
    message_source_type: '',
    medium: '',
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    intuit_tid: '',
  },
);

export const ContentGenerationFailed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    intuit_tid: '',
  },
);

export const MarkDoneMessageList = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'checkmark',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const MarkDoneMessageBody = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'mark_as_done',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {},
);

export const MarkTrashEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'mark_as_trash',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const AddCommentMessageList = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_comment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'dialog_box',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const AddCommentMessageBody = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_comment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_comment',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {},
);

export const GeneratedDraftResponseAccepted = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'up_arrow',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
  },
);

export const AdjustDraftResponseShortenClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'shorten',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    intuit_tid: '',
  },
);

export const AdjustDraftResponseLengthenClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'lengthen',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    intuit_tid: '',
  },
);

export const AdjustDraftResponseDiscardClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'discard',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
  },
);

export const AdjustDraftResponseSpecificToneClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'tone_selected',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    intuit_tid: '',
    ai_tone_selected: '',
  },
);

export const AdjustDraftResponseTryAgainClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'smart_content_generation',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'try_again',
    ui_action: 'clicked',
    ui_access_point: 'ai_draft_response',
  },
  {
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    intuit_tid: '',
  },
);

export const AddSourceSideBarEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_source',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_source',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const SkipForNowEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip_for_now',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AddButtonEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const ManageSourcesEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_source',
    action: 'engaged',
    ui_object: 'menu_item',
    ui_object_detail: 'manage_sources',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const InboxNotificationSettingsClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_notification_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'notification_settings',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const InboxManageTemplatesClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'message_templates',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const InboxSurveySourceAddClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'survey_response',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const InboxSurveySourceConfigured = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'survey_response',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const InboxEmailForwardChannelConfigured = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox/email-forward-setup',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'back_to_inbox',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ContactFormSidebarEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'contact_form',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'contact_form',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {},
);

export const AddContactFormEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'contact_form',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'bottom_card',
  },
  {},
);

export const StartTourEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'onboarding_tour',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start_tour',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const SkipTourEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'onboarding_tour',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip_tour',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const StartEmailForwardingEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'start',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const FinishTourEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'onboarding_tour',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'finish_tour',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const CreatePaymentEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_payment',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {},
);

export const SendPaymentLinkEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'payment_links',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'send_payment_link',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const ClosePaymentLinkEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'payment_links',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'close_payment_links',
    ui_action: 'clicked',
    ui_access_point: 'top_right',
  },
  {},
);

export const CancelPaymentLinkEventClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'payment_links',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel_payment_links',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {},
);

export const AddContactForm = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox/contact-form-editor',
    object: 'audience',
    object_detail: 'inbox_contact_form',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add_contact_form',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ContactFormPublished = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'inbox_contact_form',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'publish_form',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ContactFormPublishedFromPipelines = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'crm/inbox/contact-form-editor',
    object: 'audience',
    object_detail: 'inbox_contact_form',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'publish_form',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    pipeline_id: 0,
  },
);

export const InboxDraftResponseRated = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    screen: 'audience/inbox',
    object: 'audience',
    object_detail: 'content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'star',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    message_source_type: '',
    medium: '',
    thread_id: '',
    previous_message_id: '',
    composition_session_id: '',
    rating: '',
  },
);

export const ViewSummaryFromInbox = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'activity_feed',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_summary',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    thread_id: '',
    contact_id: '',
    conversation_id: '',
  },
);

export const DownloadTranscriptFromInboxActivityFeed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'activity_feed',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_transcript',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    thread_id: '',
    contact_id: '',
    conversation_id: '',
  },
);

export const DownloadTranscriptFromInboxModal = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'activity_feed',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_transcript',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    thread_id: '',
    contact_id: '',
    conversation_id: '',
  },
);

export const WelcomePageConnectGoogleButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox_google_account',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: 'crm/inbox',
  },
  {},
);

export const WelcomePageForwardingButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'existing_inbox',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: 'crm/inbox',
  },
  {},
);

export const WelcomePageConnectContactButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox_contact_form',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: 'crm/inbox',
  },
  {},
);

export const WelcomePageSkipStepForNow = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'skip_connect_sources',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip_this_step_for_now',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: 'crm/inbox',
  },
  {},
);

export const AddButtonGmailEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox_google_account',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'add',
    ui_action: 'clicked',
    ui_access_point: 'slat',
    screen: 'crm/inbox',
  },
  {},
);

export const DisconnectButtonGmailEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'inbox',
    object: 'audience',
    object_detail: 'inbox_google_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'disconnect',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
  },
);

export const ConfirmDisconnectGmailEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'multi_vertical',
    object: 'audience',
    object_detail: 'google_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'disconnect',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
  },
);
