const buildRelationships =
  'https://cdn-images.mailchimp.com/product/reports/intents/BuildRelationships.png';
const driveAudienceActions =
  'https://cdn-images.mailchimp.com/product/reports/intents/DriveAudienceActions.png';
const manageMyAudience =
  'https://cdn-images.mailchimp.com/product/reports/intents/ManageMyAudience.png';
const reengageContacts =
  'https://cdn-images.mailchimp.com/product/reports/intents/ReengageContacts.png';
const sellStuff =
  'https://cdn-images.mailchimp.com/product/reports/intents/SellStuff.png';
const shareInformation =
  'https://cdn-images.mailchimp.com/product/reports/intents/ShareInformation.png';
const somethingElse =
  'https://cdn-images.mailchimp.com/product/reports/intents/SomethingElse.png';

// can add step status
export const CAN_ADD_STEP = 'can_add_step';
export const MAX_STEP = 'max_step';
export const SHOW_PAYWALL = 'show_paywall';

export const WORKFLOW_FILTER_ALL = 'all';

export const STEP_FAKE_ID = 0;
export const STEP_DATA_TRANSFER = 'text/plain';

export const STEP = 'STEP';
export const STEP_TYPE_ACTION = 'action';
export const STEP_TYPE_CONDITION = 'condition';
export const STEP_TYPE_DELAY = 'delay';
export const STEP_TYPE_TRIGGER = 'trigger';

// For reasons unknown to mankind, we decided on sending different names to GA for tracking.
export const GA_TRACKING_STEP_TYPE_IFELSE_CONDITION = 'If / Else';
export const GA_TRACKING_STEP_TYPE_PERCENTSPLIT_CONDITION = 'Percentage Split';
export const GA_TRACKING_STEP_TYPE_TRIGGER_STARTING = 'Starting point';
export const GA_TRACKING_STEP_TYPE_TRIGGER_WAIT_FOR = 'Wait';

export const CATEGORY_POPULAR = 'popular';
export const CATEGORY_AUDIENCE = 'audience';
export const CATEGORY_MARKETING = 'marketing';
export const CATEGORY_ECOMMERCE = 'shopping';
export const CATEGORY_DATE = 'date';
export const CATEGORY_API = 'api';

export const ACTION_TYPE_ADD_REMOVE_TAG = 'add_remove_tag';
export const ACTION_TYPE_ARCHIVE_CONTACT = 'archive_contact';
export const ACTION_TYPE_SEND_EMAIL = 'send_email';
export const ACTION_TYPE_SEND_EMAIL_TX = 'send_email_tx';
export const ACTION_TYPE_SEND_SMS = 'send_sms';
export const ACTION_TYPE_SEND_SURVEY = 'send_survey';
export const ACTION_TYPE_SUB_UNSUB = 'sub_unsub_contact';
export const ACTION_SUB_OR_UNSUB_SETTING_KEY = 'sub_or_unsub';
export const ACTION_UNSUBSCRIBE_SETTING_VALUE = 'unsub';
export const ACTION_TYPE_UPDATE_MERGE = 'update_merge';
export const ACTION_TYPE_FBCA_ADD_REMOVE = 'fb_custom_audience';
export const CAMPAIGN_TYPE = 'automation-email';
export const CONDITION_TYPE_IF_ELSE = 'if_else';
export const CONDITION_TYPE_PERCENT_SPLIT = 'percent_split';
export const UPDATE_DELETE_SETTING_KEY = 'update_or_delete';
export const TRIGGER_TYPE_ABANDON_CART_V2 = 'abandon_cart_v2';
export const TRIGGER_TYPE_PRODUCT_BOUGHT = 'product_bought';
export const TRIGGER_TYPE_PRODUCT_CATEGORY = 'product_category';
export const TRIGGER_TYPE_ORDER_CONFIRMATION = 'order_confirm';
export const TRIGGER_TYPE_ORDER_INVOICE = 'order_invoice';
export const TRIGGER_TYPE_SHIPPING_CONFIRMATION = 'shipping_confirm';
export const TRIGGER_TYPE_REFUND_CONFIRMATION = 'refund_confirm';
export const TRIGGER_TYPE_ORDER_CANCELLATION = 'order_cancellation';
export const TRIGGER_TYPE_ORDER_CONFIRMATION_V2 = 'on_order_confirm';
export const TRIGGER_TYPE_ORDER_INVOICE_V2 = 'on_order_invoice';
export const TRIGGER_TYPE_SHIPPING_CONFIRMATION_V2 = 'on_shipping_confirm';
export const TRIGGER_TYPE_ORDER_DELIVERED_V2 = 'on_order_delivered';
export const TRIGGER_TYPE_REFUND_CONFIRMATION_V2 = 'on_refund_confirm';
export const TRIGGER_TYPE_ORDER_CANCELLATION_V2 = 'on_order_cancel';
export const TRIGGER_TYPE_SPECIFIC_PRODUCT_BOUGHT = 'spec_product_bought';
export const TRIGGER_TYPE_TIME_LAST_PURCHASE = 'time_last_purchase';
export const TRIGGER_TYPE_SHOPIFY_VIEW_PAGE = 'shopify_view_page';
export const TRIGGER_TYPE_SHOPIFY_VIEW_PRODUCT = 'shopify_view_product';
export const TRIGGER_TYPE_INVENTORY_PRICE_DROP = 'inventory_price_drop';
export const TRIGGER_TYPE_BACK_IN_STOCK = 'back_in_stock';

export const TRIGGER_TYPE_LOW_STOCK = 'low_stock';
export const TRIGGER_TYPE_SHOPIFY_ADD_PRODUCT = 'shopify_add_product';
export const TRIGGER_TYPE_SHOPIFY_SEARCH = 'shopify_search';
export const TRIGGER_TYPE_SHOPIFY_IN_CHECKOUT = 'shopify_in_checkout';
export const TRIGGER_TYPE_MERGE_CHANGED = 'merge_changed';
export const TRIGGER_TYPE_MANUAL_ADD = 'manual_add';
export const TRIGGER_TYPE_ZAPIER = 'zapier_custom_event';
export const TRIGGER_TYPE_SMS_SIGNUP = 'sms_signup';
export const TRIGGER_TYPE_SMS_KEYWORD = 'sms_keyword';
export const SMS_KEYWORD_ID_KEY = 'sms_keyword_id';
export const MERGE_EVENT_ID_SETTING_KEY = 'merge_event_id';
export const MERGE_EVENT_VALUE_SETTING_KEY = 'merge_event_value';
export const ACTION_TYPE_WHATSAPP = 'send_whatsapp';
export const WHATSAPP_TEMPLATE_NAME_KEY = 'template-name';
export const WHATSAPP_PLACEHOLDER_URL_KEY = 'placeholder-url';
export const WHATSAPP_DEFAULT_OPTION = 'default-option';
export const WHATSAPP_SHORTEN_LINKS_KEY = 'shorten-links';

export const SHOPIFY_ADD_PRODUCT_PRODUCTS_TYPE = 'products_type';
export const SHOPIFY_ADD_PRODUCT_SPECIFIC_PRODUCTS = 'specific_products';
export const SHOPIFY_ADD_PRODUCT_STORE_ID = 'store_id';
export const UNIT_TYPE = 'price_unit_type';
export const PRICE_DROP_AMOUNT_KEY = 'price_unit_amount';
export const TIMEFRAME_IN_SECONDS_KEY = 'timeframe_in_seconds';

// when subscribe is added to sub/unsub,
// we'll have to rethink how we handle unsubscribe.
export const TERMINATING_ACTIONS = [
  ACTION_TYPE_ARCHIVE_CONTACT,
  ACTION_TYPE_SUB_UNSUB,
];

// Converge Path
export const CONTACT_EXITS = 'contact_exits';
export const CONTACT_MOVES = 'contact_moves';

// A nice home for a future Facebook Icon :)
export const DISPLAY_INTEGRATION_ICONS = [
  TRIGGER_TYPE_ZAPIER,
  TRIGGER_TYPE_SHOPIFY_VIEW_PAGE,
  TRIGGER_TYPE_SHOPIFY_VIEW_PRODUCT,
  TRIGGER_TYPE_SHOPIFY_SEARCH,
  TRIGGER_TYPE_SHOPIFY_ADD_PRODUCT,
  TRIGGER_TYPE_SHOPIFY_IN_CHECKOUT,
  TRIGGER_TYPE_INVENTORY_PRICE_DROP,
  TRIGGER_TYPE_BACK_IN_STOCK,
  TRIGGER_TYPE_LOW_STOCK,
];

// List of integration action keys
// Currently used to check if a workflow has an integration step
export const CJB_INTEGRATION_ACTIONS = [
  ACTION_TYPE_FBCA_ADD_REMOVE,
  ACTION_TYPE_WHATSAPP,
];

export const MERGE_TEXT_TYPE = 'text';
export const MERGE_NUMBER_TYPE = 'number';
export const MERGE_RADIO_TYPE = 'radio';
export const MERGE_DROPDOWN_TYPE = 'dropdown';
export const MERGE_DATE_TYPE = 'date';
export const MERGE_BIRTHDAY_TYPE = 'birthday';
export const MERGE_ADDRESS_TYPE = 'address';
export const MERGE_ZIP_TYPE = 'zip';
export const MERGE_PHONE_TYPE = 'phone';
export const MERGE_URL_TYPE = 'url';
export const MERGE_IMAGE_URL_TYPE = 'imageurl';

export const ECOMM_TRIGGERS = [
  TRIGGER_TYPE_ABANDON_CART_V2,
  `abandoned_cart_onboarding`,
  TRIGGER_TYPE_PRODUCT_BOUGHT,
  TRIGGER_TYPE_PRODUCT_CATEGORY,
  TRIGGER_TYPE_SPECIFIC_PRODUCT_BOUGHT,
  TRIGGER_TYPE_SHOPIFY_VIEW_PAGE,
  TRIGGER_TYPE_SHOPIFY_VIEW_PRODUCT,
  TRIGGER_TYPE_SHOPIFY_ADD_PRODUCT,
  TRIGGER_TYPE_SHOPIFY_SEARCH,
  TRIGGER_TYPE_SHOPIFY_IN_CHECKOUT,
  TRIGGER_TYPE_TIME_LAST_PURCHASE,
  TRIGGER_TYPE_ORDER_CONFIRMATION,
  TRIGGER_TYPE_ORDER_INVOICE,
  TRIGGER_TYPE_SHIPPING_CONFIRMATION,
  TRIGGER_TYPE_REFUND_CONFIRMATION,
  TRIGGER_TYPE_ORDER_CANCELLATION,
  TRIGGER_TYPE_INVENTORY_PRICE_DROP,
  TRIGGER_TYPE_BACK_IN_STOCK,
  TRIGGER_TYPE_LOW_STOCK,
  TRIGGER_TYPE_ORDER_CONFIRMATION_V2,
  TRIGGER_TYPE_ORDER_INVOICE_V2,
  TRIGGER_TYPE_SHIPPING_CONFIRMATION_V2,
  TRIGGER_TYPE_REFUND_CONFIRMATION_V2,
  TRIGGER_TYPE_ORDER_DELIVERED_V2,
  TRIGGER_TYPE_ORDER_CANCELLATION_V2,
];

export const ECOMMMERCE_CHILD_ACTION_TRIGGERS = [
  TRIGGER_TYPE_ORDER_CONFIRMATION,
  TRIGGER_TYPE_SHIPPING_CONFIRMATION,
  TRIGGER_TYPE_ORDER_INVOICE,
  TRIGGER_TYPE_ORDER_CANCELLATION,
  TRIGGER_TYPE_REFUND_CONFIRMATION,
  TRIGGER_TYPE_ORDER_CONFIRMATION_V2,
  TRIGGER_TYPE_ORDER_INVOICE_V2,
  TRIGGER_TYPE_SHIPPING_CONFIRMATION_V2,
  TRIGGER_TYPE_REFUND_CONFIRMATION_V2,
  TRIGGER_TYPE_ORDER_DELIVERED_V2,
  TRIGGER_TYPE_ORDER_CANCELLATION_V2,
];

export const ORDER_NOTIFICATION_TRIGGERS = [
  TRIGGER_TYPE_ORDER_CONFIRMATION,
  TRIGGER_TYPE_ORDER_INVOICE,
  TRIGGER_TYPE_SHIPPING_CONFIRMATION,
  TRIGGER_TYPE_REFUND_CONFIRMATION,
  TRIGGER_TYPE_ORDER_CANCELLATION,
  TRIGGER_TYPE_ORDER_CONFIRMATION_V2,
  TRIGGER_TYPE_ORDER_INVOICE_V2,
  TRIGGER_TYPE_SHIPPING_CONFIRMATION_V2,
  TRIGGER_TYPE_REFUND_CONFIRMATION_V2,
  TRIGGER_TYPE_ORDER_CANCELLATION_V2,
  TRIGGER_TYPE_ORDER_DELIVERED_V2,
];

export const EMAIL_ACTION_TYPES = [
  ACTION_TYPE_SEND_SURVEY,
  ACTION_TYPE_SEND_EMAIL,
  ACTION_TYPE_SEND_EMAIL_TX,
];

// creating this to add future shopify triggers to
export const SHOPIFY_TRIGGERS = [
  TRIGGER_TYPE_SHOPIFY_VIEW_PAGE,
  TRIGGER_TYPE_SHOPIFY_VIEW_PRODUCT,
  TRIGGER_TYPE_SHOPIFY_SEARCH,
  TRIGGER_TYPE_SHOPIFY_ADD_PRODUCT,
  TRIGGER_TYPE_SHOPIFY_IN_CHECKOUT,
];

export const GEN_AI_ECOMM_TRIGGERS = [
  TRIGGER_TYPE_ABANDON_CART_V2,
  TRIGGER_TYPE_TIME_LAST_PURCHASE,
];

export const WEBHOOK_ACTION_KEY = 'send_webhook';
export const WEBHOOK_TITLE_SETTINGS_KEY = 'webhook-title';
export const WEBHOOK_URL_SETTINGS_KEY = 'webhook-url';
export const WEBHOOK_AUTH_TYPE_SETTINGS_KEY = 'webhook-auth-type';
export const WEBHOOK_AUTH_TYPE_NONE = 'None';
export const WEBHOOK_AUTH_TYPE_BASIC = 'Basic';
export const WEBHOOK_AUTH_TYPE_BEARER = 'Bearer Token';
export const WEBHOOK_AUTH_BASIC_USERNAME_SETTINGS_KEY =
  'webhook-auth-basic-username';
export const WEBHOOK_AUTH_BASIC_PASSWORD_SETTINGS_KEY =
  'webhook-auth-basic-password';
export const WEBHOOK_AUTH_BEARER_TOKEN_SETTINGS_KEY =
  'webhook-auth-bearer-token';
export const WEBHOOK_HEADER_FIELDS_SETTINGS_KEY = 'webhook-headers';
export const WEBHOOK_HEADER_FIELDS_KEY_SETTINGS_KEY = 'webhook-headers-key';
export const WEBHOOK_HEADER_FIELDS_VALUE_SETTINGS_KEY = 'webhook-headers-value';
export const WEBHOOK_CONTENT_TYPE_SETTINGS_KEY = 'webhook-content-type';
export const WEBHOOK_CONTENT_TYPE_JSON = 'JSON';
export const WEBHOOK_CONTENT_TYPE_FORMDATA = 'Formdata';
export const WEBHOOK_CONTENT_TYPE_JSON_SETTINGS_KEY = 'webhook-content-json';
export const WEBHOOK_CONTENT_TYPE_FORMDATA_SETTINGS_KEY =
  'webhook-content-formdata';
export const WEBHOOK_CONTENT_TYPE_FORMDATA_KEY = 'webhook-content-formdata-key';
export const WEBHOOK_CONTENT_TYPE_FORMDATA_VALUE =
  'webhook-content-formdata-value';

export const CONTENT_TYPE_JSON_TEXT = 'application/json';
export const CONTENT_TYPE_FORMDATA_TEXT = 'application/x-www-form-urlencoded';

// Note: 'dateformat' and 'phoneformat' are incorrect camelcase, because that is how it is saved on the backend.
export const MERGE_FORMAT_OPTIONS = ['choices', 'dateformat', 'phoneformat'];

// TODO: get rid of this and use the pbj data on the backend to determine if a pbj is ecomm
export const ECOMM_PREBUILT_JOURNEYS = [
  'abandon_cart',
  'special_offer_after_first_purchase',
  're_engage_with_customers',
];

// The following PBJs will have abandoned browse template prepopulated in email
export const ABANDONED_BROWSE_PREBUILT_JOURNEY_IDS = [
  '9a8c0f92-b210-4047-8160-cdfd0a934c6e', // shopify view page
  '8d4a7eac-7747-404a-943f-5fa04915da93', // shopify view product
];

export const MAX_CONDITION_SETTINGS = 5;

export const DRAFT = 'draft';
export const ARCHIVED = 'archived';
export const SAVE = 'save';
export const SENDING = 'sending';
export const ACTIVE = 'active';
export const PAUSED = 'paused';
export const ERROR = 'error';

export const PBJ_BLOCK_WIDTH = 340;
export const BLOCK_WIDTH = 356;

export const MAX_INITIATING_TRIGGERS = 3;
export const MAX_WAIT_TRIGGERS = 3;
export const INCREASED_MAX_WAIT_TRIGGERS = 10;
export const NODE_GAP_HORIZONTAL = 380;
export const NODE_GAP_VERTICAL = Math.round(
  NODE_GAP_HORIZONTAL / (1.618 * 1.2),
);
export const NODE_ELEMENT_IF_ELSE_LINK_SEPARATOR_RATIO = 5;

// Multi-Touchpoint Preview Map
export const AI_PBJ_IF_ELSE_LINK_SEPARATOR_RATIO = 2.5;
export const AI_PBJ_NODE_GAP_VERTICAL = 65;
export const Y_OFFSET_NONTRIGGER_TREE = 80;
// The number below is the magic number that allows the "Yes" and "No" nodes to display
// straight down if one or more paths is missing a child.
export const AI_PBJ_NODE_GAP_HORIZONTAL = 272.5;
export const REPEAT_CUSTOMER_TEMPLATE_ID_STEP_ORDER = [
  0,
  7016,
  7017,
  7018,
  7019,
  7020,
];

// PBJ-STATIC-MAP ELEMENT SIZING
export const MAP_ELEMENT_WIDTH = 272;
export const MAP_ELEMENT_GAP_X = 288;
export const MAP_ELEMENT_GAP_Y = Math.round(
  MAP_ELEMENT_GAP_X / (1.618 * 1.328),
);
export const MAP_ELEMENT_IF_ELSE_LINK_SEPARATOR_RATIO = 5;

/**
 * Side Panel Constants
 */
export const SIDE_PANEL_HIDDEN = 'hidden';
export const SIDE_PANEL_VISIBLE = 'visible';
export const SIDE_PANEL_HIDE = 'panel_hide';
export const SIDE_PANEL_SHOW = 'panel_show';
export const SIDE_PANEL_PAGE_STACK_PUSH = 'page_stack_push';
export const SIDE_PANEL_PAGE_STACK_POP = 'page_stack_pop';
export const SIDE_PANEL_PAGE_ROOT = 'root';
export const SIDE_PANEL_PAGE_WORKFLOW_SETTINGS = 'workflow_settings';
export const SIDE_PANEL_PAGE_WORKFLOW_STATS = 'workflow_stats';

/**
 * Step Panel Constants
 */
export const PANEL_INSERT_AND_SELECT_STEP = 'panel_insert_and_select';
export const PANEL_UPDATE_INSERTION_POINT = 'panel_update_insertion_point';
export const PANEL_WAIT_TRIGGER_KEY = 'wait_for';
export const PANEL_RULE_GROUPING = 'rule';
export const PANEL_ACTION_GROUPING = 'action';

/**
 * Time
 */
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
export const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;
export const MIN_DELAY_IN_SECONDS = SECONDS_IN_MINUTE * 60; // 1 hour
export const MIN_REENTER_DELAY_IN_SECONDS = SECONDS_IN_MINUTE * 5;
export const MAX_DELAY_IN_SECONDS = SECONDS_IN_DAY * 730;
export const MAX_DELAY_IN_MINUTES = MAX_DELAY_IN_SECONDS / SECONDS_IN_MINUTE;
export const MAX_DELAY_IN_HOURS = MAX_DELAY_IN_SECONDS / SECONDS_IN_HOUR;
export const MAX_DELAY_IN_DAYS = MAX_DELAY_IN_SECONDS / SECONDS_IN_DAY;
export const MAX_DELAY_IN_WEEKS = MAX_DELAY_IN_SECONDS / SECONDS_IN_WEEK;
export const MINUTE_UNIT = 'minute';
export const HOUR_UNIT = 'hour';
export const DAY_UNIT = 'day';
export const WEEK_UNIT = 'week';

export const DEFAULT_TRIGGER_FILTER = {
  match: 'any',
  conditions: [],
};
export const DEFAULT_INSERTION_POINT = {
  previousStepIds: [],
  nextStepId: null,
  isYesPath: true,
};
export const MAX_VALUE_FOR_WAIT_FOR_TRIGGER = SECONDS_IN_DAY * 730; // 730 days
export const MIN_VALUE_FOR_WAIT_FOR_TRIGGER = SECONDS_IN_MINUTE * 60; // 1 hour

/**
 * Contact Management Constants
 */
export const CM_ADD = 'add';
export const CM_REMOVE = 'remove';
export const CM_PASTED = 'pasted';
export const CM_CUSTOM_SEGMENT = 'custom-segment';
export const CM_PREBUILT_SEGMENT = 'prebuilt-segment';

//User
export const INTERNAL = 'internal';

// Conditions
export const DELETE_BOTH = 'DELETE_BOTH';
export const DELETE_NO = 'DELETE_NO';
export const DELETE_YES = 'DELETE_YES';

// Customer Journey's Sort Options
export const ASC = 'ASC';
export const CREATED_AT = 'created_at';
export const CREATE_TIME = 'create_time';
export const DESC = 'DESC';
export const UPDATED_AT = 'updated_at';
export const LAST_CREATED = 'lastCreated';
export const LAST_UPDATED = 'lastUpdated';
export const OLDEST = 'oldest';
export const SORT_OPTIONS = {
  lastCreated: { property: CREATED_AT, direction: DESC, name: LAST_CREATED },
  lastUpdated: { property: UPDATED_AT, direction: DESC, name: LAST_UPDATED },
  oldest: { property: CREATED_AT, direction: ASC, name: OLDEST },
};

// ECS (Amplitude) Event Constants
export const ECS_EVENT_ORG = 'sbseg';
export const ECS_EVENT_PURPOSE = 'prod';
export const ECS_EVENT_SCOPE = 'mc';
export const ECS_EVENT_INITIATIVE_NAME = 'core_offerings';
export const ECS_EVENT_OBJECT_AUDIENCE = 'audience';
export const ECS_EVENT_OBJECT_RECOMMENDATION = 'recommendation';
export const ECS_EVENT_OBJECT_AUTOMATIONS = 'automations';
export const ECS_EVENT_BUILDER_SCOPE_AREA = 'customer_journey_builder';
export const ECS_EVENT_SMART_CONTENT_SCOPE_AREA = 'smart_content_generation';
export const ECS_EVENT_BUILDER_SCREEN = 'customer-journey/builder';
export const ECS_EVENT_EVENT_TYPE_ENGAGED = 'automations:engaged';
export const ECS_EVENT_EVENT_TYPE_COMPLETED = 'automations:completed';
export const ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL = 'journey_map_step';
export const ECS_EVENT_PREBUILT_JOURNEY_OBJECT_DETAIL = 'prebuilt_journey';
export const ECS_EVENT_JOURNEY_SAVE_MODAL = 'save_modal';
export const ECS_EVENT_JOURNEY_ACTION_PANEL = 'action_panel';
export const ECS_EVENT_JOURNEY_TRIGGER_MODAL = 'trigger_selection_modal';
export const ECS_EVENT_JOURNEY_DELETE_MODAL = 'delete_modal';
export const ECS_EVENT_JOURNEY_MAP = 'journey_map';
export const ECS_EVENT_ACTION_PROPERTY_ENGAGED = 'engaged';
export const ECS_EVENT_ACTION_PROPERTY_STARTED = 'started';
export const ECS_EVENT_ACTION_PROPERTY_VIEWED = 'viewed';
export const ECS_EVENT_ACTION_PROPERTY_SUCCEEDED = 'succeeded';
export const ECS_EVENT_ACTION_PROPERTY_FAILED = 'failed';
export const ECS_EVENT_ACTION_PROPERTY_CLOSED = 'closed';
export const ECS_EVENT_ACTION_PROPERTY_CONTINUED = 'continued';
export const ECS_EVENT_CUB_OBJECT = 'segment';
export const ECS_EVENT_CUB_OBJECT_DETAIL = 'create_segment';
export const ECS_EVENT_CUB_OPENED = 'segment:started';
export const ECS_EVENT_UI_OBJECT_LINK = 'link';
export const ECS_EVENT_UI_OBJECT_BUTTON = 'button';
export const ECS_EVENT_UI_OBJECT_PAGE = 'page';
export const ECS_EVENT_UI_OBJECT_FIELD = 'field';
export const ECS_EVENT_UI_OBJECT_DROPDOWN_ITEM = 'dropdown_item';
export const ECS_EVENT_UI_OBJECT_COMBO_BUTTON = 'combo_button';
export const ECS_EVENT_UI_OBJECT_CARD = 'card';
export const ECS_EVENT_UI_OBJECT_ICON = 'icon';
export const ECS_EVENT_UI_ACTION_CLICKED = 'clicked';
export const ECS_EVENT_UI_ACTION_LOADED = 'loaded';
export const ECS_EVENT_SEGMENTATION_METHOD_REGULAR = 'regular';

// Workflows fetch count for homepage
export const FETCH_COUNT = 10;

// Date format for workflow slat details
export const WORKFLOW_DATE_FORMAT = { month: 'short', day: 'numeric' };

// Customer Journey Explore
export const FEATURED = 'featured';
export const CATEGORY_COLOR_TYPES = {
  'API and Integrations': { type: 'default' },
  'Date and Special Events': { type: 'warning' },
  'E-commerce': { type: 'success' },
  Audience: { type: 'new' },
  Marketing: { type: 'error' },
};
export const CUSTOMER_JOURNEY = 'customer_journey';
export const TRANSACTIONAL = 'tx';
export const PATHWAYS = {
  single_email: {
    artwork: 'single-email',
    description:
      'Quickly set up an automated email that goes out when someone signs up or interacts with your brand.',
    title: 'Single email',
  },
  customer_journey: {
    artwork: 'customer-journey',
    description:
      'Nurture your contacts by setting up the interactions they can have with your brand as they move along their personalized path.',
    title: 'Customer journey',
  },
  wizard: {
    artwork: 'wizard',
    description:
      'Set up a series of emails that start sending in a linear order when someone signs up or interacts with your brand.',
    title: 'Classic automation builder',
  },
};

export const NO_MARKETING_INTENT = {
  id: -1,
  val: 'No objective',
};

export const MARKETING_INTENTS = [
  {
    id: 1,
    val: 'Nurture relationships',
    desc:
      'Share my story, ask for feedback, communicate one-on-one, or just say thanks.',
    image: buildRelationships,
  },
  {
    id: 2,
    val: 'Sell stuff',
    desc:
      'Promote my services, advertise new products, or follow up on a purchase.',
    image: sellStuff,
  },
  {
    id: 3,
    val: 'Drive audience actions',
    desc:
      'Gather sign-ups, send download links, schedule appointments, or ask for donations or votes.',
    image: driveAudienceActions,
  },
  {
    id: 4,
    val: 'Share information',
    desc:
      'Share announcements, send out reminders, or update contacts on the status of their purchases.',
    image: shareInformation,
  },
  {
    id: 5,
    val: 'Manage my audience',
    desc: 'Add or remove tags.',
    image: manageMyAudience,
  },
  {
    id: 6,
    val: 'Re-engage contacts',
    desc:
      'Set up abandoned cart reminders and schedule win-back emails, or reach out to unengaged contacts.',
    image: reengageContacts,
  },
  {
    id: 0,
    val: 'Something else',
    desc: "None of these feel quite right.  I'm doing my own thing.",
    image: somethingElse,
  },
];

export const ABANDONED_CART_CONTAINS_SETTINGS = [
  {
    key: 'any',
    title: 'Any Products',
    type: 'products',
  },
  {
    key: 'specific-products',
    title: 'Specific Products',
    type: 'products',
  },
];

// Required integrations
export const EVENTBRITE = 'Eventbrite';
export const INTEGRATION_ZAPIER = 'Custom event in Zapier';

export const RECONFIRM_INTENT_AFTER_DAYS = 3;
export const CUSTOMER_JOURNEY_OVERVIEW_PATH = '/customer-journey';
export const CUSTOMER_JOURNEY_CLASSICS_PATH =
  '/customer-journey/my-journeys/?tab=classic';
export const CUSTOMER_JOURNEY_EDIT_PATH = '/customer-journey/builder?id=';
export const AUTOMATION_EDIT_PATH = '/campaigns/wizard/autocampaigns?id=';
export const TRANSACTIONAL_EDIT_PATH = '/automations/transactional/?id=';
export const MARKETING_INTENT_PATH = '/customer-journey/intent?id=';
export const PREBUILT_JOURNEY_EXPLORE_PATH = '/customer-journey/explore';

// filter properties
export const STATUS = 'status';
export const LIST_ID = 'list_id';

export const PAYWALL_URL = '/account/paywall/';
export const CUSTOMER_JOURNEY_BUILDER_URL = '/customer-journey/builder';

// Standard Plan
export const STANDARD_PLAN_MAX_CONTACT_LIMIT = 100000;

// Creative Assistant Explore page ad
export const CREATIVE_ASSISTANT_AD = 'creativeAssistantAd';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_STARTING_PAGE = 1;

export const JOURNEY_MODE = 'journeys';
export const CLASSIC_MODE = 'classic';

// PBJ Filtering
export const INTEGRATIONS = 'integrations';
export const VERTICALS = 'verticals';
export const OBJECTIVES = 'objectives';
export const TOPICS = 'topics';
export const GROUPS = 'groups';
export const APPS_INTEGRATIONS = 'appsIntegrations';

export const CHANNELS = 'channels';
export const POSSIBLE_VERTICALS = [
  {
    value: 'ecomm',
    label: 'e-Commerce',
  },
  {
    value: 'services',
    label: 'Services',
  },
];
export const PBJ_DEFAULT_FILTERS = {
  [TOPICS]: [],
  [APPS_INTEGRATIONS]: [],
  [CHANNELS]: [],
};

//Overview Redesign
export const OVERVIEW_TITLE = 'Customer Journeys';
export const PBJ_CREATED_AT = 'created_at';
export const PBJ_POPULARITY_SCORE = 'popularityScore';
export const PBJ_SORT_OPTIONS = [
  {
    value: PBJ_POPULARITY_SCORE,
    label: 'Popular',
  },
  {
    value: PBJ_CREATED_AT,
    label: 'Recently Added',
  },
];
export const CLASSICS_PATH =
  '/customer-journey/#/create-campaign/explore/emailCampaign:custom';
export const JOURNEY_DISPLAY_COUNT = 3;
export const OVERVIEW_JOURNEYS_FETCH_PARAMS = {
  count: JOURNEY_DISPLAY_COUNT,
  page: 1,
  orderBy: CREATED_AT,
  orderByDirection: DESC,
};
export const IN_PROGRESS = 'In Progress';

// Contact stats
export const ACTIVE_CONTACTS_LABEL = 'Active Contacts';

// Locations card lists can sit in
export const PBJ_PREVIEW_PAGE = 'pbj preview page';
export const PBJ_FRONT_DOOR = 'pbj front door';

// Statuses for GCP based PBJs
export const PBJ_ACTIVE_STATUS = 'active';
export const PBJ_DRAFT_STATUS = 'draft';

// PBJ Library re-work
export const PBJ_All_TOPICS = [
  {
    value: [],
    label: 'No topic',
  },
  {
    value: 'general',
    label: 'General',
  },
  {
    value: 'split_test',
    label: 'Split test',
  },
  {
    value: 'special_dates',
    label: 'Special dates',
  },
  {
    value: 'ecomm',
    label: 'Ecommerce',
  },
  {
    value: 'events',
    label: 'Events',
  },
  {
    value: 'feedback',
    label: 'Feedback',
  },
  {
    value: 'audience_management',
    label: 'Audience management',
  },
  {
    value: 'order_confirmation',
    label: 'Order confirmation',
  },
];

// Also known as funnel headers
export const PBJ_ALL_GROUPS = [
  {
    value: [],
    label: 'No group/funnel',
  },
  {
    value: 'welcome_contacts',
    label: 'Find & welcome new contacts',
  },
  {
    value: 'nurture_leads',
    label: 'Nurture leads',
  },
  {
    value: 'reengage_contacts',
    label: 'Re-engage contacts',
  },
  {
    value: 'support_manage_contacts',
    label: 'Support and manage contacts',
  },
  {
    value: 'transactional',
    label: 'Transactional emails',
  },
];

export const GA_MODAL_VALIDATION = {
  regexExpression: /^[\w\-_[\] ]+$/,
  inputErrorMessage:
    'Enter a name that uses numbers, letters, hyphens, spaces, brackets, or underscores.',
  onSubmitErrorMessage:
    'UTM values are required. Enter valid content for the following fields in order to save:',
};

export const DAY_OPTIONS = [
  {
    value: 0,
    label: 'Monday',
  },
  {
    value: 1,
    label: 'Tuesday',
  },
  {
    value: 2,
    label: 'Wednesday',
  },
  {
    value: 3,
    label: 'Thursday',
  },
  {
    value: 4,
    label: 'Friday',
  },
  {
    value: 5,
    label: 'Saturday',
  },
  {
    value: 6,
    label: 'Sunday',
  },
];

export const CJB_ABANDONED_CART_EXPERIMENT = {
  experimentKey: 'mcaut-4764-cjb-abandoned-cart-experiment-0201',
  controlKey: 'control',
  variantKey: 'variant',
};
export const ABANDON_CART_MERGE_TAG = 'CART:URL';
export const ABANDON_CART_MERGE_TAG_NAME = 'Abandoned Cart URL';

export const AI_FLOW_TYPE_MULTI_TOUCHPOINT = 'multi_touchpoint';
export const AI_POWERED_JOURNEYS_GROUP = 'ai_powered';

export const CJB_PBJ_LIBRARY_UPDATES_EXPERIMENT = {
  experimentKey: 'mcaut-5628-pbj-library-updates-0824',
  controlKey: 'control',
  variantKey: 'variant',
};

export const ACTION_SETTING_PROMO_CODE_EXPIRATION = 'promo_expiration';
