import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  screen: '/campaigns/edit',
  scope_area: 'email_editor',
  object: 'campaign',
  object_detail: 'preview_social_cards',
  action: 'engaged',
  ui_action: 'clicked',
};

const DEFAULT_EVENT_SPECIFIC_PARAMS = {
  campaign_id: 0,
  campaign_type: '',
  editor_type: '',
};

export const EnableSocialCardsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'enable_social_cards',
    ui_access_point: 'email_checklist',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const SocialCardIncompleteClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'your_social_card_is_incomplete',
    ui_access_point: 'email_checklist',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const SocialCardsPreviewCloseEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_access_point: 'preview_header',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const EnableCustomizedSocialCardsToggleEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'switch',
    ui_object_detail: 'enable_customized_social_cards',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
    toggle_status: false,
  },
);

export const NextImageClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const PreviousImageClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'back',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const UploadImageClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'upload_an_image',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const TitleInputClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'input_box',
    ui_object_detail: 'image_title',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const DescriptionInputClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'input_box',
    ui_object_detail: 'image_description',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const XPlatformClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_object_detail: 'x_formerly_twitter',
    ui_access_point: 'social_cards_left_pane',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const FacebookPlatformClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_object_detail: 'facebook',
    ui_access_point: 'social_cards_left_pane',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const PinterestPlatformClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_object_detail: 'pinterest',
    ui_access_point: 'social_cards_left_pane',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
  },
);

export const SaveAndCloseClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'save_and_close',
    ui_access_point: 'social_cards_settings_side_panel',
  },
  {
    ...DEFAULT_EVENT_SPECIFIC_PARAMS,
    card_complete: false,
  },
);
