import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from './OnboardingConstants';

/**
 * "This event fires when an user arrives on signup page"
 */
export const AccountSetUpLandedEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp0Event the event will contain.
   * These properties can never be changed inside of the accountSetUp0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup',
    action: 'started',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    consent_marketing_product_updates: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when an user click on sign up button"
 */
export const AccountSetupSignupButtonEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp1Event the event will contain.
   * These properties can never be changed inside of the accountSetUp1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'sign_up',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when an user click on login link"
 */
export const AccountSetupLoginLinkEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp2Event the event will contain.
   * These properties can never be changed inside of the accountSetUp2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'login',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when an user click on log in button"
 */
export const AccountSetupLoginButtonEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp3Event the event will contain.
   * These properties can never be changed inside of the accountSetUp3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'log_in',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const AccountSetupLoadFailureEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp5Event the event will contain.
   * These properties can never be changed inside of the accountSetUp5Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup',
    action: 'failed',
    ui_object: '',
    ui_object_detail: 'account_signup',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when a user clicks to return to QB."
 */
export const ReturnToQBEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp6Event the event will contain.
   * These properties can never be changed inside of the accountSetUp6Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'return_to_quickbooks',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
