import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_discovery_experience',
  scope_area: 'integration',
  object: 'integration',
};

export const DetailPageViewed = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const GetStartedClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'open_app',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationEyeBrowLinkClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'integrations',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const ViewMoreClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_more',
    ui_action: 'clicked',
    ui_access_point: 'footer',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const AdditionalChargesClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'additional_charges',
    ui_action: 'clicked',
    ui_access_point: 'right',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationWebsiteLinkClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'integration_website',
    ui_action: 'clicked',
    ui_access_point: 'right',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
  },
);

export const CategoryClicked = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'integration_category',
    ui_action: 'clicked',
    ui_access_point: 'right',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_name: '',
    integration_id: '',
    integration_category: '',
  },
);
