import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'campaign_manager',
  object: 'checkout',
};

export const UpgradeToStandardEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the UpgradeToStandardEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaign-manager',
    object_detail: 'upgrade_trigger_campaign_manager',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'upgrade_to_standard',
    ui_action: 'clicked',
    ui_access_point: 'hero',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const UpgradeSuccessfulFromCampaignManagerEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaign-manager',
    object_detail: 'account_payment',
    action: 'succeeded',
    ui_object: 'button',
    ui_object_detail: 'pay_now',
    ui_action: 'clicked',
    ui_access_point: 'card_section_right',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    user_package_plan_seleted: '',
  },
);

export const UpgradeFailFromCampaignManagerEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaign-manager',
    object_detail: 'account_payment',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'pay_now',
    ui_action: 'clicked',
    ui_access_point: 'card_section_right',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    user_package_plan_seleted: '',
  },
);

export const UpgradeCancelFromCampaignManagerEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaign-manager',
    object_detail: 'account_payment',
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
