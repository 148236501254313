import { createEcsEvent } from '../../configuration/createEvent';

/*
Source of truth: Wayfinding tab on Mailchimp ECS v1 sheet:
https://docs.google.com/spreadsheets/d/1rqiSqIBT1nW5iCZhybVbx7YKsEh-B5fKHtPDq8dhgYY/edit#gid=652732638
*/

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'product_left_nav',
  object: 'navigation',
  action: 'selected',
};

// Primary Accordion Button L0
export const LeftNavPrimaryAccordionButtonL0Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    ui_object: 'sub_menu',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
    object: 'navigation',
  },
  {
    object_detail: '',
    ui_object_detail: '',
    screen: '/',
  },
);

// SMS Accordion Button L0
// In ECS definition, currently only SMS L0 has is_l0_expanded_after_click param.
// If SMS L0 is removed after experiment, remove this ECS event as well. If SMS L0
// stays, then either remove is_l0_expanded_after_click param and send events with
// LeftNavPrimaryAccordionButtonL0Event above, or add is_l0_expanded_after_click
// to all L0s.
export const SmsLeftNavPrimaryAccordionButtonL0Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    ui_object: 'sub_menu',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
    object: 'navigation',
  },
  {
    is_l0_expanded_after_click: false,
    object_detail: '',
    ui_object_detail: '',
    screen: '/',
  },
);

// Secondary Accordion Button L1
export const LeftNavSecondaryAccordionButtonL1Event = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    ui_object: 'sub_menu',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    object_detail: '',
    ui_object_detail: '',
    screen: '/',
  },
);

// Expand/Collapse Button
export const CollapseLeftNavigationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'collaspe_keep_open_left_nav',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'collapse_keep_open_icon',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    left_nav_is_expanded: true,
    screen: '/',
  },
);

// Freddie Icon Click
export const FreddieIconClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'freddie_left_nav',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'freddie_icon',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
  },
);

// Get started button
export const LeftNavGetStartedClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'ftu_guideme',
    scope_area: 'product_front_door',
    object: 'navigation',
    object_detail: 'persistent_onboarding',
    action: 'selected',
    ui_object: 'sub_menu',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
  },
);
