import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the Profile step"
 */
export const ProfileViewedEvent = createEcsEvent(
  /**
   * This param contains the profile0Event the event will contain.
   * These properties can never be changed inside of the profile0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'user_profile',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user enters their personal info, then clicks the next CTA, and successfully passes validation"
 */
export const ProfileNextEvent = createEcsEvent(
  /**
   * This param contains the ProfileNextOrSkipEvent the event will contain.
   * These properties can never be changed inside of the ProfileNextOrSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'signup/setup',
    object_detail: 'user_profile',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    consent_phone_messaging: '',
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the Profile page"
 */
export const ProfileEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the ProfileEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the ProfileEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'signup/setup',
    object_detail: 'user_profile',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const ProfileFailedEvent = createEcsEvent(
  /**
   * This param contains the ProfileFailedEvent the event will contain.
   * These properties can never be changed inside of the ProfileFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'signup/setup',
    object_detail: 'user_profile',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
