import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

/*************************
 * TO-DO: References added for ease. Can be removed after release.
 * Tracking Plan Reference: https://wiki.intuit.com/display/DSVC/How+to+Create+A+Tracking+Plan
 *
 * DOMAIN
 * Initiative name: should align L1 on the FY24 City Map.
 * Screen: portion of the URL
 * Scope area: should describe which feature the events pertain to
 *
 * LOGICAL
 *
 * Object: What am I interacting with?
 * Action: How am I interacting with it?
 * Object Detail: How can I describe this object in 3-5 words?
 *
 *
 * USER INTERFACE
 *
 * UI Action: Which action did I take to interact with this element?
 * UI Object: What is the object I interacted with?
 * UI Object Detail: What does the object say?
 * UI Access Point: Where is this object on the page?
 *
 * Object names: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=366613319
 * Action names: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=2036547919
 * UI Properties: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=137707926
 *
 */

const DEFAULT_EVENT_PROPERTIES = {
  initiative_name: 'marketing_generation', // Formal ownership based on FY24 City map
  scope_area: 'content_studio',
  object: 'campaign',
  object_detail: 'content_brand_management',
  action: 'engaged',
  purpose: 'prod',
};

const CONFIGURABLE_PROPERTIES = {
  screen: '',
};

export const UploadsTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'uploads',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const MyProductsTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'my_products',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const InstagramTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'instagram',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const GiphyTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'giphy',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const MyLogoTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'my_logo',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ListViewToggleClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'list_view_toggle',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const GridViewToggleClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'grid_view_toggle',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ListItemSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'slat',
    ui_action: 'selected',
    ui_access_point: 'center',
    ui_object_detail: 'slat_item_selection',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const GridItemSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'list_item',
    ui_action: 'selected',
    ui_access_point: 'center',
    ui_object_detail: 'grid_item_selection',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeselectAllButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'deselect_all_items',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeleteButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'delete',
    ui_access_point: 'center',
    ui_object_detail: 'delete_item',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ImagePreviewBackButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'closed',
    ui_access_point: 'overlay',
    ui_object_detail: 'image_preview_exit',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const EditImageButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
    ui_object_detail: 'edit_image_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CopyUrlButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
    ui_object_detail: 'copy_url_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ViewDetailsClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'view_details',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const EditImageMenuItemClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'edit_image_dropdown_item',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CopyUrlMenuItemClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'copy_url_dropdown_item',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const SetMyLogoMenuItemClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'set_my_logo_dropdown_item',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ReplaceLogoButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
    ui_object_detail: 'replace_my_logo_modal_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CancelReplaceLogoModalButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'overlay',
    ui_object_detail: 'cancel_replace_my_logo_modal_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const InstagramDropdownSelection = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'listbox_item',
    ui_action: 'selected',
    ui_access_point: 'center',
    ui_object_detail: 'instagram_tab_listbox_selection',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const GoToFbSettingsClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'go_to_facebook_page_settings_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const RefreshPageInstagramTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'refresh_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const UploadButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'combo_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'upload',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ImportUrlDropdownItem = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'import_url_dropdown_item',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ImportButtonUrlModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'import_url_in_modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CancelImportUrlModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'cancel_import_url_modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CloseUploadPane = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'close_upload_pane',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CopyUrlUploadPane = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'copy_url_upload_pane',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const SortByOptionSelection = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'selectlist_item',
    ui_action: 'selected',
    ui_access_point: 'center',
    ui_object_detail: 'sortby_option_selection',
  },
  { ...CONFIGURABLE_PROPERTIES, sorted_by: '' },
);

export const SearchBarFocus = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
    ui_object_detail: 'search_field_focus',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const SearchBarSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
    ui_object_detail: 'search_field_submit',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const SearchBarClear = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
    ui_object_detail: 'search_field_clear',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ImagePreviewRenameClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'rename_image',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ImagePreviewDownloadClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'download_image',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ImagePreviewDeleteClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'delete_image',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const RenameModalSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    ui_object_detail: 'submit_rename_modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const RenameModalCancel = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    ui_object_detail: 'cancel_rename_modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeleteImageModalDelete = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    ui_object_detail: 'delete_modal_delete_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeleteImageModalCancel = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    ui_object_detail: 'delete_modal_cancel_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const MyLogoImportButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'my_logo_no_data_block_import_logo',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeleteFolderClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'delete_folder',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const FolderSelectionClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'folder_selection',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const EditFolderName = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'edit_folder_name_submit',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const MoveToClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'move_item_to_dropdown_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CreateFolderClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'create_folder_name_submits',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PaginationInputEnter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'input_field',
    ui_action: 'typed',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'page_number_input_submission',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PaginationClickLast = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'last_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PaginationClickFirst = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'first_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PaginationClickNext = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'next_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PaginationClickPrevious = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'previous_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ResetUploadsTabFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'reset_uploads_filters',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ResetProductsTabFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'reset_products_filters',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ResetGiphyTabFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'reset_giphy_filters',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ApplyUploadsTabFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'menu',
    ui_object_detail: 'apply_uploads_tab_filters',
  },
  { ...CONFIGURABLE_PROPERTIES, uploader_type: '', content_type: '' },
);

export const ApplyProductsTabFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'menu',
    ui_object_detail: 'apply_product_tab_filters',
  },
  { ...CONFIGURABLE_PROPERTIES, store: '' },
);

export const ApplyGiphyTabFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'text_button',
    ui_action: 'clicked',
    ui_access_point: 'menu',
    ui_object_detail: 'apply_giphy_filters',
  },
  { ...CONFIGURABLE_PROPERTIES, rating_value: '' },
);

export const ClearFilters = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'clear_filters',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const LinkInstagram = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'link_instagram_from_no_data_block',
  },
  { ...CONFIGURABLE_PROPERTIES },
);
