import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events for Landing Pages
const DEFAULT_EVENT_PROPERTIES_LANDING_PAGES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'landing_pages_forms',
  object: 'campaign',
  object_detail: 'landing_pages',
};

const LANDING_PAGES_CONFIGURABLE_PROPERIES = {
  landing_page_id: '',
  template_id: '',
};

export const viewedShutterstockTermsModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'modal',
    ui_object_detail: 'shuttershock_terms_modal',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const acceptShutterstockTermsModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'accept',
    ui_action: 'clicked',
    ui_access_point: 'shuttershock_terms_modal',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const declineShutterstockTermsModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'decline',
    ui_action: 'clicked',
    ui_access_point: 'shuttershock_terms_modal',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const viewedShutterstockAcceptAndChangeTermsModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'modal',
    ui_object_detail: 'shuttershock_accept_change_terms_modal',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const acceptAndChangeShutterstockTermsModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'accept_&_change_template',
    ui_action: 'clicked',
    ui_access_point: 'shuttershock_terms_modal',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const cancelChangeShutterstockTermsModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'shuttershock_terms_modal',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const viewedChangeTemplateModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'modal',
    ui_object_detail: 'change_template_modal',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const acceptChangeTemplateModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'change_template',
    ui_action: 'clicked',
    ui_access_point: 'change_template_modal',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);

export const cancelChangeTemplateModal = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_LANDING_PAGES,
    screen: '/landing-pages/templates',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'change_template_modal',
  },
  LANDING_PAGES_CONFIGURABLE_PROPERIES,
);
