import { createEcsEvent } from '../../configuration/createEvent';

/*
 * This event should fire when a user views the archived contacts page.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:51
 */

export const AudienceOrganizationViewArchivedContactsPageEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/archived-contacts',
    object: 'audience',
    object_detail: 'archived_contacts',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);

/*
 * This event should fire when a user clicks "export". Contact count should be passed as an event specific param, 0 is an option here.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:52
 */
export const AudienceOrganizationClickArchivedContactsExportEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/archived-contacts',
    object: 'audience',
    object_detail: 'archived_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'export',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    contact_count: '',
    has_contact_archive_error: '',
  },
);

/*
 * This event should fire when a user clicks "unarchive". Contact count should be passed as an event specific param, 0 is not an option here as contacts have to be selected before this action can be performed.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:53
 */

export const AudienceOrganizationClickArchivedContactsUnarchiveEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/archived-contacts',
    object: 'audience',
    object_detail: 'archived_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'unarchive',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    contact_count: '',
    has_contact_archive_error: '',
  },
);

/*
 * This event should fire when a user changes the number of contacts viewable on the page using the dropdown at the bottom of the page.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:54
 */

export const AudienceOrganizationClickArchivedContactsChangeViewableEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'lists/archived-contacts',
    object: 'audience',
    object_detail: 'archived_contacts',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'unarchive',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    contact_count: '',
    has_contact_archive_error: '',
    num_of_records_shown: '',
    records: '',
  },
);
