import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'partnerships_n_integrations',
  scope_area: 'multi_vertical',
  object: 'integration',
};
const CONFIGURABLE_PROPERTIES = {
  screen: '',
};

export const IntegrationsGetStartedCtaClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'wix_website_referral_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  { ...CONFIGURABLE_PROPERTIES },
);

export const IntegrationsConnectYourSiteCtaClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'wix_website_referral_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_your_site',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  { ...CONFIGURABLE_PROPERTIES },
);

export const IntegrationsGoToWixCtaClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'wix_website_referral_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'go_to_wix',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  { ...CONFIGURABLE_PROPERTIES },
);

export const IntegrationsFinishSyncingButtonClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname + window.location.search,
    object_detail: 'wix_website_referral_flow',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'finish_syncing',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
