import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_discovery_experience',
  scope_area: 'integration',
  object: 'integration',
};

export const IntegrationPageViewed = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/',
    object_detail: 'integration_directory',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationsCardClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'integration_name',
    ui_action: 'clicked',
    ui_access_point: 'center',
    user_id_required: false,
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const IntegrationUserSearch = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/',
    object_detail: 'integration_directory_search',
    action: 'engaged',
    ui_object: 'text_field',
    ui_object_detail: 'search_bar',
    ui_action: 'searched',
    ui_access_point: 'header',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: '',
    integration_search_filters: '',
  },
);

export const IntegrationFindMoreButton = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'find_more_apps_&_integrations',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const IntegrationFindExperts = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/integrations/',
    object_detail: 'integration_directory',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'find_expert',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
    user_id_required: false,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
