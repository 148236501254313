import { createEcsEvent } from '../../configuration/createEvent';

/*
Source of truth: Wayfinding tab on Mailchimp ECS v1 sheet:
https://docs.google.com/spreadsheets/d/1rqiSqIBT1nW5iCZhybVbx7YKsEh-B5fKHtPDq8dhgYY/edit#gid=652732638
*/

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'product_front_door',
  object: 'navigation',
  action: 'selected',
};

export const clickedCreateButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_l0',
    ui_object: 'sub_menu',
    ui_object_detail: 'create',
    ui_action: 'clicked',
    ui_access_point: 'left nav',
  },
  {
    access_to_ecu_content_gen_beta: false,
    front_door_experiment_user_category: 'control',
    screen: '',
  },
);

export const FrontDoorL1Click = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'sub_menu',
    ui_action: 'clicked',
    ui_access_point: 'front_door_left_nav',
  },
  {
    object_detail: 'default_l1',
    ui_object_detail: 'default_object_detail',
    screen: '',
  },
);

export const FrontDoorL2Click = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'sub_menu',
    ui_action: 'clicked',
    ui_access_point: 'front_door_left_nav',
  },
  {
    object_detail: 'default_l2',
    ui_object_detail: 'default_object_detail',
    screen: '',
  },
);

export const EmailRegularBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_regular_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'cta_begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const EmailPlainTextBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_plain_text_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'cta_begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const EmailTemplateBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_template_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const SmsBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'sms_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'create_sms',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const SmsContinueApp = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'sms_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'continue_application',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const AutomationBrowse = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'automations_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'browse_journeys',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const AutomationStart = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'automations_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const WebsiteManage = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'websites_websites_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'manage_site',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const WebsiteManageStore = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'websites_stores_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'manage_store',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const WebsiteAppointmentsStart = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'websites_appointments_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const LandingPageBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'landing_page_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const CreativeAssistantStart = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'creative_assistant_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'start_creating',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const SignupFormEmbeddedBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'sign_up_form_embedded_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const SignupFormPopUpBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'sign_up_form_pop_up_form_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const SignupFormLandingPageBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'sign_up_form_signup_landing_page_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const SignupEmbeddedSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'mailchimp_app_foundations',
    scope_area: 'wayfinding_search',
    object_detail: 'sign_up_form_l1',
    action: 'selected',
    ui_object: 'card',
    ui_object_detail: 'embedded_form',
    ui_action: 'clicked',
    ui_access_point: 'front_door_left_nav',
  },
  {},
);
export const SignupPopupSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'mailchimp_app_foundations',
    scope_area: 'wayfinding_search',
    object_detail: 'sign_up_form_l1',
    action: 'selected',
    ui_object: 'card',
    ui_object_detail: 'popup_form',
    ui_action: 'clicked',
    ui_access_point: 'front_door_left_nav',
  },
  {},
);
export const SignupLandingPageSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'mailchimp_app_foundations',
    scope_area: 'wayfinding_search',
    object_detail: 'sign_up_form_l1',
    action: 'selected',
    ui_object: 'card',
    ui_object_detail: 'signup_landing_page',
    ui_action: 'clicked',
    ui_access_point: 'front_door_left_nav',
  },
  {},
);

export const SurveysStart = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'surveys_l1',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'get_started',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const FbInstagramStart = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ads_facebook_instagram_ad_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const AdsGoogleStart = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'ads_google_remarketing_ad_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const PostcardOneTimeBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'postcard_one_time_send_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const PostcardRecurringBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'postcard_recurring_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

export const PostcardAbandonedBegin = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'postcard_abandoned_cart_l2',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'begin',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  { screen: '' },
);

// Is this being used?
export const AcpEmailRec = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_campaign',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'create_email',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { screen: '' },
);

// Is this being used?
export const AcpMultiRec = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'multivariate_testing_campaign_manager',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'create_test',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { screen: '' },
);

export const EmailRec = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_campaign',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'design_email',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { screen: '' },
);

export const AutomationRec = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'viewed_customer_journey',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'create_journey',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { screen: '' },
);

export const LandingPageRec = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'landing_page',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'build_landing_page',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  { screen: '' },
);
