import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

// This event should fire if the user interacts with the Margin Menu within the Nuni text editor
export const AdjustBlockMarginEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'margin',
  },
  {
    screen: '/email/editor', // email/editor|email/templates/editor
    user_template_id: null,
    campaign_id: null,
    template_id: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // layout|text|button|divider|image|logo|video|social|survey|mobile
    nuni_margin: 'all', // all|top|left|right|bottom
    nuni_margin_height: 0,
  },
);
// This event should fire if the user interacts with the Border Menu within the Nuni text editor in the email and template version
// screen and campaign_id are the only
export const AdjustBlockBorderEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'border',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // layout|text|button|divider|image|logo|video|social|survey|mobile
    nuni_border_style: 'none', // "<type: string (none|solid|dashed|dotted|double|inset|outset|groove|ridge)><Null Accepted: No>",
    nuni_border_width: 0, //"<type: integer><Null Accepted: No>",
  },
);
// This event should fire if the user interacts with the Rounded Borders box within the Nuni text editor
export const AdjustRoundedCornersEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'rounded_corners',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // text|button|divider|image|logo|video|social|survey|mobile
    nuni_rounded_corners: 'all', // all|top_left|top_right|bottom_left|bottom_right
    nuni_rounded_corners_radius: 0,
  },
);

// This event should fire when a user adjusts the alignment of a content block.
export const AdjustAlignmentEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'alignment',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // text|button|divider|image|logo|video|social|survey|mobile
    nuni_alignment_style: 'center', //left|right|center|full
  },
);

// This event should fire if the user interacts with the Source URL in the Video Block within the Nuni text editor
export const AdjustSourceEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'input_box',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'source',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // text|button|divider|image|logo|video|social|survey|mobile
  },
);

// This event should fire when a user adjusts the thickness of a content block.
export const AdjustThicknessEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'thickness',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_thickness: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // text|button|divider|image|logo|video|social|survey|mobile
  },
);

// This event should fire when a user adjusts the height of a content block.
export const AdjustHeightEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'height',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_height: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // text|button|divider|image|logo|video|social|survey|mobile
  },
);

// This event should fire when a user adjusts the width of a content block.
export const AdjustWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'width',
  },
  {
    screen: '/email/editor',
    campaign_id: null,
    template_id: null,
    user_template_id: null,
    nuni_width: null,
    nuni_tab: 'add', // add|styles
    nuni_section: 'text', // text|button|divider|image|logo|video|social|survey|mobile
  },
);
