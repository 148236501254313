import { createEcsEvent } from '../../../configuration/createEvent';

export const AnalyticsReportsSummaryEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'analytics',
    screen: '/reports/summary',
    object: 'reporting',
    object_detail: 'individual_touchpoint',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    campaign_id: '',
    campaign_type: '',
  },
);
