import { createEcsEvent } from '../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_discovery_experience',
  scope_area: 'integration',
  object: 'integration',
};

export const IntegrationsSearchEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the IntegrationsSearchEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'text_field',
    ui_object_detail: 'search_bar',
    ui_action: 'searched',
    ui_access_point: 'header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    integration_search_term: 'a',
    integration_search_filters: '',
    integration_search_count: 0,
  },
);

export const IntegrationsPaginationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'account/connected-sites/discover/',
    object_detail: 'discover',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'discover_pagination',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_page_navigation: '',
    integration_search_term: '',
  },
);
