import logger from '@mc/event-collection-standards/logger';

// HELPER FUNCTIONS
// --------------------------------------------------------------------------------------------------------------
/**
 * Properties defines in this object will always exist inside of events
 * these properties cannot be changed in any way
 */
export const propertiesRequiredOnAllEvents = {
  org: 'sbseg',
  scope: 'mc',
};

const validTypes = ['number', 'boolean', 'string', 'object'];
const validInstanceOf = [ArrayBuffer, Date];

const typeValidator = (value) => {
  return (
    validTypes.includes(typeof value) ||
    validInstanceOf.find((a) => value instanceof a) ||
    value === null
  );
};

/**
 * Gets any value and check if it passes what's considered valid for this library
 * @returns boolean
 */
export const isValidType = (value) => {
  if (Array.isArray(value)) {
    return value.map(typeValidator).filter((val) => !val).length === 0;
  }
  return typeValidator(value);
};

/**
 * WARNING DO NOT USE ANYWHERE ELSE OTHER THAN dojoEntry.js
 * Used to directly access the event object
 */
export const EVENT_IDENTITY = Symbol('proxy_target_identity');

/**
 * Depends on uniqueLibraryKey
 * @param {Object.title} title
 * @param {Object.message} message
 * @param {Object.data} data
 */
const logError = ({ title, message, data }) => {
  /**
   * We don't want to expose the /track/log endpoint to the login domain.
   * Because of that we need to prevent this from being executed on the login domain.
   * Additionaly since no endpoint is setup to receive this message a 404 error will be thrown in users console.
   * This check below just ensures this only gets kicked off anywhere else but the login domain.
   */
  if (window.location.hostname.indexOf('login.mailchimp.com') === -1) {
    if (__DEV__) {
      logger(title, { ...data, errorMessage: message });
    }
  }
  /**
   * If we're in dev throw an error if we're in prod send a warning to the console
   */
  if (__DEV__ || __TEST__) {
    throw new Error(message);
  } else {
    // eslint-disable-next-line no-console
    console.trace();
    console.warn(message);
  }
};

// END OF HELPER FUNCTIONS
// --------------------------------------------------------------------------------------------------------------

/**
 * This function is used to create an event object that can be sent to Segment.
 *
 * Users of this function will need to provide two object in the following shape:
 *
 * Param1
 * defaultProperties: {
 *    //
 *      The properties defined in here are the base properties for an event.
 *      They will never change once the event object has been created.
 *
 *      DO NOT put properties inside of this object if there are plans to
 *      modify the properties later on.
 *    //
 * },
 *
 * Param 2
 * configurableProperties: {
 *    //
 *      THe properties defined in here can be changed however you wish.
 *      However these properties should not override any properties from
 *      the defaultProperties
 *    //
 * }
 *
 *
 *
 *
 */
/**
 *
 * @param {Object} defaultProperties
 * @param {Object} configurableProperties
 * @returns Object
 */
export const createEcsEvent = (defaultProperties, configurableProperties) => {
  'use strict';

  /**
   * Check to make sure the properties defined in the default property aren't being
   * set again in the configurableProperties
   */
  for (const key in defaultProperties) {
    if (configurableProperties[key]) {
      logError({
        title: 'ECS Configuration Error',
        message: `An attempt is being made to override the property "${key}" inside of the configurableProperties`,
        data: {
          defaultProperties,
          configurableProperties,
        },
      });
    }
  }

  const finalObject = {
    ...defaultProperties,
    ...configurableProperties,
  };

  for (const key in finalObject) {
    /**
     * Check to ensure all keys are strings
     */
    if (typeof key === 'number') {
      logError({
        title: 'ECS Configuration Error',
        message: 'Object keys must be a string',
        data: {
          defaultProperties,
          configurableProperties,
        },
      });
    }

    /**
     * Checking to make sure there are no properties that are trying to override
     * the properties set on all events
     */
    if (propertiesRequiredOnAllEvents[key]) {
      logError({
        title: 'ECS Configuration Error',
        message: `An attempt is being made to override this property "${key}". This is a required field on all events, modifications are not allowed`,
        data: {
          defaultProperties,
          configurableProperties,
        },
      });
    }

    /**
     * Ensure that the default properties can no longer be modified
     */
    if (defaultProperties[key] !== undefined) {
      Object.defineProperty(finalObject, key, {
        value: finalObject[key],
        writable: false,
        configurable: false,
        enumerable: true,
      });
    }

    // Ensure the value for every property is valid
    if (!isValidType(finalObject[key])) {
      logError({
        title: 'ECS Configuration Error',
        message: `Invalid value type provided to this property: "${key}"`,
        data: {
          defaultProperties,
          configurableProperties,
        },
      });
    }
  }

  /**
   * Add the default property every event will have
   * These properties cannot be changed nor modifed once set
   */
  for (const key in propertiesRequiredOnAllEvents) {
    // Ensure that the default properties can no longer be modified
    Object.defineProperty(finalObject, key, {
      value: propertiesRequiredOnAllEvents[key],
      writable: false,
      configurable: false,
      enumerable: true,
    });
  }

  if (__TEST__) {
    /**
     * This allows an automatic check inside of the tests to make sure properties are configured properly
     */
    Object.defineProperty(finalObject, '__defaultPropertyKeys', {
      value: Object.keys(defaultProperties),
      enumerable: false,
    });
    Object.defineProperty(finalObject, '__configurablePropertyKeys', {
      value: Object.keys(configurableProperties),
      enumerable: false,
    });
  }

  /**
   * Adds a non enumerable property to the final object. This property will be checked by the ECS tracking library to ensure that events orginate
   * from the mc-ecs-storage location
   */
  Object.defineProperty(finalObject, '__mc-ecs-storage', {
    value: Symbol.for('__mc-ecs-storage'),
    enumerable: false,
    writable: false,
    configurable: false,
  });

  /**
   * This trap is used to detect if an invalid value is being added to the event property
   */
  const validator = {
    set: (obj, prop, value) => {
      if (configurableProperties[prop] !== undefined) {
        if (!isValidType(value)) {
          const errorReportObject = {
            title: 'ECS Runtime Error',
            message: `Invalid value type provided to this property: "${prop}" : ${value}`,
            data: {
              invalidProperty: prop,
              object: obj,
            },
          };

          logError(errorReportObject);

          /**
           * If false is returned, a TypeError would be thrown.
           * By not returning false no error will be thrown but also the value of the property won't change.
           */
          return new Error(errorReportObject.message);
        }
      }
      obj[prop] = value;
      return true;
    },

    /**
     * This get traps allows us to get the object that was passed into the function plus any additional Properties
     * Technically you could just "mash" together the default and configurable properties but
     * it's possible over time that new defaults would be added to those properties inside
     * of this function.
     */
    get: (obj, prop, receiver) => {
      if (prop === EVENT_IDENTITY) {
        return obj;
      }
      return Reflect.get(obj, prop, receiver);
    },
  };

  /**
   * Prevent new properties from being added to this event
   */
  return Object.seal(new Proxy(finalObject, validator));
};
