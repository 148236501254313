import { createEcsEvent } from '../../../configuration/createEvent';

const defaultProperties = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/custom-reports/details/',
  object: 'reporting',
  object_detail: 'custom_reports_details',
};

export const editDetailsEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_report',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const downloadHistoryEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'downloaded',
    ui_object: 'button',
    ui_object_detail: 'export_csv',
    ui_action: 'clicked',
    ui_access_point: 'month_accordian',
  },
  {},
);
