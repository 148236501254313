import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  screen: 'campaigns/edit',
  object: 'automations',
  object_detail: 'automate_this_email',
};

export const ReplicateModalViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    campaign_id: 0,
    variant_viewed: '',
  },
);

export const ReplicateModalEngagedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ui_object_detail: '',
    campaign_id: 0,
    variant_viewed: '',
  },
);

export const ReplicateModalCreatedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'automate_welcome_email',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: 0,
    variant_viewed: '',
  },
);
