import { createEcsEvent } from '../../configuration/createEvent';

export const AudienceListPageViewed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list_overview',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const CreateAudienceButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'new_audience',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_audience',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const OptInSettingsButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audience_opt_in',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'opt_in_settings',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const SortByDropdownClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audience_opt_in',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience_sort_by',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sort_by_method: 'name',
  },
);

export const ImportContactsLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'import_contacts',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'import contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AddSingleContactLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'add_a_single_contact',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AllContactsLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'all_contacts',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SignUpFormsLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'signup_forms',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const SettingsLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'settings',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ReplicateButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'replicate_audience',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ConfirmReplicateButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'replicate',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const CombineButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'combine_audiences',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ConfirmCombineButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'combine_audience',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const DeleteButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'delete_audience',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ConfirmDeleteButtonClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const ImportHistoryLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_import_history',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ExportsLinkClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: 'audience/manage',
    object: 'audience',
    object_detail: 'audiences_list',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_audience_exports',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);
