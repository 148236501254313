import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'audience_settings',
  screen: 'audience/settings',
  object: 'audience',
  object_detail: 'audience_settings',
  action: 'engaged',
  ui_object: 'link',
  ui_action: 'clicked',
  ui_access_point: 'center',
};

export const audienceSettingsViewSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_action: '',
    ui_access_point: '',
    ui_object_detail: '',
  },
  {},
);

export const audienceSettingsAudienceNameAndCampaignDefaultsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'audience_name_and_campaign_defaults',
  },
  {},
);

export const audienceSettingsAudienceFieldsAndMergeTagsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'audience_fields_and_merge_tags',
  },
  {},
);

export const audienceSettingsTagsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'tags',
  },
  {},
);

export const audienceSettingsGroupsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'groups',
  },
  {},
);

export const audienceSettingsGdprFieldsAndSettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'gdpr_fields_and_settings',
  },
  {},
);

export const audienceSettingsWebhooksEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'webhooks',
  },
  {},
);

export const audienceSettingsPreferencesCenterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'preferences_center',
  },
  {},
);

export const audienceSettingsPublicitySettingsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'publicity_settings',
  },
  {},
);

export const audienceSettingsRequiredEmailFooterContentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'required_email_footer_content',
  },
  {},
);

export const audienceSettingsEmailBeamerEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'email_beamer',
  },
  {},
);

export const audienceSettingsGoogleAnalyticsOnArchivePagesEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object_detail: 'google_analytics_on_archive_pages',
  },
  {},
);
