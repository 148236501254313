import { createEcsEvent } from '../../../configuration/createEvent';

// REPORTING-3313
const LEGACY_PAGE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'customer_journey/report',
  object: 'reporting',
  object_detail: 'cjb_original_report',
};

export const CJBReportViewedEvent = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    journey_id: '',
  },
);

export const LegacyCJBReportChannelToggleClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'datawell',
    ui_action: 'toggled',
    ui_access_point: 'performance_metrics',
  },
  {
    ui_object_detail: '',
  },
);

export const LegacyCJBReportViewReportClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_journey',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const LegacyCJBReportEditSelectedMetricsClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'monitor_performance_component',
  },
  {},
);

export const LegacyCJBReportViewMoreCtaClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_more',
    ui_action: 'clicked',
    ui_access_point: 'middle_section',
  },
  {},
);

export const LegacyCJBReportExportCtaClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'export',
    ui_action: 'clicked',
    ui_access_point: 'bottom_section',
  },
  {},
);

export const LegacyCJBReportCampaignNameClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'message_name',
    ui_action: 'clicked',
    ui_access_point: 'bottom_section',
  },
  {},
);

export const LegacyCJBReportCaretRightIconClick = createEcsEvent(
  {
    ...LEGACY_PAGE_PROPERTIES,
    action: 'engaged',
    ui_object: 'carrot',
    ui_object_detail: 'carrot',
    ui_action: 'clicked',
    ui_access_point: 'bottom_section',
  },
  {},
);

// additions from this line on will be for cjb report w/timeseries

const sharedProps = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/cjb_report',
  object: 'reporting',
  object_detail: 'cjb_overview_report',
};

export const CJBReportPageView = createEcsEvent(
  {
    ...sharedProps,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);

export const CJBReportPrimaryCtaClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_journey',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportDateRangeApplyClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'date_field',
    ui_object_detail: 'apply',
    ui_action: 'clicked',
    ui_access_point: 'secondary_header',
  },
  {
    cjb_report_tab: '',
    cjb_report_user_version: '',
    date_range: '',
  },
);

export const CJBReportDateRangeCancelClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'date_field',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'secondary_header',
  },
  {
    cjb_report_tab: '',
    cjb_report_user_version: '',
  },
);

// ROW 168
export const CJBReportFilterApplyClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'filter_message',
    ui_action: 'clicked',
    ui_access_point: 'secondary_header',
  },
  {
    cjb_report_tab: '',
    cjb_report_user_version: '',
  },
);

export const CJBReportEditSelectedMetricsClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'performance_overview_datawell',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportSaveSelectedMetricsClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'edit_your_metric_modal',
  },
  {
    cjb_multichannel_performance_metric: [],
    cjb_report_user_version: '',
  },
);

export const CJBReportCancelSelectedMetricsClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'edit_your_metric_modal',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportSmsToggleClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'toggle_button',
    ui_object_detail: 'sms',
    ui_action: 'clicked',
    ui_access_point: 'performance_overview_datawell',
  },
  {
    cjb_report_tab: '',
    cjb_report_user_version: '',
  },
);

export const CJBReportEmailToggleClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'toggle_button',
    ui_object_detail: 'email',
    ui_action: 'clicked',
    ui_access_point: 'performance_overview_datawell',
  },
  {
    cjb_report_tab: '',
    cjb_report_user_version: '',
  },
);

export const CJBReportMessageFilterClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'filter_object',
    ui_action: 'clicked',
    ui_access_point: 'secondary_header',
  },
  {},
);

// Performance Overview Tab
export const CJBReportPerformanceOverviewTabClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: 'performance_overview',
    ui_action: 'clicked',
    ui_access_point: 'secondary_header',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportAdditionalEngagementMetricsCollapse = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_up',
    ui_action: 'clicked',
    ui_access_point: 'engagement_accordion',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportAdditionalEcommerceMetricsCollapse = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_up',
    ui_action: 'clicked',
    ui_access_point: 'ecommerce_accordion',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportAdditionalDeliveryMetricsCollapse = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_up',
    ui_action: 'clicked',
    ui_access_point: 'delivery_accordion',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportAdditionalEngagementMetricsExpand = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_down',
    ui_action: 'clicked',
    ui_access_point: 'engagement_accordion',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportAdditionalEcommerceMetricsExpand = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_down',
    ui_action: 'clicked',
    ui_access_point: 'ecommerce_accordion',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportAdditionalDeliveryMetricsExpand = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_down',
    ui_action: 'clicked',
    ui_access_point: 'delivery_accordion',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportJourneyAnalysisExportClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'export_csv',
    ui_action: 'clicked',
    ui_access_point: 'journey_analysis',
  },
  {
    cjb_report_user_version: '',
  },
);

export const CJBReportJourneyAnalysisCampaignNameClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'message_name',
    ui_action: 'clicked',
    ui_access_point: 'journey_analysis',
  },
  {
    cjb_report_user_version: '',
    journey_id: '',
  },
);

export const CJBReportJourneyAnalysisViewReportClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'analytics',
    ui_action: 'clicked',
    ui_access_point: 'journey_analysis',
  },
  {
    cjb_report_user_version: '',
  },
);
