import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user lands on the plan selection page"
 */
export const PlanSelectionLandedEvent = createEcsEvent(
  /**
   * This param contains the planSelection0Event the event will contain.
   * These properties can never be changed inside of the planSelection0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks one of the radio button for plan selection."
 */
export const PlanSelectionRadioButtonEvent = createEcsEvent(
  /**
   * This param contains the planSelection1Event the event will contain.
   * These properties can never be changed inside of the planSelection1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'marketing_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks next on the plan selection page leading to checkout. "
 */
export const PlanSelectionNextEvent = createEcsEvent(
  /**
   * This param contains the planSelection2Event the event will contain.
   * These properties can never be changed inside of the planSelection2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks the Compare Plan Features link."
 */
export const PlanSelectionComparePlanEvent = createEcsEvent(
  /**
   * This param contains the planSelection3Event the event will contain.
   * These properties can never be changed inside of the planSelection3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'compare_plan_features',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on a CRM plan."
 */
export const PlanSelectionSelectPlanEvent = createEcsEvent(
  /**
   * This param contains the planSelection3Event the event will contain.
   * These properties can never be changed inside of the planSelection3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'select',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user continues after seeing the paid plan is coming soon modal"
 */
export const PlanSelectionContinueEvent = createEcsEvent(
  /**
   * This param contains the planSelection3Event the event will contain.
   * These properties can never be changed inside of the planSelection3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'select',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
