import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const CampaignAdjustFont = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    font_viewed_from: '',
    nuni_text_font: '',
    applied_method: 'in_this_email',
  },
);

export const TemplateAdjustFont = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    font_viewed_from: '',
    nuni_text_font: '',
    applied_method: 'in_this_email',
  },
);

export const CampaignAdjustFontSize = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font_size',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    font_size_viewed_from: '',
    nuni_text_font_size: 0,
  },
);

export const TemplateAdjustFontSize = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font_size',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    font_size_viewed_from: '',
    nuni_text_font_size: 0,
  },
);

export const CampaignAdjustBold = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'bold',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    bold_viewed_from: '',
    is_nuni_text_bold: false,
  },
);

export const TemplateAdjustBold = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'bold',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    bold_viewed_from: '',
    is_nuni_text_bold: false,
  },
);

export const CampaignAdjustItalics = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'italic',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    italic_viewed_from: '',
    is_nuni_text_italic: false,
  },
);

export const TemplateAdjustItalics = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'italic',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    italic_viewed_from: '',
    is_nuni_text_italic: false,
  },
);

export const CampaignAdjustUnderline = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'underline',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    underline_viewed_from: '',
    is_nuni_text_underline: false,
  },
);

export const TemplateAdjustUnderline = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'underline',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    underline_viewed_from: '',
    is_nuni_text_underline: false,
  },
);

export const CampaignAdjustAlignment = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'alignment',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    alignment_viewed_from: '',
    nuni_text_alignment: '',
  },
);

export const TemplateAdjustAlignment = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'alignment',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    alignment_viewed_from: '',
    nuni_text_alignment: '',
  },
);

export const CampaignAdjustLetterSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'letter_spacing',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    letter_spacing_viewed_from: '',
    nuni_text_letter_spacing: 0,
  },
);

export const TemplateAdjustLetterSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'letter_spacing',
    ui_access_point: 'text_toolbar',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: '',
    letter_spacing_viewed_from: '',
    nuni_text_letter_spacing: 0,
  },
);
