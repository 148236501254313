import { createEcsEvent } from '../../configuration/createEvent';

/*
 * Fire event when the user views the add a subscriber page (aka form)
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:85
 */

export const AudienceOrganizationViewAddASubscriberFormEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
    has_groups_enabled: false,
    screen: 'list/members/add',
  },
);

/*
 * Fire event when a users clicks the "import an audience" link near the top of the page (this launches the import flow)
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:86
 */

export const AudienceOrganizationClickImportAnAudienceCtaEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'import_an_audience',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {
    screen: 'list/members/add',
  },
);

/*
 * Fire when a user clicks the + sign in the tags section
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:87
 */

export const AudienceOrganizationClickToAddATagEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: '+',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: 'list/members/add',
  },
);

/*
 * Fire when a user clicks "manage tags" in the tags section
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:88
 */

export const AudienceOrganizationClickManageTagsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'list/members/add',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'manage_tags',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

/*
 * Fire when a user clicks the "learn more" hyperlink to view the help article
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:89
 */

export const AudienceOrganizationClickLearnMoreArticleAboutEmailPermissionEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: 'list/members/add',
  },
);

/*
 * Fire when a user clicks the "learn more about SMS marketing consent" hyperlink to view the help article
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:90
 */

export const AudienceOrganizationClickLearmAboutSmsMarketingConsentEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_about_sms_marketing_consent',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: 'list/members/add',
  },
);

/*
 * Fire when a user clicks "subscribe". Pass tags selected, groups selected, sub status box checked? Y/N and duplicate resolution box checked? Y/N
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:91
 */

export const AudienceOrganizationClickSubscribeEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'subscribe',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    marketing_channel_fields: '',
    has_checked_email_permission_box: false,
    has_checked_sms_permission_box: false,
    has_checked_resolve_duplicates_box: false,
    contact_group_selected: '',
    contact_tags_added: [],
    num_tags_selected: 0,
    num_groups_selected: 0,
    audience_tag_name: '',
    audience_group_name: '',
    screen: 'list/members/add',
    has_add_contact_error: null,
  },
);
