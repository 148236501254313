import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_experience',
  scope_area: 'integration',
  screen: 'integrations/manage',
  object_detail: 'manage',
};

const BASE_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'integration_experience',
  scope_area: 'integration',
};

export const ManagePageViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: 'manage_page',
    ui_action: 'viewed',
    ui_access_point: 'center',
    object: 'integration',
  },
  {
    integration_update_module: 'string',
  },
);

export const ConnectNewAppIntegrationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_new_app',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'integration',
  },
  {},
);

export const IntegrationOnManageClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'manage_integration',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'integration',
  },
  {
    integration_name: 'string',
  },
);

export const BrowseAllIntegrationsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'browse_apps_integrations',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'integration',
  },
  {},
);

export const OtherConnectionsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'accordian',
    ui_object_detail: 'other_connections',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'integration',
  },
  {},
);

export const LearnMoreClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'integration',
  },
  {},
);

export const AuthorizedAppDisconnectEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'deleted',
    ui_object: 'button',
    ui_object_detail: 'disconnect',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'integration',
  },
  {
    integration_name: 'string',
    system_id: 'string',
  },
);

export const RecommendationCtasClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'integration_details',
    object: 'recommendation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'recommendation_engaged',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    recommendation_type: 'string',
  },
);

export const SkipButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'integration_details',
    object: 'recommendation',
    action: 'dismissed',
    ui_object: 'button',
    ui_object_detail: 'skip_recommendation',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    recommendation_type: 'string',
  },
);

export const RecommendationActionCardsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'integration_details',
    object: 'recommendation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'recommendation_engaged',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    recommendation_type: 'string',
    secondary_type: 'string',
  },
);

export const ImportMyBrandClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'recommendation',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'recommendation_engaged',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    recommendation_type: 'string',
  },
);

export const ViewAllRecommendationsClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'recommendation',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'engaged',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    recommendation_type: 'string',
    secondary_type: 'string',
  },
);

export const ClickLeaveReviewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'review_mailchimp',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ClickTakeSurveyEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'take_survey',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ClickGoodSoFarClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'good_so_far',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ClickCouldBeBetterClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'could_be_better',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ClickDismissFeedbackClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'dismissed',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ToggleRecommendationClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'recommendation',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'accordian',
    ui_object_detail: 'toggle_recommendation',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    recommendation_type: 'string',
    is_expanded: 'boolean',
  },
);

export const CancelClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'dismissed',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    integration_name: 'string',
    client_id: 'string',
  },
);

export const ClickOutsideModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'dismissed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    integration_name: 'string',
    client_id: 'string',
  },
);

export const ClickPBJEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'automations',
    object_detail: 'previewed_prebuilt_journey',
    action: 'selected',
    ui_object: 'card',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    pbj_type: 'string',
  },
);

export const ClickViewAllPBJsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'automations',
    object_detail: 'view_all_prebuilt_journey',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_all_prebuilt_journeys',
    ui_action: 'engaged',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const StoreLinkClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'store_link',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ViewReportingClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_reporting',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const DisconnectIDXDisconnectModalClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'deleted',
    ui_object: 'button',
    ui_object_detail: 'disconnect',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    integration_name: 'string',
    client_id: 'string',
  },
);

export const ClickOutsideIDXDisconnectModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'dismissed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    integration_name: 'string',
    client_id: 'string',
  },
);

export const CancelIDXDisconnectModalClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'dismissed',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    integration_name: 'string',
    client_id: 'string',
  },
);

export const AppDisconnectedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'connection',
    action: 'deleted',
    ui_object: 'button',
    ui_object_detail: 'disconnect',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    integration_name: 'string',
    system_id: 'string',
  },
);

export const DisconnectClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'integration_details',
    action: 'deleted',
    ui_object: 'button',
    ui_object_detail: 'disconnect',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    integration_name: 'string',
    client_id: 'string',
    keep_data: 'boolean',
  },
);

export const UpdateNeededButtonClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'app_header_update_module',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'update_needed',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    screen: 'string',
    integration_name: 'string',
  },
);

export const CancelButtonClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'app_header_update_module',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'integration_update_modal',
  },
  {
    screen: 'string',
    integration_name: 'string',
  },
);

export const IllDoThisLaterClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'app_header_update_module',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'ill_do_this_later',
    ui_action: 'clicked',
    ui_access_point: 'integration_update_modal',
  },
  {
    screen: 'string',
    integration_name: 'string',
  },
);

export const UpdateNowClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'app_header_update_module',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'update_now',
    ui_action: 'clicked',
    ui_access_point: 'integration_update_modal',
  },
  {
    screen: 'string',
    integration_name: 'string',
  },
);

export const UpdateNeededModalViewedEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    object_detail: 'app_header_update_module',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    screen: 'string',
    integration_name: 'string',
  },
);

export const UpdateModuleClickedEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'manage_update_module',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'update_now',
    ui_action: 'clicked',
    ui_access_point: 'center',
    integration_name: 'shopify',
  },
  {
    update_module_location: 'string',
  },
);

export const AppDetailsPageViewedEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: 'manage_integration_detail_page',
    ui_action: 'viewed',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ManageDetailPageViewedEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: 'manage_integration_detail_page',
    ui_action: 'viewed',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    update_module: 'boolean',
    integration_source: 'string',
  },
);

export const ManageAppOptionEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    ui_object_detail: 'string',
  },
);

export const ManageAppClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'manage_app',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const AppSettingsClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'app_settings',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
  },
);

export const ReadOnlySettingClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'setting',
    ui_action: 'clicked',
    ui_access_point: 'center',
    setting_type: 'read_only',
  },
  {
    setting_id: 'string',
    integration_name: 'string',
  },
);

export const PopUpFormClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    screen: 'integrations/manage',
    object: 'integration',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'setting',
    ui_action: 'clicked',
    ui_access_point: 'center',
    setting_type: 'button',
  },
  {
    setting_id: 'string',
    integration_name: 'string',
  },
);

export const StoreActivityTrackingEnabledEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'setting',
    ui_action: 'clicked',
    ui_access_point: 'center',
    setting_type: 'button',
  },
  {
    setting_value: 'string',
    setting_id: 'string',
    integration_name: 'string',
  },
);

export const SyncCustomerTagsEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'setting',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    setting_id: 'string',
    integration_name: 'string',
    setting_value: 'string',
  },
);

export const ToggleableSettingClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'setting',
    ui_action: 'clicked',
    ui_access_point: 'center',
    setting_type: 'toggle',
  },
  {
    integration_name: 'string',
    setting_value: 'string',
  },
);

export const PaintedDoorClickEvent = createEcsEvent(
  {
    ...BASE_EVENT_PROPERTIES,
    object: 'integration',
    screen: 'integrations/manage',
    object_detail: 'integration_details',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'painted_door_link',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    painted_door_name: 'string',
  },
);

export const ecommFTUBannerViewedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'strategic_partners',
    scope_area: 'embedded_app',
    screen: '/',
    object: 'integration',
    object_detail: 'complete_integration_connection',
    action: 'viewed',
    ui_object: 'banner',
    ui_object_detail: 'shopify_connection',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    integration_name: 'string',
    entry_point: 'string',
    integration_id: 'string',
  },
);

export const ecommFTUBannerCtaPrimaryClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'strategic_partners',
    scope_area: 'embedded_app',
    screen: '/',
    object: 'integration',
    object_detail: 'complete_integration_connection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'finish_syncing_with_shopify',
    ui_action: 'clicked',
    ui_access_point: 'shopify_banner',
  },
  {
    integration_name: 'string',
    entry_point: 'string',
    integration_id: 'string',
  },
);

export const ecommFTUBannerCtaSecondaryClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'strategic_partners',
    scope_area: 'embedded_app',
    screen: '/',
    object: 'integration',
    object_detail: 'complete_integration_connection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'i_will_do_this_later',
    ui_action: 'clicked',
    ui_access_point: 'shopify_banner',
  },
  {
    integration_name: 'string',
    entry_point: 'string',
    integration_id: 'string',
  },
);

export const PromoBannerClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'billing_subscription',
    scope_area: 'revenue_retention',
    object: 'recommendation',
    screen: window.location.pathname,
    object_detail: 'account_upgrade',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'promotional_banner',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    account_promotion_banner_name: 'string',
  },
);
