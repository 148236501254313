import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DOMAIN_PROPERTIES = {
  purpose: 'prod',
  scope_area: 'email_domain',
  initiative_name: 'bulk_email',
  screen: 'account/domains',
  object_detail: 'custom_email_domains',
  object: 'account',
  action: 'engaged',
};

export const trackBeginEmailDomainAuthenticationClick = createEcsEvent(
  {
    ...DOMAIN_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
    ui_object_detail: 'start_authentication',
  },
  {},
);
