import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the Business Address step"
 */
export const BusinessAddressViewedEvent = createEcsEvent(
  /**
   * This param contains the BusinessAddressViewedEvent the event will contain.
   * These properties can never be changed inside of the BusinessAddressViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user enters their address info, then clicks the next CTA, and successfully passes validation"
 */
export const BusinessAddressNextEvent = createEcsEvent(
  /**
   * This param contains the BusinessAddressNextOrSkipEvent the event will contain.
   * These properties can never be changed inside of the BusinessAddressNextOrSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the Business Address page"
 */
export const BusinessAddressEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the BusinessAddressEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the BusinessAddressEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const BusinessAddressFailedEvent = createEcsEvent(
  /**
   * This param contains the BusinessAddressFailedEvent the event will contain.
   * These properties can never be changed inside of the BusinessAddressFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_address',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
