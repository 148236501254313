import { createEcsEvent } from '../../../configuration/createEvent';

const defaultProperties = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/custom-reports/builder',
  object: 'reporting',
  object_detail: 'custom_reports_builder',
};

export const createdReportEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'run_report',
    ui_action: 'clicked',
    ui_access_point: 'bottom_cta/header',
  },
  {},
);

export const canceledReportEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'closed',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'bottom_cta',
  },
  {},
);

export const backEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'closed',
    ui_object: 'arrow',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const customReportsBuilderMppExcludedEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'apple_mpp',
    ui_action: 'clicked',
    ui_access_point: 'feedback_block',
  },
  {
    is_applempp_excluded: '',
  },
);
