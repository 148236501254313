import {
  ECS_EVENT_BUILDER_SCREEN,
  ECS_EVENT_CUB_OBJECT,
  ECS_EVENT_CUB_OBJECT_DETAIL,
  ECS_EVENT_ACTION_PROPERTY_STARTED,
  ECS_EVENT_UI_ACTION_CLICKED,
  ECS_EVENT_UI_OBJECT_LINK,
  ECS_EVENT_SEGMENTATION_METHOD_REGULAR,
} from '@mc/constants/automation';
import { createEcsEvent } from '../../configuration/createEvent';

// These properties are constant throughout all JourneyMapStepEvents events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  screen: 'customer-journey/builder',
  object: 'segment',
  object_detail: 'journey_map_step',
};

export const CJBCubOpenedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: ECS_EVENT_BUILDER_SCREEN,
    object: ECS_EVENT_CUB_OBJECT,
    object_detail: ECS_EVENT_CUB_OBJECT_DETAIL,
    action: ECS_EVENT_ACTION_PROPERTY_STARTED,
    ui_object: ECS_EVENT_UI_OBJECT_LINK,
    ui_action: ECS_EVENT_UI_ACTION_CLICKED,
    segmentation_method: ECS_EVENT_SEGMENTATION_METHOD_REGULAR, // won't support advanced until AUT-4084 is done
  },
  {
    journey_id: 0,
    ui_object_detail: '',
    ui_access_point: '',
    has_elc_release_1_sms_cohort_ff: null,
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);
