import { createEcsEvent } from '../../../configuration/createEvent';
import {
  DEFAULT_EVENT_PROPERTIES,
  LOADING_EVENT_PROPERTIES,
} from '../OnboardingConstants';

export const IndustryStepViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'industry',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },

  {
    entry_point: '',
  },
);

export const IndustryStepNextEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'industry',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },

  {
    genai_industry_rank: '',
    next_or_skip: '',
    entry_point: '',
    is_genai_industry_choice: '',
  },
);

export const IndustryStepEngagedSkipEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'industry',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },

  {
    entry_point: '',
  },
);

export const IndustryStepFailedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'industry',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },

  {
    error: '',
    entry_point: '',
  },
);

export const IndustryDropdownOptionEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'industry',
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'industry_options',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },

  {
    entry_point: '',
    industry: '',
    genai_industry_rank: '',
  },
);

/* Industry Loading Events */

export const IndustryLoadingViewedEvent = createEcsEvent(
  {
    ...LOADING_EVENT_PROPERTIES,
    object_detail: 'industry_load_start',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },

  {
    entry_point: '',
  },
);

export const IndustryLoadingCompletedEvent = createEcsEvent(
  {
    ...LOADING_EVENT_PROPERTIES,
    object_detail: 'industry_load_end',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },

  {
    entry_point: '',
    is_able_to_process: '',
    is_timeout: '',
    industries_generated: '',
    time_to_process: '',
    is_url_provided: '',
    industries_code_1: '',
    industries_code_2: '',
    industries_code_3: '',
    industries_code_4: '',
    industries_code_5: '',
  },
);

/** Industry Step without loading with Metadata  */
export const IndustryStepViewedWithoutLoadingEvent = createEcsEvent(
  {
    ...LOADING_EVENT_PROPERTIES,
    object_detail: 'industry',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },

  {
    entry_point: '',
    is_able_to_process: '',
    is_timeout: '',
    industries_generated: '',
    time_to_process: '',
    is_url_provided: '',
    industries_code_1: '',
    industries_code_2: '',
    industries_code_3: '',
    industries_code_4: '',
    industries_code_5: '',
  },
);
