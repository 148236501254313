import { createEcsEvent } from '../../configuration/createEvent';

const EMAIL_CHECKLIST_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',
  object: 'campaign',
  object_detail: 'campaign_subject',
  action: 'engaged',
};

const SUBJECT_LINE_SAVE_PROPERTIES = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  ui_access_point: 'main_content_pane',
  ui_object: 'button',
  ui_object_detail: 'save',
  checklist_step_state: 'complete',
};

const SUBJECT_LINE_PROPERTIES = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  ui_access_point: 'main_content_pane',
  ui_object_detail: 'subject_line',
  ui_object: 'text_field',
};

const PREVIEW_TEXT_PROPERTIES = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  ui_access_point: 'main_content_pane',
  ui_object_detail: 'preview_text',
  ui_object: 'text_field',
};

const UPDATE_SEND_TIME_PROPERTIES = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  ui_access_point: 'main_content_pane',
  object_detail: 'campaign_send_time',
  ui_object: 'button',
  ui_action: 'clicked',
};

const COMPLETE_SEND_TIME_PROPERTIES = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  object_detail: 'campaign_send_time',
  ui_access_point: 'main_content_pane',
  ui_object: 'button',
  ui_action: 'clicked',
  editor_type: '',
};

const UPDATE_MULTIVARIATE_CC = {
  ...EMAIL_CHECKLIST_PROPERTIES,
  scope_area: 'multivariate_testing',
  screen: 'campaigns/wizard/variateconfirm',
  object: 'checkout',
  object_detail: 'upgrade_trigger_multivariate_testing',
  action: 'started',
  ui_access_point: 'main_content_pane',
  ui_object: 'link',
  ui_action: 'clicked',
};

export const subjectLineFocusOutEmailChecklistEvent = createEcsEvent(
  {
    ...SUBJECT_LINE_PROPERTIES,
    ui_action: 'edited',
  },
  {
    campaign_id: '',
    editor_type: '',
    has_replicated_email: false,
    best_practices_met: [],
    checklist_step_state: '',
    character_limit_exceeded: false,
    sl_content_present: false,
    checklist_state_content: false,
  },
);

export const cancelSubjectLineEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    checklist_state_content: false,
  },
);

export const previewTextFocusOutEmailChecklistEvent = createEcsEvent(
  {
    ...PREVIEW_TEXT_PROPERTIES,
    ui_action: 'edited',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
    preview_content_present: false,
    has_replicated_email: false,
    character_limit_exceeded: false,
    checklist_state_content: false,
  },
);

export const emojiAddedToSubjectLineChecklistEvent = createEcsEvent(
  {
    ...SUBJECT_LINE_PROPERTIES,
    ui_object_detail: 'subject_line',
    ui_action: 'select_emoji',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
    has_replicated_email: false,
  },
);

export const emojiAddedToPreviewLineChecklistEvent = createEcsEvent(
  {
    ...PREVIEW_TEXT_PROPERTIES,
    ui_object_detail: 'preview_text',
    ui_action: 'select_emoji',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
    has_replicated_email: false,
  },
);

export const TrackSubjectLineSaveEvents = createEcsEvent(
  {
    ...SUBJECT_LINE_SAVE_PROPERTIES,
    ui_object_detail: 'save',
    ui_action: 'clicked',
  },
  {
    ai_email_type: '',
    campaign_id: '',
    editor_type: '',
    preview_text_full: false,
    has_replicated_email: false,
    character_limit_exceeded: false,
    best_practice_alert: false,
    checklist_state_content: false,
  },
);

export const TrackSubjectLinePasteEvent = createEcsEvent(
  {
    ...SUBJECT_LINE_PROPERTIES,
    ui_action: 'pasted',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
    has_replicated_email: false,
  },
);

export const TrackPreviewTextPasteEvent = createEcsEvent(
  {
    ...PREVIEW_TEXT_PROPERTIES,
    ui_action: 'pasted',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
    has_replicated_email: false,
  },
);

export const beginUpdateSubjectEvent = createEcsEvent(
  {
    ...SUBJECT_LINE_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'edit_subject',
    ui_action: 'clicked',
  },
  {
    ai_button_exists: 'No',
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    ai_email_type: '',
    has_replicated_email: false,
    checklist_state_content: false,
  },
);
export const TrackPerfomanceModalOpenEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'recent_subject_lines',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
  },
);
export const TrackPerfomanceModalCloseEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    ui_object: 'icon',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: '',
    editor_type: '',
    checklist_step_state: '',
    performance_was_useful: '',
  },
);

export const TrackBeginNotifySendersEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'bulk_campaigns',
    screen: 'campaigns/edit',
    object: 'campaign',
    object_detail: 'notify_senders',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'im_finished',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

export const TrackCompleteNotifySendersEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'bulk_campaigns',
    screen: 'campaigns/edit',
    object: 'campaign',
    object_detail: 'notify_senders',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_notification',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

export const TrackViewPreviewEvent = createEcsEvent(
  {
    purpose: 'prod',
    scope_area: 'bulk_campaigns',
    screen: 'campaigns/edit',
    object: 'campaign',
    object_detail: 'preview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'preview',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

/* E Seapoe */
export const TrackBeginDesignEmailEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    object_detail: 'campaign_content',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: '',
    ai_email_type: '',
    ui_object_detail: '',
  },
);

export const TrackOpenRecipientsListEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    object: 'audience',
    object_detail: 'campaign_recipients_list',
    action: 'viewed',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
  },
);

export const TrackCancelRecipientsUpdateEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    object_detail: 'campaign_recipients',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
  },
);

export const TrackOpenImportContactsEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    object_detail: 'campaign_recipients',
    ui_object: 'button',
    ui_object_detail: 'import_contacts',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
  },
);

export const TrackCancelNotifySendersEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    object_detail: 'notify_senders',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ui_object_detail: '',
    campaign_id: '',
    editor_type: '',
  },
);

export const TrackCancelScheduleCampaignEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    object_detail: 'schedule_campaign',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ui_object_detail: 'cancel',
    campaign_id: '',
    editor_type: '',
    segment_id: '',
  },
);

//This event fires when a user clicks the "Cancel" button within the "Send Time" campaign checklist item.
export const cancelUpdateSendTimeEvent = createEcsEvent(
  {
    ...UPDATE_SEND_TIME_PROPERTIES,
    ui_object_detail: 'cancel',
  },
  {
    campaign_id: '',
    editor_type: '',
    segment_id: '',
  },
);

export const completeUpdateSendTimeEvent = createEcsEvent(
  {
    ...COMPLETE_SEND_TIME_PROPERTIES,
    ui_object_detail: 'save',
  },
  {
    campaign_id: '',
    editor_type: '',
    segment_id: '',
    delivery_time_option: '',
    checklist_step_state: '',
  },
);

export const SaveCampaignEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    screen: 'campaigns/edit',
    object_detail: 'schedule_campaign',
    ui_object: 'button',
    ui_object_detail: 'finish_later',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

export const BeginScheduleCampaignEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    screen: 'campaigns/edit',
    object_detail: 'schedule_campaign',
    ui_object: 'button',
    ui_object_detail: 'schedule',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

export const CompleteScheduleCampaignEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    screen: 'campaigns/edit',
    object_detail: 'schedule_campaign',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'schedule_campaign',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    campaign_id: '',
    editor_type: '',
  },
);

export const CompleteUpdateRecipientsEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    screen: 'campaigns/edit',
    object_detail: 'campaign_recipients',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    segment_send_to: null,
    segment_do_not_send_to: null,
  },
);

export const BeginUpdateRecipientsEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    screen: 'campaigns/edit',
    object_detail: 'campaign_recipients',
    ui_object: 'button',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: '',
    ai_email_type: '',
  },
);

export const startMultivariateCCEvent = createEcsEvent(
  {
    ...UPDATE_MULTIVARIATE_CC,
  },
  {
    campaign_id: '',
  },
);

export const ViewEmailChecklistEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,
    screen: 'campaigns/edit',
    object_detail: 'campaign_content',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    action: 'viewed',
  },
  {
    campaign_id: '',
    editor_type: null,
    has_replicated_email: false,
    has_ai_content_generator_contents: false,
    has_ai_inline_editor_contents: false,
    checklist_state_to: false,
    checklist_state_from: false,
    checklist_state_subject: false,
    checklist_state_sendtime: false,
    checklist_state_content: false,
  },
);
