import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events for UCP marketing
const DEFAULT_EVENT_PROPERTIES_UCP_MARKETING = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  object: 'audience',
  event_origin: 'react',
};

export const ViewSummaryFromContactProfile = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'activity_feed',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_summary',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    contact_id: '',
    conversation_id: '',
  },
);

export const DownloadTranscriptFromContactProfileActivityFeed = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'activity_feed',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_transcript',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    screen: '',
    contact_id: '',
    conversation_id: '',
  },
);

export const DownloadTranscriptFromContactProfileModal = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'activity_feed',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_transcript',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    screen: '',
    contact_id: '',
    conversation_id: '',
  },
);

export const contactProfileMailIconClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'customer_relationship_management',
    scope_area: 'contacts',
    object: 'audience',
    object_detail: 'compose_message',
    action: 'started',
    ui_object: 'icon',
    ui_object_detail: 'campaign_email',
    ui_action: 'clicked',
    ui_access_point: 'contact_profile_card',
  },
  {
    screen: '',
  },
);

export const UCPLearnMoreAboutImportanceOfPermission = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    marketing_channel_fields: '',
  },
);

export const UCPLearnMoreAboutSMSMarketingConsent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    object_detail: 'add_subscriber',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_about_sms_marketing_consent',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    marketing_channel_fields: '',
  },
);

export const ContactProfileTabClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    action: 'selected',
    screen: 'audience/contact-profile',
    object_detail: 'contact_profile_tabs',
    ui_object: 'tab',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'tab',
  },
  {
    has_shopsync: '',
    marketing_channel_fields: '',
    tab_selected: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileListClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'engaged',
    object_detail: 'contact_profile',
    ui_object: 'dropdown',
    ui_object_detail: 'actions',
    ui_action: 'clicked',
    ui_access_point: 'action_list',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileCreateItemSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'selected',
    object_detail: 'contact_profile',
    ui_object: 'dropdown_item',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'action_list',
  },
  {
    marketing_channel_fields: '',
    action_selected: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    object_detail: 'contact_profile',
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: '',
    ui_action: 'viewed',
    ui_access_point: '',
  },
  {
    marketing_channel_fields: '',
    has_shopsync: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileTab = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: '',
    ui_action: 'viewed',
    ui_access_point: '',
  },
  {
    marketing_channel_fields: '',
    has_shopsync: '',
  },
);

export const ContactProfileClickEditContactGroups = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'started',
    object_detail: 'contact_profile',
    ui_object: 'link_text',
    ui_object_detail: 'edit_groups',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileSaveEditContactGroups = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'submitted',
    object_detail: 'contact_profile',
    ui_object: 'button',
    ui_object_detail: 'save_groups',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileSaveEditGDPRInfo = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'submitted',
    object_detail: 'contact_profile',
    ui_object: 'button',
    ui_object_detail: 'save_gdpr',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileEditGDPRInfo = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'started',
    object_detail: 'contact_profile',
    ui_object: 'link_text',
    ui_object_detail: 'edit_gdpr',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileViewGDPRForm = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'engaged',
    object_detail: 'contact_profile',
    ui_object: 'link_text',
    ui_object_detail: 'view_gdpr_form',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileConnectYourStoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'engaged',
    object_detail: 'contact_profile',
    ui_object: 'link_text',
    ui_object_detail: 'connect_your_store',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileInternalNoteClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'engaged',
    object_detail: 'contact_profile',
    ui_object: 'text_field',
    ui_object_detail: 'write_an_internal_note_here',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileInternalNoteSubmitted = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'submitted',
    object_detail: 'contact_profile',
    ui_object: 'button',
    ui_object_detail: 'add_note',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileTagButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'engaged',
    object_detail: 'contact_profile',
    ui_object: 'button',
    ui_object_detail: '+',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileEditProfileClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'started',
    object_detail: 'contact_profile',
    ui_object: 'link_text',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ContactProfileEditProfileSave = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'lists/members/view',
    action: 'submitted',
    object_detail: 'contact_profile',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
    has_checked_sms_permission_box: '',
    has_checked_email_permission_box: '',
  },
);

export const OpenTagBottomSheetClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'selected',
    object_detail: 'tag',
    ui_object: 'tag',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    action_performed: '',
    tag_name: '',
    tag_id: '',
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const SelectClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'engaged',
    object_detail: 'contacts_table_bottom_sheet',
    ui_object: 'dropdown',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const TagSelectedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'engaged',
    object_detail: 'contacts_table_bottom_sheet',
    ui_object: 'dropdown_item',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {
    tag_name: '',
    tag_id: '',
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const ViewAllClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'selected',
    object_detail: 'contacts_table_bottom_sheet',
    ui_object: 'button',
    ui_object_detail: 'view_all',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {
    num_tags_selected: '',
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const CloseBottomSheetEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'dismissed',
    object_detail: 'contacts_table_bottom_sheet',
    ui_object: 'text_button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {
    num_tags_selected: '',
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const PredictiveAnalyticsDropDownEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'engaged',
    object_detail: 'predictive_analytics',
    ui_object: 'dropdown_item',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    field: '',
    value: '',
    marketing_channel_fields: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const PredictiveAnalyticsLoadedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'viewed',
    object_detail: 'predictive_analytics',
    ui_object: 'widget',
    ui_object_detail: 'predictive_analytics',
    ui_action: 'loaded',
    ui_access_point: 'right_column',
  },
  {
    contact_customer_lifetime_value: '',
    product_recommendation_type: '',
    marketing_channel_fields: '',
  },
);

export const NextContactNavigationArrowsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'selected',
    object_detail: 'contact_profile',
    ui_object: 'right_arrow',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'header_right',
  },
  {
    in_segment: '',
    marketing_channel_fields: '',
  },
);

export const PreviousContactNavigationArrowsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'selected',
    object_detail: 'contact_profile',
    ui_object: 'left_arrow',
    ui_object_detail: 'previous',
    ui_action: 'clicked',
    ui_access_point: 'header_right',
  },
  {
    in_segment: '',
    marketing_channel_fields: '',
  },
);

export const customerLifetimeValueCtaClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_UCP_MARKETING,
    screen: 'audience/contact-profile',
    action: 'started',
    object_detail: 'contact_profile',
    ui_object: 'button',
    ui_object_detail: 'see_customers_like_this',
    ui_action: 'clicked',
    ui_access_point: 'right_column',
  },
  {
    field: '',
    value: '',
    op: '',
    marketing_channel_fields: '',
  },
);
