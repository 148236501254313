import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'segments_index',
  screen: 'audience/segments',
  object: 'segment',
};

export const cubCreateSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_segment',
    ui_action: 'clicked',
    mode: 'create',
  },
  {
    segmentation_method: '',
    ui_access_point: '',
    pbs_used: '',
    funnel_id: '',
    audience_is_sms_enabled: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const cubCreateSegmentFromPBSCardEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'started',
    ui_object: 'card',
    ui_object_detail: 'pbs_card_header',
    ui_action: 'clicked',
    segmentation_method: 'regular',
    mode: 'create',
  },
  {
    ui_access_point: '',
    pbs_used: '',
    funnel_id: '',
  },
);

export const cubCreateSegmentFromScratchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'build_from_scratch',
    ui_action: 'clicked',
    ui_access_point: 'bottom_sheet',
    segmentation_method: 'regular',
    mode: 'create',
  },
  {
    funnel_id: '',
  },
);

export const cubEditSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'list',
    mode: 'edit',
  },
  {
    segmentation_method: '',
    pbs_used: '',
    funnel_id: '',
    audience_is_sms_enabled: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const cubViewSegmentsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'viewed',
    ui_object: 'page',
    ui_object_detail: 'segments',
    ui_action: 'viewed',
    ui_access_point: 'list',
  },
  {
    audience_is_sms_enabled: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const cubViewAllPBSEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'viewed',
    ui_object: 'banner',
    ui_object_detail: 'view_all_pbs',
    ui_action: 'clicked',
    ui_access_point: 'top',
    mode: 'create',
    segmentation_method: 'regular',
  },
  {
    funnel_id: '',
  },
);

// Review events need to specify the scope_area and screen, so they cannot use DEFAULT_EVENT_PROPERTIES
// As a result, we must explicitly define purpose, initiative_name, and object
export const cubReviewClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    object: 'segment',
    object_detail: 'create_segment',
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'preview_segment',
    ui_action: 'clicked',
    ui_access_point: 'bottom_sheet',
  },
  {
    scope_area: '',
    screen: '',
    mode: '',
    segmentation_method: '',
    pbs_used: '',
    funnel_id: '',
  },
);

// Condition change events need to specify the scope_area and screen, so they cannot use DEFAULT_EVENT_PROPERTIES
// As a result, we must explicitly define purpose, initiative_name, and object
export const cubChangeConditionEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    object: 'segment',
    object_detail: 'create_segment',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'segment_conditions',
    ui_action: 'clicked',
    ui_access_point: 'bottom_sheet',
  },
  {
    scope_area: '',
    screen: '',
    mode: '',
    segmentation_method: '',
    pricing_page_click: '',
    pbs_used: '',
    condition_field: '',
    funnel_id: '',
  },
);

// Max conditions events need to specify the scope_area and screen, so they cannot use DEFAULT_EVENT_PROPERTIES
// As a result, we must explicitly define purpose, initiative_name, and object
export const cubMaxConditionsReachedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    object: 'segment',
    object_detail: 'create_segment',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'segment_conditions',
    ui_action: 'clicked',
    ui_access_point: 'bottom_sheet',
  },
  {
    scope_area: '',
    screen: '',
    mode: '',
    segmentation_method: '',
    pbs_used: '',
    condition_field: '',
    max_conditions: '',
    funnel_id: '',
  },
);

export const cubViewSegmentMembersEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'segment_members',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'segment_name',
    ui_action: 'clicked',
    ui_access_point: 'segment_slat',
  },
  {
    segment_id: '',
  },
);

export const replicateSegmentEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'create_segment',
    action: 'engaged',
    ui_object: 'drop_down_item',
    ui_object_detail: 'replicate_segment',
    ui_action: 'clicked',
    ui_access_point: 'list',
  },
  {
    segment_id: '',
    segmentation_method: '',
  },
);
