import { createEcsEvent } from '../../configuration/createEvent';

/**
 * This event fires when an user Lands on Two-factor authentication SMS "Send code" interface
 */
export const SmsSendCodeInterfaceLandedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the SmsSendCodeInterfaceLanded
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'two_factor_authentication',
    ui_action: 'loaded',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

/**
 * This event fires when an user clicks on "Send code"
 */
export const SendCodeClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the SendCodeClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_code',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

/**
 * This event fires when an user clicks on "Need help?"
 */
export const NeedHelpClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the NeedHelpClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'need_help',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '' },
);

/**
 * This event fires when a user lands on Two-factor authentication SMS verification or Authenticator passcode entry interface
OR user lands on set up 2-factor authentication at login interface
 */
export const AuthenticationInterfaceScreenLandedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the AuthenticationInterfaceScreenLanded
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'tfa_after_code',
    ui_action: 'loaded',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '', tfa_activity: '' },
);

/**
 * This event fires when an user enters content into "Verification code" field
 */
export const VerificationCodeSubmittedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the VerificationCodeSubmitted
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'engaged',
    ui_object: 'text_field',
    ui_object_detail: 'verification_code',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '' },
);

/**
 * This event fires when a user enters code into the "Authentication code" field
 */
export const AuthenticationCodeSubmittedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the AuthenticationCodeSubmitted
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'engaged',
    ui_object: 'text_field',
    ui_object_detail: 'auth_code',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '' },
);

/**
 * This event fires when an user checks "Skip this for two weeks"
 */
export const SkipThisCheckboxCheckedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the SkipThisForTwoWeeksCheckboxChecked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'skip_tfa',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '', is_tfa_skipped: '' },
);

/**
 * This event fires when an user clicks on "Click here" to manage your cookie preferences
 */
export const ClickHereClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ClickHereClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_authentication',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'click_here',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

/**
 * This event fires when an user clicks "Log in"
 */
export const LogInClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the LogInClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_login',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'log_in',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '', is_tfa_skipped: '' },
);

/**
 * This event fires when a user clicks on "Enable authenticator app" button
 */
export const EnableAuthenticatorAppClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the EnableAuthenticatorAppClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_verification',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'enable',
    ui_action: 'clicked',
    ui_access_point: 'sms_verification_card',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '' },
);

/**
 * This event fires when an user clicks on "Enable SMS" button
 */
export const EnableSmsClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the EnableSmsClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_verification',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'enable',
    ui_action: 'clicked',
    ui_access_point: 'security_questions_card',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { tfa_type: '' },
);

/**
 * This event fires when a user clicks on "Learn more" link in the toast
 */
export const LearnMoreClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the LearnMoreClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_verification',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_about_verification_or_get_help',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

/**
 * This event fires when a user clicks on "I'll do this later" link is cliked
 */
export const DoThisLaterClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the i'll do this later is clicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/tfa',
    object_detail: 'account_verification',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'ill_do_this_later',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
