import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  screen: '/campaigns',
  action: 'engaged',
  ui_action: 'clicked',
};

const DEFAULT_EVENT_PROPERTIES_BULK_EMAIL = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns',
  object: 'campaign',
  action: 'engaged',
};

export const AllCampaignsCreateFolderEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_list',
    ui_object: 'button',
    ui_object_detail: 'create_folder',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsListTabEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_list',
    ui_object: 'tab',
    ui_object_detail: 'list_view',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const AllCampaignsCalendarTabEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'campaign_manager',
    object: 'automations',
    object_detail: 'campaign_list',
    ui_object: 'tab',
    ui_object_detail: 'calendar_view',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const AllCampaignsSortByDropdownEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_list',
    ui_object: 'dropdown',
    ui_object_detail: 'sort_by',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const AllCampaignsSortByCreationDateEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_list',
    ui_object: 'dropdown',
    ui_object_detail: 'creation_date',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const AllCampaignsSortByLastUpdatedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_list',
    ui_object: 'dropdown',
    ui_object_detail: 'last_updated',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const AllCampaignsViewByEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'emails',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewBySMSEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'sms',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByAutomationsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'automations',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByLandingPagesEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'landing_pages',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByAdsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'ads',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByPostcardsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'postcards',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewBySocialPostsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'social_posts',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewBySurveysEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'individual_touchpoint',
    ui_object: 'button',
    ui_object_detail: 'surveys',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByAllStatusEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_status',
    ui_object: 'button',
    ui_object_detail: 'all',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByOngoingStatusEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_status',
    ui_object: 'button',
    ui_object_detail: 'ongoing',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByDraftStatusEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_status',
    ui_object: 'button',
    ui_object_detail: 'draft',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsViewByCompletedStatusEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_status',
    ui_object: 'button',
    ui_object_detail: 'completed',
    ui_access_point: 'all_campaigns_left_nav',
  },
  {},
);

export const AllCampaignsBulkActionCheckBoxEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'campaign_list_actions',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'checkmark',
    ui_access_point: 'list_view_slat',
  },
  {
    touchpoint_type: '',
  },
);

export const AllCampaignsSocialShareEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'individual_toucpoint',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'social_share',
    ui_access_point: 'list_view_slat',
  },
  {
    touchpoint_type: '',
  },
);

export const AllCampaignsViewEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'bulk_checklist',
    ui_object: 'dropdown',
    ui_object_detail: 'view_email',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const AllCampaignsViewSMSEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'bulk_checklist',
    ui_object: 'dropdown',
    ui_object_detail: 'view_sms',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const AllCampaignsCreateSocialPostEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    action: 'started',
    object_detail: 'add_touchpoint',
    ui_object: 'card',
    ui_object_detail: 'social_post',
    ui_access_point: 'modal',
  },
  {},
);

export const AllCampaignsCreateSMSEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'add_touchpoint',
    ui_object: 'card',
    ui_object_detail: 'sms',
    ui_access_point: 'modal',
  },
  {
    campaign_type: '',
    sms_registration_status: '',
  },
);

export const AllCampaignsReplicateEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    action: 'created',
    object_detail: 'replicate_email',
    ui_object: 'button',
    ui_object_detail: 'replicate',
    ui_access_point: 'modal',
  },
  {
    plan_type: '',
  },
);

export const AllCampaignsApplyForSMSEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'add_touchpoint',
    ui_object: 'link',
    ui_object_detail: 'apply_now',
    ui_access_point: 'modal',
  },
  {
    campaign_type: '',
    sms_registration_status: '',
  },
);

export const AllCampaignsToggleSDOEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'toggle_sdo',
    action: 'navigated',
    ui_object: 'toggle',
    ui_object_detail: 'on',
    ui_access_point: 'calendar_header',
  },
  { send_day_optimization_toggle: false },
);

export const AllCampaignsEditCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'edit_campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_access_point: 'touchpoint_details_modal',
  },
  { touchpoint_type: '' },
);

export const AllCampaignsDeleteCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'delete_campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_access_point: 'touchpoint_details_modal',
  },
  { touchpoint_type: '' },
);

export const AllCampaignsReplicateCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'bulk_campaign',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'replicate',
    ui_access_point: 'touchpoint_details_modal',
  },
  { touchpoint_type: '' },
);

export const AllCampaignsChangeCalendarViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'change_view',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const AllCampaignsChangeToWeekViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'week',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const AllCampaignsChangeToDayViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'day',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const AllCampaignsChangeToMonthViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'month',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const AllCampaignsChangeToTodayViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'today',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const AllCampaignsClickCalendarCellEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: '+',
    ui_access_point: 'calendar',
  },
  {},
);

export const AllCampaignsClickTouchpointEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'full_width_calendar',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'touchpoint',
    ui_access_point: 'calendar',
  },
  {
    touchpoint_type: '',
  },
);

export const AllCampaignsEmailCelebrationReplicateEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'bulk_campaign',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'replicate_email',
    ui_access_point: 'center',
  },
  {
    editor_type: '',
    replicated_from_campaign_id: 0,
  },
);

export const AllCampaignsEmailCelebrationViewEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'preview_email',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_email_in_browser',
    ui_access_point: 'center',
  },
  {
    campaign_id: 0,
  },
);

export const pauseCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'bulk_campaigns',
    object: 'campaign',
    object_detail: 'bulk_campaign',
    ui_object: 'button',
    ui_object_detail: 'pause_and_edit',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: 0,
    editor_type: '',
  },
);

export const beginRenameEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'bulk_campaigns',
    object: 'campaign',
    object_detail: 'rename_campaign',
    action: 'engaged',
    ui_object: 'drop_down_item',
    ui_object_detail: 'rename',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: 0,
    editor_type: '',
  },
);

export const completeOrCancelRenameEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'bulk_campaigns',
    object: 'campaign',
    object_detail: 'rename_campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_access_point: 'modal',
  },
  {
    ui_object_detail: '',
    campaign_id: 0,
    editor_type: '',
  },
);

export const TrackEditCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES_BULK_EMAIL,
    object_detail: 'edit_campaign',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    editor_type: '',
    is_gentab_eligible: '',
  },
);

export const AllCampaignsEmailCelebrationBackToAllCampaignsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'celebration_screen',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_to_all_campaigns',
    ui_access_point: 'center',
  },
  {},
);

export const AllCampaignsEmailCelebrationExploreSmsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'celebration_screen',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'explore_sms',
    ui_access_point: 'center',
  },
  {},
);

export const ClickListViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'tab',
    ui_object_detail: 'list',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const ClickCalendarViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'tab',
    ui_object_detail: 'calendar',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const ClickMarketingCampaignsViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_marketing_campaigns_view',
    ui_object: 'tab',
    ui_object_detail: 'marketing_campaigns',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const ClickCreateCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'create',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const ClickViewAnalyticsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'view_analytics',
    ui_access_point: 'all_campaigns_header',
  },
  {},
);

export const SearchForCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'lifecycle_marketing_wayfinding',
    scope_area: 'page_navigation',
    object: 'navigation',
    action: 'selected',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'field',
    ui_object_detail: 'all_campaigns_list_view_search',
    ui_access_point: 'search_bar',
  },
  {},
);

export const ClearSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'clear',
    ui_access_point: 'list_view_header',
  },
  {},
);

export const FilterByTypeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown',
    ui_object_detail: 'type',
    ui_access_point: 'list_view_header',
  },
  {
    touchpoint_type: '',
  },
);

export const FilterByAudienceEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown',
    ui_object_detail: 'audience',
    ui_access_point: 'list_view_header',
  },
  {
    audience_selected: '',
  },
);

export const FilterByStatusEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown',
    ui_object_detail: 'status',
    ui_access_point: 'list_view_header',
  },
  {
    campaign_status: '',
  },
);

export const FilterByFolderEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown',
    ui_object_detail: 'folder',
    ui_access_point: 'list_view_header',
  },
  {
    folder_name_selected: '',
  },
);

export const SortByEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown',
    ui_object_detail: 'sort',
    ui_access_point: 'list_view_header',
  },
  {
    sort_by: '',
  },
);

export const FolderFilterSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_action: 'typed',
    ui_object: 'field',
    ui_object_detail: 'folder_name_search',
    ui_access_point: 'folder_dropdown_search_bar',
  },
  {},
);

export const CreateFolderFromModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'create',
    ui_access_point: 'create_folder_modal',
  },
  {},
);

export const RenameFolderFromFilterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_access_point: 'rename_folder_modal',
  },
  {},
);

export const DeleteFolderFromFilterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_access_point: 'delete_folder_modal',
  },
  {},
);

export const BulkDeleteEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_access_point: 'bulk_delete_modal',
  },
  {},
);

export const BulkMoveEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'move',
    ui_access_point: 'move_to_folder_modal',
  },
  {},
);

// Awaiting approval from ECS team
// export const BulkRemoveEvent = createEcsEvent(
//   {
//     ...DEFAULT_EVENT_PROPERTIES,
//     scope_area: 'all_campaigns',
//     object: 'automations',
//     object_detail: 'all_campaigns_list_view',
//     ui_object: 'button',
//     ui_object_detail: 'emove_from_folders',
//     ui_access_point: 'list_view_header',
//   },
//   {},
// );

export const AllCampaignsPageViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    action: 'viewed',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
  },
  {},
);

export const EmptyListCreateTouchpointEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_touchpoint',
    ui_access_point: 'center',
  },
  {},
);

export const EmptyListClearSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'clear_search',
    ui_access_point: 'center',
  },
  {},
);

export const EditCampaignFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_access_point: 'list_view_slat',
  },
  {
    touchpoint_type: '',
  },
);

export const ActionListDropdownClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown',
    ui_object_detail: 'down_arrow',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const ViewReportFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'reporting',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'view_report',
    ui_access_point: 'list_view_slat',
  },
  {
    touchpoint_type: '',
    campaign_id: null,
    journey_id: null,
    sms_campaign_id: '',
  },
);

export const ViewEmailClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'view_email',
    ui_access_point: 'list_view_slat',
  },
  {
    campaign_id: null,
  },
);

export const ViewJourneyClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'view_journey',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const DeleteCampaignFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_access_point: 'delete_confirmation_modal',
  },
  {
    touchpoint_type: '',
    campaign_id: null,
    sms_campaign_id: '',
  },
);

export const DeleteJourneyFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_access_point: 'delete_confirmation_modal',
  },
  {
    touchpoint_type: 'Customer journeys',
    journey_id: null,
  },
);

export const ReplicateExceptEmailsAndJourneysFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'all_campaigns_list_view',
    action: 'created',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'replicate',
    ui_access_point: 'list_view_slat',
  },
  {
    touchpoint_type: '',
    campaign_id: null,
    sms_campaign_id: null,
  },
);

export const SocialShareFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'social_share',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const NewConversationsFromSlatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'audience',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'new_conversations',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const AnalyticsHoverEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'reporting',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'slat',
    ui_object_detail: 'touchpoint_metrics',
    ui_action: 'hovered',
    ui_access_point: 'list_view_slat',
  },
  {},
);

export const PaginationClickPreviousEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'icon',
    ui_object_detail: 'menu_left',
    ui_access_point: 'list_view',
  },
  {},
);

export const PaginationClickNextEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'icon',
    ui_object_detail: 'menu_right',
    ui_access_point: 'list_view',
  },
  {},
);

export const PaginationClickFirstEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'icon',
    ui_object_detail: 'menu_first',
    ui_access_point: 'list_view',
  },
  {},
);

export const PaginationClickLastEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'icon',
    ui_object_detail: 'menu_last',
    ui_access_point: 'list_view',
  },
  {},
);

export const PaginationInputEnterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'input_field',
    ui_object_detail: 'page_number',
    ui_action: 'typed',
    ui_access_point: 'list_view',
  },
  {},
);

export const ReplicateEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    action: 'created',
    object_detail: 'bulk_campaign',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'replicate',
    ui_action: 'clicked',
    ui_access_point: 'list_view_slat',
  },
  {
    campaign_id: '',
  },
);

export const ReplicateJourneysEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    action: 'created',
    object_detail: 'replicate_a_journey',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'replicate_journey_modal',
  },
  {
    journey_id: '',
  },
);

export const ReplicateJourneysAutomationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    action: 'engaged',
    object_detail: 'replicate_journey',
    ui_object: ' ',
    ui_object_detail: ' ',
    ui_action: ' ',
    ui_access_point: ' ',
  },
  {
    replicated_from_journey_id: '',
    replicated_new_journey_id: '',
    touchpoint_type: 'Customer journeys',
  },
);

export const PauseJourneysEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    action: 'paused',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'pause',
    ui_action: 'clicked',
    ui_access_point: 'pause_journey_modal',
  },
  {
    journey_id: null,
  },
);

export const ClickArchiveCodeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'get_archive_code',
    ui_action: 'clicked',
    ui_access_point: 'list_view_header',
  },
  {},
);

export const SMSCampaignReplicatedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'campaign',
    object_detail: 'replicate_campaign',
    action: 'engaged',
    // UI properties not needed for this event
    ui_object: ' ',
    ui_object_detail: ' ',
    ui_action: ' ',
    ui_access_point: ' ',
  },
  {
    touchpoint_type: 'SMS',
    replicated_from_sms_campaign_id: '',
    replicated_new_sms_campaign_id: '',
  },
);

export const DateFilterAppliedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'all_campaigns_list_view',
    ui_object: 'button',
    ui_object_detail: 'apply',
    ui_action: 'clicked',
    ui_access_point: 'date_dropdown_list_header',
  },
  {
    date_type_selected: '',
    quick_select_date_selected: null,
  },
);

export const AddHolidayEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'calendar_holiday_selection',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'edit_calendar_holidays_modal',
  },
  {},
);

export const HolidaysFilterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    scope_area: 'all_campaigns',
    object: 'automations',
    object_detail: 'calendar_holiday_selection',
    ui_object: 'dropdown_item',
    ui_object_detail: 'calendar_holiday_selection',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);
