import { createEcsEvent } from '../../../configuration/createEvent';

const sharedProps = {
  purpose: 'prod',
  initiative_name: 'marketing_crm_analytics',
  scope_area: 'campaign_analytics',
  object: 'reporting',
  object_detail: 'email_overview_report',
};

const pageVisitProps = {
  object: 'reporting',
  action: 'viewed',
  purpose: 'prod',
  initiative_name: 'marketing_crm_analytics',
  scope_area: 'campaign_analytics',
};

export const IndividualReportOverviewPageView = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'viewed',
  },
  {},
);

export const IndividualReportOverviewSwitchReport = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'switch_report',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const IndividualReportOverviewViewEmail = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_email',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const IndividualReportOverviewExportCsv = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'combo_button',
    ui_object_detail: 'export_csv',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const IndividualReportOverviewExportCsvShareReport = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'drop_down_menu',
    ui_object_detail: 'share_report',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const IndividualReportOverviewExportCsvPrint = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'drop_down_menu',
    ui_object_detail: 'print',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const IndividualReportHeaderRecipientsLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'recipient',
    ui_action: 'clicked',
    ui_access_point: 'header_component',
  },
  {},
);

export const IndividualReportEmailPerformanceOpenedLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'opened',
    ui_action: 'clicked',
    ui_access_point: 'email_performance_component',
  },
  {},
);

export const IndividualReportEmailPerformanceClickedLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'clicked',
    ui_action: 'clicked',
    ui_access_point: 'email_performance_component',
  },
  {},
);

export const IndividualReportEmailPerformanceBouncesLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'bounces',
    ui_action: 'clicked',
    ui_access_point: 'email_performance_component',
  },
  {},
);

export const IndividualReportEmailPerformanceUnsubscribedLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'unsubscribed',
    ui_action: 'clicked',
    ui_access_point: 'email_performance_component',
  },
  {},
);

export const IndividualReportPerformanceOverTimeMetricDropDown = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'metric_drop_down',
    ui_action: 'clicked',
    ui_access_point: 'performance_over_time_component',
  },
  {
    email_report_metric_drop_down: '',
  },
);

export const IndividualReportPerformanceOverTimeDeliveriesLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'deliveries',
    ui_action: 'clicked',
    ui_access_point: 'performance_over_time_component',
  },
  {
    email_report_metric_drop_down: '',
  },
);

export const IndividualReportPerformanceOverTimeOrdersLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'orders',
    ui_action: 'clicked',
    ui_access_point: 'performance_over_time_component',
  },
  {
    email_report_metric_drop_down: '',
  },
);

export const IndividualReportPerformanceOverTimeOrderRevenueLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'order_revenue',
    ui_action: 'clicked',
    ui_access_point: 'performance_over_time_component',
  },
  {
    email_report_metric_drop_down: '',
  },
);

export const IndividualReportPerformanceOverTimeTotalRevenueLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'total_revenue',
    ui_action: 'clicked',
    ui_access_point: 'performance_over_time_component',
  },
  {
    email_report_metric_drop_down: '',
  },
);

export const IndividualReportClickPerformanceClickMapButton = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'click_map',
    ui_action: 'clicked',
    ui_access_point: 'click_performance_component',
  },
  {},
);

export const IndividualReportClickPerformanceTopLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'top_links',
    ui_action: 'clicked',
    ui_access_point: 'click_performance_component',
  },
  {},
);

export const IndividualReportClickPerformanceViewAllLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_all',
    ui_action: 'clicked',
    ui_access_point: 'click_performance_component',
  },
  {},
);

export const IndividualReportClickPerformanceOpenRateLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'open_rate',
    ui_action: 'clicked',
    ui_access_point: 'click_performance_component',
  },
  {},
);

export const IndividualReportClickPerformanceClickRateLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'click_rate',
    ui_action: 'clicked',
    ui_access_point: 'click_performance_component',
  },
  {},
);

export const IndividualReportEmailBenchmarkLearnMoreLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'benchmarking',
    ui_action: 'clicked',
    ui_access_point: 'email_benchmark_component',
  },
  {},
);

export const IndividualReportEmailBenchmarkSettingsLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'your_settings',
    ui_action: 'clicked',
    ui_access_point: 'email_benchmark_component',
  },
  {},
);

export const IndividualReportEmailBenchmarkRecommendedButton = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'recommended_next_action',
    ui_action: 'clicked',
    ui_access_point: 'email_benchmark_component',
  },
  {},
);

export const IndividualReportContentOptimizerViewReportButton = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_full_report',
    ui_action: 'clicked',
    ui_access_point: 'content_optimizer_component',
  },
  {},
);

export const IndividualReportContentOptimizerSuggestionsLink = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'suggestions',
    ui_action: 'clicked',
    ui_access_point: 'content_optimizer_component',
  },
  {},
);

export const IndividualReportPredictedDemGenderRecipientToggle = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'predicted_gender_recipient',
    ui_action: 'clicked',
    ui_access_point: 'predicted_dem_component',
  },
  {},
);

export const IndividualReportPredictedDemGenderOpensToggle = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'predicted_gender_opens',
    ui_action: 'clicked',
    ui_access_point: 'predicted_dem_component',
  },
  {},
);

export const IndividualReportPredictedDemGenderClicksToggle = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'predicted_gender_clicks',
    ui_action: 'clicked',
    ui_access_point: 'predicted_dem_component',
  },
  {},
);

export const IndividualReportPredictedDemAgeRecipientToggle = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'predicted_age_recipient',
    ui_action: 'clicked',
    ui_access_point: 'predicted_dem_component',
  },
  {},
);

export const IndividualReportPredictedDemAgeOpensToggle = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'predicted_age_opens',
    ui_action: 'clicked',
    ui_access_point: 'predicted_dem_component',
  },
  {},
);

export const IndividualReportPredictedDemAgeClicksToggle = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'toggle',
    ui_object_detail: 'predicted_age_clicks',
    ui_action: 'clicked',
    ui_access_point: 'predicted_dem_component',
  },
  {},
);

export const ContentOptimizerPageVisit = createEcsEvent(
  {
    ...pageVisitProps,
    screen: '/reports/content',
    object_detail: 'email_content_optimizer_report',
  },
  {},
);

export const IndividualReportPerformanceOverTimeMetricChange = createEcsEvent(
  {
    ...sharedProps,
    screen: '/analytics/report/overview',
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'metric_drop_down',
    ui_action: 'clicked',
    ui_access_point: 'performance_over_time_component',
  },
  {
    email_report_metric_drop_down: '',
  },
);
