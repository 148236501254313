import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when an user lands on payment page"
 */
export const CheckoutLandedEvent = createEcsEvent(
  /**
   * This param contains the checkout0Event the event will contain.
   * These properties can never be changed inside of the checkout0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'started',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '', payment_method: '' },
);
/**
 * "This event fires when a user clicks on card radio button to chase the payment method"
 */
export const CheckoutCardRadioButtonEvent = createEcsEvent(
  /**
   * This param contains the checkout1Event the event will contain.
   * These properties can never be changed inside of the checkout1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'card',
    ui_action: 'clicked',
    ui_access_point: 'header',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on 'paypal' radio button"
 */
export const CheckoutPaypalRadioButtonEvent = createEcsEvent(
  /**
   * This param contains the checkout2Event the event will contain.
   * These properties can never be changed inside of the checkout2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'paypal',
    ui_action: 'clicked',
    ui_access_point: 'header',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on 'connect to paypal' button"
 */
export const CheckoutConnectToPaypalEvent = createEcsEvent(
  /**
   * This param contains the checkout3Event the event will contain.
   * These properties can never be changed inside of the checkout3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_paypal',
    ui_action: 'clicked',
    ui_access_point: 'header',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on authorize mailchimp to deduct money checkbox"
 */
export const CheckoutCheckboxEvent = createEcsEvent(
  /**
   * This param contains the checkout4Event the event will contain.
   * These properties can never be changed inside of the checkout4Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'authorize',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks on Pay Now button"
 */
export const CheckoutPayNowEvent = createEcsEvent(
  /**
   * This param contains the checkout5Event the event will contain.
   * These properties can never be changed inside of the checkout5Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'pay_now',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    payment_method: '',
    cart: '',
    amount: '',
    currency: '',
    payment_exists: '',
    contact_address_exists: '',
    billing_address_exists: '',
    tax_settings_exist: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
    entry_point: '',
  },
);
/**
 * "This event fires when a user clicks on 'Pay Now' & payment does not go through. Error message shown."
 */
export const CheckoutPaymentErrorEvent = createEcsEvent(
  /**
   * This param contains the checkout6Event the event will contain.
   * These properties can never be changed inside of the checkout6Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'failed',
    ui_object: 'button',
    ui_object_detail: 'pay_now',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'checkout',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
    entry_point: '',
    payment_method: '',
  },
);
/**
 * "This event fires when the Payment Confirmation Page fails to load"
 */
export const CheckoutPaymentPageLoadErrorEvent = createEcsEvent(
  /**
   * This param contains the checkout6Event the event will contain.
   * These properties can never be changed inside of the checkout6Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_payment_initial_signup',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { error: '', qb_realm_id: '', qb_pseudonym_id: '', entry_point: '' },
);
