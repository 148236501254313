import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user clicks on a CRM plan."
 */
export const CRMPlanSelectionButtonEvent = createEcsEvent(
  /**
   * This param contains the planSelection1Event the event will contain.
   * These properties can never be changed inside of the planSelection1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'select',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    entry_point: 'crm-qb-link',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { qb_realm_id: '', crm_plan: '' },
);
/**
 * "This event fires when a user continues after seeing the paid plan is coming soon modal. "
 */
export const CRMPaidPlanSelectionNextEvent = createEcsEvent(
  /**
   * This param contains the planSelection2Event the event will contain.
   * These properties can never be changed inside of the planSelection2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'plan_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    object: 'oii_account',
    entry_point: 'crm-qb-link',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { qb_realm_id: '', notify_me: '' },
);
