import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'crm_composite_app',
  object: 'audience',
  object_detail: 'crm_terms_of_service',
};

const CONFIGURABLE_PROPERTIES = {
  screen: '',
  tos_entry_workflow: '',
};

export const ViewTermsOfService = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const AcceptedTermsOfService = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'accept_and_continue',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeclinedTermsOfService = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  { ...CONFIGURABLE_PROPERTIES },
);
