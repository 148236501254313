import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'adjust_plan',
  screen: '/account/adjust-plan',
  event_source: 'front_end:js_react',
};

export const AccountAdjustPlanEventViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'overages_adjust_plan',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const AccountKeepYourPlanClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'overages_adjust_plan',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'keep_current_plan',
    ui_action: 'clicked',
    ui_access_point: 'current_plan_card',
  },
  {},
);

export const AccountAdjustPlanClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'overages_adjust_plan',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'adjust_plan',
    ui_action: 'clicked',
    ui_access_point: 'adjusted_plan_card',
  },
  {},
);
