import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  object: 'automations',
};

export const InitialChooseStartingPointEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the InitialChooseStartingPointEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'custom_journey',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'choose_a_starting_point',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

// build from scratch created

export const BuildFromScratchCreated = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'created',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'start_building',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: 'customer-journey/create-new-journey',
  },
  {
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);

// build from scratch clicked

export const BuildFromScratchClickedExplore = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'build_from_scratch',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey/explore',
  },
  {},
);

export const BuildFromScratchClickedMyJourneys = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'build_from_scratch',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey/my-journeys',
  },
  {},
);

export const BuildFromScratchPreBuiltJourneyHomePage = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'custom_journey',
    ui_object: 'card',
    ui_object_detail: 'build_from_scratch',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: '/',
  },
  {},
);

export const SeeAllPreBuiltJourneysHomePage = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'prebuilt_journey',
    ui_object: 'button',
    ui_object_detail: 'see_all_prebuilt_journeys',
    ui_action: 'clicked',
    ui_access_point: 'center',
    screen: '/',
  },
  {},
);

export const BuildFromScratchClickedOverview = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'started',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'build_from_scratch',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey',
  },
  {},
);

// build from scratch start clicked

export const BuildFromScratchStartClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'completed',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'turn_on',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey/intent',
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    created_from_source: '',
    is_replicated_from_journey: false,
    starting_point: [],
    cjb_workflow_status: '',
    steps_taken_in_the_cjb: 0,
  },
);

export const PBJClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'completed',
    screen: 'customer-journey/builder',
    object_detail: 'prebuilt_journey',
    ui_object: 'button',
    ui_object_detail: 'turn_on',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    journey_id: 0,
    pbj_type: '',
    journey_created_from_genai: false,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    starting_point: [],
    cjb_workflow_status: '',
    steps_taken_in_the_cjb: 0,
    has_code_expiration: false,
  },
);

export const ContinueToIntentClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey/builder',
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);
export const BuildFromScratchStartClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'completed',
    object_detail: 'custom_journey',
    ui_object: 'button',
    ui_object_detail: 'turn_on',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey/builder',
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    starting_point: [],
    cjb_workflow_status: '',
    steps_taken_in_the_cjb: 0,
  },
);
export const TurnBackOnClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'continued',
    object_detail: 'journey_settings',
    ui_object: 'button',
    ui_object_detail: 'turn_back_on',
    ui_action: 'clicked',
    ui_access_point: 'header',
    screen: 'customer-journey/builder',
  },
  {
    journey_id: 0,
    pbj_type: '',
    journey_created_from_genai: false,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);

export const ClickJourneyMapConditionsPreviewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'journey_map_conditions',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'conditions',
    ui_action: 'clicked',
    ui_access_point: 'journey_map',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
    journey_map_conditions_type: '',
  },
);

export const CustomReentryTimeUpdateEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'journey_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 're-entry_time',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
    're-entry_time_settings_minutes': 0,
  },
);

export const JourneyWideExitConditionSelectEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'journey_settings',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'journey_exit_condition',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    cjb_workflow_status: '',
    pbj_type: '',
    cjb_exit_condition: '',
  },
);

export const JourneyWideExitConditionSelectStoreEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object_detail: 'journey_settings',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'store',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {
    journey_id: 0,
    journey_created_from_genai: false,
    cjb_workflow_status: '',
    pbj_type: '',
    cjb_exit_condition: '',
  },
);
