import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from './OnboardingConstants';

/**
 * "This event fires when an user arrives on Email Confirmation page"
 */
export const EmailConfirmationLandedEvent = createEcsEvent(
  /**
   * This param contains the emailConfirmation0Event the event will contain.
   * These properties can never be changed inside of the emailConfirmation0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_confirmation',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when an user clicks Gmail link"
 */
export const EmailConfirmationGmailEvent = createEcsEvent(
  /**
   * This param contains the emailConfirmation1Event the event will contain.
   * These properties can never be changed inside of the emailConfirmation1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_confirmation',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'open_gmail',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when an user clicks Outlook link"
 */
export const EmailConfirmationOutlookEvent = createEcsEvent(
  /**
   * This param contains the emailConfirmation2Event the event will contain.
   * These properties can never be changed inside of the emailConfirmation2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_confirmation',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'open_outlook',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when an user clicks Re-enter your email and try againlink"
 */
export const EmailConfirmationRetryEvent = createEcsEvent(
  /**
   * This param contains the emailConfirmation3Event the event will contain.
   * These properties can never be changed inside of the emailConfirmation3Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_confirmation',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'reenter_email_and_try_again',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const EmailConfirmationErrorEvent = createEcsEvent(
  /**
   * This param contains the emailConfirmation4Event the event will contain.
   * These properties can never be changed inside of the emailConfirmation4Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'email_confirmation',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { error: '', entry_point: '', qb_realm_id: '', qb_pseudonym_id: '' },
);
