import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

/**
 *
 * @type {{ui_action: string, initiative_name: string, ui_access_point: string, purpose: string, object_detail: string, ui_object: string, screen: string, action: string, scope_area: string, object: string}}
 */
const DEFAULT_FROM_EVENT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'campaign_from',
  action: 'engaged',

  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: 'main_content_pane',
};

/**
 * Update From Events
 * See: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=1067069431&range=113:119
 */

/** This event fires when a user clicks the "Save" button within the "From" checklist item. **/
export const CompleteUpdateFromEvent = createEcsEvent(
  { ...DEFAULT_FROM_EVENT_PROPERTIES, ui_object_detail: 'save' },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: false,
    ai_email_type: '',
  },
);

/** This event fires when a user clicks the "Add From" or "Edit From" button from the Campaign checklist page to open the "From" checklist item. **/
export const BeginUpdateFromEvent = createEcsEvent(
  { ...DEFAULT_FROM_EVENT_PROPERTIES },
  {
    ui_object_detail: '',
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: false,
    ai_email_type: '',
  },
);

/** This event fires when a user clicks the "ensure delivery" knowledge base link in the From section of the Campaigns checklist page. **/
export const OpenEnsureDeliveryKBArticleEvent = createEcsEvent(
  {
    ...DEFAULT_FROM_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'ensure_delivery',
  },
  { campaign_id: '', checklist_step_state: '', editor_type: '' },
);

/** This event fires when the user clicks the "Authenticate your domain" CTA in the closed state of the From slat on the checklist **/
export const AuthenticateYourDomainClosedStateEvent = createEcsEvent(
  {
    ...DEFAULT_FROM_EVENT_PROPERTIES,
    object: 'account',
    ui_object_detail: 'authenticate_your_domain',
    ui_access_point: 'slat',
  },
  { campaign_id: '', checklist_step_state: '', editor_type: '' },
);

/** This event fires when the user clicks the "Authenticate your domain" CTA in the open state of the From slat on the checklist **/
export const AuthenticateYourDomainOpenStateEvent = createEcsEvent(
  {
    ...DEFAULT_FROM_EVENT_PROPERTIES,
    object: 'account',
    ui_object_detail: 'authenticate_your_domain',
    ui_access_point: 'feedback_block',
  },
  { campaign_id: '', checklist_step_state: '', editor_type: '' },
);

/**
 * Verify Email Address Events
 * See: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=1067069431&range=250:258
 */

/** This event fires when a user clicks the "Verify a new email address" option from the Email Address drop down menu in the From section of the Bulk Email Checklist. **/
export const VerifyANewEmailOptionEvent = createEcsEvent(
  {
    ...DEFAULT_FROM_EVENT_PROPERTIES,

    scope_area: 'email_domain',

    ui_object: 'dropdown_menu',
    ui_object_detail: 'verify_a_new_email_address',
  },
  { campaign_id: 0 },
);
