import { createEcsEvent } from '../../configuration/createEvent';

/**
 * This event fires when an user clicks on "Forgot password" link
 */
export const ForgotPasswordClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ForgotPasswordLinkClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/',
    object_detail: 'account_password',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'forgot_password',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * This event fires when an user arrives mailchimp login.mailchimp.com/login/forgot-reset
 */
export const UsernameChallengeLandedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ForgotPasswordLinkClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/forgot',
    object_detail: 'account_password',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * This event fires when an user arrives mailchimp login.mailchimp.com/login/reset
 */
export const PasswordResetInterfaceLandedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ForgotPasswordLinkClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/reset',
    object_detail: 'account_password',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * This event fires when a user enters their new address, then clicks the next CTA
 */
export const NewPasswordSubmittedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ForgotPasswordLinkClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/reset',
    object_detail: 'account_password',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'reset_password',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * This event fires when an user arrives mailchimp login.mailchimp.com/login/reset-post
 */
export const PasswordResetSuccessEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ForgotPasswordLinkClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/reset-post',
    object_detail: 'account_password',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * This event fires when a user clicks on return to login link
 */
export const ReturnToLoginEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the ForgotPasswordLinkClicked
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'account_setup',
    screen: '/login/reset-post',
    object_detail: 'account_login',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'return_to_login',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);
