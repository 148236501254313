import { createEcsEvent } from '../../configuration/createEvent';

export const TestInviteUserViewedVariantEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the TestInviteUserViewedVariantEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'mc_identity',
    scope_area: 'experience_identity',
    screen: 'email/editor',
    object_detail: 'invite_user',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'send_a_test_email',
    ui_action: 'loaded',
    ui_access_point: 'center',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const TestInviteAUserEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the TestInviteAUserEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'mc_identity',
    scope_area: 'experience_identity',
    screen: 'email/editor',
    object_detail: 'invite_user',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'invite_a_user',
    ui_action: 'clicked',
    ui_access_point: 'send_a_test_email_modal',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const GoBackToTheEmailEditorEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the GoBackToTheEmailEditorEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'mc_identity',
    scope_area: 'experience_identity',
    screen: 'account/users/invite',
    object_detail: 'invite_user',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'go_back_to_the_email_builder',
    ui_action: 'clicked',
    ui_access_point: 'send_a_test_email_modal',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const GoBackToTheEmailEditorUserEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the GoBackToTheEmailEditorUserEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'mc_identity',
    scope_area: 'experience_identity',
    screen: 'account/users',
    object_detail: 'invite_user',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'go_back_to_the_email_builder',
    ui_action: 'clicked',
    ui_access_point: 'banner',
    object: 'account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
