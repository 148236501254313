import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  screen: '/audience/contacts',
  object: 'audience',
};

export const allContactsPageViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'all_contacts',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  { previous_page_url: '' },
);

export const addContactsActionListOpened = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_overview',
    action: 'engaged',
    ui_object: 'action_list',
    ui_object_detail: 'add_contacts',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const addContactsActionListOptionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_overview',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_contacts_action',
    ui_action: 'clicked',
    ui_access_point: 'action_list',
  },
  {
    add_contacts_action: '',
  },
);

export const audienceSwitcherAudienceSelectOpened = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience_select',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {},
);

export const audienceSwitcherAudienceSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience_name',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {
    audience_selected: '',
  },
);

export const audienceSwitcherManageAudiencesClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'manage_audiences',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {},
);

export const audienceSwitcherTotalContactCountClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_contacts',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {},
);

export const audienceSwitcherEmailSubscribedCountClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_email_subs',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {},
);

export const audienceSwitcherSmsSubscribedCountClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view_sms_subs',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {},
);

export const audienceSwitcherSeeAudienceAnalyticsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'audience_switcher',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'see_audience_analytics',
    ui_action: 'clicked',
    ui_access_point: 'audience_disclosure',
  },
  {},
);

export const allContactsTagFilterByTagsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'tag_filtering',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'filter_by_tags',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsTagFilterClickedOnTag = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'tag_filtering',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'add_tag',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    has_max: '',
    audience_tag_name: '',
    has_used_search: '',
  },
);

export const allContactsTagFilterTagRemoveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'tag_filtering',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'remove_tag',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    audience_tag_removal_source: '',
  },
);

export const allContactsTagFilterManageTagsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'tag_filtering',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'manage_tags',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsViewSegmentsCtaClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'segmentation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_segments',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSegmentNameClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'segmentation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'segment_name',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    segmentation_method: '',
    segment_name: '',
    has_used_search: '',
  },
);

export const allContactsManageSegmentsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'segmentation',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'manage_segments',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsCreateNewSegmentCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'create_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_new_segment',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSegmentMethodClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'create_segment',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'segment_builder',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    segmentation_method: '',
    segment_name: '',
    has_elc_release_1_sms_cohort_ff: '',
  },
);

export const allContactsSaveAsSegmentClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'create_segment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_as_segment',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    segmentation_method: '',
  },
);

export const allContactsSaveSegmentModalSaveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'create_segment',
    action: 'succeeded',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    segmentation_method: '',
    field_1: '',
    field_2: '',
    field_3: '',
    field_4: '',
    field_5: '',
    op_1: '',
    op_2: '',
    op_3: '',
    op_4: '',
    op_5: '',
    value_1: '',
    value_2: '',
    value_3: '',
    value_4: '',
    value_5: '',
    extra_1: '',
    extra_2: '',
    extra_3: '',
    extra_4: '',
    extra_5: '',
  },
);

export const allContactsEditSegmentClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'segment_actions',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_segment',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    segmentation_method: '',
  },
);

export const allContactsSendCampaignClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'campaign',
    object_detail: 'bulk_campaign',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'send_campaign',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    segmentation_method: '',
  },
);

export const allContactsClearSelectionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'create_segment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'clear_selection',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {
    segmentation_method: '',
  },
);

export const allContactsColumnPopoverOpened = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'column_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'columns',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsColumnsSettingsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'column_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'settings',
    ui_action: 'clicked',
    ui_access_point: 'pop_over',
  },
  {},
);

export const allContactsColumnToggledOn = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'column_settings',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'show_column',
    ui_action: 'clicked',
    ui_access_point: 'pop_over',
  },
  {
    merge_field_name: '',
  },
);

export const allContactsColumnToggledOff = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'column_settings',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'hide_column',
    ui_action: 'clicked',
    ui_access_point: 'pop_over',
  },
  {
    merge_field_name: '',
  },
);

export const allContactsColumnReordered = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'column_settings',
    action: 'engaged',
    ui_object: 'row',
    ui_object_detail: 'reorder_column',
    ui_action: 'dragged',
    ui_access_point: 'pop_over',
  },
  {
    merge_field_name: '',
  },
);

export const allContactsColumnsApplyClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'column_settings',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'apply',
    ui_action: 'clicked',
    ui_access_point: 'pop_over',
  },
  {},
);

export const allContactsExportAudienceClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'export_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'export',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsConfirmExportClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'export_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'confirm',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsSelectAllFromDropdownClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'select_contacts',
    action: 'engaged',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'select_all',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSelectVisibleFromDropdownClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'select_contacts',
    action: 'engaged',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'select_visible',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSelectAllContactsTextClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'select_contacts',
    action: 'engaged',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'select_all_#_contacts',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSelectNoneFromDropDownClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'select_contacts',
    action: 'engaged',
    ui_object: 'dropdown_menu',
    ui_object_detail: 'select_none',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSelectVisibleCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'select_contacts',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'select_visible',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSelectNoneCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'select_contacts',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'select_none',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsTagContactsCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'tag_contacts',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsTagsModalSearchClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'field',
    ui_object_detail: 'search_input',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsTagsModalCreatedClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'create',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsTagsModalTagSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'select_tag',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsTagsModalTagDeselected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'deselect_tag',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsTagsModalPopularTagSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'option',
    ui_object_detail: 'add_popular_tag',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    popular_tag_selected: '',
  },
);

export const allContactsTagsModalApplyClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'apply',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    num_tags_selected: '',
    tagging_action_selected: '',
    contact_count: '',
  },
);

export const allContactsTagsModalManageTagsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_tag',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'manage_tags',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsGroupContactsCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_group',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'group_contacts',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsGroupsModalSearchClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_group',
    action: 'engaged',
    ui_object: 'field',
    ui_object_detail: 'search_input',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsGroupsModalGroupSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_group',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'select_group',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsGroupsModalGroupDeselected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_group',
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'deselect_group',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsGroupsModalApplyClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_group',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'apply',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    num_groups_selected: '',
    grouping_action_selected: '',
    contact_count: '',
  },
);

export const allContactsGroupsModalManageGroupsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'bulk_group',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'manage_groups',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsEditSubscriptionsCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_subscription',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsEmailSubscriptionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'email_subscription',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsEmailPermissionArticleClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_subscription',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'email_permission_article',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsEmailSubscriptionModalApplyClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'apply_email_subscription',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    email_subscription_status: '',
    contact_count: '',
  },
);

export const allContactsSmsSubscriptionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'sms_subscription',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsSmsSubscriptionModalApplyClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'edit_subscription',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'apply_sms_subscription',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    sms_subscription_status: '',
    contact_count: '',
  },
);

export const allContactsArchiveContactsCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'archive_contacts',
    ui_action: 'clicked',
    ui_access_point: 'menu_dropdown',
  },
  {},
);

export const allContactsDeleteContactsCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete_contacts',
    ui_action: 'clicked',
    ui_access_point: 'menu_dropdown',
  },
  {},
);

export const allContactsManageContactsModalContinueClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'review_delete_versus_archive_modal',
  },
  {
    ui_object_detail: '',
    remove_contact_reason_selected: '',
  },
);

export const allContactsManageContactsKebabClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'kebab_menu',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const allContactsManageContactsModalPermDeleteClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'permanently_delete',
    ui_action: 'clicked',
    ui_access_point: 'permanently_delete_modal',
  },
  {
    contact_count: '',
  },
);

export const allContactsManageContactsModalArchiveInsteadClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'archive_instead',
    ui_action: 'clicked',
    ui_access_point: 'permanently_delete_modal',
  },
  { contact_count: '' },
);

export const allContactsManageContactsModalArchiveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'archive',
    ui_action: 'clicked',
    ui_access_point: 'archive_contacts_modal',
  },
  {
    contact_count: '',
  },
);

export const allContactsManageContactsModalPermDeleteInsteadClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'manage_contacts',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'permanently_delete_instead',
    ui_action: 'clicked',
    ui_access_point: 'archive_contacts_modal',
  },
  {},
);

export const allContactsCopyToAudienceCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'copy_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'copy_to_audience',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsConfirmCopyContactsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'copy_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'copy_contacts',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  { contact_count: '' },
);

export const allContactsMoveToAudienceCTAClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'move_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'move_to_audience',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  {},
);

export const allContactsConfirmMoveContactsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'move_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'move_contacts',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    contact_count: '',
  },
);

export const allContactsMoveOrCopyContactsError = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'copy_or_move_contacts',
    action: 'failed',
    ui_object: 'error_banner',
    ui_object_detail: 'cannot_copy_or_move_contacts',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const allContactsExportSegmentClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'export_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'export_segment',
    ui_action: 'clicked',
    ui_access_point: 'table',
  },
  { contact_count: '' },
);

export const allContactsSaveAndExportSegmentClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'export_contacts',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_and_export_segment',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsSaveAndSendCampaignClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'audience',
    object_detail: 'bulk_campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_and_send_campaign',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const allContactsPreviewSegmentClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'segment',
    object_detail: 'create_segment',
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'preview_segment',
    ui_action: 'clicked',
    ui_access_point: 'floating_action_button',
  },
  {
    segmentation_method: '',
  },
);

export const AudienceOrganizationImportErrorSelfHelpEvent = createEcsEvent(
  {
    purpose: 'prod',
    scpope: 'mc',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: '/lists/members',
    object: 'audience',
    object_detail: 'digital_self_help',
    ui_object: 'button',
    ui_action: 'loaded',
    ui_access_point: 'center',
    digital_self_help_button_text: "Why weren't all my contacts imported?",
    manage_contacts_audience_h_nav_option: 'view_contacts',
  },
  {
    action: '',
    ui_object_detail: '',
    ui_action: '',
  },
);

export const AudienceOrganizationImportErrorSelfHelpLoaded = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: '/audience/contacts',
    object: 'audience',
    object_detail: 'digital_self_help',
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'self_help_content',
    ui_action: 'loaded',
    ui_access_point: 'center',
    digital_self_help_button_text: "Why weren't all my contacts imported?",
  },
  {},
);

export const AudienceOrganizationImportErrorSelfHelpClicked = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'all_contacts',
    screen: '/audience/contacts',
    object: 'audience',
    object_detail: 'digital_self_help',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'help_selection',
    ui_action: 'clicked',
    ui_access_point: 'center',
    digital_self_help_button_text: "Why weren't all my contacts imported?",
  },
  {},
);
