import { createEcsEvent } from '../../configuration/createEvent';

// Default event props for the Groups page owned by AOrg
const DEFAULT_EVENT_PROPS = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  screen: 'audience/groups',
  object: 'audience',
  object_detail: 'groups_index',
};

/*
 * Fire when a user views the groups index page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:93
 */

export const AorgReactGroupsPageView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
    zero_state: '',
  },
);

export const AorgReactGroupsLearnMoreSignupFormsClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more_sign_up_forms',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactGroupsLearnMoreSegmentationClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more_segmentation',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactGroupsCreateTagsInsteadClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'create_tags_instead',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactGroupsLearnMoreAboutGroupsClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more_about_groups',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const AorgReactGroupsCreateGroupButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_groups',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    zero_state: false,
  },
);

export const AorgReactGroupsCreateGroupFormSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'group_creation_form',
  },
  {
    group_category_name: '',
    group_names: '',
    number_of_groups: 0,
    signup_form_option: 'as_checkboxes',
  },
);

export const AorgReactGroupsRenameClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'group_category_slat',
  },
  {},
);

export const AorgReactGroupsRenameSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'group_category_slat',
  },
  {},
);

export const AorgReactGroupsOptionRenameClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'group_name_slat',
  },
  {},
);

export const AorgReactGroupsOptionRenameSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'group_name_slat',
  },
  {},
);

export const AorgReactGroupsDeleteClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'group_category_slat',
  },
  {},
);

export const AorgReactGroupsDeleteSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete_groups',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AorgReactGroupsOptionDeleteClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'group_name_slat',
  },
  {},
);

export const AorgReactGroupsOptionDeleteSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete_group',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const AorgReactGroupsViewGroupsClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_groups',
    ui_action: 'clicked',
    ui_access_point: 'group_category_slat',
  },
  {},
);

export const AorgReactGroupsNumContactsClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'number_of_contacts',
    ui_action: 'clicked',
    ui_access_point: 'group_name_slat',
  },
  {},
);

export const AorgReactGroupsImportClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'import',
    ui_action: 'clicked',
    ui_access_point: 'group_name_slat',
  },
  {},
);

export const AorgReactGroupsOptionDropdownLinkClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPS,
    action: 'engaged',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'group_name_send_dropdown',
  },
  {
    ui_object_detail: 'regular_campaign',
  },
);
