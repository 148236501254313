import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'smart_content_generation',
  object: 'automations',
};

export const AiPbjSelectedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'prebuilt_journey',
    action: 'started',
    ui_object: 'card',
    ui_object_detail: 'pbj_name',
    ui_action: 'clicked',
    ui_access_point: 'front_door_landing_page',
  },
  {
    screen: '',
    is_genai_related: '',
    ai_email_type: '',
    pbj_type: '',
  },
);
