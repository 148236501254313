import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const CampaignsAdjustButtonShapeEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'button_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'shape',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: null,
    nuni_button_shape: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const TemplatesAdjustButtonShapeEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'button_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'shape',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_button_shape: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const CampaignsAdjustButtonSizeEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'button_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'size',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: null,
    nuni_button_size: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const TemplatesAdjustButtonSizeEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'button_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'size',
  },
  {
    template_id: 0,
    user_template_id: null,
    nuni_button_size: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const CampaignsAdjustButtonBorderWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'button_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'border_width',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: null,
    nuni_button_border_width: 0,
    nuni_tab: '',
    nuni_section: '',
  },
);

export const TemplatesAdjustButtonBorderWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'button_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'border_width',
  },
  {
    template_id: 0,
    user_template_id: null,
    nuni_button_border_width: 0,
    nuni_tab: '',
    nuni_section: '',
  },
);
