import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  screen: '/sms',
  object: 'recommendation',
};

// User Clicks CTA in Slat Events
export const ImportContactsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'import_contacts',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
  },
);

export const CreateSMSClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_sms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
  },
);

export const CreateWelcomeAutomationClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_welcome_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const ViewECommerceIntegrationClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_ecommerce_integration',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '', has_store_synced: false },
);
export const GoToStoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'go_to_store',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '', has_store_synced: false },
);

export const CreateAbandonedCartAutomationClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_abandoned_cart_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const EditPopupClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_forms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const CustomizeLandingPageClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'customize_landing_page',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const CreateEmailClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_email',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const CreateOptimizeFormsClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'update_forms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const PreviewClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_preview',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const SetUpAutomationClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'set_up_your_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

// User Clicks Checkbox in Slat Events
export const ImportContactsCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'import_contacts',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);

export const CreateSMSCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'create_sms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);

export const CreateWelcomeAutomationCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'create_welcome_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);

export const ViewECommerceIntegrationCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'view_ecommerce_integration',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    is_recommendation_checked: false,
    sms_registration_status: '',
    has_store_synced: false,
  },
);

export const CreateAbandonedCartAutomationCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'create_abandoned_cart_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);

export const EditPopupCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'create_forms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);
export const CreateLandingPageCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'customize_landing_page',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);
export const CreateEmailCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'send_email',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);
export const OptimizeFormsCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'update_forms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);
export const PreviewCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'send_preview',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);
export const SetupAutomationCheckboxClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'set_up_your_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_slat',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_checked: false,
  },
);

// User Clicks Expands/Collapses Slat Events
export const ImportContactsSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'import_contacts',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);

export const CreateSmsSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'create_sms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);

export const CreateWelcomeAutomationSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'create_welcome_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);

export const ViewECommerceIntegrationSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'view_ecommerce_integration',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    is_recommendation_expanded: false,
    sms_registration_status: '',
    has_store_synced: false,
  },
);

export const CreateAbandonedCartAutomationSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'create_abandoned_cart_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);
export const EditPopupSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'create_forms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);
export const CustomizeLandingPageSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'customize_landing_page',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);
export const CreateEmailSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'send_email',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);
export const OptimizeFormsSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'update_forms',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);
export const PreviewSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'send_preview',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);
export const SetUpAutomationSlatExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'set_up_your_automation',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
    is_recommendation_expanded: false,
  },
);

// User Engages with SMS Program Checklist
export const DismissAllTasksClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'dismiss_this_checklist',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const DismissAllTasksButtonClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'dismiss_task_list',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const AllTasksCompleteLoaded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'all_tasks_complete',
    ui_action: 'loaded',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsProgramChecklistCollapsed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_up',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsProgramChecklistExpanded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'menu_down',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_checklist',
    object_detail: 'product_features',
  },
  {
    sms_registration_status: '',
  },
);

export const AddContactsQuickActionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_contacts',
    ui_action: 'clicked',
    ui_access_point: 'sms_overview_secondary_header',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const ViewSMSJourneysQuickActionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_sms_journey',
    ui_action: 'clicked',
    ui_access_point: 'sms_overview_secondary_header',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const ViewMessageInboxQuickActionClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_message_inbox',
    ui_action: 'clicked',
    ui_access_point: 'sms_overview_secondary_header',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);

export const SignupLandingPageLearnMoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_signup_landing_page_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const CreateSmsLearnMoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_create_sms_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const ImportContactsLearnMoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_import_contacts_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const PreviewLearnMoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_send_preview_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const CreateFormsLearnMoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_create_forms_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
export const UpdateFormsLearnMoreClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'sms_program_update_forms_slat',
    object_detail: 'product_features',
  },
  { sms_registration_status: '' },
);
