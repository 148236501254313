import { createEcsEvent } from '../../../configuration/createEvent';

const sharedProps = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/email-dashboard',
  object: 'reporting',
  object_detail: 'email_dashboard',
};

export const EmailDashboardDateRangeDropDownSelect = createEcsEvent(
  {
    ...sharedProps,
    action: 'customized',
    ui_object: 'datepicker',
    ui_object_detail: 'date_range_popover',
    ui_action: 'updated',
    ui_access_point: 'date_range_filter',
  },
  {
    date_range: '',
  },
);
