import { createEcsEvent } from '../../configuration/createEvent';

/**
 * "This event fires when an user lands on Login page"
 */
export const LoginLandedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the LoginLandedEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'user_session',
    screen: '/',
    object_detail: 'account_login',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * "This event fires when a user clicks on the login button"
 */
export const LoginButtonClickedEvent = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the LoginButtonClickedEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'user_session',
    screen: '/',
    object_detail: 'account_login',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'login',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);

/**
 * "This event fires when an user clicks on "Create an account" link"
 */
export const CreateAccountLinkClicked = createEcsEvent(
  /**
   * This param contains the fixed properties the event will contain.
   * These properties can never be changed inside of the EmailConfirmationOutlookEvent
   */
  {
    purpose: 'prod',
    initiative_name: 'identity_bridge',
    scope_area: 'user_session',
    screen: '/',
    object_detail: 'account_signup',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'create_an_account',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  { entry_point: '' },
);
