import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'loyalty_leaderboard',
  screen: 'audience/loyalty',
  object: 'audience',
};

export const loyaltyLeaderboardViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'screen',
    ui_action: 'viewed',
    object_detail: 'loyalty_leaderboard',
    ui_object_detail: '',
    ui_access_point: '',
  },
  {},
);

export const loyaltyLeaderboardSourceDropdownSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'source',
    ui_object: 'dropdown_item',
    ui_action: 'clicked',
    ui_access_point: 'top',
    ui_object_detail: '',
  },
  {
    source_selected: '',
  },
);

export const loyaltyLeaderboardTimeFrameDropdownSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'time_frame',
    ui_object: 'dropdown_item',
    ui_action: 'clicked',
    ui_access_point: 'top',
    ui_object_detail: '',
  },
  {
    time_frame_selected: '',
  },
);

export const loyaltyLeaderboardRankBasedDropdownSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'rank',
    ui_object: 'dropdown_item',
    ui_action: 'clicked',
    ui_access_point: 'top',
    ui_object_detail: '',
  },
  {
    rank_selected: '',
  },
);

export const loyaltyLeaderboardAddSourceSelected = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'add_source',
    ui_object: 'dropdown_item',
    ui_object_detail: 'add_new',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {},
);

export const loyaltyLeaderboardFeedbackProvided = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'feedback',
    ui_object: 'link_text',
    ui_object_detail: 'let_us_know',
    ui_action: 'clicked',
    ui_access_point: 'top',
  },
  {},
);

export const loyaltyLeaderboardMessageCustomerClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'message',
    ui_object: 'button',
    ui_object_detail: 'message',
    ui_action: 'clicked',
    ui_access_point: 'right',
  },
  {},
);

export const loyaltyLeaderboardCustomerRowClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'selected',
    object_detail: 'customer_row',
    ui_object: 'slat',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: '',
  },
  {},
);

export const loyaltyLeaderboardSegmentSaved = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'save_segment_experiment',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'save_segment',
    ui_action: 'clicked',
    ui_access_point: 'top_right',
  },
  {
    source_selected: '',
  },
);
