import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the HumanAssistanceStep step"
 */
export const HumanAssistanceStepViewedEvent = createEcsEvent(
  /**
   * This param contains the HumanAssistanceStep0Event the event will contain.
   * These properties can never be changed inside of the HumanAssistanceStep0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'human_assistance',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user enters their personal info, then clicks the next CTA, and successfully passes validation"
 */
export const HumanAssistanceStepEngagedNextEvent = createEcsEvent(
  /**
   * This param contains the HumanAssistanceStepNextOrSkipEvent the event will contain.
   * These properties can never be changed inside of the HumanAssistanceStepNextOrSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'human_assistance',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'schedule_a_call',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    next_or_skip: '',
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the HumanAssistanceStep page"
 */
export const HumanAssistanceStepEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the HumanAssistanceStepEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the HumanAssistanceStepEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'human_assistance',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remind_me_later',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);
