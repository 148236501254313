import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  object: 'automations',
};

export const CustomerJourneyNavigatedCreateNewJourney = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'navigated',
    object_detail: 'viewed_customer_journey',
    screen: 'customer-journey/create-new-journey',
    // addtional params (required, but empty)
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const CustomerJourneyNavigatedOverview = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'navigated',
    object_detail: 'viewed_customer_journey',
    screen: 'customer-journey/',
    // addtional params (required, but empty)
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const CustomerJourneyNavigatedMyJourneys = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'navigated',
    object_detail: 'viewed_customer_journey',
    screen: 'customer-journey/my-journeys',
    // addtional params (required, but empty)
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const CustomerJourneyNavigatedExplore = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'navigated',
    object_detail: 'viewed_customer_journey',
    screen: 'customer-journey/explore',
    // addtional params (required, but empty)
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const CustomerJourneyNavigatedBuilder = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'navigated',
    object_detail: 'viewed_customer_journey',
    screen: 'customer-journey/builder',
    // addtional params (required, but empty)
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
