import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_domain',

  object_detail: 'new_requirements_for_domain',
};

const CONFIGURABLE_PROPERTIES = {
  screen: '',

  campaign_id: 0,
  email_domains: null,
};

export const DomainAuthHelpArticleClickEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object: 'account',
    action: 'engaged',

    ui_object: 'link',
    ui_object_detail: 'new_requirements_for_email_senders',
    ui_action: 'clicked',
    ui_access_point: 'feedback_block',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const DomainAuthStartAuthenticationEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object: 'account',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'start_authentication',
    ui_action: 'clicked',
    ui_access_point: 'feedback_block',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const DomainAuthGetHelpFromAnExpertEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    object: 'help_support',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'get_help_from_an_expert',
    ui_action: 'clicked',
    ui_access_point: 'feedback_block',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);
