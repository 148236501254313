import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  screen: '/campaigns',
  action: 'engaged',
  scope_area: 'bulk_campaigns',
  object: 'campaign',
  object_detail: 'resend_campaign',
};

export const RNOEventCancelButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'schedule_resend_campaign_modal',
  },
  {},
);

export const RNOEventCloseButton = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'closed',
    ui_access_point: 'schedule_resend_campaign_modal',
  },
  {},
);

export const RNOEventNewSubjectLine = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_object_detail: 'new_subject_line',
    ui_action: 'typed',
    ui_access_point: 'schedule_resend_campaign_modal',
  },
  {},
);

export const RNOEventResendDate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'datepicker',
    ui_object_detail: 'resend_date',
    ui_action: 'clicked',
    ui_access_point: 'schedule_resend_campaign_modal',
  },
  {},
);

export const RNOEventResendCampaign = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'resend_campaign_to_non_openers',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const RNOEventScheduleEmail = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'schedule_email',
    ui_action: 'clicked',
    ui_access_point: 'schedule_resend_campaign_modal',
  },
  {
    resent_from_campaign_id: '',
  },
);
