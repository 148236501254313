import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

export const MarketingGoalsStepViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'marketing_goals',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },

  {
    entry_point: '',
    is_ecomm_business_user: false,
  },
);

export const MarketingGoalsStepNextEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'marketing_goals',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },

  {
    marketing_goals: '',
    entry_point: '',
  },
);

export const MarketingGoalsStepEngagedSkipEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'marketing_goals',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },

  {
    entry_point: '',
  },
);

export const MarketingGoalsStepEngagedBackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'marketing_goals',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },

  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'footer',
  },
);

export const MarketingGoalsStepFailedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'marketing_goals',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },

  {
    error: '',
    entry_point: '',
  },
);
