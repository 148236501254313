import { createEcsEvent } from '../../configuration/createEvent';

const FREE_TRIAL_DEFAULT_PROPERTIES = {
  editor_type: 'NUNI',
  initiative_name: 'core_offerings',
  object_detail: 'genai_monetization',
  purpose: 'prod',
};

export const clickUpgradeNowEvent = createEcsEvent(
  {
    ...FREE_TRIAL_DEFAULT_PROPERTIES,
    object: 'checkout',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'upgrade_now',
    ui_action: 'clicked',
    scope_area: 'smart_content_generation',
  },
  {
    ui_access_point: '',
    screen: '',
    campaign_id: '',
    ai_email_type: '',
    content_type: '',
    campaign_type: '',
    ai_use_case: '',
  },
);

export const clickPostTrialReminderEvent = createEcsEvent(
  {
    ...FREE_TRIAL_DEFAULT_PROPERTIES,
    object: 'checkout',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'upgrade_to_keep_ai_tools',
    ui_action: 'clicked',
    ui_access_point: 'ai_trial_reminder_toolbar',
    scope_area: 'bulk_campaigns',
  },
  {
    screen: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    checklist_step_state: '',
  },
);

export const dismissPostTrialReminderEvent = createEcsEvent(
  {
    ...FREE_TRIAL_DEFAULT_PROPERTIES,
    object: 'checkout',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'dismiss',
    ui_action: 'clicked',
    ui_access_point: 'ai_trial_reminder_toolbar',
    scope_area: 'bulk_campaigns',
  },
  {
    screen: '',
    campaign_id: '',
    campaign_type: '',
    content_type: '',
    checklist_step_state: '',
  },
);

const FREE_TRIAL_MODAL_DEFAULT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_plans',
  object_detail: 'genai_monetization',
  event_source: 'front_end:js_react',
};

export const showGenAITrialStartModalEvent = createEcsEvent(
  {
    ...FREE_TRIAL_MODAL_DEFAULT_PROPERTIES,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'start_genai_trial',
    ui_action: 'loaded',
    ui_access_point: 'center',
    object: 'account',
  },
  {
    screen: '',
  },
);

export const clickSidebarPUBUpgradeNowClickEvent = createEcsEvent(
  {
    ...FREE_TRIAL_MODAL_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'upgrade_now',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
    object: 'checkout',
  },
  {
    screen: '',
  },
);

export const clickPurchaseCTAInPaywallModalEvent = createEcsEvent(
  {
    ...FREE_TRIAL_MODAL_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_ai_features',
    ui_action: 'clicked',
    ui_access_point: 'genai_trial_upgrade_modal',
    object: 'checkout',
  },
  {
    screen: '',
    ai_trial_source: '',
  },
);

export const userViewsTrialExtensionMessagingEvent = createEcsEvent(
  {
    ...FREE_TRIAL_MODAL_DEFAULT_PROPERTIES,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'genai_trial_extension',
    ui_action: 'loaded',
    ui_access_point: 'center',
    object: 'account',
  },
  {
    screen: '',
  },
);
