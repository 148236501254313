import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  object: 'account',
  event_source: 'front_end:js_react',
};

export const BillingPlansPageRestartButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/billing/plans',
    action: 'engaged',
    object_detail: 'paused_user_restart',
    ui_object: 'button',
    ui_object_detail: 'restart_plan',
    ui_action: 'clicked',
    ui_access_point: 'billing_paused_banner',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

//A paused user views the persistent restart button in the left nav
export const LeftNavRestartButtonViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'paused_user_restart',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    screen: '',
  },
);

//A paused user clicks the persistent restart button in the left nav
export const LeftNavRestartButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'paused_user_restart',
    ui_object: 'button',
    ui_object_detail: 'restart_plan',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
  },
);
//User views the scaled onboarding CTA in the left nav
export const LeftNavScaledOnboardingViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'persistent_scaled_onboarding',
    action: 'viewed',
    ui_object: 'widget',
    ui_object_detail: 'scaled_onboarding',
    ui_action: 'loaded',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
  },
);
//User clicks the scaled onboarding CTA in the left nav
export const LeftNavScaledOnboardingClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    action: 'engaged',
    object_detail: 'persistent_scaled_onboarding',
    ui_object: 'button',
    ui_object_detail: 'schedule_call',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
  },
);

//User clicks the scaled onboarding CTA in the left nav
export const LeftNavStrategicOnboardingClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    action: 'engaged',
    object_detail: 'persistent_strategic_onboarding',
    ui_object: 'button',
    ui_object_detail: 'schedule_call',
    ui_action: 'clicked',
    ui_access_point: 'left_nav',
  },
  {
    screen: '',
  },
);
