import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  scope_area: 'crm_composite_app',
};

export const selectsMainNavLink = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'left_nav',
    ui_action: 'clicked',
    ui_object: 'button',
  },
  {
    object_detail: '',
    ui_object_detail: '',
    screen: '',
  },
);

export const selectsFreddieLogo = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'header',
    ui_action: 'clicked',
    ui_object: 'button',
    object_detail: 'crm_overview',
    ui_object_detail: 'freddie_head',
  },
  {
    screen: '',
  },
);

export const selectsCrmSwitcher = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'top_left',
    ui_action: 'clicked',
    ui_object: 'link',
    object_detail: 'account_switch',
    ui_object_detail: 'crm',
  },
  {
    screen: '',
  },
);

export const selectsMarketingSwitcher = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'top_left',
    ui_action: 'clicked',
    ui_object: 'link',
    object_detail: 'account_switch',
    ui_object_detail: 'marketing',
  },
  {
    screen: '',
  },
);

export const viewProfileMenu = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'top_right',
    ui_action: 'clicked',
    ui_object: 'icon',
    object_detail: 'account_profile',
    ui_object_detail: 'profile',
  },
  {
    screen: '',
  },
);

export const goToAccountAndBilling = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'top_right',
    ui_action: 'clicked',
    ui_object: 'sub_menu',
    object_detail: 'account_billing',
    ui_object_detail: 'account_billing',
  },
  {
    screen: '',
  },
);

export const selectsAudienceInAudienceSwitcher = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'audience:engaged',
    object: 'audience',
    action: 'engaged',
    ui_access_point: 'top_right',
    ui_action: 'clicked',
    ui_object: 'sub_menu',
    object_detail: 'audience_selection',
    ui_object_detail: 'audience_name',
  },
  {
    screen: '',
  },
);

export const selectsLanguagePreference = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'internationalization',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'top_right',
    ui_action: 'clicked',
    ui_object: 'sub_menu',
    object_detail: 'language_preference',
    ui_object_detail: 'language_selector',
  },
  {
    screen: '',
    language_selected: '',
  },
);

export const logsOut = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'customer_relationship_management',
    event: 'navigation:selected',
    object: 'navigation',
    action: 'selected',
    ui_access_point: 'top_right',
    ui_action: 'clicked',
    ui_object: 'sub_menu',
    object_detail: 'account_logout', // corrected from previous value - language_preference - from 3/25/2024 onwards
    ui_object_detail: 'logout', //corrected from previous value - language_selector - from 3/25/2024 onwards
  },
  {
    screen: '',
  },
);
