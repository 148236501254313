import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'smart_content_generation',
  object: 'campaign',
  screen: '/sms/edit',
  object_detail: 'content_generation_submission',
};

export const SmsEditorPromptSubmitted = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    sms_registration_status: '',
    ai_prompt_id: '',
    ai_tone_selected: '',
    has_shorten_applied: '',
    has_fix_grammar_and_spelling_applied: '',
    has_try_again_applied: '',
    genai_feature_prompt_submission: '',
    generation_attempt_count: '',
  },
);
