import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  screen: 'audience/exports',
  object: 'audience',
  object_detail: 'export_history',
};

//View export history page
export const audienceExportHistoryViewExportsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_action: '',
    ui_access_point: '',
    ui_object_detail: '',
  },
  {
    previous_page_url: '',
    zero_state: false,
  },
);

//Select all
export const audienceExportHistorySelectAllExportsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'checkbox',
    ui_action: 'clicked',
    ui_access_point: 'table_header',
  },
  {},
);

//Select or deselect a slat
export const audienceExportHistorySelectDeselectExportEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'checkbox',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {
    is_export_history_slat_selected: false,
  },
);

//Click "delete exports"
export const audienceExportHistoryDeleteExportsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete_exports',
    ui_action: 'clicked',
    ui_access_point: 'table_header',
  },
  {},
);

//Click to confirm "delete exports"
export const audienceExportHistoryDeleteExportsConfirmationEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'confirm_delete_exports',
    ui_action: 'clicked',
    ui_access_point: 'delete_export_modal',
  },
  {
    export_count: 0,
  },
);

//Click "download CSV"
export const audienceExportHistoryDownloadCSVEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_as_csv',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {
    contact_count: 0,
  },
);
