import { createEcsEvent } from '../../configuration/createEvent';

/*
## Notes on ECS system, terminology and usage
Think about createEcsEvent as 'defineEventSchema'. Each one corresponds to one row in the ECS tracking spreadsheet.

Think about defaultProperties as 'base properties'. These properties will be present each time an event is fired, and
their values will be the same each time.

Lastly, configurableProperties contains the properties the event requires, and are set via the second argument to
EcsTracking.track, eg.

// in this file:
const MyEvent = createEcsEvent({ .. }, { property_name_in_configurable_properties: '' });

// when the event is being fired:
EcsTracking.track(MyEvent, { property_name_in_configurable_properties: 'the specific value for this firing' });


## Notes on organization and reuse in this file
Avoid premature abstraction: For the moment, avoid refactoring these calls to createEcsEvent in an attempt to minimize
file size, or string duplication. ECS tracking spreadsheet tends to have some churn initially, and so leaving these
calls as simple as possible (albeit very long, redundant, etc) will make it easy to modify over time, and easy for
your teammates to make sense of changes during code review.

Correspond to the spreadsheet: one `export const SomethingClicked = createEcsEvent(...);` per spreadsheet row, order the
default properties in accordance with the column ordering in the spreadsheet. Copy the variable name you choose back
into the spreadsheet (in the first column, in parentheses, so that its easy to find which row it is). Avoid using row
numbers because those change over time.

Naming consts: NounVerbed, eg. InboxOpened not OpenInbox, SchedulingModalClosed or SchedulingModalCloseClicked not
UserClickScheduleModalClose.
*/

// NOTE: createEcsEvent sets the 'org' and 'scope' properties automatically. Do not provide them in defaultProperties.

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'appointment',
  screen: 'crm/appointments/',
  object: 'audience',
  object_detail: 'job_appointments',
};

export const AttachJobModalViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    entity_type: '',
  },
);

export const AttachJobSaveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'attach_to_job',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    thread_id: '',
    payment_links_id: '',
    pipeline_job_id: '',
    pipeline_id: '',
    appointment_id: '',
    entity_type: '',
  },
);

export const AttachJobNewClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'radio_button',
    ui_object_detail: 'new_job',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    thread_id: '',
    payment_links_id: '',
    entity_type: '',
  },
);

export const AttachJobCloseClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'X',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    thread_id: '',
    payment_links_id: '',
    entity_type: '',
  },
);

export const AttachJobCancelClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    thread_id: '',
    payment_links_id: '',
    entity_type: '',
  },
);
