import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'campaign',
  screen: '/sms/bulk',
  object_detail: 'recipients_bulk_checklist',
};

export const SaveSMSRecipients = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_segment_selected: false,
    num_contacts_in_segment: 0,
    segment_send_to: '',
    segment_do_not_send_to: '',
  },
);

export const DefineSMSRecipients = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sms_registration_status: '',
  },
);
