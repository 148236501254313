import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const CampaignAdjustDividerHeightEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'divider_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'height',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_divider_height: 0,
  },
);

export const TemplateAdjustDividerHeightEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'divider_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'height',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_divider_height: 0,
  },
);

export const CampaignAdjustDividerStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'divider_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'selected',
    ui_access_point: 'side_panel',
    ui_object_detail: 'style',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_divider_style: '',
  },
);

export const TemplateAdjustDividerStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'divider_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'selected',
    ui_access_point: 'side_panel',
    ui_object_detail: 'style',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_divider_style: '',
  },
);

export const CampaignAdjustDividerWidthEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'divider_styles',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'width',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_divider_width: 0,
  },
);

export const CampaignAdjustBackgroundImage = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'widget',
    ui_object_detail: 'background_image',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
  },
);

export const TemplateAdjustBackgroundImage = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'widget',
    ui_object_detail: 'background_image',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
  },
);

export const CampaignAdjustBackgroundImageSize = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'background_image_size',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
    nuni_background_image_size: '',
  },
);

export const TemplateAdjustBackgroundImageSize = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'background_image_size',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
    nuni_background_image_size: '',
  },
);

export const CampaignAdjustBackgroundImagePosition = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'background_image_position',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
    nuni_background_image_position: '',
  },
);

export const TemplateAdjustBackgroundImagePosition = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'background_image_position',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
    nuni_background_image_position: '',
  },
);

export const CampaignAdjustBackgroundImageRepeat = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'background_image_repeat',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
    nuni_background_image_repeat: '',
  },
);

export const TemplateAdjustBackgroundImageRepeat = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'background_image_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'background_image_repeat',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_background_image_viewed_from: '',
    nuni_background_image_repeat: '',
  },
);

export const CampaignAdjustLinkBold = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'bold',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    bold_viewed_from: '',
    is_nuni_text_bold: false,
  },
);

export const TemplateAdjustLinkBold = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'bold',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    bold_viewed_from: '',
    is_nuni_text_bold: false,
  },
);

export const CampaignAdjustLinkItalic = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'italic',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    italic_viewed_from: '',
    is_nuni_text_italic: false,
  },
);

export const TemplateAdjustLinkItalic = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'italic',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    italic_viewed_from: '',
    is_nuni_text_italic: false,
  },
);

export const CampaignAdjustLinkUnderline = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'underline',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    underline_viewed_from: '',
    is_nuni_text_underline: false,
  },
);

export const TemplateAdjustLinkUnderline = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'underline',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    underline_viewed_from: '',
    is_nuni_text_underline: false,
  },
);

export const StylesCampaignAdjustFontSize = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font_size',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    font_size_viewed_from: '',
    nuni_text_font_size: 0,
  },
);

export const StyleTemplateAdjustFontSize = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font_size',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    font_size_viewed_from: '',
    nuni_text_font_size: 0,
  },
);

export const StylesCampaignAdjustBold = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'bold',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    bold_viewed_from: '',
    is_nuni_text_bold: false,
  },
);

export const StyleTemplateAdjustBold = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'bold',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    bold_viewed_from: '',
    is_nuni_text_bold: false,
  },
);

export const StyleCampaignAdjustItalics = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'italic',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    italic_viewed_from: '',
    is_nuni_text_italic: false,
  },
);

export const StyleTemplateAdjustItalics = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'italic',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    italic_viewed_from: '',
    is_nuni_text_italic: false,
  },
);

export const StyleCampaignAdjustUnderline = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'underline',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    underline_viewed_from: '',
    is_nuni_text_underline: false,
  },
);

export const StyleTemplateAdjustUnderline = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'underline',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    underline_viewed_from: '',
    is_nuni_text_underline: false,
  },
);

export const StyleCampaignAdjustAlignment = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'alignment',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    alignment_viewed_from: '',
    nuni_text_alignment: '',
  },
);

export const StyleTemplateAdjustAlignment = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'alignment',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    alignment_viewed_from: '',
    nuni_text_alignment: '',
  },
);

export const CampaignAdjustLineHeight = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'line_height',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    line_height_viewed_from: '',
    nuni_text_line_height: 0,
  },
);

export const TemplateAdjustLineHeight = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'line_height',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    line_height_viewed_from: '',
    nuni_text_line_height: 0,
  },
);

export const StylesCampaignAdjustLetterSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'letter_spacing',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    letter_spacing_viewed_from: '',
    nuni_text_letter_spacing: 0,
  },
);

export const StyleTemplateAdjustLetterSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'letter_spacing',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    letter_spacing_viewed_from: '',
    nuni_text_letter_spacing: 0,
  },
);

export const CampaignAdjustTextDirection = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'direction',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    direction_viewed_from: '',
    nuni_text_direction: '',
  },
);

export const TemplateAdjustTextDirection = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'direction',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    direction_viewed_from: '',
    nuni_text_direction: '',
  },
);

export const CampaignAdjustPaddingLeft = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'padding_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'left',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_padding_left: 0,
  },
);

export const TemplateAdjustPaddingLeft = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'padding_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'left',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_padding_left: 0,
  },
);

export const CampaignAdjustPaddingRight = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'padding_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'right',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    nuni_tab: '',
    nuni_section: '',
    nuni_padding_right: 0,
  },
);

export const TemplateAdjustPaddingRight = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/templates/editor',
    object: 'campaign',
    object_detail: 'padding_styles',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'right',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_padding_right: 0,
  },
);

/** This event should fire when the user changes the paragraph spacing in Nuni. **/
export const AdjustParagraphSpacingEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'paragraph_spacing',
    ui_action: 'applied',
  },
  {
    screen: '/email/editor',
    ui_access_point: 'side_panel',
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_text_paragraph_spacing: 0,
  },
);

const DEFAULT_PARAGRAPH_SPACING_PROPERTIES = {
  scope_area: 'email_editor',
  screen: '/email/templates/editor',
  object: 'campaign',
  object_detail: 'text_selection',
  action: 'customized',
  ui_object: 'component',
  ui_object_detail: 'paragraph_spacing',
  ui_action: 'applied',
};

export const TemplateAdjustParagraphSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...DEFAULT_PARAGRAPH_SPACING_PROPERTIES,
  },
  {
    ui_access_point: 'side_panel',
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_text_paragraph_spacing: 0,
  },
);

export const CampaignAdjustParagraphSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ...DEFAULT_PARAGRAPH_SPACING_PROPERTIES,
    screen: '/email/editor',
  },
  {
    ui_access_point: 'side_panel',
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    nuni_text_paragraph_spacing: 0,
  },
);

export const TemplateAdjustInlineParagraphSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'paragraph_spacing',
    ui_access_point: 'text_toolbar',
    ui_action: 'clicked',
    screen: '/email/templates/editor/',
  },
  {
    user_template_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
  },
);

export const CampaignAdjustInlineParagraphSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'paragraph_spacing',
    ui_access_point: 'text_toolbar',
    ui_action: 'clicked',
    screen: '/email/editor',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
  },
);

export const AdjustInlineParagraphSpacing = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'paragraph_spacing',
    ui_access_point: 'text_toolbar',
    ui_action: 'clicked',
  },
  {
    screen: '',
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'text',
  },
);

export const SectionStylesViewed = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'content_styles',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'section_styles',
    ui_action: 'loaded',
    ui_access_point: 'side_panel',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    platform_type: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const clearSectionStyles = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'content_styles',
    ui_object_detail: 'clear_section_styles',
    action: 'customized',
    ui_object: 'button',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    platform_type: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const restoreDefaultStyles = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: '/email/editor',
    object: 'campaign',
    object_detail: 'content_styles',
    ui_object_detail: 'restore_default_styles',
    action: 'customized',
    ui_object: 'button',
    ui_access_point: 'side_panel',
    ui_action: 'clicked',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    platform_type: '',
    nuni_tab: '',
    nuni_section: '',
  },
);

export const AdjustPaddingTop = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'padding_styles',
    ui_object_detail: 'top',
    action: 'customized',
    ui_object: 'component',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    platform_type: '',
    nuni_tab: '',
    nuni_section: '',
    nuni_padding_top: 0,
    screen: '',
  },
);

export const AdjustPaddingBottom = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'padding_styles',
    ui_object_detail: 'bottom',
    action: 'customized',
    ui_object: 'component',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    platform_type: '',
    nuni_tab: '',
    nuni_section: '',
    nuni_padding_bottom: 0,
    screen: '',
  },
);

export const SectionBorderStyle = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'content_styles_options',
    ui_object_detail: 'border',
    action: 'customized',
    ui_object: 'component',
    ui_access_point: 'side_panel',
    ui_action: 'applied',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    user_template_id: null,
    platform_type: '',
    screen: '',
    nuni_tab: '',
    nuni_editor_section: '',
    nuni_border_style: '',
    nuni_border_direction: '',
    nuni_border_width: '',
  },
);
