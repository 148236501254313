import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'contact_info',
  scope_area: 'finance_operations',
  object: 'account',
  object_detail: 'contact_information',
  event_source: 'front_end:js_react',
};

export const ContactInfoViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: '',
    ui_action: 'viewed',
    ui_access_point: '',
  },
  {},
);

export const ContactProfileEditClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'profile_information_card',
  },
  {},
);

export const ContactInfoViewAllClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_all',
    ui_action: 'clicked',
    ui_access_point: 'audience_in_this_account_card',
  },
  {},
);
