import { createEcsEvent } from '../../configuration/createEvent';

/*
Source of truth: Wayfinding tab on Mailchimp ECS v1 sheet:
https://docs.google.com/spreadsheets/d/1rqiSqIBT1nW5iCZhybVbx7YKsEh-B5fKHtPDq8dhgYY/edit#gid=652732638
*/

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'product_global_nav',
  object: 'navigation',
};

// Search_enter_search_input
export const SearchEnterSearchInputEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '',
    object_detail: 'search_l1',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: 'search_input', // confirm this is correct and updated to match in Sheet
    ui_action: 'clicked',
    ui_access_point: 'global_search',
  },
  // Mutable params here:
  {
    search_input: '',
    search_tab: '',
  },
);

// Search_Campaigns
export const SearchCampaignsTabEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '',
    object_detail: 'search_l1',
    action: 'selected',
    ui_object: 'tab',
    ui_object_detail: 'campaigns',
    ui_action: 'clicked',
    ui_access_point: 'global_search',
  },
  {},
);

// Search_Contacts
export const SearchContactsTabEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '',
    object_detail: 'search_l1',
    action: 'selected',
    ui_object: 'tab',
    ui_object_detail: 'contacts',
    ui_action: 'clicked',
    ui_access_point: 'global_search',
  },
  {},
);

// Search_Help Articles
export const SearchHelpTabEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '',
    object_detail: 'search_l1',
    action: 'selected',
    ui_object: 'tab',
    ui_object_detail: 'help_articles',
    ui_action: 'clicked',
    ui_access_point: 'global_search',
  },
  {},
);

// Search_ Features
export const SearchFeaturesTabEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '',
    object_detail: 'search_l1',
    action: 'selected',
    ui_object: 'tab',
    ui_object_detail: 'features',
    ui_action: 'clicked',
    ui_access_point: 'global_search',
  },
  {},
);

// Search_Results
export const SearchResultsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '',
    object_detail: 'search_l1',
    action: 'selected',
    ui_object: 'link',
    ui_object_detail: 'search_result',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  // Mutable params here:
  {
    has_search_results: true,
    search_input: '',
    search_tab: '',
    search_title: '',
    search_position: 0,
  },
);
