import { createEcsEvent } from '../../configuration/createEvent';

// These properties are constant throughout all JourneyMapStepEvents events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  screen: 'customer-journey/builder',
  object: 'automations',
  object_detail: 'journey_map_step',
};

export const ClickSendEmailReportIconEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ClickEmailCampaignReportIconEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'campaign_report',
    ui_action: 'clicked',
    ui_access_point: 'journey_map',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const ClickSaveStartingPointEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ClickSaveStartingPointEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_starting_point',
    ui_action: 'clicked',
    ui_access_point: 'trigger_selection_modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    sms_keyword_used: '',
    has_subscribe_from_keyword: true,
    has_sms_audience_enabled: true,
    trigger_data: null,
    is_wait_for_trigger: false,
    trigger_type: null,
    step_type: 'action',
    step_id: 0,
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);

export const SaveStepEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
  },
  {
    journey_id: 0,
    step_id: 0,
    step_type: '',
    step_name: '',
    step_data: '',
    is_wait_for_trigger: false,
    journey_created_from_genai: false,
    ui_object_detail: '',
    ui_access_point: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const SelectStepEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
  },
  {
    journey_id: 0,
    step_type: '',
    step_name: '',
    is_wait_for_trigger: false,
    journey_created_from_genai: false,
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const DeleteStepEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
  },
  {
    journey_id: 0,
    step_id: 0,
    step_type: '',
    step_name: '',
    step_data: '',
    is_wait_for_trigger: false,
    journey_created_from_genai: false,
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    ai_email_type: '',
  },
);

export const SelectStepOnMapEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
  },
  {
    journey_id: 0,
    step_id: 0,
    step_type: '',
    step_name: '',
    step_data: '',
    is_wait_for_trigger: false,
    journey_created_from_genai: false,
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    ui_action: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    ai_email_type: '',
  },
);
