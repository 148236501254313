import { createEcsEvent } from '../../configuration/createEvent';

// ECS Events Source: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=632572981&range=A424

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'customer_relationship_management',
  scope_area: 'appointment',
  screen: 'crm/appointments/settings',
  object: 'audience',
  object_detail: 'settings',
};

export const AppointmentsSettingsViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    gmail_connections_count: -1,
  },
);

export const AppointmentsSettingsGmailConnectClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'gmail_connect',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {},
);

export const AppointmentsSettingsSetAvailabilityClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'availability',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {},
);

export const AppointmentsSettingsGmailDisconnectClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'gmail_disconnect',
    ui_action: 'clicked',
    ui_access_point: 'slat',
  },
  {},
);
