import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
  screen: 'customer-journey/builder',
  object: 'automations',
  object_detail: 'pbj_review_flow',
};

export const ChangedAudienceEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'audience',
    ui_action: 'selected',
    ui_access_point: 'header',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);

export const ChangedStoreEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'store',
    ui_action: 'selected',
    ui_access_point: 'header',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const ContinueToJourneyBuilderEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_journey_builder',
    ui_action: 'clicked',
    ui_access_point: 'right',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const GoBackEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'go_back',
    ui_action: 'clicked',
    ui_access_point: 'right',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const EditEmailInBuilderEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_email_in_builder',
    ui_action: 'clicked',
    ui_access_point: 'footer',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);

export const ContinueEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'footer',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const TurnOnEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ExploreAiJourneysEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'turn_on',
    ui_action: 'clicked',
    ui_access_point: 'left',
    journey_created_from_genai: true,
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    starting_point: [],
    cjb_workflow_status: '',
    steps_taken_in_the_cjb: 0,
    store_id: 0,
    has_code_expiration: false,
  },
);
