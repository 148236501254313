import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'audience_organization',
  scope_area: 'contacts',
  screen: 'audience/tags',
  object: 'audience',
};

/*
 * This event should fire when the tags page is viewed.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:56
 */

export const AudienceOrganizationViewTagsPageEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    previous_page_url: '',
  },
);

/*
 * This event should fire when a user clicks "create tag" in the header
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:57
 */

export const AudienceOrganizationClickCreateTagInHeaderEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_tag',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

/*
 * This event should fire when a user clicks "create" in the tag creation modal
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:58
 */

export const AudienceOrganizationClickCreateInTagCreationModalEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create',
    ui_action: 'clicked',
    ui_access_point: 'create_tag_modal',
  },
  {},
);

/*
 * This event should fire when a user clicks "bulk tag contcts" in the header
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:59
 */

export const AudienceOrganizationClickBulkTagContactsInHeaderEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'bulk_tag_contacts',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

/*
 * This event should fire when a user clicks the box that auto selects all tags
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:65
 */

export const AudienceOrganizationSelectAllTagsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'box',
    ui_object_detail: 'select_all_tags',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

/*
 * This event should fire when a user changes their tag sort by method.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:66
 */

export const AudienceOrganizationChangeSortByMethodEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'sort_by',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    sort_by_method: '',
  },
);

/*
 * This event should fire when a user clicks the arrow that reverses the sort by order
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:67
 */

export const AudienceOrganizationSortByOrderSwitcherArrowEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'sort_order_arrow',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

/*
 * This event should fire when a user clicks an individual tag
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:68
 */

export const AudienceOrganizationSelectAnIndividualTagEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'box',
    ui_object_detail: 'select_tag',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    audience_tag_name: '',
  },
);

/*
 * This event should fire when the user clicks the "delete tags" CTA. Confirmation modal should pop-up
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:69
 */

export const AudienceOrganizationDeleteSelectedTagsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    tag_count: '',
  },
);

/*
 * This event should fire when a user clicks to rename a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:70
 */

export const AudienceOrganizationClickRenameInDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'rename',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when a user clicks "save" in the tag renaming modal
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:71
 */

export const AudienceOrganizationClickSaveInRenameModalEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'rename_tag_modal',
  },
  {
    audience_tag_name: '',
  },
);

/*
 * This event should fire when a user clicks to delete a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:72
 */

export const AudienceOrganizationClickDeleteInTagDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when a user clicks "delete" in the delete confirmation modal
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:73
 */

export const AudienceOrganizationConfirmDeleteOfSelectedTagsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'delete_tag_modal',
  },
  {
    tag_count: '',
  },
);

/*
 * This event should fire when a user clicks to export a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:74
 */

export const AudienceOrganizationClickExportAsCsvInDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'export_as_CSV',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when a user clicks send a regular campaign to a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:75
 */

export const AudienceOrganizationTagsClickSendRegularCampaignInDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'send_regular_campaign',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when a user clicks send a multivariate testing campaign to a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:76
 */

export const AudienceOrganizationTagsClickSendMultivariateTestingCampaignInDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'send_multivariate_campaign',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when a user clicks send a plain-text campaign campaign to a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:77
 */

export const AudienceOrganizationTagsClickSendPlainTextCampaignInDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'send_plain_text_campaign',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when a user clicks send an RSS campaign to a tag in the "view" dropdown.
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:78
 */

export const AudienceOrganizationTagsClickSendRssCampaignInDropdownEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'send_rss_campaign',
    ui_action: 'clicked',
    ui_access_point: 'dropdown',
  },
  {},
);

/*
 * This event should fire when the user clicks to "view" the contacts that have that tag on the contact table
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:79
 */

export const AudienceOrganizationClickToViewTagOnTheContactTableEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'view',
    ui_action: 'clicked',
    ui_access_point: 'button',
  },
  {
    audience_tag_name: '',
  },
);

/*
 * This event should fire when a user changes the number of ctags viewable on the page using the dropdown at the bottom of the page
 * ECS Standards Mailchimp Event Collection Standards (ECS) v1 - Audience Organization.csv:80
 */

export const AudienceOrganizationChangeNumberOfContactsDisplayedEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'audience_organization',
    scope_area: 'contacts',
    screen: 'audience/tags',
    object: 'audience',
    object_detail: 'tags_index',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'number_of_tags_displayed',
    ui_action: 'clicked',
    ui_access_point: 'bottom',
  },
  {
    num_of_record_shown: '',
  },
);

/*
 * This event should fire when the bulk tagging modal is opened
 */

export const AorgBulkTaggingModalViewed = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

/*
 * Fires when a user clicks the all contacts page link
 */

export const AorgBulkTaggingModalAllContactsLinkClicked = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'link',
    ui_object_detail: 'all_contacts',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

/*
 * Fires when a user clicks the learn more about CSV info link
 */

export const AorgBulkTaggingModalCSVMoreInfoClicked = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'link',
    ui_object_detail: 'csv_or_tab_delimited_text_files',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

/*
 * Fires when a user clicks submits the bulk tagging modal form
 */

export const AorgBulkTaggingModalApplyTagsClicked = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'button',
    ui_object_detail: 'apply_tag',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    bulk_tag_modal_saved_successfully: '',
    bulk_tag_modal_error_encountered: '',
    bulk_tag_modal_number_of_tags: '',
    bulk_tag_modal_tag_naming_methods: '',
    bulk_tag_modal_total_num_emails: '',
    bulk_tag_modal_num_tagged_emails: '',
    bulk_tag_modal_email_input_method: '',
    bulk_tag_modal_tagging_action: '',
  },
);

/*
 * Fires when a user clicks the X to close the bulk tagging modal
 */

export const AorgBulkTaggingModalXClicked = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

/*
 * Fires when a user clicks cancel to close the bulk tagging modal
 */

export const AorgBulkTaggingModalCancelClicked = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

/*
 * Fires when a user clicks view contacts tagged form the success feedback block
 */

export const AorgBulkTaggingModalViewTaggedContactsClicked = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'link',
    ui_object_detail: 'view_contacts_tagged',
    ui_action: 'clicked',
    ui_access_point: 'banner',
  },
  {},
);

/*
 * Fires when a user clicks view contacts tagged form the pending feedback block
 */

export const AorgBulkTaggingModalPendingTaggingViewed = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    object_detail: 'bulk_tag_contacts',
    action: 'enaged',
    ui_object: 'link',
    ui_object_detail: 'pending_message',
    ui_action: 'banner',
    ui_access_point: 'toast',
  },
  {},
);
