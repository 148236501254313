import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

// ECS Event Defealts for Email Editor Save Error Modal

export const CampaignSaveErrorModalEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'changes_not_saved_modal',
    screen: '/email/editor',
    scope_area: 'email_editor',
    ui_access_point: 'modal',
  },
  {
    // Defined at component level
    action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',

    // If using useEmailECSData hook, these will be provided for you
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    template_name: '',
    platform_type: '',
  },
);

export const TemplateSaveErrorModalEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'changes_not_saved_modal',
    screen: '/email/templates/editor',
    scope_area: 'email_editor',
    ui_access_point: 'modal',
  },
  {
    // Defined at component level
    action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',

    // If using useEmailECSData hook, these will be provided for you
    user_template_id: 0,
    template_id: 0,
    template_name: '',
    platform_type: '',
  },
);
