import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  scope_area: 'templates',
  object: 'campaign',
};

export const ClickTemplateThumbnailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/email-editor',
    object_detail: 'mailchimp_templates',
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'email_template',
    ui_action: 'clicked',
    ui_access_point: 'center',
    template_name: '',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    template_name: '',
  },
);
