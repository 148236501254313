import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'campaign_manager',
};

export const EditTouchpointEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the EditTouchpointEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'campaign',
    screen: '/campaign-manager',
    object_detail: 'edit_campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'touchpoint_details_modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    touchpoint_type: '',
  },
);

export const ReplicateTouchpointEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the EditTouchpointEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'campaign',
    screen: '/campaign-manager',
    object_detail: 'bulk_campaign',
    action: 'created',
    ui_object: 'button',
    ui_object_detail: 'replicate',
    ui_action: 'clicked',
    ui_access_point: 'touchpoint_details_modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    touchpoint_type: '',
  },
);

export const DesignYourEmailClickEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the EditTouchpointEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'campaign',
    screen: '/campaign-manager',
    object_detail: 'bulk_campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'design_your_email',
    ui_action: 'clicked',
    ui_access_point: 'touchpoint_details_modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);
