import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  event_source: 'front_end:js_react',
};

export const newUpgradeConfirmViewEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'started',
    object: 'checkout',
    object_detail: 'upgrade_confirmation',
    ui_object_detail: '',
    ui_action: '',
    ui_object: '',
    ui_access_point: '',
  },
  {
    screen: '',
  },
);

export const newUpgradeConfirmBillingHistoryClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'checkout',
    object_detail: 'upgrade_confirmation',
    ui_object_detail: 'billing_history',
    ui_action: 'clicked',
    ui_object: 'hyperlink',
    ui_access_point: 'upgrade_confirmation_bottom_sheet',
  },
  {
    screen: '',
  },
);
export const newUpgradeSendInviteClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'started',
    object: 'account',
    object_detail: 'user_role_invite',
    ui_object_detail: 'send_invite',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_access_point: 'upgrade_confirmation_bottom_sheet',
  },
  {
    screen: '',
  },
);
export const newUpgradeAccessLevelClickEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'engaged',
    object: 'account',
    object_detail: 'user_role_invite',
    ui_object_detail: 'access_level',
    ui_action: 'clicked',
    ui_object: 'dropdown',
    ui_access_point: 'upgrade_confirmation_bottom_sheet',
  },
  {
    screen: '',
  },
);
