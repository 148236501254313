import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'internationalization',
  scope_area: 'account_setup',
  screen: 'account/details',
  object: 'account',
  object_detail: 'date_format_change',
  action: 'engaged',
  ui_action: 'clicked',
  ui_access_point: 'center',
};

export const I18nSelectDateFormatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown',
    ui_object_detail: 'selected_date_format',
  },
  {
    selected_date_format: '',
  },
);

export const I18nSaveDateFormatEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'save',
  },
  {
    saved_date_format: '',
  },
);
