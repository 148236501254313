import { createEcsEvent } from '../../configuration/createEvent';

/*
This file has ECS events for page-specific search experiences. Note that events
for Search in the Global Nav are defined in Events/GlobalNav/Search.js

Source of truth: Wayfinding tab on Mailchimp ECS v1 sheet:
https://docs.google.com/spreadsheets/d/1rqiSqIBT1nW5iCZhybVbx7YKsEh-B5fKHtPDq8dhgYY/edit#gid=652732638
*/

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'page_navigation',
  object: 'navigation',
};

// All_contacts_search
// Event for clicking Magnifying Glass icon on All Contacts page
export const AllContactsSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/lists/members/',
    object_detail: 'all_contacts',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: 'all_contacts_search',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
  },
  // Mutable params here:
  {},
);

// All Contacts Search: Click Search Bar
// Event for clicking the All Contacts page search bar directly above Contacts table
export const AllContactsTableSearchBarClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'all_contacts',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: 'all_contacts_search',
    ui_action: 'clicked',
    ui_access_point: 'all_contacts_table_search_bar',
  },
  // Mutable params here:
  {
    screen: '',
  },
);

// All Contacts Search: Submit Query
// Event for submitting a search on All Contacts page via search bar directly above Contacts table
export const AllContactsTableSearchBarSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'all_contacts',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: 'all_contacts_search',
    ui_action: 'searched',
    ui_access_point: 'all_contacts_table_search_bar',
  },
  // Mutable params here:
  {
    screen: '',
    search_input: '',
  },
);

//
export const AllCampaignsSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/campaigns',
    object_detail: 'all_campaigns',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: 'all_campaigns_search',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
  },
  // Mutable params here:
  {},
);

export const IntegrationsDiscoverSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/account/connected-sites/discover/',
    object_detail: 'integrations_discover_search',
    action: 'selected',
    ui_object: 'sub_menu',
    ui_object_detail: 'integrations_discover_search',
    ui_action: 'clicked',
    ui_access_point: 'integrations_discover',
  },
  // Mutable params here:
  {},
);

export const SignupFormSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/audience/forms/dashboard/',
    object_detail: 'sign_up_form',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'magnifying_glass',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  // Mutable params here:
  {},
);

export const TagsPageSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/audience/tags/',
    object_detail: 'tags',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'magnifying_glass',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  // Mutable params here:
  {},
);

export const SegmentsPageSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/audience/segments/',
    object_detail: 'segment',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'magnifying_glass',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  // Mutable params here:
  {},
);

export const SubscriberPreferencesPageSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/lists/preferences-center/',
    object_detail: 'subscriber_preferences',
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'magnifying_glass',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  // Mutable params here:
  {},
);

export const ReportsPageSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/reports',
    object_detail: 'reports',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
  },
  // Mutable params here:
  {},
);

export const MyFilesSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/content',
    object_detail: 'my_files',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
  },
  // Mutable params here:
  {},
);

export const SavedTemplatesSearchEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/templates/',
    object_detail: 'saved_templates',
    action: 'selected',
    ui_object: 'field',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
  },
  // Mutable params here:
  {},
);
