import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  screen: '/account/close-survey',
  event_source: 'front_end:js_dojo',
};

export const CloseSurveyPageViewedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'delete_account',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const CloseSurveyPermanentlyDeleteAccountButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'permanently_delete_account',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const CloseSurveyConnectWithUsButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_with_us',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const CloseSurveyViewDeleteConfirmationModalEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'delete_account',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const CloseSurveyDeleteYourAccountButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete_your_account',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);

export const CloseSurveyCancelButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'delete_account',
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {},
);
