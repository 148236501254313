import {
  ECS_EVENT_PURPOSE,
  ECS_EVENT_OBJECT_AUDIENCE,
  ECS_EVENT_ACTION_PROPERTY_ENGAGED,
  ECS_EVENT_OBJECT_RECOMMENDATION,
} from '@mc/constants/automation';

export const DEFAULT_FORM_PROPERTIES = {
  purpose: ECS_EVENT_PURPOSE,
  initiative_name: 'marketing_channel_management',
  scope_area: 'lead_generation',
  object: ECS_EVENT_OBJECT_AUDIENCE,
  action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
};

export const VHP_FORM_PROPERTIES = {
  purpose: ECS_EVENT_PURPOSE,
  initiative_name: 'ftu_guideme',
  scope_area: 'homepage',
  screen: '/',
  object: ECS_EVENT_OBJECT_RECOMMENDATION,
};

export const CONFIGURABLE_FORM_PROPERTIES_WITH_FORM_ID = {
  unique_form_id: '',
};
export const CONFIGURABLE_FORM_PROPERTIES_WITH_FORM_TYPE = {
  form_type: '',
};
export const CONFIGURABLE_FORM_PROPERTIES_WITH_LIST_ID = {
  list_id: '',
};

export const SCOPE_AREA_LANDING_PAGES = 'landing_pages';

export const SCREEN_AUDIENCE_FORMS = '/audience/forms';
export const SCREEN_ALL_FORMS = '/audience/forms/all-forms';
export const SCREEN_CREATE_FORMS = '/audience/forms/create';
export const SCREEN_REPLICATE_FORM = '/audience/forms/replicate';
export const SCREEN_EMBEDDED_EDITOR = '/audience/forms/embedded-form/editor';
export const SCREEN_CONTACT_EDITOR = '/audience/inbox/contact-form-editor';
export const SCREEN_POPUP_EDITOR = '/signup_forms/popup-forms/editor';

export const OBJECT_DETAIL_ALL_FORMS = 'all_forms';
export const OBJECT_DETAIL_CREATE_FORMS = 'forms_setup';
export const OBJECT_DETAIL_FORMS_OVERVIEW = 'forms_overview';
export const OBJECT_DETAIL_HOSTED = 'hosted_form';
export const OBJECT_DETAIL_POPUP = 'popup_form';
export const OBJECT_DETAIL_EMBEDDED = 'embedded_form';
export const OBJECT_DETAIL_CONTACT = 'contact_form';
export const OBJECT_DETAIL_LANDING_PAGE = 'signup_landing_page';
export const OBJECT_DETAIL_TTT = 'sms_tap_to_text';
export const OBJECT_DETAIL_INTEGRATIONS = 'form_integrations';
export const OBJECT_DETAIL_REPLICATE = 'replicate_forms';
export const OBJECT_DETAIL_PAYOFF_FOCUSED_ONBOARDING =
  'payoff_focused_onboarding';

export const UI_OBJECT_DETAIL_FINISH_LATER = 'finish_later';
export const UI_OBJECT_DETAIL_CONTINUE = 'continue';
export const UI_OBJECT_DETAIL_PAYOFF_FOCUSED = 'payoff_focused_onboarding';

export const UI_ACCESS_POINT_FORM_TYPE_FILTER_DROPDOWN =
  'form_type_filter_dropdown';
export const UI_ACCESS_POINT_ALL_FORMS_SECONDARY_HEADER =
  'all_forms_secondary_header';
export const UI_ACCESS_POINT_TOP_LEFT_HEADER = 'top_left_header';
export const UI_ACCESS_POINT_DROPDOWN_MENU = 'dropdown_menu';
export const UI_ACCESS_POINT_OVERVIEW_HEADER = 'overview_header';
export const UI_ACCESS_POINT_FORMS_HERO = 'forms_hero';
export const UI_ACCESS_POINT_RECENT_FORMS_HEADER = 'recent_forms_header';
export const UI_ACCESS_POINT_PAGE_FOOTER = 'page_footer';
export const UI_ACCESS_POINT_FORM_SLAT = 'form_slat';
export const UI_ACCESS_POINT_RECENT_FORMS_SLAT = 'recent_forms_slat';
export const UI_ACCESS_POINT_DELETE_MODAL = 'delete_modal';
export const UI_ACCESS_POINT_RENAME_MODAL = 'rename_modal';
export const UI_ACCESS_POINT_FORMS_INTEGRATIONS_CARD = 'form_integrations_card';

export const UI_ACCESS_POINT_ALL_FORMS_HEADER = 'all_forms_header';
export const UI_ACCESS_POINT_CENTER = 'center';
export const UI_ACCESS_POINT_HEADER = 'header';
export const UI_ACCESS_POINT_OVERLAY = 'overlay';
export const UI_ACCESS_POINT_NAVIGATION = 'navigation';
export const UI_ACCESS_POINT_MODAL = 'modal';
export const UI_ACCESS_POINT_POPUP_BREADCRUMBS = 'popup_breadcrumbs';
export const UI_ACCESS_POINT_POPUP_CONFIG_PANEL = 'popup_config_panel';
export const UI_ACCESS_POINT_SMS_MODAL = 'sms_modal';
export const UI_ACCESS_POINT_REPLICATE_HEADER = 'replicate_header';
