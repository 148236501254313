import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  object: 'automations',
  screen: 'customer-journey/my-journeys',
  action: 'engaged',
  ui_object: '',
  ui_object_detail: '',
  ui_action: '',
  ui_access_point: '',
};

export const AllJourneysSearch = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'all_customer_journeys_search',
  },
  {
    is_result_found_cjb_search: false,
  },
);

export const ClassicAutomationsSearch = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'classic_automation_search',
  },
  {
    is_result_found_cjb_search: false,
  },
);
