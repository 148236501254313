import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the Product Preference step"
 */
export const ProductPreferenceViewedEvent = createEcsEvent(
  /**
   * This param contains the ProductPreferenceViewedEvent the event will contain.
   * These properties can never be changed inside of the ProductPreferenceViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'product_preference',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "Answer 'What do you want to explore first?' and then click either 'Next'  and successfully passes validation. Only seen by people with specific answers to Business Objective question. Not required"
 */
export const ProductPreferenceNextEvent = createEcsEvent(
  /**
   * This param contains the ProductPreferenceNextEvent the event will contain.
   * These properties can never be changed inside of the ProductPreferenceNextEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'product_preference',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    product_preference: '',
    entry_point: '',
  },
);

/**
 * "Answer 'What do you want to explore first?' and then click 'Skip' CTA, and successfully passes validation. Only seen by people with specific answers to Business Objective question. Not required"
 */
export const ProductPreferenceEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the ProductPreferenceEngagedSkipEvent the event will contain.
   * These properties can never be changed inside of the ProductPreferenceEngagedSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'product_preference',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the Product Preference page"
 */
export const ProductPreferenceEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the ProductPreferenceEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the ProductPreferenceEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'product_preference',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const ProductPreferenceFailedEvent = createEcsEvent(
  /**
   * This param contains the ProductPreferenceFailedEvent the event will contain.
   * These properties can never be changed inside of the ProductPreferenceFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'product_preference',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
