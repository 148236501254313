import { createEcsEvent } from '../../../configuration/createEvent';

const sharedProps = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/marketing-dashboard',
  object: 'reporting',
  object_detail: 'marketing_dashboard',
};

export const MarketingDashboardConversionsChangeChannel = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'conversion_channel',
    ui_action: 'selected',
    ui_access_point: 'channel_drop_down',
  },
  {
    conversion_channel: '',
  },
);

export const MarketingDashboardConversionsChangeTab = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: 'conversions_tab',
    ui_action: 'clicked',
    ui_access_point: 'conversions_tab',
  },
  {},
);

export const MarketingDashboardDeliveryChangeMetric = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'delivery_metric',
    ui_action: 'selected',
    ui_access_point: 'delivery_drop_down',
  },
  {
    delivery_metric: '',
  },
);

export const MarketingDashboardDeliveryChangeTab = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: 'delivery_tab',
    ui_action: 'clicked',
    ui_access_point: 'delivery_tab',
  },
  {},
);

export const MarketingDashboardPerformanceOverTimeMetricsChange = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'performance_metric',
    ui_action: 'selected',
    ui_access_point: 'performance_drop_down',
  },
  {
    performance_metric: '',
  },
);

export const MarketingDashboardMonitorPerformanceCustomization = createEcsEvent(
  {
    ...sharedProps,
    action: 'customized',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'performance_modal',
  },
  {
    new_performance_metrics: '',
  },
);

export const MarketingDashboardTabClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'tab',
    ui_action: 'selected',
    ui_access_point: 'tab_navigation',
  },
  {
    ui_object_detail: '',
  },
);

export const MarketingDashboardIndividualPerformanceMetricChange = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'individual_message_performance_metric',
    ui_action: 'selected',
    ui_access_point: 'individual_performance_drop_down',
  },
  {
    performance_metric: '',
  },
);

// Marketing Dashboard export campaigns
export const MarketingDashboardExportMessageReport = createEcsEvent(
  {
    ...sharedProps,
    action: 'downloaded',
    ui_object: 'button',
    ui_object_detail: 'export',
    ui_action: 'clicked',
    ui_access_point: 'campaigns_table',
  },
  {},
);

// Marketing Dashboard: clicked CTA to Custom Reports
export const MarketingDashboardCustomReportsCTA = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'custom_reports_link_out',
    ui_action: 'clicked',
    ui_access_point: 'campaigns_table',
  },
  {},
);

export const MarketingDashboardComparisonDropDownSelect = createEcsEvent(
  {
    ...sharedProps,
    action: 'customized',
    ui_object: 'drop_down',
    ui_action: 'clicked',
    ui_access_point: 'comparison_filter',
  },
  {
    ui_object_detail: '',
    new_performance_comparison: '',
  },
);

export const MarketingDashboardTableColumnSortClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'table',
    ui_object_detail: 'column_header',
    ui_action: 'sorted',
    ui_access_point: 'campaigns_table',
  },
  {
    column_sorted: '',
  },
);

export const MarketingDashboardAudienceDropDownSelect = createEcsEvent(
  {
    ...sharedProps,
    action: 'customized',
    ui_object: 'drop_down',
    ui_object_detail: '',
    ui_action: 'customized',
    ui_access_point: 'audience_filter',
  },
  {},
);

export const MarketingDashboardDateRangeDropDownSelect = createEcsEvent(
  {
    ...sharedProps,
    action: 'customized',
    ui_object: 'datepicker',
    ui_object_detail: 'date_range_popover',
    ui_action: 'updated',
    ui_access_point: 'date_range_filter',
  },
  {
    date_range: '',
  },
);

export const MarketingDashboardMessageFilterClick = createEcsEvent(
  {
    ...sharedProps,
    action: 'customized',
    ui_object: 'filter',
    ui_object_detail: 'name_contains',
    ui_action: 'clicked',
    ui_access_point: 'message_filter',
  },
  {},
);

export const MarketingDashboardPageView = createEcsEvent(
  {
    ...sharedProps,
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    plan_type: '',
    referrer: '',
    ecomm_metrics: '',
  },
);

export const MarketingDashboardMppExcluded = createEcsEvent(
  {
    ...sharedProps,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'apple_mpp',
    ui_action: 'clicked',
    ui_access_point: 'feedback_block',
  },
  {
    is_applempp_excluded: '',
  },
);
