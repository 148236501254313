import { createEcsEvent } from '../../configuration/createEvent';
import {
  DEFAULT_EMAIL_EVENT_PROPERTIES,
  DEFAULT_SELECT_EDITOR_MODAL_PROPERTIES,
  DEFAULT_PON_EVENT_PROPERTIES,
  DEFAULT_LIVE_EXPERT_EVENT_PROPERTIES,
  MUTABLE_PON_EVENT_PROPERTIES,
  MUTABLE_LIVE_EXPERT_EVENT_PROPERTIES,
} from './constants';

export const TemplateTrackViewSetDefaultEditor = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'default_editor_type',
    screen: 'email/templates/editor',
    scope_area: 'editor_selection',
    ui_object: 'button',
    action: 'viewed',
    ui_object_detail: '',
    ui_action: '',
  },
  {
    user_template_id: 0,
    platform_type: '',
    template_id: 0,
    template_name: '',
  },
);

export const CampaignTrackViewSetDefaultEditor = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'default_editor_type',
    screen: 'email/editor',
    scope_area: 'editor_selection',
    ui_object: 'button',
    action: 'viewed',
    ui_object_detail: '',
    ui_action: '',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
    template_id: 0,
    template_name: '',
  },
);

export const CampaignTrackSaveAndExitClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_and_exit',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    template_name: '',
    platform_type: '',
    campaign_type: '',
  },
);
export const TemplateTrackSaveAndExitClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_and_exit',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackExitToDashboardClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'image',
    ui_object_detail: 'freddie_head',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);
export const TemplateTrackExitToDashboardClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'image',
    ui_object_detail: 'freddie_head',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackSwitchToClassicEditorClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'switch_to_classic_editor',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);
export const TemplateTrackSwitchToClassicEditorClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'switch_to_classic_editor',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackSendTestEmailClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'send_test_email',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);
export const TemplateTrackSendTestEmailClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'send_test_email',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackSaveAsTemplateClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'save_as_template',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);

export const CampaignTrackChangeTemplateClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'change_template',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);

export const TemplateTrackChangeTemplateClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'dropdown_list',
    ui_object_detail: 'change_template',
    ui_action: 'clicked',
    ui_access_point: 'header',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackClassicBuilderModalContinueClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_classic_builder',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);

export const TemplateTrackClassicBuilderModalContinueClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_classic_builder',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackClassicBuilderModalCancelClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);

export const TemplateTrackClassicBuilderModalCancelClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const CampaignTrackClassicBuilderModalCloseClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nuni',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
    template_name: '',
  },
);

export const TemplateTrackClassicBuilderModalCloseClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'top_nav',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nuni',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    template_name: '',
  },
);

export const TrackCompleteSetDefaultEditorModal = createEcsEvent(
  {
    ...DEFAULT_SELECT_EDITOR_MODAL_PROPERTIES,
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'set_as_default_builder',
  },
  {
    screen: '',
    campaign_id: 0,
    editor_type: '',
  },
);

export const TrackIgnoreSetDefaultEditorModal = createEcsEvent(
  {
    ...DEFAULT_SELECT_EDITOR_MODAL_PROPERTIES,
    action: 'selected',
    ui_object: 'button',
    ui_object_detail: 'maybe_later',
  },
  {
    screen: '',
    campaign_id: 0,
    editor_type: '',
  },
);

export const TrackCloseSetDefaultEditor = createEcsEvent(
  {
    ...DEFAULT_SELECT_EDITOR_MODAL_PROPERTIES,
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'x',
  },
  {
    screen: '',
    campaign_id: 0,
    editor_type: '',
  },
);

export const TrackViewSetDefaultEditor = createEcsEvent(
  {
    ...DEFAULT_SELECT_EDITOR_MODAL_PROPERTIES,
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'set_as_default_builder',
  },
  {
    screen: '',
    campaign_id: 0,
    editor_type: '',
  },
);

export const NuniHeaderPONViewEvent = createEcsEvent(
  {
    ...DEFAULT_PON_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_action: 'loaded',
    ui_access_point: 'header',
  },
  {
    ...MUTABLE_PON_EVENT_PROPERTIES,
  },
);

export const NuniHeaderPONClickedEvent = createEcsEvent(
  {
    ...DEFAULT_PON_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    ...MUTABLE_PON_EVENT_PROPERTIES,
  },
);

export const NuniLiveExpertViewEvent = createEcsEvent(
  {
    ...DEFAULT_LIVE_EXPERT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_action: 'loaded',
    ui_access_point: 'header',
  },
  {
    ...MUTABLE_LIVE_EXPERT_EVENT_PROPERTIES,
  },
);

export const NuniLiveExpertClickEvent = createEcsEvent(
  {
    ...DEFAULT_LIVE_EXPERT_EVENT_PROPERTIES,
    action: 'selected',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    ...MUTABLE_LIVE_EXPERT_EVENT_PROPERTIES,
  },
);

export const TestSendClickedFromChecklistEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'bulk_campaigns',
    screen: '/campaigns/edit',
    object_detail: 'test_email',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_test',
    ui_action: 'clicked',
    ui_access_point: 'test_send_modal',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    is_test_send_successful: '',
    test_email_error_type: '',
  },
);

export const TestSendClickedFromNuniEditorEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'bulk_campaigns',
    object_detail: 'test_email',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_test',
    ui_action: 'clicked',
    ui_access_point: 'test_send_modal',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    is_test_send_successful: '',
    test_email_error_type: '',
  },
);

export const clickNeaCommentsTabEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'comments',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickSendingATestEmailLinkEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'sending_a_test_email',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickAddACommentButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_a_comment',
    ui_action: 'clicked',
    ui_access_point: 'side_panel_bottom',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickNotificationsButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'notifications',
    ui_action: 'clicked',
    ui_access_point: 'side_panel_bottom',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickAddCommentButtonInNewCommentEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'new_comment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_comment',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickCancelButtonInNewCommentEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'new_comment',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
  {},
);

export const clickExistingCommentEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'new_comments',
    action: 'engaged',
    ui_object: 'slat',
    ui_object_detail: 'existing_comment',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickCommentReplyTextAreaEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'new_comments',
    action: 'engaged',
    ui_object: 'input_box',
    ui_object_detail: 'comment_reply',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickCommentNotificationsButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'notifications',
    ui_action: 'clicked',
    ui_access_point: 'existing_comments_slat',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickMarkCompletedButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'mark_completed',
    ui_action: 'clicked',
    ui_access_point: 'existing_comments_slat',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickMarkOpenButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'mark_open',
    ui_action: 'clicked',
    ui_access_point: 'existing_comments_slat',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickDeleteCommentButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'existing_comments_slat',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickDeleteCommentModalButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'are_you_sure_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'are_you_sure_modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickCancelDeleteCommentModalButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'are_you_sure_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'are_you_sure_modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickXDeleteCommentModalButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'are_you_sure_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickContactCheckboxCommentNotificationsModalEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comments',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'checkbox',
    ui_action: 'clicked',
    ui_access_point: 'comment_notifications_modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickSaveCommentNotificationsModalButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comment_notifications_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickXCommentNotificationsModalButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comment_notifications_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickCancelCommentNotificationsModalButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'comment_notifications_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

export const clickSendTestButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'core_offerings',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/neapolitan',
    object: 'campaign',
    object_detail: 'send_a_test_modal',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_test',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    editor_type: 'nea',
  },
  {
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
  {},
);

export const PreviewButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'preview_content',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'preview',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);

// PXDEV-3064: Send Test Emails Quickly
export const SendTestButtonClickEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'test_email',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_test_email',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    platform_type: '',
    campaign_type: '',
  },
);
