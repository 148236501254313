import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  screen: 'account/close',
  event_source: 'front_end:js_react',
};

export const ClosePageViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const ClosePagePauseButtonClickedEvent = createEcsEvent(
  /**
   * This section contains the DefaultEventProperties the event will contain.
   * These properties can never be changed inside the event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'pause_my_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This section contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property on an object.
   */
  {
    experiment_variant: '',
  },
);

export const ClosePageDeleteButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'delete_my_account',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    experiment_variant: '',
  },
);

export const PauseModalViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_account',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'pause_account',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const PauseModalPauseButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'pause_my_plan',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const PauseModalChangePlanButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'change_plan_instead',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const PauseModalConnectSpecialistButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'pause_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_with_a_specialist',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const PauseModalNeverMindButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'never_mind',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {},
);

export const ClosePageUnpauseButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'unpause_my_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    experiment_variant: '',
  },
);

export const DeleteBottomSheetConsiderStepViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_consider',
    action: 'started',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const DeleteConsiderStepPausePlanLinkClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_consider',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'pause_your_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const DeleteConsiderStepTalkToSpecialistButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'close_account_consider',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_with_a_specialist',
    ui_action: 'clicked',
    ui_access_point: 'right',
  },
  {},
);

export const DeleteConsiderStepExportDataButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_consider',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'export_your_data',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const DeleteConsiderStepContinueButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_consider',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_to_delete',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {},
);

export const DeleteBottomSheetConfirmStepViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const DeleteConfirmPausePlanInsteadButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'pause_plan_instead',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {},
);

export const DeleteConfirmCancelButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {},
);

export const DeleteConfirmPermanentlyDeleteButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'permanently_delete',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {},
);

export const DeleteConfirmStepTalkToSpecialistButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'connect_with_a_specialist',
    ui_action: 'clicked',
    ui_access_point: 'right',
  },
  {},
);

export const DeleteConfirmStepExportDataButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'export_your_data',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const DeleteConfirmGetPromotionButtonClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'get_promotion',
    ui_action: 'clicked',
    ui_access_point: 'right',
  },
  {
    account_retention_promotion: '',
  },
);

export const AccountCloseConfirmationPageViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'closed_account_confirmation',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {},
);

export const ClosePageDatawellsViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'datawell',
    ui_object_detail: 'account_activity',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const ClosePageDatawellsCampaignsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'campaigns',
    ui_action: 'clicked',
    ui_access_point: 'account_activity_datawell',
  },
  {},
);

export const ClosePageDatawellsContactsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'contacts',
    ui_action: 'clicked',
    ui_access_point: 'account_activity_datawell',
  },
  {},
);

export const ClosePageDatawellsReportsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'reports',
    ui_action: 'clicked',
    ui_access_point: 'account_activity_datawell',
  },
  {},
);

export const ClosePageDatawellsTemplatesClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'templates',
    ui_action: 'clicked',
    ui_access_point: 'account_activity_datawell',
  },
  {},
);

export const ClosePageDatawellsAutomationsClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'automations',
    ui_action: 'clicked',
    ui_access_point: 'account_activity_datawell',
  },
  {},
);

export const ClosePageRecommendedActionCardViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'recommended_action',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  { recommended_action: '' },
);

export const ClosePageRecommendedActionCardClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'recommended_action',
    ui_action: 'loaded',
    ui_access_point: 'recommended_action_card',
  },
  { recommended_action: '' },
);

export const ClosePageUserViewsACBThresholdPopUp = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'overages_adjust_plan',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'adjust_plan',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const ClosePageKeepCurrentContactPlan = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'overages_adjust_plan',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'keep_current_contact_plan',
    ui_action: 'clicked',
    ui_access_point: 'adjust_plan_modal',
  },
  {},
);

export const ClosePageAdjustPlanCTAInACB = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'overages_adjust_plan',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'adjust_plan',
    ui_action: 'clicked',
    ui_access_point: 'adjust_plan_modal',
  },
  {},
);

export const ClosePageEcommCloseActionView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'ecom_action_data',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const ClosePageEcommCloseActionClickCTA = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_a_journey',
    ui_action: 'clicked',
    ui_access_point: 'ecom_action_data_card',
  },
  {},
);

export const ClosePageEcommCloseActionViewTotalRevenue = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'hyperlink',
    ui_object_detail: 'total_revenue',
    ui_action: 'loaded',
    ui_access_point: 'ecom_action_data_card',
  },
  {},
);

export const ClosePageEcommCloseActionClickTotalRevenue = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'total_revenue',
    ui_action: 'clicked',
    ui_access_point: 'ecom_action_data_card',
  },
  {},
);

export const ClosePageEcommCloseActionViewEmailSent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'hyperlink',
    ui_object_detail: 'emails_sent',
    ui_action: 'loaded',
    ui_access_point: 'ecom_action_data_card',
  },
  {},
);

export const ClosePageEcommCloseActionClickEmailSent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'emails_sent',
    ui_action: 'clicked',
    ui_access_point: 'ecom_action_data_card',
  },
  {},
);

export const ClosePageEcommCloseActionViewDataWellsEcomm = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'datawell',
    ui_object_detail: 'ecom_data_well',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    revenue_data_shown: '',
    orders_data_shown: '',
    open_rate_data_shown: '',
  },
);

export const ClosePageEcommCloseActionClickDwEcommRevenue = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'revenue',
    ui_action: 'clicked',
    ui_access_point: 'ecom_datawell',
  },
  {},
);

export const ClosePageEcommCloseActionClickDwEcommOrders = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'orders',
    ui_action: 'clicked',
    ui_access_point: 'ecom_datawell',
  },
  {},
);

export const ClosePageEcommCloseActionClickDwEcommOpenRate = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'open_rate',
    ui_action: 'clicked',
    ui_access_point: 'ecom_datawell',
  },
  {},
);

export const ClosePageHVCLowSendSkuOfferPageView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'low_send_sku_offer',
    action: 'viewed',
    ui_object: 'banner',
    ui_object_detail: 'low_send_sku_offer',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    low_send_offer_copy: '',
  },
);

export const ClosePageHVCLowSendSkuOfferClickSendEmail = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'low_send_sku_offer',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'schedule_a_call',
    ui_action: 'clicked',
    ui_access_point: 'low_send_sku_offer_banner',
  },
  {
    low_send_offer_copy: '',
  },
);

export const BrowseMorePowerfulPlansClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'browse_more_powerful_plans',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const ExploreMorePowerfulPlansClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'explore_more_powerful_plans',
    ui_action: 'clicked',
    ui_access_point: 'right',
  },
  {},
);

export const UpgradePathChurnPageViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'viewed',
    ui_object: 'card',
    ui_object_detail: 'upgrade_path',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    account_upgrade_path_shown: '',
    account_data_counter_shown: '',
  },
);

export const UpgradePathChurnPageUpgradePlanEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'upgrade_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    account_data_counter_shown: '',
  },
);

export const UpgradePathChurnPageAdjustPlanEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'pause_delete_account',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'adjust_plan',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    account_data_counter_shown: '',
  },
);

export const OneDollarPromoDowngradeToFreeViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'downgrade_to_free_promo',
    action: 'viewed',
    ui_object: 'overlay',
    ui_object_detail: '1mo_for_6mo_offer',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {},
);

export const OneDollarPromoOnAccountCloseViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'close_account_confirm',
    action: 'viewed',
    ui_object: 'button',
    ui_object_detail: 'get_offer_now',
    ui_action: 'loaded',
    ui_access_point: 'right',
  },
  {},
);
