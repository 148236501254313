import { createEcsEvent } from '../../configuration/createEvent';
import {
  DEFAULT_EMAIL_EVENT_PROPERTIES,
  DEFAULT_PON_EVENT_PROPERTIES,
  MUTABLE_PON_EVENT_PROPERTIES,
} from './constants';

export const CampaignTrackAddBlockEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'add_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'dragged',
    ui_access_point: 'side_panel',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    platform_type: '',
    ui_object_detail: '',
  },
);

export const TemplateTrackAddBlockEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'add_content',
    action: 'customized',
    ui_object: 'button',
    ui_action: 'dragged',
    ui_access_point: 'side_panel',
  },
  {
    user_template_id: 0,
    template_id: 0,
    platform_type: '',
    ui_object_detail: '',
  },
);

export const NuniColumnPONViewEvent = createEcsEvent(
  {
    ...DEFAULT_PON_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_action: 'loaded',
    ui_access_point: 'side_panel',
  },
  {
    ...MUTABLE_PON_EVENT_PROPERTIES,
  },
);

export const NuniColumnPONClickedEvent = createEcsEvent(
  {
    ...DEFAULT_PON_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    ...MUTABLE_PON_EVENT_PROPERTIES,
  },
);

export const ClickedSetLogoEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'logo_content',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: 'email/editor',
    ui_object_detail: 'set_logo',
  },
);
