import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the InviteUser step"
 */
export const InviteUserViewedEvent = createEcsEvent(
  /**
   * This param contains the InviteUser0Event the event will contain.
   * These properties can never be changed inside of the InviteUser0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'invite_user',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user enters their personal info, then clicks the next CTA, and successfully passes validation"
 */
export const InviteUserEngagedNextEvent = createEcsEvent(
  /**
   * This param contains the InviteUserNextOrSkipEvent the event will contain.
   * These properties can never be changed inside of the InviteUserNextOrSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'invite_user',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_invitation',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    next_or_skip: '',
    account_invitee_access_level: '',
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the InviteUser page"
 */
export const InviteUserEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the InviteUserEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the InviteUserEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'invite_user',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'i_will_do_this_later',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const InviteUserFailedEvent = createEcsEvent(
  /**
   * This param contains the InviteUserFailedEvent the event will contain.
   * These properties can never be changed inside of the InviteUserFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'invite_user',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the Brand Import page"
 */
export const InviteUserEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the InviteUserEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the InviteUserEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'invite_user',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);
