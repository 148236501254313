import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'finance_operations',
  object: 'account',
  object_detail: 'billing_information',
  event_source: 'front_end:js_react',
};

export const BillingInfoViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'viewed',
    ui_object: 'screen',
    ui_object_detail: '',
    ui_action: 'viewed',
    ui_access_point: '',
  },
  {},
);

export const BillingPaymentEditClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'billng_payment_card',
  },
  {},
);

export const BillingPaymentEditSaveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'billng_payment_card',
  },
  {},
);

export const BillingPaymentEditCancelClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'billng_payment_card',
  },
  {},
);

export const PrimaryAccountContactEditClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'primary_account_contact_card',
  },
  {},
);

export const PrimaryAccountContactEditSaveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'primary_account_contact_card',
  },
  {},
);

export const PrimaryAccountContactEditCancelClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'primary_account_contact_card',
  },
  {},
);

export const BillingPaymentAddPaymentMethodClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_payment_method',
    ui_action: 'clicked',
    ui_access_point: 'billng_payment_card',
  },
  {},
);

export const BillingPaymentChangePaymentMethodClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'change',
    ui_action: 'clicked',
    ui_access_point: 'billing_payment_card',
  },
  {},
);

export const BillingPaymentRemovePaymentMethodClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'billing_payment_card',
  },
  {},
);

export const BillingTaxInfoContactSupportClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'hyperlink',
    ui_object_detail: 'contact_support',
    ui_action: 'clicked',
    ui_access_point: 'tax_information_card',
  },
  {},
);

export const BillingPaymentMethodRemoveModalViewed = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'remove_payment_method',
    ui_action: 'loaded',
    ui_access_point: 'remove_payment_method_modal',
  },
  {},
);

export const BillingPaymentMethodRemoveModalRemoveClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: window.location.pathname,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remove',
    ui_action: 'clicked',
    ui_access_point: 'remove_payment_method_modal',
  },
  {},
);
