import { createEcsEvent } from '../../configuration/createEvent';

/**
 * The DEFAULT_EVENT_PROPERTIES stay the same for every event in this file
 *  If the event you're working on has different values for these properties it will go in another file (See README.md)
 */
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'sms_marketing',
  scope_area: 'sms',
  object: 'audience',
};

export const SmsOverviewCardAudienceEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside of the SmsImportContactsEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
  },
  {
    ui_access_point: 'sms_overview_card',
    object_detail: '',
    ui_object_detail: '',
  },
);

export const InboxOpenedEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_inbox',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object_detail: 'inbox',
  },
  {
    sms_registration_status: '',
  },
);

export const SmsUploadContactsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/sms',
    action: 'started',
    ui_object_detail: 'import_contacts',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'sms_overview_step_1',
    object_detail: 'import_contacts',
  },
  {
    sms_registration_status: '',
  },
);
