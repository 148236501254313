import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  screen: 'settings/affiliate',
  initiative_name: 'react_migration_account_settings',
  scope_area: 'account_settings',
  object: 'affiliate',
  action: 'engaged',
  ui_action: 'clicked',
};

export const AffiliateLinkEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_access_point: 'center',
    object_detail: 'referral_program_overview',
  },
  {
    // used for 3 links
    // 'referral_program_overview_page', 'referral_program', 'mailchimp_referral_program_terms'
    ui_object_detail: '',
  },
);

export const OpenChangeImageModalEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_access_point: 'center',
    ui_object_detail: 'change_image',
  },
  {
    // used for 2 buttons
    // 'email_campaign_badge_change_image', 'signup_form_badge_change_image'
    object_detail: '',
  },
);

export const SelectBadgeImageEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'image',
    ui_access_point: 'modal',
  },
  {
    // used for 2 image selections
    // 'email_campaign_badge', 'signup_form_badge'
    object_detail: '',
    ui_object_detail: '',
  },
);

export const ShowCampaignsBadgeEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'show_badge',
    ui_object: 'checkbox',
    ui_object_detail: 'show_campaigns_badge',
    ui_access_point: 'center',
  },
  {},
);

export const ReferralEmailOptInEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: '',
    ui_object: 'checkbox',
    ui_access_point: 'center',
  },
  {},
);
