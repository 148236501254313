import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the Brand Import step"
 */
export const BrandImportViewedEvent = createEcsEvent(
  /**
   * This param contains the BrandImportViewedEvent the event will contain.
   * These properties can never be changed inside of the BrandImportViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'brand_import',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    is_genai_url_available: false,
    is_ecomm_business_user: false,
  },
);

/**
 * "Provide website to pull in brand info and click Next or Skip CTA, and successfully passes validation. Not required"
 */
export const BrandImportNextEvent = createEcsEvent(
  /**
   * This param contains the BrandImportNextEvent the event will contain.
   * These properties can never be changed inside of the BrandImportNextEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'brand_import',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    next_or_skip: '',
    website_url: '',
    entry_point: '',
    is_genai_url_submitted: false,
    is_wix_intent: false,
  },
);

/**
 * "Provide website to pull in brand info and click Next or Skip CTA, and successfully passes validation. Not required"
 */
export const BrandImportEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the BrandImportEngagedSkipEvent the event will contain.
   * These properties can never be changed inside of the BrandImportEngagedSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'brand_import',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    is_wix_intent: false,
  },
);

/**
 * "This event fires when a user goes back from the Brand Import page"
 */
export const BrandImportEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the BrandImportEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the BrandImportEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'brand_import',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const BrandImportFailedEvent = createEcsEvent(
  /**
   * This param contains the BrandImportFailedEvent the event will contain.
   * These properties can never be changed inside of the BrandImportFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'brand_import',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
