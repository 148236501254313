import { createEcsEvent } from '../../configuration/createEvent';
import {
  DEFAULT_EVENT_PROPERTIES,
  LOADING_EVENT_PROPERTIES,
} from './OnboardingConstants';

/**
 * "This event fires when a user begins account setup"
 */
export const AccountSetUpStartedEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp0Event the event will contain.
   * These properties can never be changed inside of the accountSetUp0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_setup',
    action: 'started',
    ui_object: '',
    ui_object_detail: '',
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/* Creative Assistant Start Loading Event */
export const CreativeAssistantLoadingStartEvent = createEcsEvent(
  {
    ...LOADING_EVENT_PROPERTIES,
    object_detail: 'ai_load_start',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    entry_point: '',
    is_genai_url_available: false,
  },
);

/* Creative Assistant End Loading Event */
export const CreativeAssistantLoadingEndEvent = createEcsEvent(
  {
    ...LOADING_EVENT_PROPERTIES,
    object_detail: 'ai_load_end',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    entry_point: '',
    is_genai_url_available: false,
  },
);
