import { createEcsEvent } from '../../configuration/createEvent';

const NEA_DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_editor',
  screen: 'campaigns/wizard/neapolitan',

  object: 'campaign',
};

export const ViewNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'campaign_content',
    action: 'viewed',

    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    inline_eligible: 'N',
    inline_feature_flag_enabled: 'N',
  },
);

export const PreviewNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'top_nav',
    action: 'engaged',

    ui_object: 'dropdown',
    ui_object_detail: 'preview',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    preview_selected: '',
    campaign_id: '',
    template_id: '',
    platform_type: '',
    campaign_type: '',
    editor_type: 'nea',
  },
);

export const TemplateNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'top_nav',
    action: 'engaged',

    ui_object: 'dropdown',
    ui_object_detail: 'template',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    template_option: '',
  },
);

export const SaveAndCloseNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'add_content',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'save_and_close',
    ui_action: 'clicked',
    ui_access_point: 'edit_drawer',
  },
  {
    block_type: '',
  },
);

export const ContinueNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,

    object_detail: 'top_nav',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'continue',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const AddBlockNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    object_detail: 'add_content',
    action: 'customized',

    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    ui_object_detail: '',
    campaign_id: '',
    template_id: '',
    platform_type: '',
    campaign_type: '',
    editor_type: 'nea',
  },
);

export const DeleteBlockNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    object_detail: 'block_actions',
    action: 'customized',

    ui_object: 'button',
    ui_object_detail: 'delete_block',
    ui_action: 'clicked',
    ui_access_point: 'content_block_header',
  },
  {
    campaign_id: '',
    template_id: '',
    platform_type: '',
    campaign_type: '',
    editor_type: 'nea',
    block_type: '',
    image_intuit_tid: '',
  },
);

export const EditBlockNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    object_detail: 'block_actions',
    action: 'customized',

    ui_object: 'button',
    ui_object_detail: 'edit_pencil',
    ui_action: 'clicked',
    ui_access_point: 'content_block_header',
  },
  {
    campaign_id: '',
    template_id: '',
    platform_type: '',
    campaign_type: '',
    editor_type: 'nea',
    block_type: '',
    image_intuit_tid: '',
  },
);

export const DuplicateBlockNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    object_detail: 'block_actions',
    action: 'customized',

    ui_object: 'button',
    ui_object_detail: 'duplicate_block',
    ui_action: 'clicked',
    ui_access_point: 'content_block_header',
  },
  {
    campaign_id: '',
    template_id: '',
    platform_type: '',
    campaign_type: '',
    editor_type: 'nea',
    block_type: '',
    image_intuit_tid: '',
  },
);

export const EditDrawerViewedNeapolitanEvent = createEcsEvent(
  {
    ...NEA_DEFAULT_PROPERTIES,
    initiative_name: 'core_offerings',
    object_detail: 'add_content',
    action: 'viewed',

    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    campaign_id: '',
    template_id: '',
    screen: '',
    block_type: '',
    ai_button_exists: 'No',
    inline_eligible: 'N',
    inline_feature_flag_enabled: 'N',
  },
);

export const selectTemplateExportEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'templates',
    screen: 'templates',
    object: 'campaign',
    object_detail: 'export_templates',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'export_as_html',
    ui_action: 'clicked',
    ui_access_point: 'template_slat_list',
  },
  {
    template_id: '',
    user_template_id: '',
  },
);

export const clickedCancelCloseExportTemplateModalEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'templates',
    screen: 'templates',
    object: 'campaign',
    object_detail: 'export_templates',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'export_modal',
  },
  {
    template_id: '',
    user_template_id: '',
  },
);

export const clickedXCloseExportTemplateModalEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'templates',
    screen: 'templates',
    object: 'campaign',
    object_detail: 'export_templates',
    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'clicked',
    ui_access_point: 'export_modal',
  },
  {
    template_id: '',
    user_template_id: '',
  },
);

export const exportsTemplateEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'templates',
    screen: 'templates',
    object: 'campaign',
    object_detail: 'export_templates',
    action: 'completed',

    ui_object: 'button',
    ui_object_detail: 'export_template',
    ui_action: 'clicked',
    ui_access_point: 'export_modal',
  },
  {
    template_id: '',
    user_template_id: '',
  },
);

export const clicksTemplateComboButtonEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'templates',
    screen: 'templates',
    object: 'campaign',
    object_detail: 'saved_templates',
    action: 'engaged',
    ui_object: 'combo_button',
    ui_object_detail: 'down_arrow',
    ui_action: 'clicked',
    ui_access_point: 'template_slat_list',
  },
  {
    template_id: '',
    user_template_id: '',
  },
);

export const selectSendToMandrillEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'templates',
    screen: 'templates',
    object: 'campaign',
    object_detail: 'send_to_mandrill',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'send_to_mandrill',
    ui_action: 'clicked',
    ui_access_point: 'template_slat_list',
  },
  {
    template_id: '',
    editor_type: '',
  },
);

const NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  scope_area: 'email_editor',
  object: 'campaign',
  object_detail: 'email_template_selection',
};

// Views "Using our Templates" Shutterstock modal
export const email_viewUsingShstTemplateModal = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'using_our_templates_modal',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// Clicks "I accept" button in "Using our Templates modal
export const email_acceptButtonShstTemplateModal = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'i_accept',
    ui_action: 'clicked',
    ui_access_point: 'using_our_templates_modal',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// Clicks "Decline" button in "Using our Templates" modal
export const email_declineButtonShstTemplateModal = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'decline',
    ui_action: 'clicked',
    ui_access_point: 'using_our_templates_modal',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// Views "Switching Templates" Shutterstock modal
export const email_viewSwitchingShstTemplateModal = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'switching_templates_modal',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// Clicks "I accept" button in "Using our Templates modal
export const email_acceptSwitchingButtonShstTemplateModal = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'accept_and_change_template',
    ui_action: 'clicked',
    ui_access_point: 'switching_templates_modal',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// Clicks "Decline" button in "Using our Templates" modal
export const email_declineSwitchingButtonShstTemplateModal = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'switching_templates_modal',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// Mouseover "i" button for shutterstock tooltip
export const email_shutterstockTooltipHover = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'tooltip',
    ui_object_detail: 'i',
    ui_action: 'hovered',
    ui_access_point: 'featured_themes',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);

// click "a" link to shutterstock tersm
export const email_shutterstockTermsLinkClick = createEcsEvent(
  {
    ...NEA_EMAIL_TEMPLATE_SELECTION_DEFAULT_PROPERTIES,
    action: 'engaged',
    ui_object: 'link',
    ui_object_detail: 'additional_terms_and_conditions',
    ui_action: 'clicked',
    ui_access_point: 'featured_themes',
  },
  {
    screen: 'campaigns/wizard/template',
    template_id: '',
    template_name: '',
    campaign_id: '',
    platform_type: '',
    campaign_type: '',
  },
);
