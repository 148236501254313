import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user lands on 'Linking Confirmation' page"
 */
export const AccountLinkingConfirmationLandedEvent = createEcsEvent(
  /**
   * This param contains the accountLinkingConfirmation0Event the event will contain.
   * These properties can never be changed inside of the accountLinkingConfirmation0Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'linking_confirmation',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);

/**
 * "This event fires when a user clicks on Go To Mailchimp'"
 */
export const AccountLinkingConfirmationGoToMailchimpEvent = createEcsEvent(
  /**
   * This param contains the accountLinkingConfirmation1Event the event will contain.
   * These properties can never be changed inside of the accountLinkingConfirmation1Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'connect_accounts',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'go_to_mailchimp',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
/**
 * "This event fires when a user clicks on Go To Mailchimp CTA and an error occurs"
 */
export const AccountLinkingConfirmationLoadFailureEvent = createEcsEvent(
  /**
   * This param contains the accountLinkingConfirmation2Event the event will contain.
   * These properties can never be changed inside of the accountLinkingConfirmation2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'linking_confirmation',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
    object: 'oii_account',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
    qb_realm_id: '',
    qb_pseudonym_id: '',
  },
);
