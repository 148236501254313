import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from './OnboardingConstants';

/**
 * This event fires when an user click on continue with google button on sign up page
 */
export const signUpWithGoogleEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp2Event the event will contain.
   * These properties can never be changed inside of the accountSetUp2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_signup_google_sso',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_with_google',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * This event fires when an user click on continue with google button on login page
 */
export const loginWithGoogleEvent = createEcsEvent(
  /**
   * This param contains the accountSetUp2Event the event will contain.
   * These properties can never be changed inside of the accountSetUp2Event
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_login_google_sso',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'continue_with_google',
    ui_action: 'clicked',
    ui_access_point: 'center',
    object: 'account',
    user_id_required: false,
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);
