import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

/*************************
 * TO-DO: References added for ease. Can be removed after release.
 * Tracking Plan Reference: https://wiki.intuit.com/display/DSVC/How+to+Create+A+Tracking+Plan
 *
 * DOMAIN
 * Initiative name: should align L1 on the FY24 City Map.
 * Screen: portion of the URL
 * Scope area: should describe which feature the events pertain to
 *
 * LOGICAL
 *
 * Object: What am I interacting with?
 * Action: How am I interacting with it?
 * Object Detail: How can I describe this object in 3-5 words?
 *
 *
 * USER INTERFACE
 *
 * UI Action: Which action did I take to interact with this element?
 * UI Object: What is the object I interacted with?
 * UI Object Detail: What does the object say?
 * UI Access Point: Where is this object on the page?
 *
 * Object names: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=366613319
 * Action names: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=2036547919
 * UI Properties: https://docs.google.com/spreadsheets/d/1ot7qUWMSE1LBAWs8z0GgSEEZ0WCJ3SN3qRKouDekc4I/edit#gid=137707926
 *
 */
const DEFAULT_EVENT_PROPERTIES = {
  initiative_name: 'marketing_generation',
  scope_area: 'email_templates',
  action: 'engaged',
  object: 'campaign',
  object_detail: 'email_template_management',
  purpose: 'prod',
};

const CONFIGURABLE_PROPERTIES = {
  screen: '',
};

export const SavedTemplatesTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'saved_templates',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PurchasedTemplatesTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'tab',
    ui_object_detail: 'purchased_templates',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ContentStudioButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'button',
    ui_object_detail: 'content_studio_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CreateTemplateButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'button',
    ui_object_detail: 'create_template_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateEditButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'edit_template_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CreateEmailButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'create_email_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const SendToMandrillButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'send_to_mandrill_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const PreviewButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'preview_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ExportAsHtmlButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'export_as_html_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ShareButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'share_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const ReplicateButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'replicate_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DuplicateAndEditButtonClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'combo_button',
    ui_object_detail: 'duplicate_and_edit_button',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateSelect = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'slat',
    ui_object_detail: 'template_link',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplatePreviewClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'image',
    ui_action: 'clicked',
    ui_access_point: 'slat',
    ui_object_detail: 'preview_template',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const DeleteTemplateFolderClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'delete_folder',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateFolderSelectionClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'drop_down_item',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'folder_selection',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const EditTemplateFolderName = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'edit_folder_name_submit',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateMoveToFolderClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'dropdown',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'move_item_to_dropdown_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CreateTemplateFolderClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'create_folder_name_submits',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplatePaginationInputEnter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'input_field',
    ui_action: 'typed',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'page_number_input_submission',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplatePaginationClickLast = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'last_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplatePaginationClickFirst = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'first_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplatePaginationClickNext = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'next_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplatePaginationClickPrevious = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon',
    ui_action: 'clicked',
    ui_access_point: 'menu_bottom',
    ui_object_detail: 'previous_page_click',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateSortByOptionSelection = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'selectlist_item',
    ui_action: 'selected',
    ui_access_point: 'center',
    ui_object_detail: 'sortby_option_selection',
  },
  { ...CONFIGURABLE_PROPERTIES, sorted_by: '' },
);

export const TemplateSearchBarFocus = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
    ui_object_detail: 'search_field_focus',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateSearchBarSubmit = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
    ui_object_detail: 'search_field_submit',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const TemplateSearchBarClear = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon_button',
    ui_action: 'clicked',
    ui_access_point: 'search_bar',
    ui_object_detail: 'search_field_clear',
  },
  { ...CONFIGURABLE_PROPERTIES },
);
