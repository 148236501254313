import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const CA_DEFAULT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'brand_management',
  scope_area: 'brand_engine',
  screen: '/creative-assistant',
  object: 'campaign',
};

// Instrumented in MainHeader.js
export const clickCANavItemEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_overview',
    action: 'engaged',
    ui_object: 'tab',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'topic_selector',
  },
);

// Instrumented in StepsCTA.js
export const clickCACreateNewDesignEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'started',
    ui_object: 'button',
    ui_object_detail: 'create_new_design',
    ui_action: 'clicked',
    ui_access_point: 'create_design_front_door',
  },
);

// Instrumented in StepsCTA.js
export const clickCACreateDesignNextCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'create_design_front_door',
    creative_assistant_goal: '',
    ca_designer_step: '',
    creative_assistant_heading_text: '',
    creative_assistant_subheading_text: '',
    creative_assistant_button_text: '',
  },
);

// Instrumented in StepsCTA.js
export const clickCACreateDesignBackCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back',
    ui_action: 'clicked',
    ui_access_point: 'create_design_front_door',
    creative_assistant_goal: '',
    ca_designer_step: '',
    creative_assistant_heading_text: '',
    creative_assistant_subheading_text: '',
    creative_assistant_button_text: '',
  },
);

// Instrumented in StepsCTA.js
export const clickCACreateDesignSkipCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'create_design_front_door',
    creative_assistant_goal: '',
    ca_designer_step: '',
    creative_assistant_heading_text: '',
    creative_assistant_subheading_text: '',
    creative_assistant_button_text: '',
  },
);

// Instrumented in Imagery.js
export const clickCASuggestSmartPhotosSuccessCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'suggest_smart_photos',
    ui_action: 'clicked',
    ui_access_point: 'center',
    has_shutterstock_image: false,
    shutterstock_id: '',
  },
);

// Instrumented in Imagery.js
export const clickCASuggestSmartPhotosFailedCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'feedback_block',
    ui_object_detail: 'photo_error',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
);

// Instrumented in Imagery.js
export const clickCACreateDesignRemoveImageCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'remove_image',
    ui_action: 'clicked',
    ui_access_point: 'cards_list',
    has_shutterstock_image: false,
    shutterstock_id: '',
  },
);

// Instrumented in AddImage.js
export const clickCACreateDesignAddImageCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_image',
    ui_action: 'clicked',
    ui_access_point: 'cards_list',
  },
);

// Instrumented in StockPhotoTOSModal.js
export const clickCAAcceptShutterstockPhotosModalCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_design',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'yes_i_want_photos',
    ui_action: 'clicked',
    ui_access_point: 'shuttershock_smart_photo_modal',
  },
);

// Instrumented in StockPhotoTOSModal.js
export const clickCADeclineShutterstockPhotosModalCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_design',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'no_thanks',
    ui_action: 'clicked',
    ui_access_point: 'shuttershock_smart_photo_modal',
  },
);

// Instrumented in ImagePane.js
export const clickImagePaneTabSwitchCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'tab',
    ui_action: 'clicked',
    ui_access_point: 'topic_selector',
    ui_object_detail: '',
  },
);

// Instrumented in MyFiles.js
export const clickInsertImageFromMyFilesCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'image',
    ui_object_detail: 'my_files_image',
    ui_action: 'selected',
    ui_access_point: 'cards_list',
  },
);

// Instrumented in StepsCTA.js
export const clickCAGenerateDesignsCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'generate_designs',
    ui_action: 'clicked',
    ui_access_point: 'center',
    has_shutterstock_image: false,
    shutterstock_id: '',
  },
);

// Instrumented in DesignButtons.js
export const clickCACreateDesignsDesignButtonsCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'image_card',
    design_renderer_id: '',
    has_shutterstock_image: false,
    shutterstock_id: '',
    ui_object_detail: '',
  },
);

// Instrumented in SelectChannel.js
export const clickCACreateDesignsChannelsChecklistCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_create_designs',
    action: 'engaged',
    ui_object: 'drop_down_item',
    ui_object_detail: 'channel_size',
    ui_action: 'selected',
    ui_access_point: 'center',
    channel_size_selected: '',
    design_renderer_id: '',
    has_shutterstock_image: false,
    shutterstock_id: '',
  },
);

// Instrumented in CreateDesigns.js
export const clickCACreateNewDesignCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_design_library',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_new_design',
    ui_action: 'clicked',
    ui_access_point: 'center',
    design_renderer_id: '',
  },
);

// Instrumented in SaveAll.js
export const clickCASaveToDesignLibraryCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_design_library',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_to_design_library',
    ui_action: 'clicked',
    ui_access_point: 'header',
    channel_size_selected: '',
    design_renderer_id: '',
    has_shutterstock_image: false,
    shutterstock_id: '',
  },
);

// Instrumented in SaveAll.js
export const clickCANameDesignSaveCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_design_library',
    action: 'completed',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'name_your_design_modal',
    design_renderer_id: '',
    has_shutterstock_image: false,
    shutterstock_id: '',
    creative_assistant_design_name: '',
  },
);

// Instrumented in MultiChannel.js, DesignDetails.js
export const clickCADownloadImageCTAEvent = createEcsEvent(
  {
    ...CA_DEFAULT_PROPERTIES,
  },
  {
    object_detail: 'creative_assistant_design_library',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'download_selected',
    ui_action: 'clicked',
    ui_access_point: 'header',
    channel_size_selected: '',
    design_renderer_id: '',
    has_shutterstock_image: false,
    shutterstock_id: '',
  },
);
