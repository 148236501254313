import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  screen: 'account/details',
  initiative_name: 'bulk_email',
  scope_area: 'editor_selection',
  object: 'campaign',
  action: 'selected',
  ui_action: 'clicked',
  ui_access_point: 'main_content_pane',
};

export const DefaultEditor = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'card',
    object_detail: 'default_editor_type',
    ui_object_detail: '',
  },
  {
    editor_type: '',
  },
);
