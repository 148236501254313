export const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_gtkm',
  scope_area: 'account_setup',
  object: 'account',
  screen: 'signup/setup',
};

export const LOADING_EVENT_PROPERTIES = {
  ...DEFAULT_EVENT_PROPERTIES,
  scope_area: 'smart_content_generation',
};
