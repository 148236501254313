import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from './OnboardingConstants';

/**
 * "This event fires when the user successfully completes account setup"
 */
export const SetupCompletedCompleteEvent = createEcsEvent(
  /**
   * This param contains the SetupCompletedCompleteEvent the event will contain.
   * These properties can never be changed inside of the SetupCompletedCompleteEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'account_setup_complete',
    action: 'completed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);
