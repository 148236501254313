import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'brand_management',
  scope_area: 'brand_engine',
  object: 'campaign',
};

export const ImportMyBrandEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/creative-assistant',
    object_detail: 'creative_assistant_brand_import',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'import_my_brand',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
  },
);

export const ImportFrontDoorViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/creative-assistant',
    object_detail: 'creative_assistant_brand_import',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'front_door',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
  },
);

export const SuccessfullyBrandImportEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/creative-assistant',
    object_detail: 'creative_assistant_successful_brand_import',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    screen: '',
  },
);

export const FailedBrandImportEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/creative-assistant',
    object_detail: 'creative_assistant_failed_brand_import',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    screen: '',
  },
);
