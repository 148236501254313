import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'brand_management',
  scope_area: 'asset_management',
  object: 'campaign',
};
const CONFIGURABLE_PROPERTIES = {
  screen: '',
};

export const CanvaTabClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'content_brand_management_canva',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'canva',
    ui_action: 'clicked',
    ui_access_point: 'content_studio_bottom_sheet',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  { ...CONFIGURABLE_PROPERTIES },
);

export const CanvaZeroStateLoaded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'content_brand_management_canva',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'canva_zero_state',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CanvaLearnMoreButtonClicked = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'content_brand_management_canva',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'learn_more',
    ui_action: 'clicked',
    ui_access_point: 'content_studio_bottom_sheet',
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CanvaNoContentScreenLoaded = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'content_brand_management_canva',
    action: 'viewed',
    ui_object: 'modal',
    ui_object_detail: 'canva_no_content',
    ui_action: 'loaded',
    ui_access_point: 'center',
    user_id_required: false,
  },
  { ...CONFIGURABLE_PROPERTIES },
);

export const CanvaUserClicksGoToCanva = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'content_brand_management_canva',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'go_to_canva',
    ui_action: 'clicked',
    ui_access_point: 'content_studio_bottom_sheet',
  },
  { ...CONFIGURABLE_PROPERTIES },
);
