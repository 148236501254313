import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'bulk_email',
  scope_area: 'email_editor',
  object: 'campaign',
  screen: 'email/editor',
};

export const RemoveBlockEvent = createEcsEvent(
  /**
   * Immutable properties
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'block_actions',
    action: 'customized',
    ui_object: 'button',
    ui_object_detail: 'delete_block',
    ui_action: 'clicked',
    ui_access_point: 'floating_toolbar',
    editor_type: 'nuni',
  },
  /**
   * Changeable properties
   */
  {
    campaign_id: '',
    template_id: '',
    platform_type: '',
    campaign_type: '',
    block_type: '',
    image_intuit_tids: '', // the single-item string version of this param
  },
);

export const NarrowFloatingToolbarIntuitAssistEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    initiative_name: 'core_offerings',
    scope_area: 'smart_content_generation',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'block_actions',
    action: 'customized',
    ui_object: 'button',
    ui_object_detail: 'enable_genai_tool',
    ui_action: 'clicked',
    ui_access_point: 'floating_toolbar',
  },
  {
    ai_button_exists: 'Yes',
    campaign_id: '',
    platform_type: '',
    template_id: '',
    campaign_type: '',
    editor_type: 'nuni',
    block_type: '',
  },
);
