import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'ftu_guideme',
  scope_area: 'homepage',
  object: 'recommendation',
  object_detail: 'goal_based_onboarding',
};

// Note: GBO is Goal Based Onboarding
// Event sent when an user views the GBO module.
export const GBOViewedEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'viewed',
    ui_object: 'widget',
    ui_object_detail: 'goal_name',
    ui_action: 'loaded',
    ui_access_point: 'center',
    homepage_content: 'goal based onboarding',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    gbo_header: '',
    gbo_task_header: '',
    gbo_progress: '',
    gbo_goal: '',
    oc_scenario: '',
    screen: '',
  },
);

export const GBOCollapseExpandEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'accordion',
    homepage_content: 'goal based onboarding',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    ui_object_detail: '',
    gbo_header: '',
    gbo_task_header: '',
    gbo_goal: '',
    oc_scenario: '',
    screen: '',
  },
);

export const GBOPrimaryCtaEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'primary_cta',
    ui_action: 'clicked',
    ui_access_point: 'center',
    homepage_content: 'goal based onboarding',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    gbo_header: '',
    gbo_task_header: '',
    gbo_goal: '',
    primary_homepage_goal_cta_content: '',
    oc_scenario: '',
    screen: '',
  },
);

export const GBOSecondaryCtaEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'secondary_cta',
    ui_action: 'clicked',
    ui_access_point: 'center',
    homepage_content: 'goal based onboarding',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    gbo_header: '',
    gbo_task_header: '',
    gbo_goal: '',
    secondary_homepage_goal_cta_content: '',
    oc_scenario: '',
    screen: '',
  },
);

export const GBOGoalSwitchEvent = createEcsEvent(
  // This object will have the default properties that will not change for this event.
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'center',
    ui_object_detail: 'selected_goal_name',
    homepage_content: 'goal based onboarding',
  },
  // This object will have the properties that are subject to change. These properties can be modified.
  {
    gbo_header: '',
    gbo_task_header: '',
    gbo_goal: '',
    gbo_progress: '',
    selected_goal_name: '',
    oc_scenario: '',
    screen: '',
  },
);
