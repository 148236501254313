import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'campaign_manager',
  screen: '/campaign-manager',
  object: 'automations',
  object_detail: 'full_width_calendar',
};

export const TodayViewEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the CalendarEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'today',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {},
);

export const CampaignsFilterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'campaign_name',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const CampaignsFilterSelectionEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'campaign',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const DropdownCreateCampaignEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_campaign',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const HolidayFilterEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'holiday_selection',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const HolidayFilterSelectionEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'country_name',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
    calendar_holiday_type: 'federal',
  },
  {
    calendar_country: '',
  },
);

export const DropdownAddHolidaysEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_holidays',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const ExpandDayViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'down_arrow',
    ui_action: 'clicked',
    ui_access_point: 'in_calendar_day_view',
  },
  {},
);

export const CollapseDayViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'up_arrow',
    ui_action: 'clicked',
    ui_access_point: 'in_calendar_day_view',
  },
  {},
);

export const ExpandWeekViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'down_arrow',
    ui_action: 'clicked',
    ui_access_point: 'in_calendar_week_view',
  },
  {},
);

export const CollapseWeekViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'up_arrow',
    ui_action: 'clicked',
    ui_access_point: 'in_calendar_week_view',
  },
  {},
);

export const ChangeToMonthViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'month',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const ChangeToWeekViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'week',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const ChangeToDayViewEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'day',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const ChangeViewDropdownClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'dropdown',
    ui_object_detail: 'change_view',
    ui_action: 'clicked',
    ui_access_point: 'calendar_header',
  },
  {},
);

export const TouchpointClickEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'card',
    ui_object_detail: 'touchpoint',
    ui_action: 'clicked',
    ui_access_point: 'calendar',
  },
  {
    touchpoint_type: '',
  },
);

export const CalendarCellClick = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'created',
    ui_object: 'button',
    ui_object_detail: '+',
    ui_action: 'clicked',
    ui_access_point: 'calendar',
  },
  {},
);
