import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'email_domain',
  screen: 'campaigns/edit',

  object: 'account',
  object_detail: 'authenticate_your_domain',
};

const CONFIGURABLE_PROPERTIES = {
  campaign_id: 0,
  email_domains: null,
};

export const MungingModalViewedEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'viewed',

    ui_object: null,
    ui_object_detail: null,
    ui_action: null,
    ui_access_point: null,
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const MungingModalAuthenticateNowClickedEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'authenticate_now',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const MungingModalRemindMeLaterClickedEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'remind_me_later',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const MungingModalCloseClickedEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);

export const MungingModalHelpArticleClickedEvent = createEcsEvent(
  {
    ...DEFAULT_PROPERTIES,

    action: 'engaged',

    ui_object: 'link',
    ui_object_detail: 'set_up_email_domain_authentication',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  {
    ...CONFIGURABLE_PROPERTIES,
  },
);
