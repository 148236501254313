import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const ButtonBorderStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'border',
  },
  {
    campaign_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'button',
    nuni_button_border_style: '',
    nuni_button_border_width: 0,
  },
);

export const ButtonTemplateBorderStyleEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    screen: 'email/templates/editor',
    object: 'campaign',
    object_detail: 'content_block_options',
    action: 'customized',
    ui_object: 'component',
    ui_action: 'applied',
    ui_access_point: 'side_panel',
    ui_object_detail: 'border',
  },
  {
    user_template_id: 0,
    template_id: 0,
    nuni_tab: 'add',
    nuni_section: 'button',
    nuni_button_border_style: '',
    nuni_button_border_width: 0,
  },
);
