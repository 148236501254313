import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

export const FontSelectionViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'text_selection',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'font',
    ui_action: 'loaded',
  },
  {
    screen: '',
    ui_access_point: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    font_viewed_from: '',
    current_font: '',
  },
);

export const FontSelectionAppliedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font',
    ui_action: 'applied',
  },
  {
    screen: '',
    ui_access_point: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    font_viewed_from: '',
    font_applied: '',
  },
);

export const FontSelectionAppliedEventForEmailFontPicker = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'text_selection',
    action: 'customized',
    ui_object: 'component',
    ui_object_detail: 'font',
    ui_action: 'applied',
  },
  {
    screen: '',
    ui_access_point: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    nuni_tab: '',
    nuni_section: '',
    font_viewed_from: '',
    font_applied: '',
    applied_method: '',
  },
);

export const MoreFontsEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'text_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'see_more_fonts',
    ui_action: 'clicked',
    ui_access_point: 'side_panel',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
  },
);

export const FontSelectionEventForFontModal = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    scope_area: 'email_editor',
    object_detail: 'text_selection',
    action: 'engaged',
    ui_object: 'dropdown',
    ui_action: 'selected',
    ui_access_point: 'add_a_font_modal',
  },
  {
    screen: '',
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    font_applied: '',
    ui_object_detail: '',
  },
);
