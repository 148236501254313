import { createEcsEvent } from '../../configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'partnerships_n_integrations',
  scope_area: 'multi_vertical',
  object: 'integration',
  object_detail: 'attribution_settings',
  event_origin: 'react',
  action: 'engaged',
};

export const clickEditAttributionSettingsCampaignReport = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'link',
    ui_object_detail: 'edit_attribution_settings',
    ui_action: 'clicked',
    ui_access_point: 'ecomm_perf_datawell',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const clickEditAttributionSettingsMarketingDashboard = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'analytics/marketing-dashboard/',
    ui_object: 'drop_down_item',
    ui_object_detail: 'edit_attribution_settings',
    ui_action: 'clicked',
    ui_access_point: 'settings_dropdown',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const clickEditAttributionSettingsIntegrationsDashboard = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/manage',
    ui_object: 'button',
    ui_object_detail: 'edit_attribution_settings',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_slat',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const audienceDropdownOpeningMenu = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'drop_down',
    ui_object_detail: 'audience_selector',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const audienceDropdownSelectingItem = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'drop_down_item',
    ui_object_detail: 'audience_selector',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const windowOpenMenu = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'drop_down',
    ui_object_detail: 'window_selector',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
    channel_type: '',
  },
);

export const windowSelectItem = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'drop_down_item',
    ui_object_detail: 'window_selector',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
    channel_type: '',
  },
);

export const windowEnterData = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'text_field',
    ui_action: 'edited',
    integration_name: 'text_box',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    ui_object_detail: '',
    integration_id: '',
    channel_type: '',
  },
);

export const clickSaveAttributionSettings = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const cancelAttributionSettings = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const closeScreenAttributionSettings = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'button',
    ui_object_detail: 'x',
    ui_action: 'action',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const clickSeePreviousChanges = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'accordion',
    ui_object_detail: 'see_previous_changes',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const clickKBLink = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'reports/summary',
    ui_object: 'link',
    ui_object_detail: 'how_mailchimp_attribution_works',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);

export const launchFromIntegration = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'integrations/manage',
    ui_object: 'button',
    ui_object_detail: 'edit_attribution_settings',
    ui_action: 'clicked',
    ui_access_point: 'attribution_settings_modal',
  },
  {
    integration_name: '',
    integration_id: '',
  },
);
