/**
 * This file serves as the entry point for passing events into DOJO.
 * This file imports all of the events inside of the Events folder and
 * after some modification attaches them to the global object under the
 * property __MC_ECS_EVENT.
 */
import { EVENT_IDENTITY } from './configuration/createEvent';
import * as Events from './index';

/**
 * Events that are exported from the index.js file are all wrapped in a proxy.
 * In order for those same objects to work in DOJO we need to remove the proxy wrapper
 * and just get the plain object.
 * @param {Events} events
 * @returns
 */
const convertEventsIntoRegularObjects = (events) => {
  const eventPOJOs = {};
  for (const eventName in events) {
    eventPOJOs[eventName] = events[eventName][EVENT_IDENTITY];
  }
  return eventPOJOs;
};

/**
 * Adding Events to the global object
 */
window.__MC_ECS_EVENT = convertEventsIntoRegularObjects(Events);
