import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'subscription_billing',
  scope_area: 'revenue_retention',
  screen: '/account/close-help',
  event_source: 'front_end:js_react',
};

export const CloseHelpPageHVCLowSendSkuOfferPageView = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'low_send_sku_offer',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    low_send_offer_copy: '',
  },
);

export const CloseHelpPageHVCLowSendSkuOfferClickSendEmail = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'help_support',
    object_detail: 'low_send_sku_offer',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'schedule_a_call',
    ui_action: 'clicked',
    ui_access_point: 'left',
  },
  {
    low_send_offer_copy: '',
  },
);

export const CloseHelpPageHVCLowSendSkuOfferClickClose = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object: 'account',
    object_detail: 'low_send_sku_offer',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'close',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {
    low_send_offer_copy: '',
  },
);
