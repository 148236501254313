import { createEcsEvent } from '../../configuration/createEvent';

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  screen: 'customer-journey/builder',
  object: 'automations',
};

export const ViewPauseToEditModalEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ViewPauseToEditModalEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'pause_to_edit',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const ClickPauseToEditEvent = createEcsEvent(
  /**
   * This section contains the default properties the event will contain
   * These properties can never be changed inside the ClickPauseToEditEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'pause_to_edit',
    action: 'paused',
    ui_object: 'button',
    ui_object_detail: 'pause',
    ui_action: 'clicked',
    ui_access_point: 'modal',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const PauseButtonInModalEvent = createEcsEvent(
  /**
   * This section contains the default properties of the event
   * These properties can never be changed
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: 'paused',
    object_detail: 'journey_settings',
    ui_object: 'button',
    ui_object_detail: 'pause',
    ui_action: 'clicked',
    ui_access_point: 'modal',
    screen: 'customer-journey/builder',
  },
  /**
   * This section contains properties that can be changed
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    journey_id: 0,
    journey_created_from_genai: true,
    pbj_type: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    cjb_workflow_status: '',
  },
);
