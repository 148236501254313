import { createEcsEvent } from '../../configuration/createEvent';

/*
Source of truth: UniversalSearchExperiment section in Wayfinding tab on Mailchimp ECS v1 sheet:
https://docs.google.com/spreadsheets/d/1rqiSqIBT1nW5iCZhybVbx7YKsEh-B5fKHtPDq8dhgYY/edit#gid=652732638
*/

// these properties are constant throughout all events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'lifecycle_marketing_wayfinding',
  scope_area: 'product_global_nav',
  object: 'navigation',
  object_detail: 'universal_search',
  action: 'selected',
};

// USE: Search bar
export const UniversalSearchBarEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_object_detail: 'search_bar',
    ui_action: 'clicked',
    ui_access_point: 'top_center_app_header',
  },
  { screen: '' },
);

// USE: user clicks on any result
export const UniversalSearchClickResult = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'search_result',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  // Mutable params here:
  {
    // Tracking "no results" with this event mirrors SearchResultsEvent in ./Search.js
    // ECS events are typically tied to a user interaction, so data team didn't
    // want an event dedicated to "no results" case.
    has_search_results: true,
    // result_type is one of: campaigns, contacts, features
    result_type: '',
    search_input: '',
    search_position: 0,
    // search_title is used for Feature results only (Campaign and Contact names are user data)
    search_title: '',
    screen: '',
  },
);

// USE: user input into field
export const UniversalSearchInput = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'field',
    ui_object_detail: 'search_input',
    ui_action: 'clicked',
    ui_access_point: 'top_center_app_header',
  },
  // Mutable params here:
  {
    search_input: '',
    screen: '',
  },
);

// USE: Contacts filter
export const UniversalSearchContactsFilter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'contacts',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: campaigns filter
export const UniversalSearchCampaignsFilter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'campaigns',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: Features filter
export const UniversalSearchFeaturesFilter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'features',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: Audience filter
export const UniversalSearchAudienceFilter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'audience_filter',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: Search_Help_articles
export const UniversalSearchHelpFilter = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'button',
    ui_object_detail: 'help_articles_link',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: Feedback thumbs up
export const UniversalSearchFeedbackThumbsUp = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon',
    ui_object_detail: 'thumbs_up',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: Feedback thumbs down
export const UniversalSearchFeedbackThumbsDown = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'icon',
    ui_object_detail: 'thumbs_down',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);

// USE: show_more_results
export const UniversalSearchShowMoreResults = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    ui_object: 'link',
    ui_object_detail: 'more_results',
    ui_action: 'clicked',
    ui_access_point: 'search_modal',
  },
  { screen: '' },
);
