import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const defaultProperties = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'analytics',
  screen: 'analytics/custom-reports/graph/',
  object: 'reporting',
  object_detail: 'custom_reports_graph',
};

export const metricGraphEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'engaged',
    ui_object: 'drop_down',
    ui_object_detail: 'metric_value_selected',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  {},
);

export const exportCSVGraphEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'downloaded',
    ui_object: 'button',
    ui_object_detail: 'export_csv',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const editReportGraphEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit_report',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const viewReportDetailsGraphEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'view_report_details',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {},
);

export const customReportsGraphMppExcludedEvent = createEcsEvent(
  {
    ...defaultProperties,
    action: 'engaged',
    ui_object: 'checkbox',
    ui_object_detail: 'apple_mpp',
    ui_action: 'clicked',
    ui_access_point: 'feedback_block',
  },
  {
    is_applempp_excluded: '',
  },
);
