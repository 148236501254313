import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
};

export const dismissedOnAutomationsTurnOnEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',

    object: 'automations',
    object_detail: 'content_generation',
    action: 'dismissed',

    ui_object: 'button',
    ui_object_detail: 'cancel',
    ui_action: 'clicked',
    ui_access_point: 'turn_on_confirmation_modal',
  },
  {
    journey_id: 0, // needs to default to number
    access_to_content_gen: false,
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const acknowledgeAiEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',

    object: 'automations',
    object_detail: 'prebuilt_journey',
    action: 'completed',

    ui_object: 'button',
    ui_object_detail: 'turn_on',
    ui_action: 'clicked',
    ui_access_point: 'generated_email_content_modal',
  },
  {
    journey_id: 0, // needs to default to number
    pbj_type: '',
    journey_created_from_genai: '',
    ai_email_type: '',
    created_from_source: '',
    is_replicated_from_journey: false,
    starting_point: [],
    steps_taken_in_the_cjb: 0,
    cjb_workflow_status: '',
  },
);

// Generate Tab
const GENERATE_TAB_PROPERTIES = {
  initiative_name: 'core_offerings',
  scope_area: 'smart_content_generation',
  screen: 'email/editor',

  object: 'smart_content',
  object_detail: 'content_generation',
  action: 'selected',

  ui_object: 'button',
  ui_action: 'clicked',
  ui_access_point: 'generate_tab',
};

export const genTabViewEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    action: 'viewed',
    ui_object_detail: 'generate',
  },
  {
    campaign_type: '',
    editor_type: '',
    template_id: '',
  },
);

export const gptResultsRewriteEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_object_detail: 'rewrite_results',
  },
  {},
);

export const addGeneratedTextEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_object_detail: 'target_button',
  },
  {
    generated_text: '',
    content_type: '',
  },
);

export const dragGeneratedTextEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_action: 'dragged',
    ui_object: 'card',
  },
  {
    generated_text: '',
    content_type: '',
  },
);

export const copyGeneratedTextEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_object_detail: 'copy_button',
  },
  {
    generated_text: '',
    content_type: '',
  },
);

export const contentTypeChangedEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    action: 'engaged',
    ui_object: 'drop_down_item',
  },
  {
    content_type: '',
  },
);

export const retryClickedEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    action: 'engaged',
    ui_object_detail: 'edit_prompt',
  },
  {},
);

export const promptEditedDoneEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    action: 'customized',
    ui_object_detail: 'done',
  },
  {
    content_type: '',
    email_summary: '',
    character_count: '',
    industry_changed_from: '',
    industry_changed_to: '',
    goal_changed_from: '',
    goal_changed_to: '',
  },
);

export const toneChangedEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    action: 'customized',
    ui_object_detail: '',
  },
  {
    content_type: '',
    tone_selected: '',
    tone_deselected: '',
  },
);

export const positiveFeedbackEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_object_detail: 'thumbs_up',
  },
  {
    added_copy_to_editor: '',
    generated_text: '',
    selected_text: '',
    feedback: '',
  },
);

export const negativeFeedbackEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_object_detail: 'thumbs_down',
  },
  {
    added_copy_to_editor: '',
    generated_text: '',
    selected_text: '',
    feedback: '',
  },
);

export const submitFeedbackEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    ui_object_detail: 'submit',
  },
  {
    added_copy_to_editor: '',
    generated_text: '',
    selected_text: '',
    feedback: '',
  },
);

export const violationPageViewedEvent = createEcsEvent(
  {
    ...GENERATE_TAB_PROPERTIES,
    action: 'failed',
  },
  {
    content_type: '',
    flagged_content: '',
    error_reasoning: '',
  },
);

// Image Generation Events
export const ViewImageGenOptionsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/create-campaign',
    object: 'campaign',
    object_detail: 'image_content_generation',
    action: 'viewed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    campaign_id: '',
    ai_email_type: '',
    variant_positions: '',
  },
);

export const SelectGenImageOptionEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/create-campaign',
    object: 'campaign',
    object_detail: 'image_content_generation',
    action: 'engaged',
    ui_object: 'image',
    ui_object_detail: '',
    ui_action: 'clicked',
    ui_access_point: 'image_gen_loading_screen',
  },
  {
    campaign_id: '',
    ai_email_type: '',
    image_intuit_tids: '', // the single-item string version of this param
    image_variant_position: '',
    image_variant_type: '',
  },
);

export const SkipImageGenOptionsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/create-campaign',
    object: 'campaign',
    object_detail: 'image_content_generation',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip_add_image',
    ui_action: 'clicked',
    ui_access_point: 'image_gen_loading_screen',
  },
  {
    campaign_id: '',
    ai_email_type: '',
  },
);

export const ImageGenFailureEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/create-campaign',
    object: 'campaign',
    object_detail: 'image_content_generation',
    action: 'failed',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    ui_access_point: '',
  },
  {
    campaign_id: '',
    ai_email_type: '',
  },
);

export const ExperimentFeedbackEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: '/create-campaign',
    object: 'campaign',
    objct_detail: 'content_generation',
    action: 'selected',
    ui_object: 'icon',
    ui_object_detail: '',
    ui_action: 'click',
    ui_access_point: 'overlay',
  },
  {
    campaign_id: '',
    access_to_ecu_content_gen_beta: true,
    ai_email_type: 'product_announcement',
    loader_step_removed_experiment: false,
  },
);

export const ClickPromoCodeAIPBJEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object: 'automations',
    object_detail: 'pbj_review_flow',
    action: 'engaged',

    ui_object: 'dropdown',
    ui_object_detail: 'promo_code_selector',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview_card',
    journey_created_from_genai: true,
    journey_review_step_name: 'review_and_edit',
  },
  {
    journey_id: 0, // needs to default to number
    pbj_type: '',
    cjb_workflow_status: '',
  },
);

export const SelectPromoCodeAIPBJEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'customer-journey/builder',
    object: 'automations',
    object_detail: 'pbj_review_flow',
    action: 'engaged',

    ui_object: 'dropdown_item',
    ui_object_detail: 'promo_code',
    ui_action: 'clicked',
    ui_access_point: 'journey_preview_card',
    journey_created_from_genai: true,
    journey_review_step_name: 'review_and_edit',
  },
  {
    journey_id: 0, // needs to default to number
    pbj_type: '',
    cjb_workflow_status: '',
    has_code_expiration: '',
  },
);
