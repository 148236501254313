import { createEcsEvent } from '@mc/mc-ecs-storage/configuration/createEvent';

const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'brand_management',
  scope_area: 'brand_engine',
  object: 'campaign',
  object_detail: 'creative_assistant_brand_kit',
};

export const ViewBrandKitColorsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'email/editor',
    object: 'campaign',
    action: 'viewed',
    ui_object: 'component',
    ui_object_detail: 'brand_kit_colors',
    ui_action: 'loaded',
    ui_access_point: 'center',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
  },
);

export const AddBrandKitColorsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'email/editor',
    object: 'campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'add_color',
    ui_action: 'clicked',
    ui_access_point: 'color_card',
    brand_kit_color_type: 'primary',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    brand_kit_color_type: '',
  },
);

export const DeleteBrandKitColorsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'email/editor',
    object: 'campaign',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'delete',
    ui_action: 'clicked',
    ui_access_point: 'color_card',
    brand_kit_color_type: 'primary',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    brand_kit_color_type: '',
  },
);

export const SaveBrandKitColorsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'email/editor',
    object: 'campaign',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'save_changes',
    ui_action: 'clicked',
    ui_access_point: 'header',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
  },
);

export const EditBrandKitColorsEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    screen: 'email/editor',
    object: 'campaign',
    action: 'engaged',
    ui_object: 'icon',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'color_card',
    brand_kit_color_type: 'primary',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
    screen: '',
    brand_kit_color_type: '',
  },
);

export const EditBtnBrandKitColorsEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'email_editor',
    object: 'campaign',
    object_detail: 'color_selection',
    screen: '/email/editor',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'edit',
    ui_action: 'clicked',
    ui_access_point: 'brand_kit_colors_side_panel',
  },
  {
    campaign_id: 0,
    template_id: 0,
    user_template_id: 0,
  },
);
