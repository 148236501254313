import { createEcsEvent } from '../../configuration/createEvent';
import { DEFAULT_EMAIL_EVENT_PROPERTIES } from './constants';

const DEFAULT_TEMPLATE_LIBRARY_FILTER_EVENT_PROPERTIES = {
  ...DEFAULT_EMAIL_EVENT_PROPERTIES,
  ui_access_point: 'filterContainerTop',
  object_detail: 'templates_filter',
  scope_area: 'templates',
};

const CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES = {
  screen: '',
};

export const EmailTemplateLibraryViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    ui_access_point: 'pageContainer_templateSelection',
    object_detail: 'email_template_selection',
    action: 'viewed',
    scope_area: 'templates',
    ui_object: '',
    ui_action: '',
    ui_object_detail: '',
  },
  {
    ...CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES,
    campaign_id: '',
  },
);

export const TemplateLibraryViewedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'email_template_selection',
    action: 'viewed',
    ui_access_point: 'pageContainer_templateSelection',
    ui_object: '',
    ui_action: '',
    ui_object_detail: '',
  },
  {
    ...CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES,
    template_id: '',
  },
);

export const TemplateFilterEngagedDropDownEvent = createEcsEvent(
  {
    ...DEFAULT_TEMPLATE_LIBRARY_FILTER_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'drop_down',
    ui_action: 'clicked',
  },
  {
    ...CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES,
    ui_object_detail: '',
  },
);

export const TemplateFilterEngagedDropDownItemEvent = createEcsEvent(
  {
    ...DEFAULT_TEMPLATE_LIBRARY_FILTER_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'drop_down_item',
  },
  {
    ...CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES,
    ui_object_detail: '',
    ui_action: '',
    template_filter_value: '',
  },
);

export const TemplateFilterClearAllEvent = createEcsEvent(
  {
    ...DEFAULT_TEMPLATE_LIBRARY_FILTER_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'clear_all',
    ui_action: 'clicked',
  },
  { ...CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES },
);

export const TemplateFilterFreeToggleEvent = createEcsEvent(
  {
    ...DEFAULT_TEMPLATE_LIBRARY_FILTER_EVENT_PROPERTIES,
    action: 'engaged',
    ui_object: 'switch',
    ui_object_detail: 'free_templates_only',
    ui_action: 'toggled',
  },
  {
    ...CUSTOMIZABLE_TEMPLATE_LIBRARY_EVENT_PROPERTIES,
    has_free_template_toggle_status: '',
  },
);

export const CampaignStartFromScratchModal = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'start_from_scratch',
    screen: '/email/editor',
    scope_area: 'templates',
    ui_access_point: 'modal',
  },
  {
    action: '',
    campaign_id: 0,
    campaign_type: '',
    ui_object: '',
    ui_object_detail: '',
    ui_action: '',
    platform_type: '',
    template_id: 0,
    template_name: '',
  },
);

export const TemplateStartFromScratchModal = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    object_detail: 'start_from_scratch',
    screen: '/email/templates/editor',
    scope_area: 'templates',
    ui_access_point: 'modal',
  },
  {
    ui_object: '',
    user_template_id: 0,
    action: '',
    ui_object_detail: '',
    ui_action: '',
    platform_type: '',
    template_id: 0,
    template_name: '',
  },
);

export const TemplateOrderSelectionCampaignToggleButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'mailchimp_templates',
    ui_access_point: 'template_menu',
    scope_area: 'templates',
    ui_action: 'clicked',
    ui_object: 'button',
    screen: '/email/editor',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    ui_object_detail: '',
  },
);

export const TemplateOrderSelectionTemplateToggleButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    object_detail: 'mailchimp_templates',
    ui_access_point: 'template_menu',
    scope_area: 'templates',
    ui_action: 'clicked',
    ui_object: 'button',
    screen: '/email/templates/editor',
  },
  {
    user_template_id: 0,
    ui_object_detail: '',
  },
);

export const TemplateApplyPreviewButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    scope_area: 'templates',
    ui_action: 'clicked',
    ui_object: 'button',
    screen: '/email/templates/editor',
  },
  {
    user_template_id: 0,
    template_id: 0,
    template_name: '',
    ui_object_detail: '',
    ui_access_point: '',
    object_detail: '',
    platform_type: '',
  },
);

export const CampaignApplyPreviewButtonEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    scope_area: 'templates',
    ui_action: 'clicked',
    ui_object: 'button',
    screen: '/email/editor',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    template_id: 0,
    template_name: '',
    ui_object_detail: '',
    ui_access_point: '',
    object_detail: '',
    platform_type: '',
  },
);

export const CampaignBrandImportSuccessEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'viewed',
    scope_area: 'templates',
    screen: '/email/editor',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    object_detail: 'creative_assistant_templates_successful_brand_import',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
  },
);

export const TemplatesBrandImportSuccessEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'viewed',
    scope_area: 'templates',
    screen: '/email/templates/editor',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    object_detail: 'creative_assistant_templates_successful_brand_import',
  },
  {
    user_template_id: 0,
    platform_type: '',
  },
);

export const CampaignBrandImportFailedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'viewed',
    scope_area: 'templates',
    screen: '/email/editor',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    object_detail: 'creative_assistant_templates_failed_brand_import',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
  },
);

export const TemplatesBrandImportFailedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'viewed',
    scope_area: 'templates',
    screen: '/email/templates/editor',
    ui_action: '',
    ui_object: '',
    ui_object_detail: '',
    ui_access_point: '',
    object_detail: 'creative_assistant_templates_failed_brand_import',
  },
  {
    user_template_id: 0,
    platform_type: '',
  },
);

export const CampaignBrandImportClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    scope_area: 'templates',
    screen: '/email/editor',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'close_import_brand',
    ui_access_point: 'custom_email_tile',
    object_detail: 'creative_assistant_templates',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
  },
);

// Visual Home Page #mission-team-ecomm-ftu-public
// https://docs.google.com/spreadsheets/d/1aSYCReUWcGIMnbr2Je5G6DKOUlD8exv4niBkkJFpsFc/edit?gid=935568934#gid=935568934
export const VisualHomePageCreateCreativeAssistantEmailEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'created',
    scope_area: 'templates',
    screen: '/',
    ui_action: 'clicked',
    ui_object: 'card',
    ui_object_detail: 'custom_email_designs',
    ui_access_point: 'center',
    object_detail: 'creative_assistant_templates',
  },
  {},
);

export const VisualHomePageCreateEmailTemplateEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'created',
    scope_area: 'templates',
    screen: '/',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'apply',
    ui_access_point: 'template_card_overlay',
    object_detail: 'mailchimp_templates',
  },
  {
    template_id: 0,
  },
);

export const VisualHomePageClickedSeeAllTemplatesEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'created',
    scope_area: 'templates',
    screen: '/',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'see_all_email_templates',
    ui_access_point: 'center',
    object_detail: 'mailchimp_templates',
  },
  {},
);

export const TemplatesBrandImportClosedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    scope_area: 'templates',
    screen: '/email/templates/editor',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'close_import_brand',
    ui_access_point: 'custom_email_tile',
    object_detail: 'creative_assistant_templates',
  },
  {
    user_template_id: 0,
    platform_type: '',
  },
);

export const CampaignBrandImportClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    scope_area: 'templates',
    screen: '/email/editor',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'import_my_brand',
    ui_access_point: 'custom_email_tile',
    object_detail: 'creative_assistant_templates',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
  },
);

export const TemplatesBrandImportClickedEvent = createEcsEvent(
  {
    ...DEFAULT_EMAIL_EVENT_PROPERTIES,
    action: 'engaged',
    scope_area: 'templates',
    screen: '/email/templates/editor',
    ui_action: 'clicked',
    ui_object: 'button',
    ui_object_detail: 'import_my_brand',
    ui_access_point: 'custom_email_tile',
    object_detail: 'creative_assistant_templates',
  },
  {
    user_template_id: 0,
    platform_type: '',
  },
);

export const createNeaCampaignInNewBuilderClickEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/template',
    object: 'campaign',
    object_detail: 'email_template_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_email_in_new_builder',
    ui_action: 'clicked',
    ui_access_point: 'banner',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
  },
);

export const createNeaCampaignInNewBuilderHoverEvent = createEcsEvent(
  {
    purpose: 'prod',
    initiative_name: 'bulk_email',
    scope_area: 'email_editor',
    screen: 'campaigns/wizard/template',
    object: 'campaign',
    object_detail: 'email_template_selection',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'create_email_in_new_builder',
    ui_action: 'hovered',
    ui_access_point: 'banner',
  },
  {
    campaign_id: 0,
    campaign_type: '',
    platform_type: '',
  },
);
