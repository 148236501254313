import { createEcsEvent } from '../../../configuration/createEvent';

const EMAIL_CHECKLIST_PROPERTIES = {
  purpose: 'prod',

  initiative_name: 'bulk_email',
  scope_area: 'bulk_campaigns',
  screen: 'campaigns/edit',

  object: 'campaign',
  object_detail: 'campaign_subject',
  action: 'engaged',
};

export const TrackBeginUpdateSubjectEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,

    ui_object: 'button',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    ui_object_detail: '',
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: '',
    ai_email_type: '',
    ai_button_exists: '',
    has_replicated_email: false,
    has_ai_post_trial_reminder: false,
  },
);

export const TrackSaveSubjectUpdateEvent = createEcsEvent(
  {
    ...EMAIL_CHECKLIST_PROPERTIES,

    ui_object: 'button',
    ui_object_detail: 'save',
    ui_action: 'clicked',
    ui_access_point: 'main_content_pane',
  },
  {
    campaign_id: '',
    checklist_step_state: '',
    editor_type: '',
    access_to_ecu_content_gen_beta: '',
    ai_email_type: '',
    has_replicated_email: false,
  },
);
