import {
  ECS_EVENT_BUILDER_SCREEN,
  ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL,
  ECS_EVENT_ACTION_PROPERTY_ENGAGED,
  ECS_EVENT_UI_ACTION_CLICKED,
  ECS_EVENT_UI_OBJECT_LINK,
  ECS_EVENT_OBJECT_AUTOMATIONS,
} from '@mc/constants/automation';
import { createEcsEvent } from '../../configuration/createEvent';

// These properties are constant throughout all CJB SMSEvents events
const DEFAULT_EVENT_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'core_offerings',
  scope_area: 'customer_journey_builder',
  screen: ECS_EVENT_BUILDER_SCREEN,
  object: ECS_EVENT_OBJECT_AUTOMATIONS,
  object_detail: ECS_EVENT_JOURNEY_MAP_OBJECT_DETAIL,
};

export const SMSClickedApplyEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
    ui_object: ECS_EVENT_UI_OBJECT_LINK,
    ui_action: ECS_EVENT_UI_ACTION_CLICKED,
  },
  {
    journey_id: 0,
    step_id: 0,
    ui_object_detail: '',
    ui_access_point: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const SMSClickedLearnMoreEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
    ui_object: ECS_EVENT_UI_OBJECT_LINK,
    ui_action: ECS_EVENT_UI_ACTION_CLICKED,
  },
  {
    journey_id: 0,
    step_id: 0,
    ui_object_detail: '',
    ui_access_point: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);

export const SMSClickedApplyNowEvent = createEcsEvent(
  {
    ...DEFAULT_EVENT_PROPERTIES,
    action: ECS_EVENT_ACTION_PROPERTY_ENGAGED,
    ui_object: ECS_EVENT_UI_OBJECT_LINK,
    ui_action: ECS_EVENT_UI_ACTION_CLICKED,
  },
  {
    journey_id: 0,
    ui_object_detail: '',
    ui_access_point: '',
    created_from_source: '',
    is_replicated_from_journey: false,
  },
);
