import { createEcsEvent } from '../../configuration/createEvent';

const BASE_PROPERTIES = {
  purpose: 'prod',
  initiative_name: 'human_assistance',
  scope_area: 'support',
  object: 'help_support',
  object_detail: 'live_help',
  ui_object: 'button',
  ui_object_detail: 'live_expert_help',
  ui_access_point: 'header',
};

export const LiveExpertHelpButtonClickedEvent = createEcsEvent(
  {
    ...BASE_PROPERTIES,
    action: 'selected',
    ui_action: 'clicked',
    live_help_content: 'Live expert help',
  },
  {
    screen: '',
    button_placement: '',
  },
);
