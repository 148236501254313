import { createEcsEvent } from '../../../configuration/createEvent';
import { DEFAULT_EVENT_PROPERTIES } from '../OnboardingConstants';

/**
 * "This event fires when a user arrives on the Business Objective step"
 */
export const BusinessObjectiveViewedEvent = createEcsEvent(
  /**
   * This param contains the BusinessObjectiveViewedEvent the event will contain.
   * These properties can never be changed inside of the BusinessObjectiveViewedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_objective',
    action: 'viewed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "Answer 'What's your top goal with Mailchimp?', then click the 'next' and successfully passes validation"
 */
export const BusinessObjectiveNextEvent = createEcsEvent(
  /**
   * This param contains the BusinessObjectiveNextEvent the event will contain.
   * These properties can never be changed inside of the BusinessObjectiveNextEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_objective',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'next',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    business_objective: '',
    entry_point: '',
  },
);

/**
 * "Answer 'What's your top goal with Mailchimp?', then click the 'skip' CTA, and successfully passes validation"
 */
export const BusinessObjectiveEngagedSkipEvent = createEcsEvent(
  /**
   * This param contains the BusinessObjectiveEngagedSkipEvent the event will contain.
   * These properties can never be changed inside of the BusinessObjectiveEngagedSkipEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_objective',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'skip',
    ui_action: 'clicked',
    ui_access_point: 'center',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    entry_point: '',
  },
);

/**
 * "This event fires when a user goes back from the Business Objective page"
 */
export const BusinessObjectiveEngagedBackEvent = createEcsEvent(
  /**
   * This param contains the BusinessObjectiveEngagedBackEvent the event will contain.
   * These properties can never be changed inside of the BusinessObjectiveEngagedBackEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_objective',
    action: 'engaged',
    ui_object: 'button',
    ui_object_detail: 'back_arrow',
    ui_action: 'clicked',
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    current_step: '',
    previous_step: '',
    entry_point: '',
    ui_access_point: 'header',
  },
);

/**
 * "This event fires when a user clicks the next CTA, and there's an error"
 */
export const BusinessObjectiveFailedEvent = createEcsEvent(
  /**
   * This param contains the BusinessObjectiveFailedEvent the event will contain.
   * These properties can never be changed inside of the BusinessObjectiveFailedEvent
   */
  {
    ...DEFAULT_EVENT_PROPERTIES,
    object_detail: 'business_objective',
    action: 'failed',
    ui_object: "'",
    ui_object_detail: "'",
    ui_action: "'",
    ui_access_point: "'",
  },
  /**
   * This param contains the ConfigurableProperties that are mutable
   * You can modify these properties as you would any other property
   * on an object.
   */
  {
    error: '',
    entry_point: '',
  },
);
